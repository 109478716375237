import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import CustomizedSelect from "./customizedSelect";
import {
  getNewPurchaseFundList,
  getFolioSipCancellation,
  getCancellationReason,
} from "shared/store/actions/investors";
import { connect } from "react-redux";
import {
  GET_FUNDS_LIST,
  GET_FUNDS_LIST_FAILED,
  GET_FUNDS_LIST_SUCCESS,
} from "shared/store/types/investors";
import { DetailsGroup } from "./newPurchaseConfirm";
import { BUILD_URL } from "../home";
import { SipCancelModal } from "../customModal";
import { SundaramMutualFund } from "./newPurchaseForm";

class SipCancellation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sipTypes: [
        { label: "SIP", value: "SIP" },
        { label: "STP", value: "STP" },
        { label: "SWP", value: "SWP" },
      ],
      funds: [],
      folios: [],
      fund: { value: "" },
      sipType: { value: "" },
      cancelDetails: {},
      checked: "",
      ShowMore: "",
      visible: "",
      rejectionReasons:[],
      rejectionreasonsOpen:false,
      othersText:{value:""},
      selectedReason:"",
      reasonMandatory:false,

    };
  }
  async componentDidMount(){
    const response = await this.props.getCancellationReason({txntype:"SIP"})
    this.setState({rejectionReasons:response.DtData})
   } 

  handleSipTypeChange = async (event) => {
    this.setState({
      sipType: { value: event.target.value },
      folios: [],
      funds: [],
      fund: { value: "105" },
    });
    const { investorData } = this.props;
    const body = {
      Trtype:event.target.value,
      Pan: investorData.PAN,
      Fund: "105",
      invdistflag: "P",
    };

    const response = await this.props.getFolioSipCancellation(body);
    if (!!response && !!response.DtData && response.DtData.length > 0) {
      this.setState({
        folios: response.DtData,
        fund: { value: "105" },
      });
    }else {
      alert("No Active Registrations")
    }

  };

  handleFundChange = async (event) => {
    const { sipType } = this.state;
    const { investorData } = this.props;
    this.setState({
      fund: { value: event.target.value },
    });

    const body = {
      Trtype: sipType.value,
      Pan: investorData.PAN,
      Fund: "105",
      invdistflag: "P",
    };
    const response = await this.props.getFolioSipCancellation(body);

    if (!!response && !!response.DtData && response.DtData.length > 0) {
      this.setState({
        folios: response.DtData,
        fund: { value: event.target.value },
      });
    } else if (!response) {
      alert("No data found  for the following pan number");
      // this.props.history.push(`${process.env.PUBLIC_URL}/investors`);
    }
  };

  handleChangeCheckbox = (value) => () => {
    this.setState({
      checked: value,
    });
  };
  onSubmit = () => {
    const { checked, folios, fund, sipType } = this.state;

    const Foliodata = !!folios && folios.find((ele) => ele.ihno == checked);
    if (!!Foliodata) {
      this.props.history.push({
        pathname: `${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation/confirm`,
        state: {
          folioDetails: Foliodata,
          type: "Sip cancellation",
          fundName: "Sundaram Mutual Fund",
          sipType: sipType.value,
        },
      });
    }
  };

  ShowDetails = (label) => () => {
    if (label === this.state.ShowMore) {
      this.setState({
        ShowMore: "",
      });
    } else {
      this.setState({
        ShowMore: label,
      });
    }
  };

  pause = (ele) => () => {
    if (ele.pause_flag === "Y" && ele.pause_err_msg === "") {
      this.props.history.push({
        pathname: `${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation/confirm`,
        state: {
          type: "Sip Pause",
          pauseDetails: ele,
          scheme: { Fund: "105" },
          fundName: "SUNDARAM MUTUAL FUND",
          sipType: this.state.sipType.value,
        },
      });
    } else if (!!ele.pause_err_msg) {
      alert(`${ele.pause_err_msg}`);
    }
  };

  closeYesModal = () => {
    this.setState({
      open: false,
      rejectionreasonsOpen:false,
      message: "",
      notAllowedPopUp: false,
      othersText:{value:""},
      selectedReason:""
    });
  };


  closeNoModal = () => {
    this.setState({
      open:false,
      rejectionreasonsOpen:true,
    })
  };

  closemodelafterrejecttion=()=>{
    if(!this.state.selectedReason || (this.state.selectedReason.includes("Others") && !this.state.othersText.value)){
      this.setState({reasonMandatory:true})
      return;
    }
    if(this.state.selectedReason.includes("Others") && !this.state.othersText.value){
      this.setState({
        othersText: {
          value:"",
          isError: true,
          errorMessage: "Reason is mandatory",
        },
      });
      return;
    }
    if (this.state.selectedReason.includes("Others") && this.state.othersText.value.length < 10) {
      this.setState({
        othersText: {
          value:"",
          isError: true,
          errorMessage: "Please enter at least 10 characters",
        },
      });
      return;
    }
    if(this.state.selectedReason.includes("Others") && this.state.othersText.value.length > 100) {
      this.setState({
        othersText: {
          value: "",
          isError: true,
          errorMessage: "Please enter at most 100 characters",
        },
      });
      return;
    }
     const { cancelDetails } = this.state;
      this.props.history.push({
        pathname: `${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation/confirm`,
        state: {
          type: "Sip cancellation",
          pauseDetails: cancelDetails,
          scheme: { Fund: "105" },
          fundName: "SUNDARAM MUTUAL FUND",
          sipType: this.state.sipType.value,
          cancellationReason:this.state.selectedReason.includes("Others")?this.state.othersText.value:this.state.selectedReason,
          reasons:this.state.rejectionReasons
        },
      });
  };

  cancel = (ele) => () => {
    if (
      ele.pause_flag === "Y" &&
      ele.pause_err_msg === "" &&
      this.state.sipType.value === "SIP"
    ) {
      this.setState({
        open: true,
        cancelDetails: ele,
        message: "Are you sure you want to Cancel? Instead Choose Pause",
      });
    } else {
      this.props.history.push({
        pathname: `${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation/confirm`,
        state: {
          type: "Sip cancellation",
          pauseDetails: ele,
          scheme: { Fund: "105" },
          fundName: "SUNDARAM MUTUAL FUND",
          sipType: this.state.sipType.value,
        },
      });
    }
  };
  handleReasonChange = (e)=>{
    this.setState({selectedReason:e.target.value,reasonMandatory:false,othersText:{value:""}})
  }
  selectedotherreason = (e) => {
    const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
    if (inputValue.length <= 100) {
      this.setState({ othersText: { value: inputValue } });
    } else if (inputValue.length < 10) {
      this.setState({
        othersText: {
          value: inputValue,
          isError: true,
          errorMessage: "Please enter at least 10 characters",
        },
      });
      return;
    } else {
      this.setState({
        othersText: {
          value: inputValue.slice(0, 100),
          isError: true,
          errorMessage: "Maximum character length is 100",
        },
      });
    }
  }


  render() {
    const { sipTypes, sipType, folios } = this.state;
    return (
      <div className="transaction-details">
        <div className="title">Pause/ Cancel Module</div>
        <div className="input-margin">
          <CustomizedSelect
            placeholder="Select Mode"
            name="Select Mode"
            bordercolor="rgba(0, 0, 0, 0.5)"
            handleChange={this.handleSipTypeChange}
            value={sipType.value}
            // inputRef={ele => (this.sipTypeField = ele)}
            // isError={sipType.isError}
            // errorMessage={sipType.errorMessage}
          >
            {sipTypes.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>


        {folios.map((ele, index) => (
          <div
            style={{
              boxShadow: "0px 0px 4px 0px lightgray",
              borderRadius: 7,
              margin: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,

                justifyContent: "space-between",
                borderBottom: "1px solid lightgray",
                background: "white",
                padding: "15px 20px",
              }}
            >
              <div
                style={{
                  fontFamily: "SourceSansPro-Bold",
                  fontSize: 14,
                  color: "#5f5f6f",
                }}
              >
                {this.state.sipType.value} - {index + 1}
              </div>
              <div style={{ display: "flex" }}>
                {ele.pause_flag !== "N" && this.state.sipType.value === "SIP" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "5px 8px",
                      background: "#ebf9f4",
                      border: "0.5px solid #b7e8d8",
                      borderRadius: 5,
                    }}
                    onClick={this.pause(ele)}
                  >
                    <img
                      src={BUILD_URL + "/images/pause_icon.svg"}
                      style={{ height: 15, width: 15, marginRight: 5 }}
                    />
                    <div
                      style={{
                        fontFamily: "SourceSansPro-SemiBold",
                        fontSize: 13,
                        color: "#4bc89d",
                      }}
                    >
                      Pause
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 10,
                    padding: "5px 8px",
                    background: "#fcf1f0",
                    border: "0.5px solid #e86a5e",
                    borderRadius: 5,
                  }}
                  onClick={this.cancel(ele)}
                >
                  <img
                    src={BUILD_URL + "/images/cancel_icon.svg"}
                    style={{ height: 15, width: 15, marginRight: 5 }}
                  />
                  <div
                    style={{
                      fontFamily: "SourceSansPro-SemiBold",
                      fontSize: 13,
                      color: "#e45949",
                    }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <DetailsGroup
                reverse={true}
                mainLabel="Scheme Name"
                subLabel={ele.Scheme}
                mainLabel1="Installment Amount (INR)"
                subLabel2={ele.amt.toFixed(2)}
              />

              {this.state.ShowMore === ele.ihno && (
                <div>
                  <DetailsGroup
                    mainLabel="Folio"
                    subLabel={ele.acno}
                    mainLabel1="Registration Date"
                    subLabel2={ele.trdate}
                  />
                  <DetailsGroup
                    mainLabel="From"
                    subLabel={ele.fromdate}
                    mainLabel1="To"
                    subLabel2={ele.todate}
                  />
                  <DetailsGroup
                    mainLabel="Frequency"
                    subLabel={ele.freq}
                    // mainLabel1="Installment Amount (INR)"
                    // subLabel2={ele.amt}
                  />
                </div>
              )}
            </div>

            <div
              style={{
                textAlign: "center",
                padding: "2px 0px",
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 12,
              }}
              onClick={this.ShowDetails(ele.ihno)}
            >
              {this.state.ShowMore === ele.ihno
                ? "Hide Details"
                : "View Details"}
            </div>
          </div>
        ))}

<SipCancelModal
  {...this.state}
  closeNoModal={this.closeNoModal}
  closeYesModal={this.closeYesModal}
  closemodelafterrejecttion={this.closemodelafterrejecttion}
  handleReasonChange={this.handleReasonChange}
  selectedotherreason={this.selectedotherreason}
/>

      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { token, userId, investorData } = authReducer;

    return {
      token,
      userId,
      investorData,
    };
  },
 
  { getNewPurchaseFundList, getFolioSipCancellation,  getCancellationReason}
)(SipCancellation);
