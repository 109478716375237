import _slicedToArray from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { GET_INVESTORS_LIST_SUCCESS, YES, GET_INVESTOR_DETAILS_SUCCESS, PANALL, FAVOURITE_INVESTOR_SUCCESS, GET_INVESTORS_LIST, SEARCH_INVESTORS_SUCCESS, REMOVE_SEARCH_INVESTOR_LIST, SEARCH_INVESTORS, GET_INVESTOR_DETAILS, GET_UPCOMING_SIP_DETAILS_SUCCESS, ALL, STORE_TYPE } from "../types";
var initialState = {
  investors: [],
  count: 0,
  searchList: [],
  favList: [],
  investments: [],
  investorDetailedSchemes: [],
  upComingSipDetails: [],
  selectedTab: ALL
};
export function investor() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_INVESTORS_LIST_SUCCESS:
      {
        var DtData = action.body.DtData;
        DtData = DtData.map(function (investor) {
          return _objectSpread({}, investor, {
            Kyc: investor.Kyc === YES,
            Fatca: investor.Fatca === YES
          });
        });
        return _objectSpread({}, state, {
          investors: DtData,
          count: DtData.length
        });
      }
    // case SEARCH_INVESTORS:
    // // case GET_INVESTORS_LIST: {
    // //   return { ...state, investors: [] };
    // // }

    case GET_INVESTOR_DETAILS:
      {
        return _objectSpread({}, state, {
          investorData: {},
          showAll: false,
          fundAllocation: [],
          pieData: [],
          investments: [],
          communicationDetails: [],
          investorInvestmentDetails: [],
          fundLevelInvestments: []
        });
      }

    case GET_INVESTOR_DETAILS_SUCCESS:
      {
        var _action$body = action.body,
            communicationDetails = _action$body.Table2,
            Table3 = _action$body.Table3,
            investorInvestmentDetails = _action$body.Table5,
            Table11 = _action$body.Table11,
            fundLevelInvestments = _action$body.Table12,
            investments = _action$body.Table13,
            investorDetailedSchemes = _action$body.Table14;
        var asOnDate = action.body.Dtinformation[0].asondate;
        var _state$investors = state.investors,
            investors = _state$investors === void 0 ? [] : _state$investors;

        var _Table = _slicedToArray(Table11, 1),
            investorData = _Table[0];

        var fav = investors.find(function (inv) {
          return inv.Pan === investorData.PAN;
        }) || {};
        var fundAllocation = Table3.filter(function (fund) {
          return fund.PAN === PANALL;
        });
        var pieData = fundAllocation.map(function (item) {
          return {
            name: item.name,
            value: item.totpercent
          };
        });
        var investorTotal = investorInvestmentDetails.find(function (x) {
          return x.FundDesc === "Total" && x.PAN === "ALL";
        });
        return _objectSpread({}, state, {
          investorData: _objectSpread({}, investorData, {
            fav: fav.Favorite
          }),
          fundAllocation: fundAllocation,
          investorDetailedSchemes: investorDetailedSchemes,
          pieData: pieData,
          investorTotal: investorTotal,
          asOnDate: asOnDate,
          investments: investments,
          communicationDetails: communicationDetails,
          fundLevelInvestments: fundLevelInvestments
        });
      }

    case FAVOURITE_INVESTOR_SUCCESS:
      {
        return _objectSpread({}, state, {
          favList: action.data
        });
      }

    case REMOVE_SEARCH_INVESTOR_LIST:
      {
        return _objectSpread({}, state, {
          searchList: []
        });
      }

    case SEARCH_INVESTORS_SUCCESS:
      {
        var Dtdata = action.body.Dtdata;
        return _objectSpread({}, state, {
          investors: Dtdata,
          count: Dtdata.length
        });
      }

    case SEARCH_INVESTORS:
      {
        return {
          investors: [],
          count: 0
        };
      }

    case GET_UPCOMING_SIP_DETAILS_SUCCESS:
      {
        var _DtData = action.body.DtData;
        return _objectSpread({}, state, {
          upComingSipDetails: _DtData
        });
      }

    case STORE_TYPE:
      {
        var selectedTab = action.body.selectedTab;
        return _objectSpread({}, state, {
          selectedTab: selectedTab
        });
      }

    default:
      return state;
  }
}