import React, { Component } from "react";
import Appbar from "./appbar";
import DemoCarousel from "../auth/carsouel";
import Footer from "./footer";

export default class CommonTemplate extends Component {
  render() {
    const { title, textOne, textTwo } = this.props;
    return (
      <div className="login-container">
        <Appbar />
        <Footer />
        <div className="login-without-appbar">
          <DemoCarousel />
          <div className="login-right-div" style={{ overflowY: "scroll" }}>
            <div className="login-title">{title}</div>
            <div className="otp-text" style={{marginBottom:20}}>{textOne}</div>
            <div className="otp-text">{textTwo}</div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
