import React, { Component } from "react";
import TabHeader from "../../forms/tabHeader";
import {
  INACTIVE_INVESTORS,
  NEW_INVESTORS,
  ACTIVE_INVESTORS
} from "shared/store/types";

export default class ListOfInvestors extends Component {
  render() {
    const { active } = this.props;
    return (
      <div
        className="tab-container"
        style={{
          justifyContent: "space-between",
          margin: "25px 20px 15px 20px"
        }}
      >
        <TabHeader
          value="New Investors"
          active={active === NEW_INVESTORS}
          onClick={this.props.onClick(NEW_INVESTORS)}
        />
         <TabHeader
          value="Active Investors"
          active={active === ACTIVE_INVESTORS}
          onClick={this.props.onClick(ACTIVE_INVESTORS)}
        />
        <TabHeader
          value="Inactive Investors"
          active={active === INACTIVE_INVESTORS}
          onClick={this.props.onClick(INACTIVE_INVESTORS)}
        />
      </div>
    );
  }
}
