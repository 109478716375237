import React, { Component } from "react";
import CommonBreadCrumb from "../breadCrumbs";
import InvestorRouter from "./InvestorRoutes";

export const INVESTOR_URL = `${process.env.PUBLIC_URL}/investors`;

const breadcrumbNameMap = {
  "/investors": { title: "Investors", to: INVESTOR_URL },


  "/investors/details": {
    title: "Investor Details",
    to: INVESTOR_URL + "/details",
  },

  "/investors/new-investor/ekyc": {
    title: "EKYC",
    to: INVESTOR_URL + "/new-investor/ekyc",
  },
  "/investors/new-investor": {
    title: "New Investor",
    to: INVESTOR_URL,
  },
  "/investors/transction-types": {
    title: "Transaction Types",
    to: INVESTOR_URL + "/transction-types",
  },
  // "investors/details/:PAN": {
  //   title:"Investor Details",
  //   to: INVESTOR_URL + "/investors/details/:PAN",
  // },



  // NEW PURCHASE ROUTES

  "/investors/transction-types/nfo-new-purchase": {
    title: "New Purchase",
    to: INVESTOR_URL + "/transction-types/nfo-new-purchase",
  },

  "/investors/transction-types/nfo-new-purchase/confirm": {
    title: "Confirm",
    to: INVESTOR_URL + "/investors/transction-types/nfo-new-purchase/confirm",
  },


  // additional Purchase routes

  "/investors/transction-types/additional-purchase": {
    title: "Additonal Purchase",
    to: INVESTOR_URL + "/transction-types/additional-purchase",
  },

  "/investors/transction-types/additional-purchase/confirm": {
    title: "Confirm",
    to: INVESTOR_URL + "/investors/transction-types/additional-purchase/confirm",
  },

  // Redemption Routes



  "/investors/transction-types/redemption": {
    title: "Redemption",
    to: INVESTOR_URL + "/transction-types/redemption",
  },

  "/investors/transction-types/redemption/confirm": {
    title: "Confirm",
    to: INVESTOR_URL + "/investors/transction-types/redemption/confirm",
  },


 // SIP Routes

 "/investors/transction-types/sip": {
  title: "SIP",
  to: INVESTOR_URL + "/transction-types/sip",
},

"/investors/transction-types/sip/confirm": {
  title: "Confirm",
  to: INVESTOR_URL + "/investors/transction-types/sip/confirm",
},

// STP Routes

"/investors/transction-types/stp": {
  title: "STP",
  to: INVESTOR_URL + "/transction-types/stp",
},

"/investors/transction-types/stp/confirm": {
  title: "Confirm",
  to: INVESTOR_URL + "/investors/transction-types/stp/confirm",
},

// SWP Routes

"/investors/transction-types/swp": {
  title: "SWP",
  to: INVESTOR_URL + "/transction-types/swp",
},

"/investors/transction-types/swp/confirm": {
  title: "Confirm",
  to: INVESTOR_URL + "/investors/transction-types/swp/confirm",
},

// SWITCH Routes

"/investors/transction-types/switch": {
  title: "SWITCH",
  to: INVESTOR_URL + "/transction-types/switch",
},

"/investors/transction-types/switch/confirm": {
  title: "Confirm",
  to: INVESTOR_URL + "/investors/transction-types/swp/confirm",
},

// SIP Pause/ Cancellation Routes



"/investors/transction-types/sip-cancellation": {
  title: "SIP Pause/Cancellation",
  to: INVESTOR_URL + "/transction-types/sip-cancellation",
},

"/investors/transction-types/sip-cancellation/confirm": {
  title: "Confirm",
  to: INVESTOR_URL + "/investors/transction-types/sip-cancellation/confirm",
},


// end of routes
  "/investors/sip-cancellation/digital/transact/confirm": {
    title: "Confirm",
    to: INVESTOR_URL + "/sip-cancellation/digital/transact/confirm",
  },
  "/investors/nct-type": {
    title: "NCT",
    to: INVESTOR_URL + "/transction-types",
  },
  "/investors/nct-type/transaction-modes": {
    title: "Mode of Transaction",
    to: INVESTOR_URL + "/transction-types",
  },
  "/investors/nct-type/digital": {
    title: "Digital Mode",
    to: INVESTOR_URL + "/transction-types",
  },
  "/investors/nct-type/digital/transact": {
    title: "Transact",
    to: INVESTOR_URL + "/nct-type/digital/transact",
  },
  "/investors/nct-type/digital/transact/confirm": {
    title: "Confirm",
    to: INVESTOR_URL + "/nct-type/digital/transact/confirm",
  },

  "/investors/eNACH": {
    title: "eNACH",
    to: INVESTOR_URL + "/transction-types",
  },
  "/investors/eNACH/transaction-modes": {
    title: "Mode of Transaction",
    to: INVESTOR_URL + "/transction-types",
  },
  "/investors/eNACH/digital": {
    title: "Digital Mode",
    to: INVESTOR_URL + "/transction-types",
  },
  "/investors/eNACH/digital/transact": {
    title: "Transact",
    to: INVESTOR_URL + "/eNACH/digital/transact",
  },
  "/investors/eNACH/digital/transact/confirm": {
    title: "Confirm",
    to: INVESTOR_URL + "/eNACH/digital/transact/confirm",
  },
};

export default class InvestorComponent extends Component {
  render() {
    const { location } = this.props;
    const pathnames = location.pathname.includes("/ekyc") || location.pathname.includes("/investors/details")
      ? location.pathname
          .split("/")
          .filter((name) => name)
          .slice(0, 2)
      : location.pathname.split("/").filter((name) => name);
    return (
      <div>
        <CommonBreadCrumb
          hideFirst={true}
          pathnames={pathnames}
          breadcrumbNameMap={breadcrumbNameMap}
        />
        <InvestorRouter />
      </div>
    );
  }
}
