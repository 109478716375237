import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Investors from "./InvestorMain";
import InvestorDetails from "./InvestorDetails";
import NFOAndNewPurchaseDigital from "../transactions/nfoAndNewPurchase";
import TransactionMode from "../transactions/TransactionModes";
import TransactionType from "../transactions/TransactionTypes";
import EKYC from "../transactions/ekycForm";

export default class InvestorRouter extends Component {
  render() {
    const { history, location } = this.props;
    return (
      <Switch location={location} history={history}>
        <Route
          path={`${process.env.PUBLIC_URL}/investors`}
          exact
          component={Investors}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/details/:PAN`}
          component={InvestorDetails}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/new-investor/ekyc/:pan`}
          component={EKYC}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/transction-types`}
          component={TransactionType}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/nfo-new-purchase/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/nfo-new-purchase`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/additional-purchase/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/additional-purchase`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/redemption/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/redemption`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/switch/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/switch`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/sip/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/sip`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/swp/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/swp`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/stp/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/stp`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/sip-cancellation/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/nct-type/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/nct-type/digital`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/investors/eNACH/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors/eNACH/digital`}
          component={NFOAndNewPurchaseDigital}
        />
      </Switch>
    );
  }
}
