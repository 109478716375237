import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { getInvestorDetails, checkKyc } from "shared/store/actions";
import { REQ_BY } from "shared/store/types";
import { INVESTOR_URL } from "./investors";
import { NEW_TRANSACTION_URL } from "./transactions";
import { MAILBACK_URL } from "./mailBackRequest";
import { HISTORY_URL } from "./transactionHistory";
import { BUILD_URL } from "./home";
import CustomLoading from "../home/CustomLoader";
import CustomLoader from "../forms/customLoader";

class TableData extends Component {
  constructor(props) {
    super(props);
  }

  goToTransaction = (PAN, name, mailBackName) => async () => {
    const response = await this.getInvestorDetails(PAN);

    if (response) {
      this.props.history.push({
        pathname: `${process.env.PUBLIC_URL}/investors` + "/transction-types",
      });
    } else {
      const kycResponse = await this.getKycDetails(PAN);
      if (kycResponse) {
        const {
          DtData: [info],
        } = kycResponse;
        if (info.KYCBlock === "N") {
          this.props.history.push({
            pathname:
              `${process.env.PUBLIC_URL}/investors` + "/transction-types",
          });
        }
      }
    }
  };

  getInvestorDetails = async (pan) => {
    const params = { PAN: pan, Uid: this.props.userId, ReqBy: REQ_BY };
    const response = await this.props.getInvestorDetails(params);
    return response;
  };

  getKycDetails = async (pan) => {
    const params = {
      i_pan: pan,
      fund: "105",
      userid: this.props.userId,
    };
    const kycResponse = await this.props.checkKyc(params);
    return kycResponse;
  };

  goToMailBack = (PAN, mailBackName) => async () => {
    const response = await this.getInvestorDetails(PAN);
    if (response) {
      if (mailBackName === "mailBackSoa") {
        this.props.history.push({
          pathname: MAILBACK_URL + "/investor/investor-selection/mail-back-soa",
        });
      } else if (mailBackName === "ELSS") {
        this.props.history.push({
          pathname: MAILBACK_URL + "/investor/investor-selection/ELSS",
        });
      } else if (mailBackName === "CAN") {
        this.props.history.push({
          pathname: MAILBACK_URL + "/investor/investor-selection/CAN",
        });
      } else if (mailBackName === "CGS") {
        this.props.history.push({
          pathname: MAILBACK_URL + "/investor/investor-selection/CGS",
        });
      } else if (mailBackName === "GAS") {
        this.props.history.push({
          pathname: MAILBACK_URL + "/investor/investor-selection/GAS",
        });
      } else if (mailBackName === "Networth") {
        this.props.history.push({
          pathname: MAILBACK_URL + "/investor/investor-selection/Networth",
        });
      } else {
        this.props.history.push({
          pathname:
            MAILBACK_URL +
            "/investor/investor-selection/consolidated-account-statement",
        });
      }
    } else {
      const kycResponse = await this.getKycDetails(PAN);
      const {
        DtData: [info],
      } = kycResponse;
      if (info.KYCBlock === "N") {
        if (mailBackName === "mailBackSoa") {
          this.props.history.push({
            pathname:
              MAILBACK_URL + "/investor/investor-selection/mail-back-soa",
          });
        } else if (mailBackName === "ELSS") {
          this.props.history.push({
            pathname: MAILBACK_URL + "/investor/investor-selection/ELSS",
          });
        } else if (mailBackName === "CAN") {
          this.props.history.push({
            pathname: MAILBACK_URL + "/investor/investor-selection/CAN",
          });
        } else {
          this.props.history.push({
            pathname:
              MAILBACK_URL +
              "/investor/investor-selection/consolidated-account-statement",
          });
        }
      }
    }
  };

  goToTransactionHistory = (PAN) => async () => {
    const response = await this.getInvestorDetails(PAN);
    if (response) {
      this.props.history.push({
        pathname: `${HISTORY_URL}/details/xxxxxxxxxxx`,
        state: { isKyc: true },
      });
    } else {
      const kycResponse = await this.getKycDetails(PAN);
      const {
        DtData: [info],
      } = kycResponse;
      if (info.KYCBlock === "N") {
        this.props.history.push({
          pathname: `${HISTORY_URL}/details/xxxxxxxxxxx`,
          state: { isKyc: false },
        });
      }
    }
  };

  render() {
    const pathname = this.props.location.pathname;
    const { investors, count } = this.props;
    const TableHeaders = [
      "Name",
      "KYC and FATCA Status",
      "Mobile",
      "PAN",
      `${pathname.includes("transaction-history")?"View Tranx History":"Initiate Transaction"}`,
    ];
    return (
      <div>
        {count > 0 ? (
          <CustomLoader display={true} />
        ) : !investors.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "SourceSansPro-SemiBold",
            }}
          >
            No data Found
          </div>
        ) : (
          ((this.props.tran_enable_flag === "Y" &&
            investors.length !== this.props.investorsCount) ||
            this.props.tran_enable_flag === "N") && (
            <React.Fragment>
              <Paper
                style={{
                  padding: "20px 5px",
                }}
                className="investors-paper"
              >
                <Table>
                  <TableHead
                    className="table-header"

                  >
                    <TableRow>
                      {TableHeaders.map((ele, index) => (
                        <TableCell className="table-cell" style={{ fontFamily: "SourceSansPro-SemiBold",padding:0,paddingBottom:10,textAlign:ele==="Name"?"left":"center",paddingLeft:ele==="Name"?30:0 }}>{ele}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {investors.map((investor, index) => (
                      <TableRow key={index}>
                        <TableCell className="table-cell" >
                          <img
                            src={BUILD_URL + "/images/user.png"}
                            alt="user"
                            className="user-icon"
                          />
                          {pathname.includes("/new-transaction") ||
                          pathname.includes("/mailBackRequest") ||
                          pathname.includes("/transaction-history") ? (
                            <span
                              className="investor-name"
                              style={{ fontFamily: "SourceSansPro-SemiBold" }}
                            >
                              {investor.Name}
                            </span>
                          ) : (
                            <span
                              className="mouse-pointer"
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `${INVESTOR_URL}/details/xxxxxxxxxx`,
                                  state: `${investor.Pan}`,
                                })
                              }
                              style={{
                                borderBottom: "2px solid #80bce3",
                                paddingBottom: 3,
                                fontFamily: "SourceSansPro-SemiBold",
                              }}
                            >
                              {investor.Name}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="table-cell"
                          style={{ width: 200 ,textAlign:'center'}}
                        >
                          <span className="kyc-fafc-verification">
                            <img
                              src={
                                !!investor.Kyc
                                  ? BUILD_URL +
                                    "/images/checkmark_investors.svg"
                                  : BUILD_URL + "/images/cross_investors.svg"
                              }
                              alt={
                                !!investor.Kyc
                                  ? "kyc-complete"
                                  : "kyc-incomplete"
                              }
                              className="verified-icon"
                            />
                            <span className="verfication-text">KYC</span>
                            <img
                              src={
                                !!investor.Fatca
                                  ? BUILD_URL +
                                    "/images/checkmark_investors.svg"
                                  : BUILD_URL + "/images/cross_investors.svg"
                              }
                              className="verified-icon"
                              alt={
                                !!investor.Fatca
                                  ? "fatca-complete"
                                  : "fatca-incomplete"
                              }
                            />
                            <span className="verfication-text">FATCA</span>
                          </span>
                        </TableCell>
                        <TableCell className="table-cell" style={{textAlign:"center"}}>
                          <img
                            src={BUILD_URL + "/images/call_investors.svg"}
                            alt="phone-icon"
                            className="phone-icon"
                          />
                          {investor.Mobile}
                        </TableCell>
                        <TableCell className="table-cell" style={{textAlign:"center"}}>
                          {investor.Pan}
                        </TableCell>

                        <TableCell className="table-cell" aria-disabled={true} style={{textAlign:"center"}}>
                          <div
                            className="mouse-pointer"
                            style={{
                              display:
                                !!investor.Pan &&
                                investor.Pan.charAt(3).toUpperCase() === "P"
                                  ? "block"
                                  : "none",
                              minWidth: 180,
                            }}
                            onClick={
                              pathname.includes("/transaction-history")
                                ? this.goToTransactionHistory(investor.Pan)
                                : pathname.includes(
                                    "/mailBackRequest/investor/investor-selection"
                                  )
                                ? this.goToMailBack(
                                    investor.Pan,
                                    this.props.location.state.name
                                  )
                                : this.goToTransaction(investor.Pan)
                            }
                          >
                            {pathname.includes("/investors") ||
                            pathname.includes("/transaction-history") ? (
                              <span className="transaction-button">
                                {pathname.includes("/investors") && (
                                  <img
                                    src={BUILD_URL + "/images/rupees_white.png"}
                                    className="transction-action-icon"
                                    alt="rupees-icon"
                                  />
                                )}
                                {pathname.includes("/investors")
                                  ? "New Transaction"
                                  : "View Tranx History"}
                              </span>
                            ) : (
                              <span
                                style={{
                                  border: "1px solid #007ac8",
                                  padding: "5px 25px 7px 25px",
                                  color: "#00579b",
                                  borderRadius: 3,
                                }}
                              >
                                Select
                              </span>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div className="investor-blocks">
                {investors.map((investor, index) => (
                  <Paper
                    className="investor-blocks-paper"
                    style={{
                      boxShadow: "0px 0px 3px 0px rgba(211,211,211,0.8)",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexGrow: 1,
                          flexBasis: 0,
                        }}
                      >
                        <img
                          src={BUILD_URL + "/images/user.png"}
                          alt="user"
                          className="user-block-icon"
                        />
                        {pathname.includes("/new-transaction") ||
                        pathname.includes("/mailBackRequest") ||
                        pathname.includes("/transaction-history") ? (
                          <span className="investor-name">{investor.Name}</span>
                        ) : (
                          <span
                            className="mouse-pointer"
                            onClick={() =>
                              this.props.history.push({
                                pathname: `${INVESTOR_URL}/details/xxxxxxxxxx`,
                                state: `${investor.Pan}`,
                              })
                            }
                            style={{
                              borderBottom: "2px solid #80bce3",
                              paddingBottom: 3,
                              fontFamily: "SourceSansPro-SemiBold",
                            }}
                            className="tabledata_invname"
                          >
                            {investor.Name}
                          </span>
                        )}
                      </div>
                      <div
                        onClick={
                          pathname.includes("/transaction-history")
                            ? this.goToTransactionHistory(investor.Pan)
                            : pathname.includes(
                                "/mailBackRequest/investor/investor-selection"
                              )
                            ? this.goToMailBack(
                                investor.Pan,
                                this.props.location.state.name
                              )
                            : this.goToTransaction(investor.Pan)
                        }
                      >
                        <div className="mouse-pointer " style={{
                              display:
                                !!investor.Pan &&
                                investor.Pan.charAt(3).toUpperCase() === "P"
                                  ? "block"
                                  : "none",
                              // minWidth: 180,
                            }}>
                          {pathname.includes("/investors") ||
                          pathname.includes("/transaction-history") ? (
                            <span className="transaction-button">
                              {pathname.includes("/investors") && (
                                <img
                                  src={BUILD_URL + "/images/rupees_white.png"}
                                  className="transction-action-icon"
                                  alt="rupees-icon"
                                />
                              )}
                              {pathname.includes("/investors")
                                ? "New Transaction"
                                : "View Tranx History"}
                            </span>
                          ) : (
                            <span
                              style={{
                                border: "1px solid #f58220",
                                padding: "5px 25px 7px 25px",
                                color: "#f58220",
                                borderRadius: 3,
                              }}
                            >
                              Select
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexGrow: 1,
                          flexBasis: 0,
                        }}
                      >
                        <span className="kyc-fafc-verification">
                          <img
                            src={
                              !!investor.Kyc
                                ? BUILD_URL + "/images/checkmark_investors.svg"
                                : BUILD_URL + "/images/cross_investors.svg"
                            }
                            alt={
                              !!investor.Kyc ? "kyc-complete" : "kyc-incomplete"
                            }
                            className="verified-icon"
                          />
                          <span className="verfication-text">KYC</span>
                          <img
                            src={
                              !!investor.Fatca
                                ? BUILD_URL + "/images/checkmark_investors.svg"
                                : BUILD_URL + "/images/cross_investors.svg"
                            }
                            className="verified-icon"
                            alt={
                              !!investor.Fatca
                                ? "fatca-complete"
                                : "fatca-incomplete"
                            }
                          />
                          <span className="verfication-text">FATCA</span>
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexGrow: 1,
                          flexBasis: 0,
                        }}
                      >
                        <div>
                          <img
                            src={BUILD_URL + "/images/call_investors.svg"}
                            alt="phone-icon"
                            className="phone-icon"
                          />
                        </div>
                        <div className="fontCommon">{investor.Mobile}</div>
                      </div>
                      <div
                        style={{
                          flexGrow: 1,
                          flexBasis: 0,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="fontCommon"
                      >
                        <span className="span-calss">PAN :</span>
                        {investor.Pan}
                      </div>
                    </div>
                  </Paper>
                ))}
              </div>
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}

export default connect(
  ({ investor, authReducer, loading }) => {
    const { userId, token, tran_enable_flag } = authReducer;
    const { investors, loaderDisplay, count: investorsCount } = investor;
    const { count } = loading;
    return {
      investors,
      userId,
      token,
      loaderDisplay,
      count,
      tran_enable_flag,
      investorsCount,
    };
  },
  { getInvestorDetails, checkKyc }
)(TableData);
