import React, { Component } from "react";
import {
  EXPIRING_NEXT_MONTH,
  CLOSED_THIS_MONTH,
  SIP_INVESTMENTS,
  CWS,
  CWOS,
} from "shared/store/types";
import { REQ_BY } from "shared/store/types/index";
import { connect } from "react-redux";
import SipSummaryMainTabs from "./sipMainTabs";
import { getSipSummary } from "shared/store/actions/dashboard";
import moment from "moment";
import { Debtbutton, DebtbuttonActive } from "../../forms/button";
import { NEW_TRANSACTION_URL } from "../searchComponent";
import { getInvestorDetails, checkKyc } from "shared/store/actions/investors";
import { INVESTOR_URL } from "../investors";

class SipSummaryTabContainer extends Component {
  constructor(props) {
    super(props);
    const { active } = this.props.location.state;
    this.state = {
      active: active || EXPIRING_NEXT_MONTH,
      tableData: [],
      Investmentsbutton: CWS,
    };
  }

  async componentDidMount() {
    // const sipParams = {
    //   Uid: this.props.userId,
    //   AgentCode: this.props.token,
    //   DateVal: moment(new Date()).format("MM-DD-YYYY"),
    // };
    // await this.props.getSipSummary(sipParams);
    this.setTableData(this.state.active, this.state.Investmentsbutton);
  }

  getKycDetails = async (pan) => {
    const params = {
      i_pan: pan,
      fund: "105",
      userid: this.props.userId,
    };
    const kycResponse = await this.props.checkKyc(params);
    return kycResponse;
  };
  getInvestorDetails = async (pan) => {
    const params = { PAN: pan, Uid: this.props.userId, ReqBy: REQ_BY };
    const response = await this.props.getInvestorDetails(params);
    return response;
  };

  setTableData = (active, button) => {
    if (
      active === EXPIRING_NEXT_MONTH &&
      !!this.props.sipSummaryData &&
      !!this.props.sipSummaryData.Table9 &&
      this.props.sipSummaryData.Table9.length > 0
    ) {
      this.setState({
        tableData: this.props.sipSummaryData.Table9,
      });
    } else if (
      active === CLOSED_THIS_MONTH &&
      !!this.props.sipSummaryData &&
      !!this.props.sipSummaryData.Table10 &&
      this.props.sipSummaryData.Table10.length > 0
    ) {
      this.setState({
        tableData: this.props.sipSummaryData.Table10,
      });
    } else if (
      active === SIP_INVESTMENTS &&
      !!this.props.sipSummaryData &&
      button === CWOS &&
      !!this.props.sipSummaryData.Table8 &&
      this.props.sipSummaryData.Table8.length > 0
    ) {
      this.setState({
        tableData: this.props.sipSummaryData.Table8,
      });
    } else if (
      active === SIP_INVESTMENTS &&
      !!this.props.sipSummaryData &&
      button === CWS &&
      !!this.props.sipSummaryData.Table7 &&
      this.props.sipSummaryData.Table7.length > 0
    ) {
      this.setState({
        tableData: this.props.sipSummaryData.Table7,
      });
    } else {
      this.setState({
        tableData: [],
      });
    }
  };

  onChangeActive = (active) => () => {
    this.setState({
      active,
    });
    this.setTableData(active, CWS);
    // this.props.getTransactionData({ transflag: active, Arn: this.props.token });
  };
  changeDateOn = (value) => () => {
    this.setState({
      Investmentsbutton: value,
    });
    this.setTableData(SIP_INVESTMENTS, value);
  };

  goToTransaction = (PAN, name, mailBackName) => async () => {
    const response = await this.getInvestorDetails(PAN);

    if (response) {
      this.props.history.push({
        pathname: NEW_TRANSACTION_URL + "/transction-types",
      });
    } else {
      const kycResponse = await this.getKycDetails(PAN);
      if (kycResponse) {
        const {
          DtData: [info],
        } = kycResponse;
        if (info.KYCBlock === "N") {
          this.props.history.push({
            pathname: NEW_TRANSACTION_URL + "/transction-types",
          });
        }
      }
    }
  };

  render() {
    const { tableData, Investmentsbutton } = this.state;
    return (
      <div style={{ padding: 20 }}>
        <SipSummaryMainTabs
          active={this.state.active}
          onClick={this.onChangeActive}
        />
        {this.state.active === SIP_INVESTMENTS && (
          <div className="radio-buttons-div" style={{ display: "flex" }}>
            <Debtbutton
              buttontitle="CLIENT WITH SIP"
              width={150}
              active={Investmentsbutton === CWS}
              onClick={this.changeDateOn(CWS)}
            />
            <Debtbutton
              buttontitle="CLIENT WITHOUT SIP"
              width={150}
              active={Investmentsbutton === CWOS}
              onClick={this.changeDateOn(CWOS)}
            />
          </div>
        )}
        {!!tableData && tableData.length > 0 ? (
          tableData.map((ele, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 5,
                }}
              >
                <div className="investor-investments-container-title">
                  {ele.invname}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Debtbutton
                    buttontitle="Transact"
                    onClick={this.goToTransaction(ele.pan)}
                  />
                  <DebtbuttonActive
                    buttontitle="View History"
                    onClick={() =>
                      this.props.history.push(
                        `${INVESTOR_URL}/details/${ele.pan}`
                      )
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "SourceSansPro-SemiBold",
                  color: "#00579b",
                }}
              >
                {ele.funddesc}
              </div>
              {this.state.active !== SIP_INVESTMENTS && (
                <div>Folio - {ele.acno}</div>
              )}
              <div className="investor-investments-sub-container">
                <div>
                  <div className="investor-investments-text">Email</div>
                  <div className="investor-investments-sub-text">
                    {ele.email}
                  </div>
                </div>
                {this.state.Investmentsbutton === CWS ? (
                  <div>
                    <div className="investor-investments-text">Start Date</div>
                    <div className="investor-investments-sub-text">
                      {/* <FormatCurrency value={transaction.td_nav} /> */}
                      {ele.StartDate}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="investor-investments-text">PAN</div>
                    <div className="investor-investments-sub-text">
                      {/* <FormatCurrency value={transaction.td_nav} /> */}
                      {ele.pan}
                    </div>
                  </div>
                )}
                {this.state.Investmentsbutton === CWS && (
                  <div>
                    <div className="investor-investments-text">EndDate</div>
                    <div className="investor-investments-sub-text">
                      {ele.EndDate}
                    </div>
                  </div>
                )}
                <div>
                  <div className="investor-investments-text">Mobile Number</div>
                  <div className="investor-investments-sub-text">
                    {ele.mobile}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : tableData.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              fontFamily: "Ubuntu-Italic",
              fontSize: 15,
              color: "red",
            }}
          >
            No Data Found
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontFamily: "Ubuntu-Italic",
              fontSize: 15,
              color: "#00579b",
            }}
          >
            loading please wait...
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ authReducer, dashboard, loading }) => {
    const { token, userId } = authReducer;
    const { count } = loading;
    const { sipSummaryData } = dashboard;
    return { token, sipSummaryData, userId };
  },
  { getSipSummary, getInvestorDetails, checkKyc }
)(SipSummaryTabContainer);
