import React, { Component } from "react";
import { Loader_icon } from "./appEssentials";

export default class CustomLoader extends Component {
  render() {
    const { display } = this.props;
    return (
      <div class="preloader" style={{ display: !!display ? "flex" : "none" }}>
        <div class="content">
          <div class="loader-circle"></div>
          <div class="loader-line-mask">
            <div class="loader-line"></div>
          </div>
          {/* <img src={Loader_icon} /> */}
        </div>
      </div>
    );
  }
}
