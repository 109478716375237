import React, { Component } from "react";
import CustomizedSelect from "./customizedSelect";
import { Input } from "../../forms/input";
import Signbutton from "../../forms/button";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import { YES, SWP } from "shared/store/types";
import {
  swpOptions,
  CalcSIPEnddt,
  getMinimumAmount,
} from "shared/store/actions";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { INVESTOR_URL } from "../investors";
import { Checkbox } from "@material-ui/core";
class Swp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minAmountValue: { value: "" },
      fund: { value: { value: "105" } },
      folios: [],
      folio: { value: "" },
      schemes: [],
      scheme: { value: "" },
      swpOption: { value: "" },
      swpOptions: [],
      swpFrequency: { value: "" },
      swpFrequencyOptions: [],
      withdrawals: { value: "" },
      sipDay: { value: "" },
      sipStartDate: { value: "" },
      sipEndDate: { value: "" },
      amount: { value: "" },
      sipCycle: [],
    };
  }
  handleFundChange = (value) => {
    if (!value) {
      this.setState({
        fund: { value: "" },
        folios: [],
        folio: { value: "" },
        schemes: [],
        scheme: { value: "" },
        swpOption: { value: "" },
        swpOptions: [],
        swpFrequency: { value: "" },
        swpFrequencyOptions: [],
        withdrawals: { value: "" },
        sipDay: { value: "" },
        sipStartDate: { value: "" },
        sipEndDate: { value: "" },
        amount: { value: "" },
        sipCycle: [],
        perpetualSip: false,
      });
    } else if (value.value === "105") {
      return;
    } else {
      const fund = !!value ? value.value : "";
      const folios = this.props.fundList.reduce((prev, curr) => {
        if (curr.sFlag === YES && curr.Fund === fund) {
          return [...prev, curr.Acno];
        }
        return prev;
      }, []);
      this.setState({
        fund: { value },
        folios: Array.from(new Set(folios)),
        folio: { value: "" },
        scheme: { value: "" },
        withdrawals: { value: "" },
        swpFrequency: { value: "" },
      });
    }
  };

  async componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    this.getfolios();
  }

  getfolios = () => {
    const folios = this.props.fundList.reduce((prev, curr) => {
      if (curr.sFlag === YES && curr.Fund.trim() === "105") {
        return [...prev, curr.Acno];
      }
      return prev;
    }, []);
    this.setState({
      fund: { value: { value: "105" } },
      folios: Array.from(new Set(folios)),
      folio: { value: "" },
      scheme: { value: "" },
      withdrawals: { value: "" },
      swpFrequency: { value: "" },
    });
  };
  handleFolioChange = async (value) => {
    const folio = !!value ? value.value : "";
    const schemes = !!value ? this.schemes(folio) : [];
    this.setState({
      folio: { value },
      schemes,
      scheme: { value: "" },
    });
  };
  schemes = (folio) => {
    return this.props.fundList.reduce((prev, curr) => {
      if (curr.Fund === "105" && curr.Acno === folio) {
        return [...prev, curr];
      }
      return prev;
    }, []);
  };
  handlechangeSIPEndDateChange = () => {
    const { perpetualSip } = this.state;
    if (!perpetualSip) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sipEndDate: { value: "31/12/2099" },
        withdrawals: {
          value: "",
          placeholder: "Perpetual SWP",
          disabled: true,
        },
      });
    } else if (!!perpetualSip) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sipDay: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        withdrawals: { value: "", placeholder: "", disabled: false },
      });
    }
  };
  handleSchemeChange = async (value) => {
    if (!value) {
      this.setState({
        swpOption: { value: "" },
        swpOptions: [],
        swpFrequency: { value: "" },
        swpFrequencyOptions: [],
        withdrawals: { value: "" },
        sipDay: { value: "" },
        sipStartDate: { value: "" },
        sipEndDate: { value: "" },
        amount: { value: "" },
        sipCycle: [],
      });
    } else if (
      !!value &&
      !!this.state.scheme.value.value &&
      value.value.SchDesc === this.state.scheme.value.value.SchDesc
    ) {
      return;
    } else {
      if (value.value.BalUnits === 0) {
        this.setState({
          folio: {
            value: "",
            isError: true,
            errorMessage: "SWP in zero balance folios is not allowed",
          },
          scheme: {
            value: "",
          },
        });
      } else {
        const params = {
          fund: "105",
          opt: "SWPW",
        };
        let swpOptionsResponse = [];
        const response = await this.props.swpOptions(params);
        if (response) {
          swpOptionsResponse = response.Dtinformation;
        }
        this.setState({
          scheme: { value },
          swpOptions: swpOptionsResponse,
        });
      }
    }
  };
  handleSwpOptonChange = async (event) => {
    if (
      !!this.state.swpOption.value &&
      event.target.value.code === this.state.swpOption.value.code
    ) {
      return;
    } else {
      const swpOption = event.target.value;
      const params = {
        opt: "SWF",
        fund: "105",
      };
      const response = await this.props.swpOptions(params);
      const swpFrequencyOptionsResponse = response.Dtinformation;

      this.setState({
        swpOption: { value: swpOption },
        withdrawals: { value: "" },
        swpFrequency: { value: "" },
        swpFrequencyOptions: swpFrequencyOptionsResponse,
      });
    }
  };

  handleSwpFrequencyChange = async (event) => {
    const swpFrequency = event.target.value;
    const params = {
      opt: "SWPD",
      fund: "105",
      Astscheme:
        !!event.target.value && !!event.target.value.FrequencyDescription
          ? event.target.value.FrequencyDescription
          : "",
    };
    const response = await this.props.swpOptions(params);
    const sipCycleResponse = response.Dtinformation;

    this.setState({
      swpFrequency: { value: swpFrequency },
      sipDay: { value: "" },

      sipStartDate: { value: "" },
      sipEndDate: { value: "" },
      sipCycle: sipCycleResponse,
    });
    const minParams = {
      Fund: "105",
      Scheme: this.state.scheme.value.value.Sch,
      Trtype: "SWP",
      Frequency: this.state.swpFrequency.value.FrequencyID,
    };

    const minResponse = await this.props.getMinimumAmount(minParams);
    if (!!minResponse && !minResponse.error) {
      const { DtData } = minResponse;
      const minAmount = DtData[0].minamt;
      this.setState({
        minAmountValue: {
          value: minAmount,
        },
      });
    }
  };

  handleNoOfWithDrawals(event) {
    if (event.target.value.length <= 3 && !!event.target.value) {
      if (parseFloat(event.target.value) <= 500 && event.target.value !== "0") {
        this.setState({
          withdrawals: {
            value: parseFloat(event.target.value.replace(/^0+/, "")),
          },
          sipStartDate: { value: "" },
          sipEndDate: { value: "" },
          sipDay: { value: "" },
          swpFrequency: { value: "" },
        });
      } else if (
        parseFloat(event.target.value) > 500 &&
        event.target.value !== "0"
      ) {
        this.setState({
          withdrawals: {
            value: "",
            isError: true,
            errorMessage: !!event.target.value
              ? "*No. of Withdrawls should not be more than 500 as stated above"
              : "",
          },
          sipStartDate: { value: "" },
          sipEndDate: { value: "" },
          sipDay: { value: "" },
          swpFrequency: { value: "" },
        });
      }
    } else if (!event.target.value) {
      this.setState({
        withdrawals: {
          value: "",
          isError: true,
          errorMessage:
            !!event.target.value && event.target.value !== "0"
              ? "No. of Withdrawals should not be more than 500"
              : event.target.value === "0"
              ? "No. of Withdrwals should not be zero"
              : "*Please enter the minimum no of Withdrawals",
        },
        sipStartDate: { value: "" },
        sipEndDate: { value: "" },
        sipDay: { value: "" },
      });
    }
  }
  async handleSwpDay(event) {
    const day = event.target.value;

    if (!!day) {
      const params = {
        Fund: "105",
        StartDate: day,
        installments: this.state.withdrawals.value,
        Frequency: this.state.swpFrequency.value.FrequencyDescription,
        trtype: "SWP",
        sch: this.state.scheme.value.value.Sch,
      };

      const response = await this.props.CalcSIPEnddt(params);

      const sipStartDateResponse = response.DtData[0].SIP_StartDate;
      const sipEndDateResponse = response.DtData[0].SIP_EndDate;
      this.setState({
        sipDay: { value: day },
        sipStartDate: { value: sipStartDateResponse },
        sipEndDate: { value: sipEndDateResponse },
      });
    }
  }

  showError = (label) => () => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "withdrawals") {
      this.setState({
        [label]: {
          value,
          isError: this.state.withdrawals.value < 1 ? true : !value,
          errorMessage:
            this.state.withdrawals.value < 1
              ? "*Please enter the vaild number of Withdrawals as stated above"
              : "",
        },
      });
    }
    if (label === "amount") {
      if (
        parseFloat(this.state.amount.value) < this.state.minAmountValue.value &&
        this.state.swpOption.value.Description === "Fixed"
      ) {
        this.setState({
          amount: {
            value: "",
            isError: true,
            errorMessage: `*Minimum amount is  ₹${this.state.minAmountValue.value} as mentioned above`,
          },
        });
      } else if (
        !this.state.amount.value &&
        this.state.swpOption.value.Description === "Fixed"
      ) {
        this.setState({
          amount: {
            value: "",
            isError: true,
            errorMessage: `*Amount is Mandatory`,
          },
        });
      }
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
          errorMessage: `*${
            label.charAt(0).toUpperCase() + label.slice(1)
          } is Mandatory`,
        },
      });
    }
  };

  handleMinimumAmount(event) {
    if (event.target.value.length <= 10) {
      this.setState({
        amount: { value: parseFloat(event.target.value.replace(/^0+/, "")) },
      });
    }
  }

  submit = () => {
    let currValue;
    if (!!this.state.scheme.value) {
      currValue = parseFloat(
        this.state.scheme.value.value.CurValue.replace(/,/g, "")
      );
    }
    if (this.state.amount.value === "") {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: "*Amount is Mandatory",
        },
      });
    }
    const {
      fund,
      funds,
      folios,
      schemes,
      swpOptions,
      swpFrequencyOptions,
      withdrawals,
      sipStartDate,
      sipEndDate,
      amount,
      sipCycle,
      bank,
      banks,
      perpetualSip,
      ...others
    } = this.state;
    const keys = Object.keys(others);
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage:
                key === "scheme"
                  ? "*Scheme is mandatory"
                  : key === "folio"
                  ? "*Folio is mandatory"
                  : key === "sipDay"
                  ? "*SWP Day is mandatory"
                  : key === "swpFrequency"
                  ? "*Withdrawal Frequency is mandatory"
                  : key === "swpOption"
                  ? "*Withdrawal Option is mandatory"
                  : `${key} is Mandatory*`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "folio": {
          this.folioField.focus();
          break;
        }
        case "scheme": {
          this.schemeField.focus();
          break;
        }
        case "swpOption": {
          this.swpOptionField.focus();
          break;
        }
        case "sipDay": {
          this.sipDayField.focus();
          break;
        }
        case "swpFrequency": {
          this.swpFrquencyField.focus();
          break;
        }
        default:
          break;
      }
    } else if (this.state.withdrawals.value < 1 && !perpetualSip) {
      this.setState({
        withdrawals: {
          value: "",
          isError: true,
          errorMessage: `*Please check the minimum Withdrawals Value as stated above`,
        },
      });
    } else if (
      !this.state.amount.value &&
      this.state.swpOption.value.Description === "Fixed"
    ) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `*Amount is Mandatory`,
        },
      });
    } else if (
      parseFloat(this.state.amount.value) < this.state.minAmountValue.value &&
      this.state.swpOption.value.Description === "Fixed"
    ) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `*Minimum amount is  ₹${this.state.minAmountValue.value} as stated above`,
        },
      });
    } else {
      const { funds, folios, schemes, swpFrequencyOptions, ...others } =
        this.state;

      this.props.history.push({
        pathname: INVESTOR_URL + "/transction-types/swp/confirm",
        state: {
          title: "SWP Payment Summary",
          type: SWP,
          Folio: { value: others.scheme.value.value.Acno },
          withDrawals: others.withdrawals.value,
          scheme: others.scheme.value.value,
          swpFrequency: others.swpFrequency.value,
          sipEndDate: others.sipEndDate.value,
          swpOption: others.swpOption.value,
          sipStartDate: others.sipStartDate.value,
          amount: others.amount.value,
          sipDay: others.sipDay.value,
        },
      });
    }
  };
  render() {
    const {
      funds,
      fund,
      folio,
      folios,
      scheme,
      schemes,
      swpOption,
      swpOptions,
      swpFrequency,
      swpFrequencyOptions,
      withdrawals,
      sipDay,
      sipStartDate,
      sipEndDate,
      sipCycle,
      amount,
      perpetualSip,
    } = this.state;
    return (
      <div className="transaction-details">
        <div className="title">SWP Registration</div>

        <div className="input-margin">
          <div className="input-name ">Folio*</div>
          <IntegrationReactSelect
            suggestions={folios.map((item) => ({
              label: item,
              value: item,
            }))}
            disabled={!fund.value}
            value={folio.value}
            placeholder="Select Folio"
            handleChange={this.handleFolioChange}
            inputRef={(ele) => (this.folioField = ele)}
            isError={folio.isError}
            errorMessage={folio.errorMessage}
          />
        </div>
        <div className="input-margin">
          <div className="input-name ">Scheme*</div>
          <IntegrationReactSelect
            suggestions={schemes.map((item) => ({
              label: item.PlnDesc
                ? `${item.SchDesc1} - ${item.PlnDesc} - ${item.OptDesc}`
                : item.fm_schdesc,
              value: item,
            }))}
            disabled={!folio.value}
            value={scheme.value}
            placeholder="Select Scheme"
            handleChange={this.handleSchemeChange}
            inputRef={(ele) => (this.schemeField = ele)}
            isError={scheme.isError}
            errorMessage={scheme.errorMessage}
          />
        </div>
        <div className="redemption-details">
          <div className="keys">
            <div>Current Value</div>
          </div>
          <div className="values">
            <div>
              {!!scheme.value ? `₹ ${scheme.value.value.CurValue}` : ""}
            </div>
          </div>
        </div>
        <div className="input-margin">
          <CustomizedSelect
            name="Withdrawal Option*"
            placeholder="Withdrawal Option"
            handleChange={this.handleSwpOptonChange}
            value={swpOption.value}
            disabled={!scheme.value}
            inputRef={(ele) => (this.swpOptionField = ele)}
            isError={swpOption.isError}
            errorMessage={swpOption.errorMessage}
          >
            {swpOptions.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item.Description === "Fixed"
                  ? "Fixed Amount Withdrawal"
                  : item.Description === "Capital Appreciation"
                  ? "Capital Appreciation Withdrawal"
                  : item.Description}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>
        <div className="input-margin">
          <Input
            name="No. of Withdrawals* (Minimum: 1)"
            onChange={(event) => this.handleNoOfWithDrawals(event)}
            onBlur={this.showError("withdrawals")}
            isShowError={withdrawals.isError}
            errorMessage={withdrawals.errorMessage}
            disabled={!scheme.value || withdrawals.disabled}
            value={withdrawals.value}
            placeholder={withdrawals.placeholder}
          />
        </div>
        <div className="input-margin">
          <CustomizedSelect
            name="Withdrawal Frequency*"
            placeholder="Withdrawal Frequency"
            handleChange={this.handleSwpFrequencyChange}
            value={swpFrequency.value}
            inputRef={(ele) => (this.swpFrquencyField = ele)}
            isError={swpFrequency.isError}
            disabled={!withdrawals.value && !perpetualSip}
            errorMessage={swpFrequency.errorMessage}
          >
            {swpFrequencyOptions.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item.FrequencyDescription}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>
        {swpOption.value.Description === "Fixed" && (
          <div>
            <div
              style={{
                margin: "20px 5px 10px",
                color: "#00579b",
                fontSize: 14,
                fontFamily: "SourceSansPro-SemiBold",
                backgroundColor: "aliceblue",
                padding: "10px 0px",
                textAlign: "center",
              }}
            >
              Minimum Amount:{" "}
              {!!this.state.minAmountValue
                ? `₹${this.state.minAmountValue.value}`
                : ""}
            </div>
            <div className="input-margin">
              <Input
                name="Investment Amount*"
                onKeyDown={(evt) =>
                  (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                  evt.preventDefault()
                }
                bordercolor="rgba(0, 0, 0, 0.5)"
                type="number"
                errorMessage={amount.errorMessage}
                isShowError={amount.isError}
                onBlur={this.showError("amount")}
                onChange={(event) => this.handleMinimumAmount(event)}
                value={amount.value}
              />
            </div>
          </div>
        )}
        <div className="input-margin">
          <CustomizedSelect
            name="SWP Date*"
            placeholder="SWP Date"
            value={swpFrequency.value ? sipDay.value : ""}
            disabled={!swpFrequency.value}
            inputRef={(ele) => (this.sipDayField = ele)}
            isError={sipDay.isError}
            errorMessage={sipDay.errorMessage}
            handleChange={(event) => this.handleSwpDay(event)}
          >
            {sipCycle.map((item, index) => (
              <MenuItem value={item.sip_cycleid} key={index}>
                {item.sip_cycleid}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>
        {!!sipDay && !!sipDay.value && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              onClick={this.handlechangeSIPEndDateChange}
              value={!!this.state.perpetualSip}
              checked={!!this.state.perpetualSip}
            />
            <div style={{ fontFamily: "OpenSans-SemiBold", fontSize: 14 }}>
              Perpetual SWP
            </div>
          </div>
        )}
        <div className="input-name">Withdrawal Period</div>
        <div className="input-margin">
          <Input
            name="From"
            value={swpFrequency.value ? sipStartDate.value : ""}
            disabled={true}
          />
        </div>

        <div className="input-margin">
          <Input
            name="To"
            value={swpFrequency.value ? sipEndDate.value : ""}
            disabled={true}
          />
        </div>
        <div className="digital-form-button-next-div">
          <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const {
      fundList,
      token,
      userId,
      bankDetails,
      investorData,
      communicationDetails,
    } = authReducer;
    const { euinList } = transactions;
    return {
      fundList,
      token,
      userId,
      euinList,
      bankDetails,
      investorData,
      communicationDetails,
    };
  },
  { swpOptions, CalcSIPEnddt, getMinimumAmount }
)(Swp);
