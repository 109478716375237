import React,{Component} from "react";
import MailbackRouter from "./mailBackRoutes";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { BUILD_URL } from "../home";
import { dashboardActive } from "../../forms/appEssentials";

const MAILBACK_URL = `${process.env.PUBLIC_URL}/mailBackRequest`;

export default class MailBackComponent extends Component {
    render (){
        return (
            <div>
                <MailbackRouter />
            </div>
        )
    }
}


export class MailBackBreadCrumb extends Component {
  render() {
      const {name} = this.props;
        return(
            <div className="tab-breadcrumbs-container" style={{ marginTop: 0 }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="Breadcrumb"
            >
              <div className="flex">
                <img
                  src={dashboardActive}
                  alt="web-page-home"
                  style={{ height: 20, marginRight: 10,cursor:"pointer" }}
                />
                 <a
                onClick={() => this.props.history.push(MAILBACK_URL + "/investor")}
                className="breadcrumbs-unselect-label"
                style={{cursor:"pointer"}}
              >
                Mail Back Types
              </a>
              </div>



              <a color="inherit" className="breadcrumbs-unselect-label"
              style={{cursor:"pointer"}}
              onClick={() => this.props.history.push({
                  pathname:MAILBACK_URL + "/investor/investor-selection",
                  state:
                  !!this.props.investorData ?
                          { name: !!name && name === "Mail Back SOA"?"mailBackSoa":name==="Consolidated Account Statment"?"consolidated":name==="ELSS Account Statement"?"ELSS":name==="CAN Based Account Statement"?"CAN":name==="Capital Gains Account Statement"?"CGS":name==="Grandfathering Account Statement"?"GAS":name==="Networth Report"?"Networth":"Networth",
                            investorData: this.props.investorData
                          } :
                          {
                            name:!!name && name === "Mail Back SOA"?"mailBackSoa":name==="Consolidated Account Statment"?"consolidated":name==="ELSS Account Statement"?"ELSS":name==="CAN Based Account Statement"?"CAN":name==="Capital Gains Account Statement"?"CGS":name==="Grandfathering Account Statement"?"GAS":name==="Networth Report"?"Networth":"Networth",
                  }}
                )}
              >
              Investors Selection
              </a>
            <div className="transaction-summary-label">{this.props.name==="Mailback SOA"?"Mail Back SOA":this.props.name}</div>
            </Breadcrumbs>
          </div>
        )
    }
}