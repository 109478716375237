import React, { Component } from "react";
import CommonTemplate from "./commonTemplate";
import Signbutton from "../forms/button";
import { Input } from "../forms/input";
import { connect } from "react-redux";
import { forgotPassword } from "shared/store/actions";
import { CommonOtp } from "./otp";
import { BUILD_URL } from "../home/home";


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: { value: "" }
    };
  }

  changeValue = event => {
    if(event.target.value.length <=45){

      this.setState({
        userId: { value: event.target.value }
      });
    }
  };

  showError = () => {
    const {
      userId: { value }
    } = this.state;
    this.setState({
      userId: {
        value,
        isError: !value,
        errorMessage: "User ID is mandatory"
      }
    });
  };

  sendOtp = async () => {
    const {
      userId: { value }
    } = this.state;
    if (!value) {
      this.userIdField.focus();
      this.setState({
        userId: {
          value,
          isError: !value,
          errorMessage: "User ID is mandatory"
        }
      });
    } else {
      this.regenerteOtp();
    }
  };

  submitOtp = async enteredOtp => {
    const { otp } = this.state;
    if (Number(enteredOtp.join("")) === otp) {
      this.props.history.replace({
        pathname: BUILD_URL + "/reset-password",
        state: { userId: this.state.userId }
      });
    } else {
      alert("Please enter a valid OTP");
    }
  };

  regenerteOtp = async () => {
    const {
      userId: { value }
    } = this.state;
    const otp = Math.floor(100000 + Math.random() * 900000);
    const params = {
      ReqBy: "a",
      userid: value,
      otp
    };
    console.log(params,"params")

    const response = await this.props.forgotPassword(params);

    if (!!response && !!response.Dtinformation) {
      const {
        Dtinformation: [info]
      } = response;

      this.setState({
        otp,
        textOne:
          info.Error_Message.trim() ===
          "OTP has been sent to your registered EMail ID"
            ? "OTP has been sent to your registered Email ID and Mobile Number"
            : info.Error_Message
      });
    } else {
      this.setState({
        userId: {
          value: "",
          isError: true,
          errorMessage: "Please enter  valid UserID"
        }
      });
    }
  };
  keyPress = e => {
    if (e.key === "Enter") {
      this.sendOtp();
      e.preventDefault();
    }
  };

  render() {
    const { userId, otp, textOne } = this.state;
    return (
      <CommonTemplate title="Forgot Password" textOne={textOne}>

        <Input
          name="User ID"
          value={userId.value}
          bordercolor="rgba(0, 0, 0, 0.5)"
          onChange={this.changeValue}
          onBlur={this.showError}
          isShowError={userId.isError}
          inputref={ele => (this.userIdField = ele)}
          errorMessage={userId.errorMessage}
          disabled={!!otp}
          onKeyPress={this.keyPress}
        />
        {!otp ? (
          <Signbutton buttontitle="Submit" onClick={this.sendOtp} />
        ) : (
          <CommonOtp
            submitOtp={this.submitOtp}
            regenerteOtp={this.regenerteOtp}
          />
        )}
      </CommonTemplate>
    );
  }
}

export default connect(null, { forgotPassword })(ForgotPassword);
