import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { REQ_BY } from "shared/store/types";
import {
  searchInvestors,
  getInvestors,
  getInvestorDetails,
  checkKyc,
} from "shared/store/actions";
import { KycNotDoneDialog } from "./customModal";

export const NEW_TRANSACTION_URL = `${process.env.PUBLIC_URL}/investors`;
class Search extends Component {
  constructor(props) {
    super(props);
    const { tran_enable_flag } = this.props;
    this.state = {
      radioValue: tran_enable_flag === "Y" ? "pan" : "pan",
      inputValue: "",
      errorMessage: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputValue } = prevState;
    if (!this.state.inputValue && !!inputValue) {
      this.props.getInvestors({
        ARN: this.props.token,
        AllFavFlag: this.props.selectedTab,
      });
    }
  }

  handleChange = (event) => {
    this.setState({
      radioValue: event.target.value,
      inputValue: "",
      errorMessage: "",
    });
  };

  changeInput = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      this.setState({
        errorMessage: "",
      });
    }
    this.setState({
      inputValue:
        this.state.radioValue === "pan"
          ? inputValue.toUpperCase()
          : this.state.radioValue === "mobile" && inputValue.length > 10
          ? this.state.inputValue
          : inputValue,
    });
    // if (this.state.radioValue === "name" && inputValue.length >= 4) {
    //   this.search();
    // }
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.search();
      e.preventDefault();
    }
  };

  search = async () => {
    const { inputValue, radioValue } = this.state;
    const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (
      radioValue === "pan" &&
      (/[A-Za-z]{5}\d{4}[A-Za-z]{1}/.test(inputValue) === false ||
        inputValue === "")
    ) {
      this.setState({
        errorMessage: "(Please enter a Valid PAN)",
      });
    } else if (radioValue === "name" && !inputValue) {
      this.setState({
        errorMessage: "(Please enter a Valid name)",
      });
    } else if (radioValue === "folio" && !inputValue) {
      this.setState({
        errorMessage: "(Please enter a Valid Folio)",
      });
    } else if (
      radioValue === "mobile" &&
      (/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(inputValue) ===
        false ||
        inputValue === "")
    ) {
      this.setState({
        errorMessage: "(Please enter a Valid Mobile Number)",
      });
    } else if (
      radioValue === "email" &&
      (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(inputValue) ===
        false ||
        inputValue === "")
    ) {
      this.setState({
        errorMessage: "Please enter an Valid Email ID",
      });
    } else {
      if (!!this.state.inputValue) {
        const parameters = {
          [this.state.radioValue]:
            this.state.radioValue === "pan"
              ? this.state.inputValue.toUpperCase()
              : this.state.inputValue,
          agent: this.props.token,
          uid: this.props.userId,
        };
        const response = await this.props.searchInvestors(parameters, true);
        if (!response && this.state.radioValue === "pan") {
          const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
          if (!this.state.inputValue || !regpan.test(this.state.inputValue)) {
            this.setState({ errorMessage: "Please enter valid PAN" });
          } else if (this.state.inputValue.charAt(3) !== "P") {
            this.setState({
              errorMessage: "Non Indivdual PAN is not allowed to transact",
            });
          }
        } else {
          this.setState({
            errorMessage: "",
          });
          const params = {
            Uid: this.props.userId,
            ReqBy: REQ_BY,
            PAN:
              this.state.radioValue === "pan"
                ? this.state.inputValue.toUpperCase()
                : !!response &&
                  !!response.Dtdata &&
                  !!response.Dtdata[0] &&
                  !!response.Dtdata[0].Pan
                ? response.Dtdata[0].Pan
                : this.state.inputValue,
          };

          const Investorresponse = await this.props.getInvestorDetails(
            params,
            true
          );

          if (!!Investorresponse && !Investorresponse.error) {
            // this.props.history.push({
            //   pathname: process.env.PUBLIC_URL + "/investors/transction-types",
            //   pan: this.state.radioValue === "pan"?this.state.inputValue: !!response &&
            //   !!response.Dtdata &&
            //   !!response.Dtdata[0] &&
            //   !!response.Dtdata[0].Pan
            // ? response.Dtdata[0].Pan:"",
            // });
          }
          // else {
          //   if (this.state.radioValue === "pan") {
          //     const kycResponse = await this.props.checkKyc(
          //       {
          //         fund: " ",
          //         userid: " ",
          //         i_pan: this.state.inputValue,
          //       },
          //       true
          //     );

          //     const {
          //       DtData: [info],
          //     } = kycResponse;
          //     if (info.KYCBlock === "N") {
          //       this.props.history.push({
          //         pathname: NEW_TRANSACTION_URL + "/transction-types",
          //         state: {
          //           pan: !!this.state.inputValue ? this.state.inputValue : "",
          //         },
          //       });
          //     } else {
          //       this.setState({ openModal: true });
          //     }
          //   } else {
          //     alert("No Data Found");
          //   }
          // }
        }
      }
    }
  };
  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  continue = () => {
    this.closeModal();
    this.props.history.push({
      pathname:
        NEW_TRANSACTION_URL + `/new-investor/ekyc/${this.state.inputValue}`,
    });
  };

  render() {
    const { count } = this.props;

    return (
      <div>
        {count === 0 && (
          <Paper
            className="investor-paper"
            style={{
              boxShadow: "0px 1px 3px 0px lavender",
              marginBottom: 20,
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <div className="investor-search-by">Search by :</div>
              <div
                style={{
                  borderRight: "2px solid #e7e8e9",
                  padding: 7,
                  paddingRight: 20,
                  flexGrow: 1,
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="Search"
                    name="search"
                    value={this.state.radioValue}
                    style={{ flexDirection: "row" }}
                    onChange={this.handleChange}
                  >
                    <div className="investor-radio-button">
                      <FormControlLabel
                        value="name"
                        control={
                          <Radio
                            disabled={
                              this.props.tran_enable_flag === "Y" ? true : false
                            }
                            className="investor-search-radio"
                            style={{ color: "#00579B" }}
                          />
                        }
                        className="investor-formcontrol-label"
                        style={{ margin: 0 }}
                      />
                      <div className="investor-form-label">Name</div>
                    </div>

                    <div className="investor-radio-button">
                      <FormControlLabel
                        value="pan"
                        control={
                          <Radio
                            className="investor-search-radio"
                            style={{ color: "#00579B" }}
                          />
                        }
                        className="investor-formcontrol-label"
                        style={{ margin: 0 }}
                      />
                      <div className="investor-form-label">PAN</div>
                    </div>
                    <div className="investor-radio-button">
                      <FormControlLabel
                        value="folio"
                        control={
                          <Radio
                            className="investor-search-radio"
                            style={{ color: "#00579B" }}
                          />
                        }
                        className="investor-formcontrol-label"
                        style={{ margin: 0 }}
                      />
                      <div className="investor-form-label">Folio No.</div>
                    </div>
                    <div className="investor-radio-button">
                      <FormControlLabel
                        value="mobile"
                        control={
                          <Radio
                            className="investor-search-radio"
                            style={{ color: "#00579B" }}
                          />
                        }
                        className="investor-formcontrol-label"
                        style={{ margin: 0 }}
                      />
                      <div className="investor-form-label">Mobile</div>
                    </div>
                    <div className="investor-radio-button">
                      <FormControlLabel
                        value="email"
                        control={
                          <Radio
                            className="investor-search-radio"
                            style={{ color: "#00579B" }}
                          />
                        }
                        className="investor-formcontrol-label"
                        style={{ margin: 0 }}
                      />
                      <div className="investor-form-label">Email ID</div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div style={{ display: "flex", flexGrow: 1 }}>
              <div className="investor-input-div">
                <SearchIcon className="investor-input-searchicon" />
                <InputBase
                  value={this.state.inputValue}
                  placeholder="Type here"
                  className="investor-input-inputbase"
                  inputProps={{
                    "aria-label": "Search",
                    maxLength: this.state.radioValue === "pan" ? "10" : "",
                    className: "investor-input-inputbase number-input",
                    type:
                      this.state.radioValue === "mobile" ||
                      this.state.radioValue === "folio"
                        ? "number"
                        : "",
                  }}
                  // inputRef={ele => (this.InputField = ele)}
                  onChange={this.changeInput}
                  onKeyPress={this.keyPress}
                  onKeyDown={(evt) =>
                    evt.key === "e" &&
                    (this.state.radioValue === "mobile" ||
                      this.state.radioValue === "folio") &&
                    evt.preventDefault()
                  }
                />
              </div>
              <div className="investor-search-button" onClick={this.search}>
                Search
              </div>
            </div>
          </Paper>
        )}
        {!!this.state.errorMessage ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontFamily: "Ubuntu-Italic",
              fontSize: 12,
              marginBottom: 10,
              color: "red",
            }}
          >
            {this.state.errorMessage}
          </div>
        ) : (
          ""
        )}
        <KycNotDoneDialog
          open={this.state.openModal}
          close={this.closeModal}
          continue={this.continue}
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer, investor, loading }) => {
    const { token, userId, tran_enable_flag } = authReducer;
    const { count } = loading;
    const { selectedTab } = investor;
    return { token, userId, selectedTab, count, tran_enable_flag };
  },
  { searchInvestors, getInvestors, getInvestorDetails, checkKyc }
)(Search);
