import React, { Component } from "react";
import Appbar from "./appbar";
import Signbutton from "../forms/button";
import { Input } from "../forms/input";
import DemoCarousel from "../auth/carsouel";
import { connect } from "react-redux";
import { generateOtp } from "shared/store/actions";
import { BUILD_URL } from "../home/home";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../forms/appEssentials";
import Footer from "./footer";
import { RepeatOneSharp } from "@material-ui/icons";
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: { value: "" },
      arnCode: { value: "" },
      password: { value: "" },
      confirmPassword: { value: "" },
      showPassword: false,
      confirmShowPassword: false,
    };
  }

  changeValue = (label) => (event) => {
    if (label === "arnCode") {
      if (event.target.value.length <= 20)
        this.setState({
          [label]: { value: event.target.value },
        });
    } else if (label != "arnCode") {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;
    if (label == "userId") {
      if (this.state.userId.value === "") {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: "User Id is Mandatory",
          },
        });
      }
    }
    if (label == "arnCode") {
      if (this.state.arnCode.value === "") {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: "ARN Code  is Mandatory",
          },
        });
      }
      if (/^[0-9]{4,6}$/.test(this.state.arnCode.value)) {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: "",
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `ARN code should be a min of 4 digits and a max of 6 digits.`,
          },
        });
      }
    }
    if (label == "password") {
      if (this.state.password.value === "") {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: "Password  is Mandatory",
          },
        });
      }
      if (
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
          this.state.password.value
        )
      ) {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: "",
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `Password should be minimum 8 characters in length and should consist of at least One Capital alphabet, One Small alphabet, One Special Character and One numeric number`,
          },
        });
      }
    }
    if (label == "confirmPassword") {
      if (this.state.confirmPassword.value === "") {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: "Confirm Password is Mandatory",
          },
        });
      }
    }
  };
  keyPress = (label) => (e) => {
    if (e.key === "Enter") {
      // Do code here
      if (label === "userId") {
        this.arnCodeField.focus();
      } else if (label === "arnCode") {
        this.passwordField.focus();
      } else if (label === "password") {
        this.confirmPasswordField.focus();
      } else {
        this.generateOtp();
      }
      e.preventDefault();
    }
  };

  generateOtp = async () => {
    const { showPassword, confirmShowPassword, ...others } = this.state;
    const keys = Object.keys(others);
    const findError = keys.every((key) => {
      return !!this.state[key].value;
    });
    const isPasswordEqual =
      this.state.password.value === this.state.confirmPassword.value;
    if (!findError) {
      keys.forEach((key) => {
        !this.state[key].value &&
          this.setState({
            [key]: {
              ...this.state[key],
              isError: true,
              errorMessage: `${
                key === "userId"
                  ? "User ID"
                  : key === "password"
                  ? "Password"
                  : key === "arnCode"
                  ? "ARN Code"
                  : key === "confirmPassword"
                  ? "Confirm Password"
                  : key
              } is mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !this.state[key].value);
      switch (firstErroredKey) {
        case "userId":
          this.userIdField.focus();
          break;
        case "password":
          this.passwordField.focus();
          break;
        case "arnCode":
          this.arnCodeField.focus();
          break;
        case "confirmPassword":
          this.confirmPasswordField.focus();
          break;
      }
      return;
    }
    if (!/^[0-9]{4,6}$/.test(this.state.arnCode.value)) {
      this.setState({
        arnCode: {
          ...this.state.arnCode,
          isError: true,
          errorMessage:
            "ARN code should be a min of 4 digits and a max of 6 digits.",
        },
      });
      this.arnCodeField.focus();
      return;
    }
    if (
      !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
        this.state.password.value
      )
    ) {
      this.setState({
        password: {
          ...this.state.password,
          isError: true,
          errorMessage: "Enter valid Password",
        },
      });
      this.passwordField.focus();
      return;
    }
      if (this.state.userId.value === this.state.password.value && this.state.userId.value.length > 7) {
        this.setState({
          password: {
            ...this.state.password,
            isError: true,
            errorMessage: "Password should not consist of username/loginname",
          },
        });
        return;
      }
    if (!isPasswordEqual) {
      this.setState({
        confirmPassword: {
          ...this.state.confirmPassword,
          isError: true,
          errorMessage: "Passwords don't match",
        },
      });
      this.confirmPasswordField.focus();
      return;
    }

    const payload = {
      UserId: this.state.userId.value,
      ARNCode: `ARN-${this.state.arnCode.value}`,
      OTPMsg: Math.floor(100000 + Math.random() * 900000),
    };
    const response = await this.props.generateOtp(payload);
    if (
      response.error === "User id already exists for this ARN code." ||
      response.error ===
        "The ARN number mentioned has an active user Id, If you don’t remember your user id, you can use forgot user id to retrieve your Id."
    ) {
      alert(
        "The ARN number mentioned has an active user Id, If you don’t remember your user id, you can use forgot user id to retrieve your Id."
      );
      this.setState({
        userId: { value: "" },
        arnCode: { value: "" },
        password: { value: "" },
        confirmPassword: { value: "" },
      });
      return;
    }
    if (response.error === "Invalid ARN code.") {
      alert("Enter valid ARN Code");
      return;
    }
    if (!!response) {
      this.props.history.push({
        pathname: BUILD_URL + "/otp",
        state: { payload: { ...payload, password: this.state.password.value } },
      });
    }
  };

  render() {
    const { userId, password, arnCode, confirmPassword } = this.state;

    return (
      <div className="login-container">
        <Appbar />
        <Footer />
        <div className="login-without-appbar">
          <DemoCarousel />
          <div className="login-right-div" style={{ overflow: "scroll" }}>
            <div>
              <div className="login-title">SIGN UP</div>
              <div className="input-margin">
                <Input
                  name="User ID"
                  image={"/images/id-card.svg"}
                  autoFocus={true}
                  value={userId.value}
                  onChange={this.changeValue("userId")}
                  onBlur={this.showError("userId")}
                  isShowError={userId.isError}
                  errorMessage={userId.errorMessage}
                  inputref={(ele) => (this.userIdField = ele)}
                  onKeyPress={this.keyPress("userId")}
                />
              </div>
              <div className="input-margin">
                <Input
                  name="ARN Code"
                  errorMessage={arnCode.errorMessage}
                  image={"/images/id-card.svg"}
                  value={arnCode.value}
                  onChange={this.changeValue("arnCode")}
                  onBlur={this.showError("arnCode")}
                  isShowError={arnCode.isError}
                  inputref={(ele) => (this.arnCodeField = ele)}
                  onKeyPress={this.keyPress("arnCode")}
                />
              </div>

              <Input
                name="Password"
                errorMessage={password.errorMessage}
                type={!!this.state.showPassword ? "text" : "password"}
                height={15}
                onClick={() =>
                  this.setState({
                    showPassword: !this.state.showPassword,
                  })
                }
                image={"/images/locked.svg"}
                image2={!!this.state.showPassword ? EYE_ICON : EYE_ICON_HIDDEN}
                value={password.value}
                onChange={this.changeValue("password")}
                onBlur={this.showError("password")}
                isShowError={password.isError}
                inputref={(ele) => (this.passwordField = ele)}
                onKeyPress={this.keyPress("password")}
              />
              <div className="password-regualations input-margin">
                <span className="pass-star">*</span> Password should be at least
                8 characters in length & should contain at least 1 uppercase
                alphabet (A-Z), 1 special character and 1 numeric character
              </div>
              <Input
                name="Confirm Password"
                errorMessage={confirmPassword.errorMessage}
                type={!!this.state.confirmShowPassword ? "text" : "password"}
                height={15}
                onClick={() =>
                  this.setState({
                    confirmShowPassword: !this.state.confirmShowPassword,
                  })
                }
                image={"/images/locked.svg"}
                image2={
                  !!this.state.confirmShowPassword ? EYE_ICON : EYE_ICON_HIDDEN
                }
                value={confirmPassword.value}
                onChange={this.changeValue("confirmPassword")}
                onBlur={this.showError("confirmPassword")}
                isShowError={confirmPassword.isError}
                inputref={(ele) => (this.confirmPasswordField = ele)}
                onKeyPress={this.keyPress("confirmPassword")}
              />
              <Signbutton
                buttontitle="Generate OTP"
                onClick={this.generateOtp}
              />
              <div className="login-no-account">
                Already have an account?{" "}
                <span
                  className="login-no-account-span mouse-pointer"
                  onClick={() => this.props.history.push(BUILD_URL + "/login")}
                >
                  SIGN IN
                </span>
              </div>
              <p
                className="Request-text"
                style={{ marginTop: 20, color: "black" }}
              >
                If you have access to Kfintech common portal then request you to
                use the same credentials to Sign In
              </p>
              {/* <div style={{ textAlign: "center", marginTop: 30 }}>
                <a
                  href="https://mfs.kfintech.com/mfs/Generalpages/KFinKart_termsconditions.aspx"
                  target="_blank"
                  style={{
                    textAlign: "center",
                    fontFamily: "SourceSansPro-Regular",
                    fontSize: 15,
                    color: "#00579b",
                    textDecoration: "underline",
                    width: "100%",
                  }}
                >
                  Terms and Conditions*
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { generateOtp })(Register);
