import React, { Component } from "react";
import CustomizedSelect from "./customizedSelect";
import Signbutton, { Debtbutton } from "../../forms/button";
import { Input } from "../../forms/input";
import {
  EXISTING,
  EXISTINGP,
  NEW,
  NEWP,
  YES,
  PAYMENT_MODE,
  REGULAR,
  PURCHASE,
  SIP_TRANS_TYPE,
  INVESTMENT_FREQUENCY,
  SIP_DAY,
  SIP,
  NO,
} from "shared/store/types";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { Checkbox } from "@material-ui/core";
import {
  validateSubBroker,
  swpOptions,
  getNewPurchaseFundList,
  getOtherSchemes,
  getAssetClasses,
  CalcSIPEnddt,
  setSipDetails,
  submitPurchaseData,
  showPurchaseConfirmation,
  getMinimumAmount,
  getCheckPanKotm,
  getCheckPanAotm,
} from "shared/store/actions";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { INVESTOR_URL } from "../investors";
const initialState = {
  fund: { value: "" },
  funds: [],
  folio: { value: "" },
  folios: [],
  category: { value: "" },
  minimumLumpsumAmout: "",
  categories: [],
  scheme: { value: "" },
  schemes: [],
  investmentFrequency: { value: "" },
  frequencies: [],
  installments: { value: "" },
  sipDay: { value: "" },
  sipDays: [],
  perpetualSip: false,
  sip_start_date: { value: "" },
  sip_end_date: { value: "" },
  installmentAmount: { value: "" },
  investmentAmount: { value: "" },
  mode: { value: "" },
  modes: [],
  minAmount: "",
  minimumInstallemnts: "0",
  code: { value: "+91" },
  kotm: false,
  selectedKotm: { value: "" },
  umrn: [],

};
class Sip extends Component {
  constructor(props) {
    super(props);
    const { sipDetails, location, fundList } = this.props;
    const { state } = location;
    const checkExisting =
      !!fundList && fundList.find((ele) => ele.Fund === "105" && !!ele.Acno);
    this.state = {
      sipTypes:
        (!!state && !!state.pan) || !checkExisting
          ? [
              { label: "New Folio - SIP Registration", value: NEWP },
              {
                label: "New Folio - SIP Registration with payment",
                value: NEW,
              },
            ]
          : [
              { label: "Existing Folio - SIP Registration", value: EXISTING },
              {
                label: "Existing Folio - SIP Registration with payment",
                value: EXISTINGP,
              },
              { label: "New Folio - SIP Registration", value: NEWP },
              {
                label: "New Folio - SIP Registration with payment",
                value: NEW,
              },
            ],

      invName: { value: "" },
      sipType: { value: "" },
      fund: { value: "" },
      funds: [],
      folio: { value: "" },
      folios: [],
      arnCode: { value: this.props.token },
      subArnCode: { value: "" },
      subBrokerCode: { value: "" },
      schemeType: EXISTING,
      category: { value: "" },
      categories: [],
      schemes: [],
      scheme: { value: "" },
      modes: [],
      mode: { value: "" },
      euinNo: { value: "" },
      radioValue: { value: "no" },
      minAmount: "",
      minAmountValue: { value: "" },
      frequencies: [],
      investmentFrequency: { value: "" },
      sipDays: [],
      sipDay: { value: "" },
      installments: { value: "" },
      sip_start_date: { value: "" },
      sip_end_date: { value: "" },
      email: { value: "" },
      mobile: { value: "" },
      installmentAmount: { value: "" },
      investmentAmount: { value: "" },
      Family_Code_Email: { value: "" },
      Family_Code_Phone: { value: "" },
      ...sipDetails,
    };
  }

  componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }
  FamilyDescriptionEmail = (event) => {
    this.setState({
      Family_Code_Email: { value: event },
    });
  };
  FamilyDescriptionPhone = (event) => {
    this.setState({
      Family_Code_Phone: { value: event },
    });
  };
  handlechangeSIPEndDateChange = () => {
    const { perpetualSip } = this.state;
    if (!perpetualSip && this.state.kotm) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sip_end_date: { value: this?.state?.selectedKotm?.value["MANDATE END DATE"] },
        installments: {
          value: "",
          placeholder: "Perpetual SIP",
          disabled: true,
        },
      });
    } else if (!perpetualSip && !this.state.kotm) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sip_end_date: { value: "31/12/2099" },
        installments: {
          value: "",
          placeholder: "Perpetual SIP",
          disabled: true,
        },
      });
    } else if (!!perpetualSip) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sipDay: { value: "" },
        sip_end_date: { value: "" },
        sip_start_date: { value: "" },
        installments: {
          value: "",
          placeholder: "",
          disabled: false,
        },
      });
    }
  };
  getUMRN = async (pass) => {
    const params = {
      Fund: "105",
      PAN: this.props.investorData.PAN,
      Acno: this.state.folio.value.value ? this.state.folio.value.value : 0,
    };
    const response =  (pass === "KOTM") ? await this.props.getCheckPanKotm(params): await this.props.getCheckPanAotm(params) ;
    const umrn = await response.Table1;
    if (umrn.length > 1) {
      const maxAmt = umrn.reduce((max, obj) => (obj.Amt > max ? obj.Amt : max), 0);
      const filteredData = umrn.filter(obj => obj.Amt === maxAmt);
      this.setState({
        selectedKotm: { value: filteredData[0] }
      })
    }
    if (umrn.length === 1) {
      this.setState({
        selectedKotm: { value: umrn[0] }
      })
    }
    this.setState({
      umrn,
    });
  };

  validateBrokerCode = (fund) => async () => {
    const { subArnCode, arnCode } = this.state;
    if (
      !!subArnCode.value &&
      subArnCode.value === arnCode.value.split("-")[1]
    ) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `Please enter a valid Sub ARN Code`,
        },
      });
    } else {
      const params = {
        Fund: "105",
        SubAgentCd: !!subArnCode.value ? `ARN-${subArnCode.value}` : "",
        AgentCd: arnCode.value,
      };
      const response = await this.props.validateSubBroker(params);

      if (!response) {
        this.setState({
          subArnCode: {
            value: "",
            isError: true,
            errorMessage: `Please enter a valid Sub ARN code`,
          },
        });
      }
    }
  };

  handleFolioChange = (value) => {
    const folio = !!value ? value.value : "";
    const { funds, fund, folios, modes, mode } = this.state;
    const state = { funds, fund, folios, modes, mode };
    if (!value) {
      this.setState({
        ...initialState,
        ...state,
        folio: { value },
      });
      return;
    } else if (
      !!value &&
      !!this.state.folio.value &&
      value.value === this.state.folio.value.value
    ) {
      return;
    } else {
      const schemes = this.schemes(folio);
      this.setState({
        ...initialState,
        ...state,
        folio: { value },
        minAmountValue: { value: "" },
        schemes,
        schemeType: EXISTING,
      });
    }
  };

  changeEuin = (value) => {
    this.setState({
      euinNo: { value },
    });
  };

  changeValue = (label) => (event) => {
    if (
      (label === "installmentAmount" || label === "investmentAmount") &&
      event.target.value.length <= 10
    ) {
      this.setState({
        [label]: { value: parseFloat(event.target.value.replace(/^0+/, "")) },
      });
    } else if (label === "code") {
      if (!/^[+]\d{1,3}/.test(event.target.value)) {
        this.setState({
          [label]: {
            value: event.target.value,
            isError: true,
            errorMessage: "Enter a valid Country Code",
          },
        });
      } else {
        this.setState({ [label]: { value: event.target.value } });
      }
    }
    if (label === "installments" && event.target.value.length <= 3) {
      if (parseFloat(event.target.value) <= 999) {
        this.setState({
          [label]: {
            value: parseFloat(event.target.value.replace(/^0+/, "")),
          },
          sipDay: { value: "" },
          sip_start_date: { value: "" },
          sip_end_date: { value: "" },
        });
      } else if (!event.target.value) {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: !!event.target.value
              ? "*No. of installments should not be more than 500"
              : "*No. of installments is Mandatory",
          },
          sipDay: { value: "" },
          sip_start_date: { value: "" },
          sip_end_date: { value: "" },
        });
      }
    } else if (label === "mobile" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label === "subArnCode" || label === "subBrokerCode") {
      if (/^\d{0,15}$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
        return;
      } else {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: "Enter a valid number",
          },
        });
        return;
      }
    } else if (label === "invName") {
      if (/^[A-Za-z ]{0,50}$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
      } else if (
        !/^[A-Za-z ]{0,50}$/.test(event.target.value) &&
        !!event.target.value
      ) {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: `Enter a valid name`,
          },
        });
        return;
      }
    } else if (label === "mode") {
      this.setState({
        sipDay: { value: "" },
        sip_start_date: { value: "" },
        sip_end_date: { value: "" },
        perpetualSip: false,
      })
      if (event.target.value === "KOTM") {
        this.getUMRN("KOTM");
      }
      if (event.target.value === "AOTM") {
        this.getUMRN("AOTM");
      }
      this.setState({
        [label]: { value: event.target.value },
        kotm: event.target.value === "KOTM"|| event.target.value === "AOTM",
        selectedKotm: { value: "" },
      });
    } else if (label === "kotm") {
      this.setState({
        selectedKotm: {
          value: event.target.value,
          selectedKotm: { value: "" },
        },
      });
    } else if (label === "radioValue" && event.target.value === "no") {
      if (!this.state.euinNo.value) {
        this.setState({
          radioValue: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: { value: event.target.value },
          euinNo: { value: "" },
        });
      }
    } else if (label === "email") {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label === "invName") {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (
      label !== "installmentAmount" &&
      label !== "invName" &&
      label !== "subArnCode" &&
      label !== "mode" &&
      label !== "installments" &&
      label !== "subBrokerCode" &&
      label !== "email" &&
      label !== "mobile" &&
      label !== "investmentAmount" &&
      label === "radioValue" &&
      event.target.value !== "no"
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  getAssetClasses = async (fund, folio) => {
    const params = {
      Fund: "105",
      Folio: folio,
    };
    const assetClasses = await this.props.getAssetClasses(params);
    if (assetClasses) {
      this.setState({
        categories: assetClasses.DtData,
      });
    }
  };

  getOtherSchemes = async (category) => {
    const params = {
      schtype: REGULAR,
      category,
      acno:
        this.state.sipType.value === NEW || this.state.sipType.value === NEWP
          ? "0"
          : this.state.folio.value.value,
      fundCode: "105",
      trantype: SIP_TRANS_TYPE,
    };

    const otherSchemes = await this.props.getOtherSchemes(params);
    const FundName = "105";

    if (otherSchemes) {
      this.setState({
        schemes: otherSchemes.DtData,
        fund: {
          ...this.state.fund,
          errorMessage:
            (FundName.trim() === "128" ||
              FundName.trim() === "116" ||
              FundName.trim() === "178") &&
            this.state.category.value === "LIQUID" &&
            otherSchemes.DtData.length === 0
              ? "Liquid Schemes are not allowed for SIP"
              : otherSchemes.DtData.length === 0 &&
                (FundName.trim() !== "128" ||
                  FundName.trim() !== "116" ||
                  FundName.trim() !== "178")
              ? "No Schemes under this fund"
              : "",
        },
      });
    }
  };
  schemes = (folio) => {
    if (
      this.state.sipType.value === EXISTING ||
      this.state.sipType.value === EXISTINGP
    ) {
      return this.props.fundList.reduce((prev, curr) => {
        if (
          parseFloat(curr.Fund) === 105 &&
          parseFloat(curr.Acno) === parseFloat(folio) &&
          curr.sipSchFlg === YES
        ) {
          return [...prev, curr];
        }
        return prev;
      }, []);
    }
  };

  handleCategoryChange = (event) => {
    const category = event.target.value;
    const { funds, fund, folios, modes, mode, folio, categories } = this.state;
    const state = {
      funds,
      fund,
      folios,
      modes,
      mode,
      folio,
      categories,
    };
    if (!category) {
      this.setState({
        ...initialState,
        ...state,
        category: { value: "" },
      });
      return;
    }
    this.setState({
      ...initialState,
      ...state,
      category: { value: category },
    });
    this.getOtherSchemes(category);
  };

  handleSipTypeChange = async (event) => {
    const sipType = event.target.value;
    const { communicationDetails, investorData, location } = this.props;
    const { state } = location;

    const detailsEmail = communicationDetails.find(
      (detail) => detail.PAN === investorData.PAN && !!detail.email
    );
    const detailsMobile = communicationDetails.find(
      (detail) => detail.PAN === investorData.PAN && !!detail.Mobile
    );

    const Setstate = {
      ...initialState,
      schemeType: EXISTING,
      invName: {
        value:
          !!investorData && !!investorData.invname ? investorData.invname : "",
      },
      email: {
        value:
          (sipType === NEW || sipType === NEWP) &&
          // !!location.state &&
          // !location.state.pan &&
          !!detailsEmail &&
          !!detailsEmail.email
            ? detailsEmail.email
            : "",
      },
      mobile: {
        value:
          (sipType === NEW || sipType === NEWP) &&
          // !!location.state &&
          // !location.state.pan &&
          !!detailsMobile &&
          !!detailsMobile.Mobile
            ? detailsMobile.Mobile
            : "",
      },
      disbleEmail:
        !!detailsEmail &&
        !!detailsEmail.email &&
        (sipType === NEW || sipType === NEWP)
          ? true
          : false,
      disbleMobile:
        !!detailsMobile &&
        (sipType === NEW || sipType === NEWP) &&
        !!detailsMobile.Mobile
          ? true
          : false,
    };

    if (!sipType) {
      this.setState({
        ...state,
        sipType: { value: event.target.value },
        minAmountValue: { value: "" },
      });
      return;
    } else if (event.target.value === this.state.sipType.value) {
      return;
    }
    const params = {
      opt: PAYMENT_MODE,
      Fund: "105",
      astscheme:
        !!this.props.investorData && !!this.props.investorData.PAN
          ? this.props.investorData.PAN
          : !!state && !!state.pan
          ? state.pan
          : "",
    };

    const folios = this.props.fundList.reduce((prev, curr) => {
      if (parseFloat(curr.Fund) === 105 && curr.sipFlag === YES) {
        return [...prev, curr.Acno];
      }
      return prev;
    }, []);

    this.setState({
      ...Setstate,
      sipType: { value: sipType },
      funds: [],
      fund: { value: { value: "105" } },
      folios: Array.from(new Set(folios)),
      folio: { value: "" },
    });
    if (!!sipType) {
      this.validateBrokerCode("105")();
    }
    if (sipType === NEW || sipType === NEWP) {
      this.getAssetClasses("105");
    }
    const response = await this.props.swpOptions(params);
    if (response) {
      const { Dtinformation } = response;
      this.setState({
        modes: Dtinformation,
        minAmountValue: { value: "" },
        mode: { value: "" },
      });
    }
  };

  changeSchemeType = (value) => () => {
    const { funds, fund, folios, modes, mode, folio } = this.state;
    const state = {
      funds,
      fund,
      folios,
      modes,
      mode,
      folio,
    };
    if (value === this.state.schemeType) {
      return;
    }
    if (value === EXISTING && !!this.state.fund.value) {
      const schemes = this.schemes(this.state.folio.value.value);
      this.setState({
        ...initialState,
        ...state,
        schemeType: value,
        minAmountValue: { value: "" },
        schemes,
      });
    } else {
      this.setState({
        ...initialState,
        minAmountValue: { value: "" },
        ...state,
        schemeType: value,
      });
      if (!!this.state.fund.value && !!this.state.folio.value) {
        this.getAssetClasses(
          this.state.sipType.value === NEW
            ? this.state.fund.value.value.amc_code
            : this.state.fund.value.value.Fund,
          this.state.folio.value.value
        );
      }
    }
  };

  handleSchemeChange = (value) => {
    const scheme = !!value ? value.value : "";

    const {
      funds,
      fund,
      folios,
      modes,
      mode,
      folio,
      categories,
      category,
      schemes,
    } = this.state;
    const state = {
      funds,
      fund,
      folios,
      modes,
      mode,
      folio,
      categories,
      category,
      schemes,
    };
    if (!value) {
      this.setState({
        ...initialState,
        ...state,
        scheme: { value: value },
      });
      return;
    }

    this.setState({
      ...initialState,
      ...state,
      scheme: { value },
      multiples: !!scheme && !!scheme.multiples ? scheme.multiples : "",
      minimumLumpsumAmout:
        this.state.sipType.value === NEW || this.state.sipType.value === NEWP
          ? scheme.fm_newpur
          : "",
    });
    const params = {
      opt: INVESTMENT_FREQUENCY,
      fund: "105",
    };
    this.getInvestmentFrequency(params, "frequencies");
  };

  getInvestmentFrequency = async (params, label) => {
    const response = await this.props.swpOptions(params);
    if (response) {
      this.setState({
        [label]: response.Dtinformation,
      });
    }
  };

  changeFrequency = async (event) => {
    const investmentFrequency = event.target.value;
    let minAmount = "";
    let minInstallment="";
    const {
      funds,
      fund,
      folios,
      modes,
      mode,
      folio,
      categories,
      category,
      schemes,
      scheme,
      frequencies,
    } = this.state;
    const state = {
      funds,
      fund,
      folios,
      modes,
      mode,
      folio,
      categories,
      category,
      schemes,
      scheme,
      frequencies,
    };
    if (!investmentFrequency) {
      this.setState({
        ...initialState,
        ...state,
        installments: { value: "" },
        sip_end_date: { value: "" },
        sip_start_date: { value: "" },
        sipDay: { value: "" },
        investmentFrequency: { value: investmentFrequency },
      });
      return;
    }
    if (this.state.sipType.value === NEW || this.state.sipType.value === NEWP) {
      const minParams = {
        Fund: "105",
        Scheme: this.state.scheme.value.value.fm_scheme,
        Trtype: "SIP",
        Frequency: event.target.value.FrequencyID,
      };
      const response = await this.props.getMinimumAmount(minParams);

      if (response) {
        const { DtData } = response;
        minAmount = DtData[0].minamt;
        minInstallment = DtData[0].mininst;
      }
    }
    if (
      this.state.sipType.value === EXISTING ||
      this.state.sipType.value === EXISTINGP
    ) {
      const minParams = {
        Fund: "105",

        Scheme:
          this.state.scheme.value.value.Sch ||
          this.state.scheme.value.value.fm_scheme,
        Trtype: "SIP",
        Frequency: event.target.value.FrequencyID,
      };

      const response = await this.props.getMinimumAmount(minParams);

      if (!!response && !response.error) {
        const { DtData } = response;
        minAmount = DtData[0].minamt;
        minInstallment = DtData[0].mininst;
      }
    }

    this.setState({
      investmentFrequency: { value: investmentFrequency },
      installments: { value: "" },
      sipDay: { value: "" },
      sip_end_date: { value: "" },
      sip_start_date: { value: "" },
      minAmountValue: { value: minAmount || "" },
      minimumInstallemnts:minInstallment|| "",
      minimumLumpsumAmout: this.state.minimumLumpsumAmout,
    });

    const params = {
      opt: SIP_DAY,
      fund: "105",
      astscheme: investmentFrequency.FrequencyID,
    };
    this.getInvestmentFrequency(params, "sipDays");
  };

  changeSipDay = (value) => {
    const sipDay = !!value ? value.value : "";
    this.setState({
      sipDay: { value: value },
      investmentAmount: { value: "" },
    });
    if (!!sipDay) {
      this.getSipEndDate(sipDay);
    } else if (!this.state.installments.value) {
      this.setState({
        sipDay: { value: "" },

        installments: {
          isError: true,
          errorMessage:
            "*Please enter minimum no. of installments as mentioned above",
        },
      });
      this.installmentsField.focus();
    }
  };

  getSipEndDate = async (sipDay) => {
    const params = {
      fund: "105",

      sch:
        this.state.scheme.value.value.Sch ||
        this.state.scheme.value.value.fm_scheme,
      StartDate: sipDay,
      Installments: this.state.installments.value,
      Frequency: this.state.investmentFrequency.value.FrequencyID,
      trtype: "SIP",
      modeofregistration:this.state.mode.value,
    };
    const response = await this.props.CalcSIPEnddt(params);
    if (response) {
      const {
        DtData: [dates],
      } = response;
      const { SIP_EndDate, SIP_StartDate } = dates;
      if (!!SIP_EndDate) {
        const reformSIPEndDT = SIP_EndDate.split("/");
        if (parseFloat(reformSIPEndDT[2]) >= 2099) {
          this.setState({
            sipDay: { value: "" },
            sip_start_date: { value: "" },
            sip_end_date: { value: "" },
            installments: {
              value: "",
              isError: true,
              errorMessage:
                "*Your SIP installments are going beyond 2099, select lesser number of installments as mentioned above.",
            },
          });
          this.installmentsField.focus();
        } else if (parseFloat(reformSIPEndDT[2]) < 2099) {
          this.setState({
            sip_start_date: { value: SIP_StartDate },
            sip_end_date: { value: SIP_EndDate },
          });
        }
      }
    }
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;
    if (label === "installmentAmount") {
     if (Number(this.state.selectedKotm.value.Amt) < Number(this.state.installmentAmount.value)) {
        this.setState({
          installmentAmount: {
            value: "",
            isError: true,
            errorMessage: `SIP installment amount cannot exceed
          mandate registration limit of ${this.state.selectedKotm.value.Amt}.`,
          },
        });
        this.installmentAmount.focus();
        return;
      }
  
  
      if (!this.state.installmentAmount.value) {
        this.setState({
          [label]: {
            isError: true,
            errorMessage: "*Minimum SIP Amount is Mandatory",
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError:
              parseFloat(this.state.installmentAmount.value) <
              parseFloat(this.state.minAmountValue.value)
                ? true
                : !value,

            errorMessage:
              parseFloat(this.state.installmentAmount.value) <
              parseFloat(this.state.minAmountValue.value)
                ? `*Minimum SIP Amount should be atleast ₹${this.state.minAmountValue.value} as mentioned above`
                : "",
          },
        });
      }
    } else if (label === "email") {
      this.setState({
        [label]: {
          value,
          isError: !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            this.state.email.value
          )
            ? true
            : !value,
          errorMessage: !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
            this.state.email.value
          )
            ? `*Enter a vaild Email ID`
            : "",
        },
      });
    } else if (label === "email") {
      if (
        /^(?!.{51})([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?))*$/.test(
          this.state.email.value
        )
      ) {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: ``,
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `*Enter a valid Email Id`,
          },
        });
      }
    } else if (label === "mobile") {
      if (
        /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
          this.state.mobile.value
        )
      ) {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: ``,
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `Enter a valid Phone Number`,
          },
        });
      }
    } else if (label === "installments") {
      if (
        !!this.state.investmentFrequency.value &&
        this.state.installments.value < this.state.minimumInstallemnts
      ) {
        this.setState({
          installments: {
            value: "",
            isError: true,
            errorMessage:
              "*No. of installments should be more than the minimum installments as mentioned above",
          },
        });
        this.installmentsField.focus();
      }
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
          errorMessage: `*${label} is Mandatory`,
        },
      });
    }
  };
  getMinimumInstallments = async () => {
    var minInstallment;
    if (!this.state.installmentAmount.value) {
      this.setState({
        installmentAmount: {
          isError: true,
          errorMessage: "*Minimum SIP Amount is Mandatory",
        },
      });
    } else if (
      parseFloat(this.state.installmentAmount.value) <
      parseFloat(this.state.minAmountValue.value)
    ) {
      this.setState({
        installmentAmount: {
          value: this.state.installmentAmount.value,
          isError: true,
          errorMessage: `*Minimum SIP Amount should be atleast ₹${this.state.minAmountValue.value} as mentioned above`,
        },
      });
    } else {
      if (
        this.state.sipType.value === NEW ||
        this.state.sipType.value === NEWP
      ) {
        const minParams = {
          Fund: "105",
          Scheme: this.state.scheme.value.value.fm_scheme,
          Trtype: "SIP",
          Frequency: this.state.investmentFrequency.value.FrequencyID,
          Amount: this.state.installmentAmount.value,
        };
        const response = await this.props.getMinimumAmount(minParams);

        if (response) {
          const { DtData } = response;
          minInstallment = DtData[0].mininst;
          this.setState({
            minimumInstallemnts: minInstallment,
            installments: { value: "" },
          });
        }
      }
      if (
        this.state.sipType.value === EXISTING ||
        this.state.sipType.value === EXISTINGP
      ) {
        const minParams = {
          Fund: "105",

          Scheme:
            this.state.scheme.value.value.Sch ||
            this.state.scheme.value.value.fm_scheme,
          Trtype: "SIP",
          Frequency: this.state.investmentFrequency.value.FrequencyID,
          Amount: this.state.installmentAmount.value,
        };

        const response = await this.props.getMinimumAmount(minParams);

        if (!!response && !response.error) {
          const { DtData } = response;
          minInstallment = DtData[0].mininst;
          this.setState({
            minimumInstallemnts: minInstallment,
            installments: { value: "" },
          });
        }
      }
    }
  };
  submit = async () => {
    const {
      sipTypes,
      funds,
      fund,
      folio,
      folios,
      arnCode,
      subArnCode,
      invName,
      installments,
      email,
      subBrokerCode,
      schemeType,
      category,
      mobile,
      categories,
      schemes,
      modes,
      euinNo,
      perpetualSip,
      radioValue,
      minimumLumpsumAmout,
      minAmount,
      minAmountValue,
      frequencies,
      sipDays,
      investmentAmount,
      minimumInstallemnts,
      disbleEmail,
      disbleMobile,
      multiples,
      code,
      Family_Code_Email,
      Family_Code_Phone,
      kotm,
      selectedKotm,
      umrn,

      ...others
    } = this.state;

    const { location } = this.props;
    const { state } = location;
    let d1,d2
    const split_enddate = this?.state?.sip_end_date?.value.split("/")
    const split_mandatedate = this?.state?.selectedKotm?.value["MANDATE END DATE"]?.split("/")
     d1 = new Date(Number(split_enddate[2]), Number(split_enddate[1]) - 1, Number(split_enddate[0]))
    if(this?.state?.selectedKotm?.value["MANDATE END DATE"]?.split("/")){
     d2 = new Date(Number(split_mandatedate[2]), Number(split_mandatedate[1]) - 1, Number(split_mandatedate[0]))
    }
    if (!this.state.installmentAmount.value) {
      this.setState({
        installmentAmount: {
          value: "",
          isError: true,
          errorMessage: "installmentAmount  is mandatory",
        },
      });
    }
    if (
      !this.state.selectedKotm.value &&
      kotm
    ) {
      this.setState({
        selectedKotm: {
          value: "",
          isError: true,
          errorMessage: "UMRN is mandatory*",
        },
      });
    }

    if (!this.state.email.value) {
      this.setState({
        email: {
          value: "",
          isError: true,
          errorMessage: "*Email ID is Mandatory",
        },
      });
    }
    if (
      !/^[+]\d{1,3}/.test(this.state.code.value) &&
      (this.state.sipType.value === NEW || this.state.sipType.value === NEWP)
    ) {
      this.setState({
        code: {
          value: "",
          isError: true,
          errorMessage: !code.value
            ? "Country Code is Mandatory"
            : "Enter a valid Country Code",
        },
      });
      return;
    }
    if (!this.state.invName.value) {
      this.setState({
        invName: {
          value: "",
          isError: true,
          errorMessage: "*Investor name  is Mandatory",
        },
      });
    }
    if (!this.state.mobile.value) {
      this.setState({
        mobile: {
          value: "",
          isError: true,
          errorMessage: "*Mobile is Mandatory",
        },
      });
    }

    if (!this.state.folio.value) {
      this.setState({
        folio: {
          value: "",
          isError: true,
          errorMessage: "*Folio is Mandatory",
        },
      });
    }
    if (!this.state.category.value) {
      this.setState({
        category: {
          value: "",
          isError: true,
          errorMessage: "*Category is Mandatory",
        },
      });
    }
    if (!this.state.investmentAmount.value) {
      this.setState({
        investmentAmount: {
          value: "",
          isError: true,
          errorMessage: "*Investment amount is Mandatory",
        },
      });
    }
    if (!this.state.euinNo.value) {
      this.setState({
        euinNo: {
          value: "",
          isError: true,
          errorMessage: "*EUIN Declaration is Mandatory",
        },
      });
    }

    const keys = Object.keys(others);

    const findError = keys.every((key) => !!others[key].value);

    const arn = this.state.arnCode.value.split("-");
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "sipType"
                  ? "*SIP Type"
                  : key === "scheme"
                  ? "*Scheme"
                  : key === "investmentFrequency"
                  ? "*Investment Frequency"
                  : key === "sipDay"
                  ? "*SIP day"
                  : key === "sip_start_date"
                  ? "*SIP Start Date"
                  : key === "sip_end_date"
                  ? "*SIP End Date"
                  : key === "installmentAmount"
                  ? "*Installment Amount"
                  : key === "mode"
                  ? "*Mode of Registration"
                  : key
              } is Mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "sipType":
          this.sipTypeField.focus();
          break;
        case "scheme":
          this.schemeField.focus();
          break;
        case "investmentFrequency":
          this.investmentFrequency.focus();
          break;

        case "sipDay":
          this.sipDay.focus();
          break;
        case "installmentAmount":
          this.setState({
            installmentAmount: {
              isError: true,
              errorMessage: `*Installment Amount is Mandatory`,
            },
          });
          this.installmentAmount.focus();
          break;
        case "mode":
          this.modeField.focus();
          break;
        case "email":
          this.emailField.focus();
          break;
        case "mobile":
          this.mobileField.focus();
          break;
        default:
          break;
      }
    } else if (
      invName.value === "" &&
      (this.state.sipType.value === NEW || this.state.sipType.value === NEWP)
    ) {
      this.setState({
        invName: {
          value: "",
          isError: true,
          errorMessage: "*Investor name is Mandatory",
        },
      });
      this.invNameField.focus();
      return;
    } else if (others.sipType === EXISTING && !folio.value) {
      this.setState({ folio: { ...folio, isError: true } });
      this.folioField.focus();
      return;
    } else if (
      (others.sipType.value === NEW || schemeType === NEW) &&
      !category.value
    ) {
      this.setState({ category: { ...category, isError: true } });
      this.categoryField.focus();
      return;
    } else if (
      (others.sipType.value === NEW || others.sipType.value === NEWP) &&
      (!email.value ||
        !/^(?!.{51})([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?))*$/.test(
          email.value
        ))
    ) {
      this.setState({
        email: {
          ...email,
          isError: true,
          errorMessage: "*Please enter a valid Email ID",
        },
      });
      this.emailField.focus();
      return;
    } else if (
      others.sipType.value === NEW &&
      (!mobile.value ||
        !/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
          mobile.value
        ))
    ) {
      this.setState({ mobile: { ...mobile, isError: true } });
      this.mobileField.focus();
      return;
    } else if (radioValue.value === "no" && !euinNo.value) {
      this.setState({
        euinNo: { ...euinNo, isError: true },
      });
      this.euinField.focus();
      return;
    } else if (
      (others.sipType.value === NEW || others.sipType.value === EXISTINGP) &&
      (!investmentAmount.value ||
        parseFloat(investmentAmount.value) <
          parseFloat(this.state.minimumLumpsumAmout))
    ) {
      if (
        parseFloat(investmentAmount.value) <
        parseFloat(this.state.minimumLumpsumAmout)
      ) {
        this.setState({
          investmentAmount: {
            value: "",
            isError: true,
            errorMessage: `Lumpsum amount should be more than ₹${this.state.minimumLumpsumAmout}`,
          },
        });
      } else {
        this.setState({
          investmentAmount: { ...investmentAmount, isError: true },
        });
      }

      this.investmentAmount.focus();
      return;
    } else if (arn[1] === this.state.subArnCode.value) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `and ARN code should  not match `,
        },
      });
    }else if (d1 > d2) {
      this.setState({
        sip_end_date: {
          value: "",
          isError: true,
          errorMessage: `SIP end date cannot exceed mandate validity
          date of ${selectedKotm.value["MANDATE END DATE"]}.`,
        },
      });
      return;
    } else if (+this.state.selectedKotm.value.Amt < +this.state.installmentAmount.value) {
      this.setState({
        installmentAmount: {
          value: "",
          isError: true,
          errorMessage: `SIP installment amount cannot exceed
        mandate registration limit of ${this.state.selectedKotm.value.Amt}.`,
        },
      });
      this.installmentAmount.focus();
      return;
    } else if (
      !!others.investmentFrequency.value &&
      !!minimumInstallemnts &&
      this.state.installments.value < minimumInstallemnts &&
      !this.state.perpetualSip
    ) {
      this.installmentsField.focus();
      this.setState({
        installments: {
          value: "",
          isError: true,
          errorMessage: `*Please check minimum no. of installments mentioned above`,
        },
      });
    } else if (
      this.state.minAmountValue.value > this.state.installmentAmount.value
    ) {
      this.setState({
        installmentAmount: {
          value: "",
          isError: true,
          errorMessage: `*Minimum SIP amount is ₹${this.state.minAmountValue.value} as mentioned above`,
        },
      });
      this.installmentAmount.focus();
      return;
    } else {
      this.props.setSipDetails(this.state);
      const { location } = this.props;
      const { state } = location;
      if (
        others.sipType.value === NEW ||
        others.sipType.value === NEWP ||
        others.sipType.value === EXISTINGP
      ) {
        const { investorData } = this.props;
        const params = {
          InvestorName: this.state.invName.value || "",
          i_entby: this.props.userId,
          trtype: PURCHASE,
          Desci: "Yes~P~",
          SubbrokerArn: !!this.state.subArnCode.value
            ? `ARN-${subArnCode.value}`
            : "",
          ARNCode: this.state.arnCode.value,
          EuinCode:
            this.state.radioValue.value === "no"
              ? this.state.euinNo.value.value
              : "",
          i_Userid: this.props.userId,
          Mobile: this.state.mobile.value,
          PanNo: investorData.PAN || state.pan,
          Email: this.state.email.value,
 i_Acno:  this.state.sipType.value === EXISTINGP
          ? this.state.folio.value.value
          : "0",
          Subbroker: this.state.subBrokerCode.value,
          i_Fund: "105",
          i_UntAmtValue:
            this.state.sipType.value === NEW ||
            this.state.sipType.value === EXISTINGP
              ? this.state.investmentAmount.value
              : "",
          EUINFlag: this.state.radioValue.value === "no" ? NO : YES,
          EuinValid: this.state.radioValue.value === "no" ? NO : YES,
          noofinstallments:this.state.installments.value,
          i_Scheme:
            this.state.scheme.value.value.Sch ||
            this.state.scheme.value.value.fm_scheme,
          i_Plan:
            this.state.scheme.value.value.Pln ||
            this.state.scheme.value.value.fm_plan,
          i_Option:
            this.state.scheme.value.value.Opt ||
            this.state.scheme.value.value.fm_option,
          i_oldihno: "0",
          i_InvDistFlag: "P",
          sipenddt: new Date(
            this.state.sip_end_date.value.split("/").reverse().join("/")
          ).toLocaleDateString("en-US"),
          sipstartdt: new Date(
            this.state.sip_start_date.value.split("/").reverse().join("/")
          ).toLocaleDateString("en-US"),
          sipfrequency: this.state.investmentFrequency.value.FrequencyID,
          sipamount: this.state.installmentAmount.value,
          ModuleName: "Webile",
          paymode: this.state.mode.value,
          paymenttype: this.state.mode.value,
          countrycode: this.state.code.value,
          fEmailFlag: this.state.sipType.value === EXISTINGP?"":this.state.Family_Code_Email.value.value,
          fMobileFlag: this.state.sipType.value === EXISTINGP?"":this.state.Family_Code_Phone.value.value,
          UMRN: this.state.selectedKotm?.value?.UMRNNO,

        };
        const response = await this.props.submitPurchaseData(params);
        if (!!response && !response.error) {
          const {
            DtData: [purchaseData],
          } = response;
          const confirmationParams = {
            Trtype: PURCHASE,
            fund: "105",
            Refno: purchaseData.refno,
            ModuleName: "Webile",
          };
          const confirmationResponse =
            await this.props.showPurchaseConfirmation(confirmationParams);

          if (!!confirmationResponse && !confirmationResponse.error) {
            this.props.history.push({
              pathname: INVESTOR_URL + "/transction-types/sip/confirm",
              state: {
                title: "SIP",
                type: SIP,
                folio: {
                  value:
                    this.state.sipType.value === EXISTINGP
                      ? this.state.folio.value
                      : "",
                },
                paymenttype: this.state.mode.value,
                InvestorEmail:
                  this.state.sipType.value === NEW ||
                  this.state.sipType.value === NEWP
                    ? this.state.email.value
                    : "",
                InvestorMobile:
                  this.state.sipType.value === NEW ||
                  this.state.sipType.value === NEWP
                    ? this.state.mobile.value
                    : "",
                arnCode: this.state.arnCode.value,
                scheme: {
                  Fund: "105",
                },
              },
            });
          } else if (!!confirmationResponse && !!confirmationResponse.error) {
            alert(`${confirmationResponse.error}`);
          }
        } else if (!!response && !!response.error) {
          alert(`${response.error}`);
        }
      } else {
        this.props.history.push({
          pathname: INVESTOR_URL + "/transction-types/sip/confirm",
          state: {
            title: "SIP",
            type: SIP,
            InvestorEmail:
              this.state.sipType.value === NEW ||
              this.state.sipType.value === NEWP
                ? this.state.email.value
                : "",
            InvestorMobile:
              this.state.sipType.value === NEW ||
              this.state.sipType.value === NEWP
                ? this.state.mobile.value
                : "",
            arnCode: this.state.arnCode.value,
            Folio: { value: this.state.folio.value.value },
            scheme: {
              Fund: "105",
            },
            UMRN: this.state.selectedKotm.value.UMRNNO,
          },
        });
      }
    }
  };

  render() {
    const {
      sipType,
      schemeType,
      fund,
      sipTypes,
      funds,
      folios,
      folio,
      arnCode,
      subArnCode,
      subBrokerCode,
      modes,
      mode,
      categories,
      category,
      schemes,
      scheme,
      euinNo,
      radioValue,
      minAmount,
      frequencies,
      investmentFrequency,
      sipDays,
      sipDay,
      installments,
      sip_end_date,
      sip_start_date,
      installmentAmount,
      investmentAmount,
      email,
      mobile,
      invName,
      Family_Code_Email,
      Family_Code_Phone,
      kotm,
      selectedKotm,
      code,
    } = this.state;
    const { euinList } = this.props;
    const { location } = this.props;
    const { state } = location;
    return (
      <div className="transaction-details">
        <div className="title">SIP Registration</div>
        <div className="input-margin">
          <CustomizedSelect
            placeholder="Select SIP Type"
            name="SIP Type*"
            bordercolor="rgba(0, 0, 0, 0.5)"
            handleChange={this.handleSipTypeChange}
            value={sipType.value}
            inputRef={(ele) => (this.sipTypeField = ele)}
            isError={sipType.isError}
            errorMessage={sipType.errorMessage}
          >
            {sipTypes.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>

        {(sipType.value === NEW || sipType.value === NEWP) && (
          <div className="input-margin">
            <div className="input-margin">
              <Input
                name="Investor Name*"
                value={invName.value}
                onChange={this.changeValue("invName")}
                // onBlur={this.showError("invName")}
                errorMessage={invName.errorMessage}
                isShowError={invName.isError}
                inputref={(ele) => (this.invNameField = ele)}
              />
            </div>
            <div className="input-margin">
              <div className="input-name input-integrated-label">
                Email Family Description
              </div>
              <IntegrationReactSelect
                suggestions={family_description_codes.map((item) => ({
                  label: item.family_description,
                  value: item.family_code,
                }))}
                value={Family_Code_Email.value}
                placeholder="Select Family Description"
                handleChange={this.FamilyDescriptionEmail}
              />
            </div>
            <div className="input-margin">
              <Input
                name="Email ID*"
                value={email.value}
                onChange={this.changeValue("email")}
                onBlur={this.showError("email")}
                errorMessage={email.errorMessage}
                isShowError={email.isError}
                inputref={(ele) => (this.emailField = ele)}
              />
            </div>
            <div className="input-margin">
              <div className="input-name input-integrated-label">
                Mobile Family Description
              </div>
              <IntegrationReactSelect
                suggestions={family_description_codes.map((item) => ({
                  label: item.family_description,
                  value: item.family_code,
                }))}
                value={Family_Code_Phone.value}
                placeholder="Select Phone Description"
                handleChange={this.FamilyDescriptionPhone}
              />
            </div>

            <div className="input-margin">
              <Input
                name="Country Code"
                bordercolor="#a6a6a6"
                value={this.state.code.value}
                onChange={this.changeValue("code")}
                onBlur={this.showError("code")}
                isShowError={this.state.code.isError}
                errorMessage={this.state.code.errorMessage}
                inputref={(ele) => (this.codeField = ele)}
              />
            </div>
            <div className="input-margin">
              <Input
                name="Mobile*"
                value={mobile.value}
                onKeyDown={(evt) =>
                  (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                  evt.preventDefault()
                }
                type="number"
                onChange={this.changeValue("mobile")}
                onBlur={this.showError("mobile")}
                isShowError={mobile.isError}
                errorMessage={mobile.errorMessage}
                inputref={(ele) => (this.mobileField = ele)}
              />
            </div>
          </div>
        )}

        {/* <div className="input-margin">
          <div className="input-name ">AMC</div>
          <SundaramMutualFund />
        </div> */}
        {(sipType.value === EXISTING || sipType.value === EXISTINGP) && (
          <div>
            <div className="input-margin">
              <div className="input-name ">Folio*</div>
              <IntegrationReactSelect
                suggestions={folios.map((item) => ({
                  label: item,
                  value: item,
                }))}
                value={folio.value}
                placeholder="Select Folio"
                handleChange={this.handleFolioChange}
                inputRef={(ele) => (this.folioField = ele)}
                disabled={!fund.value}
                isError={folio.isError}
                errorMessage={folio.errorMessage}
              />
            </div>
            <div className="input-name">Select Scheme Type</div>
            <div className="radio-buttons-div">
              <Debtbutton
                buttontitle="Existing Scheme"
                width={150}
                active={schemeType === EXISTING}
                onClick={this.changeSchemeType(EXISTING)}
              />
              <Debtbutton
                buttontitle="New Scheme"
                width={150}
                active={schemeType === NEW}
                onClick={this.changeSchemeType(NEW)}
              />
            </div>
          </div>
        )}

        {(schemeType === NEW ||
          sipType.value === NEW ||
          sipType.value === NEWP) && (
          <div className="input-margin">
            <CustomizedSelect
              placeholder="Select Category"
              name="Category*"
              disabled={sipType.value === EXISTING ? !folio.value : !fund.value}
              bordercolor="rgba(0, 0, 0, 0.5)"
              value={category.value}
              handleChange={this.handleCategoryChange}
              inputRef={(ele) => (this.categoryField = ele)}
              isError={category.isError}
              errorMessage={category.errorMessage}
            >
              {categories.map((item, index) => (
                <MenuItem value={item.CatValue} key={index}>
                  {item.CatDesc}
                </MenuItem>
              ))}
            </CustomizedSelect>
          </div>
        )}
        <div className="input-margin">
          <div className="input-name ">Scheme*</div>
          <IntegrationReactSelect
            suggestions={schemes.map((item) => ({
              label: item.PlnDesc
                ? `${item.SchDesc1} - ${item.PlnDesc} - ${item.OptDesc}`
                : item.fm_schdesc,
              value: item,
            }))}
            value={scheme.value}
            placeholder="Select Scheme"
            // handleInputchange={this.handleInputChange}
            handleChange={this.handleSchemeChange}
            disabled={
              schemeType === EXISTING &&
              (sipType.value === EXISTING || sipType.value === EXISTINGP)
                ? !folio.value
                : !category.value
            }
            inputRef={(ele) => (this.schemeField = ele)}
            isError={scheme.isError}
            errorMessage={scheme.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="ARN Code"
            value={arnCode.value.split("-")[1]}
            disabled={true}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Sub ARN Code"
            disabled={!fund.value}
            value={subArnCode.value}
            isShowError={subArnCode.isError}
            errorMessage={subArnCode.errorMessage}
            onBlur={this.validateBrokerCode(
              !!fund.value
                ? this.state.sipType.value === NEW
                  ? fund.value.value.amc_code
                  : fund.value.value.Fund
                : ""
            )}
            onChange={this.changeValue("subArnCode")}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Sub Broker Code"
            value={subBrokerCode.value}
            isShowError={subBrokerCode.isError}
            errorMessage={subBrokerCode.errorMessage}
            onChange={this.changeValue("subBrokerCode")}
          />
        </div>

        <div className="input-field-container" style={{ marginBottom: 5 }}>
          <div className="input-name">EUIN Declaration*</div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Search"
              name="search"
              value={radioValue.value}
              style={{ flexDirection: "row" }}
              onChange={this.changeValue("radioValue")}
            >
              <div className="investor-radio-button">
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">YES</div>
              </div>
              <div className="investor-radio-button">
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">NO</div>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        {radioValue.value === "no" ? (
          <div className="input-margin">
            <IntegrationReactSelect
              suggestions={euinList.map((item) => ({
                value: item.abm_agent,
                label: item.abm_agent,
              }))}
              value={euinNo.value}
              placeholder="Select EUIN"
              errorMessage={euinNo.errorMessage}
              handleChange={this.changeEuin}
              disabled={!euinList.length}
              inputRef={(ele) => (this.euinField = ele)}
              isError={euinNo.isError}
            />
          </div>
        ) : (
          <div className="euin-text">
            I/We hereby confirm that the EUIN box has been intentionally left
            the blank by me/us as this is an 'execution-only' transaction
            without any interaction or advice by any personnel of the above
            distributor or notwithstanding the advice of in-appropriateness,if
            any provided by any personnel of the distributor and the distributor
            has not charged any advisory fees on this transaction
          </div>
        )}

        <div className="input-margin">
          <CustomizedSelect
            placeholder="Investment Frequency*"
            name="Investment Frequency"
            value={investmentFrequency.value}
            handleChange={this.changeFrequency}
            // onBlur={this.showError("investmentFrequency")}
            disabled={!scheme.value}
            inputRef={(ele) => (this.investmentFrequency = ele)}
            isError={investmentFrequency.isError}
            errorMessage={investmentFrequency.errorMessage}
          >
            {frequencies.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item.FrequencyDescription}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>
        <div className="input-margin">
          <Input
            name={`Minimum SIP amount* (₹${
              !!this.state.minAmountValue.value
                ? this.state.minAmountValue.value
                : ""
            })`}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            bordercolor="rgba(0, 0, 0, 0.5)"
            value={installmentAmount.value}
            onChange={this.changeValue("installmentAmount")}
            type="number"
            onBlur={this.getMinimumInstallments}
            isShowError={installmentAmount.isError}
            inputref={(ele) => (this.installmentAmount = ele)}
            errorMessage={installmentAmount.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            value={installments.value}
            label={`(Minimum ${this.state.minimumInstallemnts || ""})`}
            name={"No. of installments*"}
            type="number"
            bordercolor="rgba(0, 0, 0, 0.5)"
            onChange={this.changeValue("installments")}
            onBlur={this.showError("installments")}
            placeholder={installments.placeholder}
            disabled={installments.disabled}
            isShowError={installments.isError}
            inputref={(ele) => (this.installmentsField = ele)}
            errorMessage={installments.errorMessage}
          />
        </div>
        <div className="input-margin">
          <CustomizedSelect
            placeholder="Mode of Registration"
            name="Mode of Registration"
            // disabled={!fund.value}
            bordercolor="rgba(0, 0, 0, 0.5)"
            value={mode.value}
            handleChange={this.changeValue("mode")}
            isError={mode.isError}
          >
            {modes.map((item, index) => (
              <MenuItem value={item.Pay_Mode} key={index}>
                {item.Pay_Mode}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>
        {kotm && (
          <div>
            <div className="input-margin">
              <CustomizedSelect
                placeholder="select UMRN"
                name="UMRN"
                borderColor="#a6a6a6"
                value={selectedKotm.value}
                handleChange={this.changeValue("kotm")}
                inputref={(ele) => (this.kotmField = ele)}
                isError={selectedKotm.error}
                errorMessage={selectedKotm.errorMessage}
                disabled={this.state?.umrn.length === 1 ? true : false}
              >
                {this.state.umrn.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item.UMRNNO}
                  </MenuItem>
                ))}
              </CustomizedSelect>
            </div>
            <div>
              {this.state?.umrn.length === 1 &&
                <div className="euin-text">The investor has only one active KOTM mandate.</div>
              }
            </div>
          </div>
        )}
         {kotm && (
          <div className="redemption-details">
            <div className="keys">
              <div>Mandate amount limit</div>
              <div>Start Date</div>
              <div>End Date</div>
              <div>Bank Name</div>
              <div>Bank Account No</div>
            </div>
            <div className="values">
              <div>
                {selectedKotm.value ? selectedKotm.value.Amt : ""}
                (INR)
              </div>
              <div>
                {selectedKotm.value
                  ? selectedKotm.value["MANDATE START DATE"]
                  : ""}{" "}
              </div>
              <div>
                {selectedKotm.value
                  ? selectedKotm.value["MANDATE END DATE"]
                  : ""}
              </div>
              <div>
                {selectedKotm.value ? selectedKotm.value["BANK NAME"] : ""}
              </div>{" "}
              <div>
                {selectedKotm.value ? selectedKotm.value["BANK A/C NO"] : ""}
              </div>
            </div>
          </div>
        )}

        <div className="input-margin">
          <div className="input-name ">SIP Date*</div>
          <IntegrationReactSelect
            suggestions={sipDays.map((item) => ({
              value: item.sip_cycleid,
              label: item.sip_cycleid,
            }))}
            value={sipDay.value}
            placeholder="SIP Day"
            handleChange={this.changeSipDay}
            disabled={!investmentFrequency.value || !installments.value}
            inputRef={(ele) => (this.sipDay = ele)}
            isError={sipDay.isError}
            errorMessage={sipDay.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="SIP Start Date*"
            disabled={true}
            value={sip_start_date.value}
            isShowError={sip_start_date.isError}
            errorMessage={sip_start_date.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="SIP End Date*"
            disabled={true}
            errorMessage={sip_end_date.errorMessage}
            value={sip_end_date.value}
            isShowError={sip_end_date.isError}
          />
        </div>
        {!!sipDay && !!sipDay.value && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              onClick={this.handlechangeSIPEndDateChange}
              value={!!this.state.perpetualSip}
              checked={!!this.state.perpetualSip}
            />
            <div style={{ fontFamily: "OpenSans-SemiBold", fontSize: 14 }}>
              Perpetual SIP
            </div>
          </div>
        )}
        <div className="redemption-details">
          <div className="keys">
            <div>Minimum SIP Amount </div>
            {(sipType.value === NEW || sipType.value === EXISTINGP) && (
              <div>Minimum Lumpsum Investment Amount </div>
            )}
          </div>
          <div className="values">
            <div>
              {!!this.state.minAmountValue.value
                ? this.state.minAmountValue.value
                : ""}
              (₹)
            </div>
            {(sipType.value === NEW || sipType.value === EXISTINGP) && (
              <div>{this.state.minimumLumpsumAmout}(₹)</div>
            )}
          </div>
        </div>

        {(sipType.value === NEW || sipType.value === EXISTINGP) && (
          <Input
            name="Lumpsum Investment Amount* (₹)"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            bordercolor="rgba(0, 0, 0, 0.5)"
            value={investmentAmount.value}
            onChange={this.changeValue("investmentAmount")}
            type="number"
            onBlur={this.showError("investmentAmount")}
            isShowError={investmentAmount.isError}
            inputref={(ele) => (this.investmentAmount = ele)}
            errorMessage={investmentAmount.errorMessage}
          />
        )}
        <div className="digital-form-button-next-div">
          <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const { fundList, token, userId, investorData, communicationDetails } =
      authReducer;
    const { euinList, fundsList, sipDetails = {} } = transactions;
    return {
      fundList,
      token,
      userId,
      euinList,
      investorData,
      communicationDetails,
      fundsList,
      sipDetails,
    };
  },
  {
    validateSubBroker,
    swpOptions,
    getNewPurchaseFundList,
    getOtherSchemes,
    getAssetClasses,
    CalcSIPEnddt,
    setSipDetails,
    getCheckPanKotm,
    getCheckPanAotm,
    submitPurchaseData,
    showPurchaseConfirmation,
    getMinimumAmount,
  }
)(Sip);
export const family_description_codes = [
  {
    family_description: "Self",
    family_code: "SE",
  },
  {
    family_description: "Spouse",
    family_code: "SP",
  },
  {
    family_description: "Dependent Children",
    family_code: "DC",
  },
  {
    family_description: "Dependent Siblings",
    family_code: "DS",
  },
  {
    family_description: "Dependent Parents",
    family_code: "DP",
  },
  {
    family_description: "Guardian",
    family_code: "GD",
  },
];
