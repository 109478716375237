import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getTransactionHistory,
  getTrackDetails,
  RemoveTransactionData
} from "shared/store/actions";
import { Paper } from "@material-ui/core";
import { Debtbutton, DebtbuttonActive } from "../../forms/button";
import { NEW_TRANSACTION_URL } from "../transactions";
import { FormatCurrency } from "../../home/currencyFormatter";
import { PerformanceModal } from "../customModal";
import { BUILD_URL } from "../../forms/appEssentials";
import CustomLoader from "../../forms/customLoader";
import { dateTimePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import { NavigateNext } from "@material-ui/icons";

class HistoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      showError: false,
      openPerformanceModal: false,
      TrackData: [],
      display: false,
    };
  }

  async componentDidMount() {
    const { state } = this.props.location;
    const { isKyc } = state;
    if (!!isKyc) {
      await this.props.RemoveTransactionData()
      this.getTransactions("105");
    } else {
      this.setState({ showError: true });
    }
  }

  getTransactions = async () => {
    this.setState({ display: true });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const { investorData, userId } = this.props;
    const params = {
      Uid: userId,
      pan: investorData.PAN,
      InvdDistFlag: "P",
      Fund: "105",
    };
    await this.props.getTransactionHistory(params);
    this.setState({
      display: false,
    });
  };

  closePerformanceModal = () => {
    this.setState({
      openPerformanceModal: false,
    });
  };

  gotoTrack = (item) => async () => {
    const reverseDate = !!item && !!item.td_trdt ? item.td_trdt.split("/") : "";
    const formDate = !!reverseDate
      ? `${reverseDate[1]}/${reverseDate[0]}/${reverseDate[2]}`
      : "";
    const params = {
      Fund: item.td_fund,
      Ihno: item.td_ihno,
      Status: item.Status,
      SchemeDesc: item.td_scheme,
      TrDate: formDate,
    };
    const response = await this.props.getTrackDetails(params);
    if (!!response && !!response.DtData) {
      this.setState({
        openPerformanceModal: true,
        TrackData: response.DtData,
      });
    }
  };

  render() {
    const { state } = this.props.location;
    const { isKyc } = state;
    const { showError, display } = this.state;
    const { transactions, investorData } = this.props;

    return (
      <div className="transaction-history">
        {!!transactions && !!transactions.length ?
          <Paper className="right-bar-container-paper">
            <PerformanceModal
              open={this.state.openPerformanceModal}
              data={this.state.TrackData}
              closeModal={this.closePerformanceModal}
            />
            {!isKyc && !!showError ? (
              <div className="no-data">
                No Investor PAN found. Please submit Investor PAN
              </div>
            ) : (
              <React.Fragment>
                {!!display ? (
                  <CustomLoader display={!!display} />
                ) :
                  <div className="digital-form-Right-div digital-form-Right-div-responsive">
                    <SingleAmcheader
                      investorData={investorData}
                      style={{ borderLeft: 0 }}
                    />
                    <div className="heading">
                      Transactions<span>(Last 10 Transactions)</span>
                    </div>
                    {transactions.map((transaction, index) => (
                      <div key={index} className="history-card">
                        <div className="flex-row-center-between main_block">
                          <div className="tranacation-history-title">
                            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 13, marginBottom: 3 }}>Fund Name</div>
                            <div className="title">{transaction.SchemeDesc}</div>
                          </div>
                          <div style={{ display: "flex", }}>
                            {transaction.Status === "Pending" ||
                              transaction.Status === "Pending payment confirmation" ||
                              transaction.Status === "Success" ? (
                              <div>
                                <Debtbutton
                                  width={100}
                                  buttontitle="Track"
                                  onClick={this.gotoTrack(transaction)}
                                />
                              </div>
                            ) : (
                              <div style={{ width: 100 }}></div>
                            )}
                            <div
                              style={{
                                display:
                                  !!investorData &&
                                    !!investorData.PAN &&
                                    investorData.PAN.charAt(3).toUpperCase() === "P"
                                    ? "flex"
                                    : "none",
                              }}
                            >
                              {transaction.Status === "Rejected" ? (
                                <Debtbutton
                                  width={100}
                                  buttontitle="Reintiate"
                                  onClick={() =>
                                    this.props.history.push(
                                      NEW_TRANSACTION_URL + "/transction-types"
                                    )
                                  }
                                />
                              ) : (
                                <DebtbuttonActive
                                  width={100}
                                  buttontitle="Transact"
                                  onClick={() =>
                                    this.props.history.push(
                                      NEW_TRANSACTION_URL + "/transction-types"
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="history_sec">
                          <TransactionSubContainer
                            list={[
                              { key: "Type", value: transaction.TrtypeDesc },
                              {
                                key: "Amount",
                                color: "#00579b",
                                value: (
                                  <FormatCurrency
                                    value={transaction.td_amt.split(".")[0]}
                                  />
                                ),
                              },
                              { key: "Ref No", value: transaction.td_trno },
                              { key: "Folio", value: transaction.td_acno },
                              {
                                key: "NAV (₹)",
                                value: !!transaction.td_nav && transaction.td_nav !== "-"
                                  ? `₹${transaction.td_nav}`
                                  : "-",
                              },
                              { key: "Units", value: transaction.td_units },
                              { key: "Date", value: transaction.td_trdt },
                              {
                                key: "Status",
                                value: transaction.Status,
                                color:
                                  transaction.Status === "Success"
                                    ? "#34A853"
                                    : "#D0021B",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </React.Fragment>
            )}
          </Paper> : <div className="no-data"> No Data Found</div>
        }
        <div className="mobile-aum-list">
          <SingleAmcheader
            investorData={investorData}
            style={{ borderLeft: 0 }}
          />
          <div className="heading">
            Transactions<span>(Last 10 Transactions)</span>
          </div>
          {transactions.length > 0 ? (
            transactions.map((transaction, index) => (
              <InvestorTableCard
                key={index}
                Name={transaction.SchemeDesc}
                history={this.props.history}
                Type={transaction.TrtypeDesc}
                Ref_No={transaction.td_trno}
                Folio={transaction.td_acno}
                Units={transaction.td_units}
                Date={transaction.td_trdt}
                Status={transaction.Status}
                Amount={transaction.td_amt}
                NAV={transaction.td_nav}
                Investordata={investorData}
                transaction={transaction}
                gotoTrack={this.gotoTrack}
              />
            ))
          ) : (
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 14,
                textAlign: "center",
                color: "red",
              }}
            >
              {" "}
              No Data Found
            </div>
          )}
        </div>
      </div >
    );
  }
}

export default connect(
  ({ authReducer, transactionHistory }) => {
    const { investorData, userId } = authReducer;
    const { fundsList, transactions } = transactionHistory;
    return { investorData, userId, fundsList, transactions };
  },
  { getTransactionHistory, getTrackDetails, RemoveTransactionData }
)(HistoryDetail);

export class SingleAmcheader extends Component {
  render() {
    const { invname, PAN } = this.props.investorData;
    return (
      <div className="single-amc-header">
        <div className="content">
          <div>
            <img
              src={BUILD_URL + "/images/su_user_icon.svg"}
              alt="user_icon"
              style={{ height: 40, width: 40 }}
            />
          </div>
          <div style={{ marginLeft: 20 }} className="right-side">
            <div className="name">{invname}</div>
            <div className="pan">PAN: {PAN}</div>
          </div>
        </div>
      </div>
    );
  }
}

export class TransactionSubContainer extends Component {
  render() {
    const { list } = this.props;
    return (
      <>
        {list.map((ele, index) => (
          <div key={index}>
            <div className="list-text">{ele.key}</div>
            <div
              className="list-value"
              style={{ color: !!ele.color ? ele.color : "#293139" }}
            >
              {!!ele.value ? ele.value : "-"}
            </div>
          </div>
        ))}
      </>
    );
  }
}
export class InvestorTableCard extends Component {
  render() {
    const { Type, Ref_No, Folio, Units, Date, Status, Amount, NAV, Name, Investordata, transaction, gotoTrack } = this.props;
    return (
      <div>
        <div
          style={{
            background: "white",
            border: "1px solid rgba(211,211,211,0.3)",
            padding: "7px 10px",
            margin: "10px 0px",
          }}
        ><div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                color: "#00579b",
                fontSize: 14,
                paddingBottom: 5,
                borderBottom: "1px solid rgba(211,211,211,0.3)",
              }}
            >
              {Name}
            </div>
          </div>

          <div style={{ display: "flex", padding: "8px 0px" }}>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Type
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#293139",
                }}
              >
                {!!Type ? Type : "-"}
              </div>
            </div>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Amount
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#00579b",
                }}
              >
                ₹{!!Amount.split(".")[0] ? Amount.split(".")[0] : "-"}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", padding: "8px 0px" }}>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Ref No
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#293139",
                }}
              >
                {!!Ref_No ? Ref_No : "-"}
              </div>
            </div>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Folio
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#293139",
                }}
              >
                {!!Folio ? Folio : "-"}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", padding: "8px 0px" }}>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                NAV
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#293139",
                }}
              >
                {!!NAV && NAV !== "-"
                  ? `₹${NAV}`
                  : "-"}
              </div>
            </div>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Units
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#293139",
                }}
              >
                {!!Units ? Units : "-"}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", padding: "8px 0px" }}>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Date
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color: "#293139",
                }}
              >
                {!!Date ? Date : "-"}
              </div>
            </div>
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
              <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
                Status
              </div>
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 13,
                  color:
                    Status === "Success"
                      ? "#34A853"
                      : "#D0021B",
                }}
              >
                {!!Status ? Status : "-"}
              </div>
            </div>
          </div>
          <div style={{ display: "flex",justifyContent:"space-around" }}>
            {Status === "Pending" ||
              Status === "Pending payment confirmation" ||
              Status === "Success" ? (
              <div>
                <Debtbutton
                  width={100}
                  buttontitle="Track"
                  onClick={gotoTrack(transaction)}
                />
              </div>
            ) : (
              <div style={{ width: 100 }}></div>
            )}
            <div
              style={{
                display:
                  !!Investordata &&
                    !!Investordata.PAN &&
                    Investordata.PAN.charAt(3).toUpperCase() === "P"
                    ? "flex"
                    : "none",
              }}
            >
              {Status === "Rejected" ? (
                <Debtbutton
                  width={100}
                  buttontitle="Reintiate"
                  onClick={() =>
                    this.props.history.push(
                      NEW_TRANSACTION_URL + "/transction-types"
                    )
                  }
                />
              ) : (
                <DebtbuttonActive
                  width={100}
                  buttontitle="Transact"
                  onClick={() =>
                    this.props.history.push(
                      NEW_TRANSACTION_URL + "/transction-types"
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}