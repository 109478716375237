import React, { Component } from "react";

export default class Signbutton extends Component {
  render() {
    return (

        <button
          className="login-button-sign-in close-margin"
          onClick={this.props.onClick}
          disabled={this.props.disabled}
          style={{
            padding: this.props.padding,
            width: !!this.props.width ? this.props.width : "100%",
            marginTop: this.props.margin ? this.props.margin : 30,
            textTransform:"capitalize"
          }}
        >
          {this.props.buttontitle}
        </button>

    );
  }
}
export class Debtbutton extends Component {
  render() {
    return (
      <div>
        <button
          disabled={this.props.disabled}
          className={
            this.props.active
              ? "login-button-debt-button-active"
              : "login-button-debt-button"

          }
          style={{ width: this.props.width ? this.props.width : 120 ,textTransform:"capitalize",display:!!this.props.display?this.props.display:"block"}}
          onClick={this.props.onClick}
        >
          {this.props.buttontitle}
        </button>
      </div>
    );
  }
}

export class DebtbuttonActive extends Component {
  render() {
    return (
      <div>
        <button
          className="login-button-debt-button-active"
          onClick={this.props.onClick}
          style={{ outline: 0 ,textTransform:"capitalize" ,width:this.props.width ? this.props.width : "100%"}}
        >
          {this.props.buttontitle}
        </button>
      </div>
    );
  }
}

export class DebtbuttonNowidth extends Component {
  render() {
    return (
      <div>
        <button
          className="login-button-debt-button-active-no-width"
          onClick={this.props.onClick}
          style={{textTransform:"capitalize"}}
        >
          {this.props.buttontitle}
        </button>
      </div>
    );
  }
}
