import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { BUILD_URL } from "./home";

export default class CommonBreadCrumb extends Component {
  lastAndTo = (index) => {
    let { pathnames } = this.props;
    if (pathnames.find((p) => p === process.env.PUBLIC_URL.split("/").pop())) {
      pathnames = pathnames.slice(1);
    }
    const last = index === pathnames.length - 1;
    const to = `/${pathnames.slice(0, index + 1).join("/")}`;
    return { last, to };
  };

  render() {
    let { hideFirst, pathnames, breadcrumbNameMap } = this.props;
    if (pathnames.find((p) => p === process.env.PUBLIC_URL.split("/").pop())) {
      pathnames = pathnames.slice(1);
    }
    return pathnames.length === 1 && !!hideFirst ? (
      <div />
    ) : (
      <Breadcrumbs
        style={{ margin: "30px 50px 45px" }}
        className="bread-crumbs bc-responsive"
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="Breadcrumb"
      >
        {pathnames.map((value, index) => {
          const { last, to } = this.lastAndTo(index);
          return (
            <div style={{ display: "flex" }} key={index}>
              {index === 0 && (
                <img
                  alt="web-page-home"
                  src={BUILD_URL + "/images/home_active.svg"}
                  style={{ height: 20, marginRight: 10 }}
                />
              )}
              {last ? (
                <div className="transaction-summary-label">
                  {breadcrumbNameMap[to].title === "New Purchase"
                    ? "New Folio Purchase"
                    : breadcrumbNameMap[to].title}
                </div>
              ) : (
                <NavLink
                  className="breadcrumbs-unselect-label"
                  to={breadcrumbNameMap[to].to}
                >
                  {breadcrumbNameMap[to].title === "New Purchase"
                    ? "New Folio Purchase"
                    : breadcrumbNameMap[to].title}
                </NavLink>
              )}
            </div>
          );
        })}
      </Breadcrumbs>
    );
  }
}
