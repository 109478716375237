import React, { Component } from "react";
import CustomizedSelect from "./customizedSelect";
import { Input } from "../../forms/input";
import Signbutton, { Debtbutton } from "../../forms/button";
import { connect } from "react-redux";
import {
  YES,
  EXISTING,
  NEW,
  REGULAR,
  PURCHASE,
  NO,
  ALL,
  ADDITIONAL_PURCHASE,
} from "shared/store/types";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  validateSubBroker,
  getAssetClasses,
  getOtherSchemes,
  submitPurchaseData,
  showPurchaseConfirmation,
} from "shared/store/actions";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { NEW_TRANSACTION_URL } from ".";
import { SundaramMutualFund } from "./newPurchaseForm";
import { INVESTOR_URL } from "../investors";
class AdditionalPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fund: { value: { value: "117" } },
      folios: [],
      folio: { value: "" },
      minAmount: "",
      schemes: [],
      schemeType: EXISTING,
      combo: { flag: "", disclamier: "", label: "" },
      isCombo: false,
      scheme: { value: "" },
      radioValue: { value: "no" },
      arnCode: { value: this.props.token },
      subArnCode: { value: "" },
      subBrokerCode: { value: "" },
      euinNo: { value: "" },
      category: { value: "" },
      bank: { value: "" },
      categories: [],
      banks: [],
      amount: { value: "" },
      showSmart: false,
      smart: { flag: "yes" },
    };
  }

  handleFundChange = (value) => {
    if (!value) {
      this.setState({
        fund: { value: "" },
        folios: [],
        folio: { value: "" },
        scheme: { value: "" },
        schemeType: EXISTING,
        subArnCode: { value: "" },
        subBrokerCode: { value: "" },
        euinNo: { value: "" },
        category: { value: "" },
        // bank: { value: "" },
        radioValue: { value: "no" },
        showSmart: false,
        amount: { value: "" },
      });
      return;
    } else if (value.value === this.state.fund.value.value) {
      return;
    } else {
      const fund = value.value;
      const folios = this.props.fundList.reduce((prev, curr) => {
        if (curr.pFlag === YES && curr.Fund === fund) {
          return [...prev, curr.Acno];
        }
        return prev;
      }, []);
      this.setState({
        fund: { value },
        folios: Array.from(new Set(folios)),
        folio: { value: "" },
        schemes: [],
        scheme: { value: "" },
        schemeType: EXISTING,
        subArnCode: { value: "" },
        subBrokerCode: { value: "" },
        euinNo: { value: "" },
        category: { value: "" },
        // bank: { value: "" },
        radioValue: { value: "no" },
        showSmart: false,
        amount: { value: "" },
      });
      if (!!fund) {
        this.validateBrokerCode(fund)();
      }
    }
  };

  async componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    this.getFolios();
  }

  getFolios = () => {
    const fund = "105";
    const folios = this.props.fundList.reduce((prev, curr) => {
      if (curr.pFlag === YES && curr.Fund.trim() === fund) {
        return [...prev, curr.Acno];
      }
      return prev;
    }, []);

    this.setState({
      fund: { value: { value: "105" } },
      folios: Array.from(new Set(folios)),
      folio: { value: "" },
      schemes: [],
      scheme: { value: "" },
      schemeType: EXISTING,
      subArnCode: { value: "" },
      subBrokerCode: { value: "" },
      euinNo: { value: "" },
      category: { value: "" },
      radioValue: { value: "no" },
      amount: { value: "" },
    });
    this.validateBrokerCode(fund)();
  };

  validateBrokerCode = (fund) => async () => {
    const { subArnCode, arnCode } = this.state;
    if (
      !!this.state.subArnCode.value &&
      this.state.subArnCode.value === this.state.arnCode.value.split("-")[1]
    ) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: "ARN and Sub ARN Code should not be the same",
        },
      });
      return;
    }
    const params = {
      Fund: "105",
      SubAgentCd: !!subArnCode.value ? `ARN-${subArnCode.value}` : "",
      AgentCd: arnCode.value,
    };
    const response = await this.props.validateSubBroker(params);

    if (!response) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `Please enter a valid Sub ARN Code`,
        },
      });
    }
  };

  handleFolioChange = (value) => {
    if (!value) {
      this.setState({
        folio: { value: "" },
        schemes: [],
        schemeType: EXISTING,
        banks: [],
        scheme: { value: "" },
        // bank: { value: "" },
        subArnCode: { value: "" },
        subBrokerCode: { value: "" },
        euinNo: { value: "" },
        category: { value: "" },
        radioValue: { value: "no" },
        amount: { value: "" },
        showSmart: false,
      });
      return;
    } else if (value.value === this.state.folio.value.value) {
      return;
    } else {
      const folio = value.value;
      const schemes = this.schemes(folio);
      // const banks = this.props.bankDetails.filter(
      //   (item) => item.foliono === folio
      // );
      this.setState({
        folio: { value },
        schemes,
        schemeType: EXISTING,
        // banks,
        showSmart: false,
        // bank: { value: "" },
      });
    }
  };

  changeEuin = (value) => {
    this.setState({
      euinNo: { value },
    });
  };

  changeValue = (label) => (event) => {
    if (label === "amount" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value.replace(/^0+/, "") },
      });
    } else if (label === "subArnCode" || label === "subBrokerCode") {
      if (/^\d{0,15}$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: "Enter a valid number",
          },
        });
      }
    } else if (label === "radioValue" && event.target.value === "no") {
      if (!this.state.euinNo.value) {
        this.setState({
          radioValue: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: { value: event.target.value },
          euinNo: { value: "" },
        });
      }
    } else if (
      label !== "amount" &&
      label !== "subArnCode" &&
      label !== "subBrokerCode" &&
      label === "radioValue" &&
      event.target.value !== "no"
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  getAssetClasses = async () => {
    const params = {
      Fund: "105",
      Folio: this.state.folio.value.value,
    };
    const assetClasses = await this.props.getAssetClasses(params);
    if (assetClasses) {
      this.setState({
        categories: assetClasses.DtData,
      });
    }
  };

  getOtherSchemes = async (value) => {
    const params = {
      schtype: REGULAR,
      category: value,
      acno: this.state.folio.value.value,
      fundCode: "105",
      trantype: PURCHASE,
    };
    const otherSchemes = await this.props.getOtherSchemes(params);
    if (otherSchemes) {
      this.setState({
        schemes: otherSchemes.DtData,
      });
    }
  };

  schemes = (folio) => {
    return this.props.fundList.reduce((prev, curr) => {
      if (
        curr.pSchFlg === YES &&
        curr.Fund.trim() === "105" &&
        curr.Acno === folio
      ) {
        return [...prev, curr];
      }
      return prev;
    }, []);
  };

  handleCategoryChange = (event) => {
    if (event.target.value) {
      this.getOtherSchemes(event.target.value);
      this.setState({
        category: { value: event.target.value },
        scheme: { value: "" },
        showSmart: false,
      });
    } else {
      this.setState({
        category: { value: "" },
        scheme: { value: "" },
        showSmart: false,
      });
    }
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "amount") {
      if (this.state.amount.value === "") {
        console.log("hello");
        this.setState({
          amount: {
            value: "",
            isError: true,
            errorMessage: `Investment Amount is Mandatory* `,
          },
        });
      } else if (parseFloat(this.state.minAmount) > parseFloat(this.state.amount.value)) {
        console.log("hello");
        this.setState({
          amount: {
            value,
            isError: true,
            errorMessage: `Minimum amount is ₹${this.state.minAmount} `,
          },
        });
      }
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };

  changeSchemeType = (value) => () => {
    if (value === this.state.schemeType) {
      return;
    }
    if (value === EXISTING) {
      const schemes = this.schemes(this.state.folio.value.value);
      this.setState({
        schemeType: value,
        schemes,
        category: { value: "" },
        scheme: { value: "" },
        amount: { value: "" },
        showSmart: false,
      });
    } else {
      this.setState({
        schemeType: value,
        scheme: { value: "" },
        category: { value: "" },
        amount: { value: "" },
        showSmart: false,
      });
      if (!!this.state.fund.value && !!this.state.folio.value) {
        this.getAssetClasses();
        this.getOtherSchemes();
      }
    }
  };

  handleSchemeChange = (value) => {
    if (!value) {
      this.setState({
        scheme: { value: "" },

        scheme: { value: "" },
        // bank: { value: "" },
        subArnCode: { value: "" },
        subBrokerCode: { value: "" },
        euinNo: { value: "" },

        radioValue: { value: "no" },
        amount: { value: "" },
        showSmart: false,
      });
      return;
    } else if (
      !!value &&
      !!this.state.scheme.value &&
      value.value === this.state.scheme.value.value
    ) {
      return;
    } else {
      const scheme = value.value;
      const checkCombo =
        !!scheme && !!scheme.combo_flag && scheme.combo_flag === "Y";
      this.setState({
        scheme: { value },
        minAmount: scheme.MinAmt || scheme.fm_newpur,
        amount: { value: "" },
        isCombo: !!checkCombo ? true : false,
        combo: {
          flag: !!checkCombo ? "Y" : "N",
          disclamier: scheme.combo_disclaimer,
          label: scheme.combo_lable,
        },
        // bank: { value: "" },
        subArnCode: { value: "" },
        subBrokerCode: { value: "" },
        euinNo: { value: "" },
        showSmart: false,
        radioValue: { value: "no" },
      });
    }
  };

  changeSmart = (event) => {
    this.setState({
      smart: { flag: event.target.value },
    });
  };

  submit = async () => {
    const {
      funds,
      categories,
      smart,
      showSmart,
      schemes,
      folios,
      banks,
      arnCode,
      subArnCode,
      subBrokerCode,
      radioValue,
      euinNo,
      minAmount,
      schemeType,
      category,
      bank,
      isCombo,
      combo,
      fund,
      ...others
    } = this.state;

    const keys = Object.keys(others);

    if (this.state.schemeType === NEW && this.state.category.value === "") {
      this.setState({
        category: {
          value: "",
          isError: true,
          errorMessage: "Category is Mandatory*",
        },
      });
    }
    if (
      this.state.euinNo.value === "" &&
      this.state.radioValue.value === "no"
    ) {
      this.setState({
        euinNo: {
          value: "",
          isError: true,
          errorMessage: "EUIN Declaration is Mandatory*",
        },
      });
    }
    const findError = keys.every((key) => !!others[key].value);
    const arn = this.state.arnCode.value.split("-");
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "category"
                  ? "Category"
                  : key === "folio"
                  ? "Folio"
                  : key === "scheme"
                  ? "Scheme"
                  : key === "amount"
                  ? "Investment Amount"
                  : key
              } is Mandatory*`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        // case "fund": {
        //   this.fundField.focus();
        //   break;
        // }
        case "folio": {
          this.folioField.focus();
          break;
        }
        case "scheme": {
          this.schemeField.focus();
          break;
        }
        case "amount": {
          this.amountField.focus();
          break;
        }
        // case "bank": {
        //   this.bankField.focus();
        //   break;
        // }
      }
    } else if (schemeType === NEW && !category.value) {
      this.setState({
        category: { ...category, isError: true },
      });
      this.categoryField.focus();
      return;
    } else if (radioValue.value === "no" && !euinNo.value) {
      this.setState({
        euinNo: { ...euinNo, isError: true },
      });
      this.euinField.focus();
      return;
    } else if (
      !!others.amount.value &&
      parseFloat(minAmount) > parseFloat(others.amount.value)
    ) {
      this.setState({
        amount: {
          ...others["amount"],
          isError: true,
          errorMessage: `Minimum amount is ₹${minAmount}`,
        },
      });
      this.amountField.focus();
      return;
    } else if (arn[1] === this.state.subArnCode.value) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `and ARN code should  not match`,
        },
      });
    } else {
      const { communicationDetails, investorData } = this.props;
      const Xmobile = communicationDetails.find(
        (detail) =>
          detail.Fund === this.state.fund.value.value &&
          detail.Acno === this.state.folio.value.value &&
          detail.PAN === investorData.PAN &&
          !!detail.Mobile
      );
      const Xemail = communicationDetails.find(
        (detail) =>
          detail.Fund === this.state.fund.value.value &&
          detail.Acno === this.state.folio.value.value &&
          detail.PAN === investorData.PAN &&
          !!detail.email
      );

      const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";
      const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";
      const params = {
        InvestorName: investorData.invname,
        i_entby: this.props.userId,
        trtype: PURCHASE,
        Desci: "Yes~P~",
        SubbrokerArn: !!this.state.subArnCode.value
          ? `ARN-${subArnCode.value}`
          : "",
        ARNCode: this.state.arnCode.value,
        EuinCode:
          this.state.radioValue.value === "no"
            ? this.state.euinNo.value.value
            : "",
        i_Userid: this.props.userId,
        Mobile: Mobile,
        PanNo: investorData.PAN,
        Email: Email,
        i_Acno: this.state.folio.value.value,

        Subbroker: this.state.subBrokerCode.value,
        i_Fund: "105",
        i_UntAmtValue: this.state.amount.value,
        EUINFlag: this.state.radioValue.value === "no" ? NO : YES,
        EuinValid: this.state.radioValue.value === "no" ? NO : YES,
        // Bankid: `${this.state.bank.value.bnkcode}~${this.state.bank.value.bnkactype}~${this.state.bank.value.bnkacno}`,
        i_Scheme:
          this.state.scheme.value.value.Sch ||
          this.state.scheme.value.value.fm_scheme,
        i_Plan:
          this.state.scheme.value.value.Pln ||
          this.state.scheme.value.value.fm_plan,
        i_Option:
          this.state.scheme.value.value.Opt ||
          this.state.scheme.value.value.fm_option,
        i_oldihno: "0",
        i_InvDistFlag: "P",
        ModuleName: "Webile",
      };

      const response = await this.props.submitPurchaseData(params);
      if (!!response && !response.error) {
        const {
          DtData: [purchaseData],
        } = response;
        const confirmationParams = {
          Trtype: PURCHASE,
          fund: "105",
          Refno: purchaseData.refno,
          ModuleName: "Webile",
        };
        const confirmationResponse = await this.props.showPurchaseConfirmation(
          confirmationParams
        );

        if (!!confirmationResponse && !confirmationResponse.error) {
          this.props.history.push({
            pathname:
              INVESTOR_URL + "/transction-types/additional-purchase/confirm",
            state: {
              title: "ADDITIONAL PURCHASE SUMMARY",
              type: ADDITIONAL_PURCHASE,
              scheme: { Fund: this.state.fund.value.value },
              ARNCode: this.state.arnCode.value.split("-")[1],
              Folio: this.state.folio.value,
              folio: this.state.folio.value,
              methodType: this.state.schemeType,
            },
          });
        } else if (!!confirmationResponse && !!confirmationResponse.error) {
          alert(`${confirmationResponse.error}`);
        }
      } else if (!!response && !!response.error) {
        alert(`${response.error}`);
      }
    }
  };

  ComboChange = () => {
    this.setState({
      isCombo: !this.state.isCombo,
    });
  };

  render() {
    const {
      fund,
      funds = [],
      folio,
      folios = [],
      schemeType,
      schemes = [],
      scheme,
      radioValue,
      arnCode,
      subArnCode,
      subBrokerCode,
      euinNo,
      categories,
      category,
      combo,
      isCombo,
      // bank,
      banks,
      amount,
      smart,
      showSmart,
    } = this.state;
    const { euinList } = this.props;
    return (
      <div className="transaction-details">
        <div className="title">Additional Purchase</div>
        {/* <div className="input-margin">
          <div className="input-name ">AMC</div>
          <SundaramMutualFund />
          </div> */}
        <div className="input-margin">
          <div className="input-name ">Folio*</div>
          <IntegrationReactSelect
            suggestions={folios.map((item) => ({
              label: item,
              value: item,
            }))}
            value={folio.value}
            placeholder="Select Folio"
            handleChange={this.handleFolioChange}
            inputRef={(ele) => (this.folioField = ele)}
            disabled={!fund.value}
            isError={folio.isError}
            errorMessage={folio.errorMessage}
          />
        </div>
        <div className="input-name">Select Scheme Type</div>
        <div className="radio-buttons-div">
          <Debtbutton
            buttontitle="Existing Scheme"
            width={150}
            active={schemeType === EXISTING}
            onClick={this.changeSchemeType(EXISTING)}
          />
          <Debtbutton
            buttontitle="Other Schemes"
            width={150}
            active={schemeType === NEW}
            onClick={this.changeSchemeType(NEW)}
          />
        </div>
        {schemeType === NEW && (
          <div className="input-margin">
            <CustomizedSelect
              name="Category"
              bordercolor="rgba(0, 0, 0, 0.5)"
              handleChange={this.handleCategoryChange}
              value={category.value}
              disabled={!folio.value}
              placeholder="Select Category"
              inputRef={(ele) => (this.categoryField = ele)}
              isError={category.isError}
              errorMessage={category.errorMessage}
            >
              {categories.map((item, index) => (
                <MenuItem value={item.CatValue} key={index}>
                  {item.CatDesc}
                </MenuItem>
              ))}
            </CustomizedSelect>
          </div>
        )}
        <div className="input-margin">
          <div className="input-name ">Scheme*</div>
          <IntegrationReactSelect
            suggestions={schemes.map((item) => ({
              value: item,
              label: item.PlnDesc
                ? `${item.SchDesc1} - ${item.PlnDesc} - ${item.OptDesc}`
                : item.fm_schdesc,
            }))}
            value={scheme.value}
            placeholder="Select Scheme"
            handleChange={this.handleSchemeChange}
            disabled={!folio.value}
            inputRef={(ele) => (this.schemeField = ele)}
            isError={scheme.isError}
            errorMessage={scheme.errorMessage}
          />
        </div>
        <div className="input-margin">
          <Input
            name="ARN Code"
            value={arnCode.value.split("-")[1]}
            disabled={true}
            bordercolor="rgba(0, 0, 0, 0.5)"
          />
        </div>
        <div className="input-margin">
          <Input
            name="Sub ARN Code"
            disabled={!fund.value}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            value={subArnCode.value}
            isShowError={subArnCode.isError}
            errorMessage={subArnCode.errorMessage}
            onBlur={this.validateBrokerCode(
              fund.value ? fund.value.value.Fund : ""
            )}
            onChange={this.changeValue("subArnCode")}
            inputref={(ele) => (this.subArnField = ele)}
            bordercolor="rgba(0, 0, 0, 0.5)"
          />
        </div>
        <div className="input-margin">
          <Input
            name="Sub Broker Code"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            isShowError={subBrokerCode.isError}
            errorMessage={subBrokerCode.errorMessage}
            value={subBrokerCode.value}
            onChange={this.changeValue("subBrokerCode")}
            inputref={(ele) => (this.subBrokerField = ele)}
            bordercolor="rgba(0, 0, 0, 0.5)"
          />
        </div>
        <div className="input-field-container ">
          <div className="input-name">EUIN Declaration*</div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Search"
              name="search"
              value={radioValue.value}
              style={{ flexDirection: "row" }}
              onChange={this.changeValue("radioValue")}
            >
              <div className="investor-radio-button">
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">YES</div>
              </div>
              <div className="investor-radio-button">
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">NO</div>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        {radioValue.value === "no" ? (
          <div className="input-margin">
            <IntegrationReactSelect
              suggestions={euinList.map((item) => ({
                value: item.abm_agent,
                label: item.abm_agent,
              }))}
              errorMessage={euinNo.errorMessage}
              value={euinNo.value}
              placeholder="Choose EUIN"
              handleChange={this.changeEuin}
              disabled={!euinList.length}
              inputRef={(ele) => (this.euinField = ele)}
              isError={euinNo.isError}
            />
          </div>
        ) : (
          <div className="euin-text">
            I/We hereby confirm that the EUIN Declaration box has been
            intentionally left the blank by me/us as this is an 'execution-only'
            transaction without any interaction or advice by any personnel of
            the above distributor or notwithstanding the advice of
            in-appropriateness,if any provided by any personnel of the
            distributor and the distributor has not charged any advisory fees on
            this transaction
          </div>
        )}
        <div style={{ marginBottom: 20 }}>
          <Input
            name="Minimum Investment Amount*"
            value={amount.value}
            label={`: (₹)${!!this.state.minAmount ? this.state.minAmount : ""}`}
            type="number"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            onBlur={this.showError("amount")}
            onChange={this.changeValue("amount")}
            inputref={(ele) => (this.amountField = ele)}
            bordercolor="rgba(0, 0, 0, 0.5)"
            isShowError={amount.isError}
            errorMessage={amount.errorMessage}
          />
        </div>
        {/* {!!showSmart && (
          <div className="input-field-container">
            <div
              className="input-name"
              style={{ fontFamily: "SourceSansPro-SemiBold", fontSize: 16 }}
            >
              Your investor is eligible for a SMART facility
            </div>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Search"
                name="search"
                value={smart.flag}
                style={{ flexDirection: "row" }}
                onChange={this.changeSmart}
              >
                <div className="investor-radio-button">
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        className="investor-search-radio"
                        style={{ color: "#00579b" }}
                      />
                    }
                    className="investor-formcontrol-label"
                    style={{ margin: 0 }}
                  />
                  <div className="investor-form-label">YES</div>
                </div>
                <div className="investor-radio-button">
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        className="investor-search-radio"
                        style={{ color: "#00579b" }}
                      />
                    }
                    className="investor-formcontrol-label"
                    style={{ margin: 0 }}
                  />
                  <div className="investor-form-label">NO</div>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        )} */}
        {/* <CustomizedSelect
          name="Select Bank"
          handleChange={this.changeValue("bank")}
          value={bank.value}
          bordercolor="rgba(0, 0, 0, 0.5)"
          placeholder="Select Bank"
          disabled={!folio.value}
          inputRef={(ele) => (this.bankField = ele)}
          isError={bank.isError}
          errorMessage={bank.errorMessage}
        >
          {banks.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item.bnkname} - {item.bnkacno}
            </MenuItem>
          ))}
        </CustomizedSelect> */}
        <div className="digital-form-button-next-div">
          <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
        </div>
         
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const {
      fundList,
      token,
      userId,
      bankDetails,
      investorData,
      communicationDetails,
    } = authReducer;
    const { euinList } = transactions;
    return {
      fundList,
      token,
      userId,
      euinList,
      bankDetails,
      investorData,
      communicationDetails,
    };
  },
  {
    validateSubBroker,
    getAssetClasses,
    getOtherSchemes,
    submitPurchaseData,
    showPurchaseConfirmation,
  }
)(AdditionalPurchase);
