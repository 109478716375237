import React, { Component } from "react";
import CommonBreadCrumb from "../breadCrumbs";
import DashboardRouter from "./dashboardroutes";

export const DASHBOARD_URL = `${process.env.PUBLIC_URL}/dashboard`;

const breadcrumbNameMap = {
  "/dashboard": { title: "Dashboard", to: DASHBOARD_URL },
  "/dashboard/transaction-summary": {
    title: "Transaction Summary",
    to: `${DASHBOARD_URL}/transaction-summary`,
  },
  "/dashboard/investorType": {
    title: "Types of Investors",
    to: `${DASHBOARD_URL}/investorType`,
  },
  "/dashboard/SipSummaryTypes": {
    title: "SIP Summary Types",
    to: `${DASHBOARD_URL}/SipSummaryTypes`,
  },
  "/dashboard/nfo-funds": {
    title: "NFO",
    to: `${DASHBOARD_URL}/nfo-funds`,
  },
  "/dashboard/nfo-funds/nfo-transact": {
    title: "NFO TRANSACT",
    to: `${DASHBOARD_URL}/nfo-funds/nfo-transact`,
  },
};

export default class DashboardComponent extends Component {
  render() {
    const { location } = this.props;
    const pathnames = location.pathname.split("/").filter((name) => name);
    return (
      <div>
        <CommonBreadCrumb
          hideFirst={true}
          pathnames={pathnames}
          breadcrumbNameMap={breadcrumbNameMap}
        />
        <DashboardRouter />
      </div>
    );
  }
}
