import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { GET_FUNDS_LIST_PAN_SUCCESS, GET_TRANSACTION_HISTORY_SUCCESS, GET_NAV_DATA_SUCCESS, REMOVE_NAV_DATA, REMOVE_TRANSACTION_HISTORY_DATA } from "../types";
var initialState = {
  fundsList: [],
  transactions: [],
  navData: []
};
export function transactionHistory() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_FUNDS_LIST_PAN_SUCCESS:
      {
        var fundsList = action.body.Table1;
        return _objectSpread({}, state, {
          fundsList: fundsList
        });
      }

    case GET_TRANSACTION_HISTORY_SUCCESS:
      {
        var transactions = action.body.Table1;
        return _objectSpread({}, state, {
          transactions: transactions
        });
      }

    case GET_NAV_DATA_SUCCESS:
      {
        var navData = action.body.Table1;
        return _objectSpread({}, state, {
          navData: navData
        });
      }

    case REMOVE_NAV_DATA:
      {
        return _objectSpread({}, state, {
          navData: []
        });
      }

    case REMOVE_TRANSACTION_HISTORY_DATA:
      {
        return _objectSpread({}, state, {
          transactions: []
        });
      }

    default:
      return state;
  }
}