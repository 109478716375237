import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import thunk from "redux-thunk";
import apiMiddleware from "shared/store/api.middleware";
// import reduxLogger from "redux-logger";
import rootReducer from "shared/store/reducers";

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

export const history = createBrowserHistory();

// const webRootReducer = history =>
//   combineReducers({
//     router: connectRouter(history),
//     rootReducer
//   });

const persistConfig = {
  key: "JMF-DIT",
  storage,
  whitelist: ["authReducer"]
};

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer(connectRouter(history))
  // webRootReducer(history) // root reducer with router state
);
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk, apiMiddleware, routerMiddleware(history))
);

export const persistor = persistStore(store);
export default store;
