import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import NavData from "./navData";
import {
  ALL,
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAILED,
  GET_SCHEME_LIST,
  GET_SCHEME_LIST_SUCCESS,
  GET_SCHEME_LIST_FAILED,
  REGULAR,
} from "shared/store/types";
import {
  getNewPurchaseFundList,
  getNavData,
  removeNavData,
} from "shared/store/actions";
import { connect } from "react-redux";
import CustomizedSelect from "../transactions/customizedSelect";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { SundaramMutualFund } from "../transactions/newPurchaseForm";
export const NAV_URL = `${process.env.PUBLIC_URL}/nav`;
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fund: { value: "" },
      category: { value: "" },
      scheme: { value: "" },
      time: "1D",
    };
  }

    componentDidMount() {
      const params = {
        opt: ALL,
        plntype: REGULAR,
        opt: "AT",
        fund: "105",
      };
      const types = [
        GET_CATEGORY_LIST,
        GET_CATEGORY_LIST_SUCCESS,
        GET_CATEGORY_LIST_FAILED,
      ];
      this.props.getNewPurchaseFundList(params, types);
      this.props.removeNavData();
      this.setState({
        category: { value: "" },
        scheme: { value: "" },
        time: "1D",
      });
      this.props.removeNavData();
    }




  handleChange = (label) => async (event) => {
    this.setState({ [label]: { value: event.target.value } });
    if (!!event.target.value) {
      const params = {
        plntype: REGULAR,
        opt: "S",
        schemetype: event.target.value,
        fund: "105",
      };
      const types = [
        GET_SCHEME_LIST,
        GET_SCHEME_LIST_SUCCESS,
        GET_SCHEME_LIST_FAILED,
      ];
      await this.props.getNewPurchaseFundList(params, types);
    }
    this.setState({
      category: { value: event.target.value },
      scheme: { value: "" },
    });
    this.props.removeNavData();
  };

  changeScheme = (value) => {
    this.setState({
      scheme: { value },
    });
    if (!!value) {
      const plan = value.value.split("~");
      const params = {
        Plan: plan[2],
        flag: this.state.time,
        Fund: "105",
        Scheme: plan[1],
      };
      this.props.getNavData(params);
    }
    if (!value) {
      this.props.removeNavData();
    }
  };

  changeTime = (value) => () => {
    this.setState({
      time: value,
    });
    const { scheme } = this.state;
    if (!!scheme.value) {
      const plan = scheme.value.value.split("~");
      const params = {
        Plan: plan[2],
        flag: value,
        Fund: "105",
        Scheme: plan[1],
      };
      this.props.getNavData(params);
    }
  };

  render() {
    const { fundsList, schemeList, categoryList } = this.props;
    const { fund, category, scheme, time } = this.state;
    return (
      <div className="nav-main-container-margin" style={{overflow:"hidden"}}>
        <div className="investor-title" style={{ marginBottom: 30 }}>
          NAV Details
        </div>
        <Paper
          className="flex right-bar-container-paper"
          style={{
            flexWrap: "wrap",
            justifyContent: "center",
            overflow:"hidden",
          }}
        >
          <div className="nav-left-container">
            {/* <div className="input-margin" style={{ margin: "30px 0px" }}>
              <div className="input-name">AMC</div>
              <SundaramMutualFund />
            </div> */}
            <div className="input-margin nav-main-container-margin" >
              <CustomizedSelect
                name="Category"
                handleChange={this.handleChange("category")}
                value={category.value}
                placeholder="Select Category"
                bordercolor="rgba(0, 0, 0, 0.5)"
              >
                {categoryList.map((item, index) => (
                  <MenuItem value={item.fm_subcategory} key={index}>
                    {/* {item.fm_subcategory}   */}
                    {item.fm_subcategory==="CASH FUND"?"LIQUID":item.fm_subcategory==="DEBT FUND"?"DEBT":item.fm_subcategory==="EQUITY FUND"?"EQUITY":item.fm_subcategory}  
                  </MenuItem>
                ))}
              </CustomizedSelect>
            </div>
            <div className="input-margin nav-main-container-margin" >
              <div className="input-name">Scheme</div>
              <IntegrationReactSelect
                suggestions={schemeList.map((item) => ({
                  label: item.Scheme_Desc,
                  value: item.Scheme_Plan,
                }))}
                value={scheme.value}
                placeholder="Select Scheme"
                handleChange={this.changeScheme}
                disabled={!category.value}
              />
            </div>
            <div className="nav-dragger-names-div">
              <div
                className={
                  time === "1D"
                    ? "nav-dragger-name-active"
                    : "nav-dragger-names"
                }
                onClick={this.changeTime("1D")}
              >
                1 W
              </div>
              <div
                className={
                  time === "1M"
                    ? "nav-dragger-name-active"
                    : "nav-dragger-names"
                }
                onClick={this.changeTime("1M")}
              >
                1 M
              </div>
              <div
                className={
                  time === "3M"
                    ? "nav-dragger-name-active"
                    : "nav-dragger-names"
                }
                onClick={this.changeTime("3M")}
              >
                3 M
              </div>
              <div
                className={
                  time === "1Y"
                    ? "nav-dragger-name-active"
                    : "nav-dragger-names"
                }
                onClick={this.changeTime("1Y")}
              >
                1 Yr
              </div>

              <div
                className={
                  time === "3Y"
                    ? "nav-dragger-name-active"
                    : "nav-dragger-names"
                }
                onClick={this.changeTime("3Y")}
              >
                3 Yr
              </div>
              <div
                className={
                  time === "5Y"
                    ? "nav-dragger-name-active"
                    : "nav-dragger-names"
                }
                onClick={this.changeTime("5Y")}
              >
                5 Yr
              </div>
            </div>
          </div>
          <div className="nav-right-container" style={{overflow:"hidden"}}>
            <NavData />
            {/* <DebtbuttonActive buttontitle="INITIATE" /> */}
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ transactions }) => {
    const { fundsList, schemeList, categoryList } = transactions;
    return { fundsList, schemeList, categoryList };
  },
  { getNewPurchaseFundList, getNavData, removeNavData }
)(Nav);
