import React, { Component } from "react";

export default class TabHeader extends Component {
  render() {
    return (
      <div
        className={!!this.props.active ? "tabs-active" : "tabs"}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          cursor: "pointer"
        }}
        onClick={this.props.onClick}
      >
        {this.props.value}
      </div>
    );
  }
}
