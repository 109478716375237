import React, { Component } from "react";
import Appbar from "./appbar";
import Signbutton from "../forms/button";
import { Input } from "../forms/input";
import DemoCarousel from "../auth/carsouel";
import { connect } from "react-redux";
import {
  userLogin,
  generateLoginOtp,
  validateLoginOtp,
  passwordPolicy_JMF,
} from "shared/store/actions";
import { REQ_BY } from "shared/store/types";
import { DASHBOARD_URL } from "../home/dashboard";
import { BUILD_URL } from "../home/home";

import CustomLoader from "../forms/customLoader";

import { getProfileData } from "shared/store/actions/profile";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../forms/appEssentials";
var token1;
var token2;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: "" },
      otp: { value: "" },
      password: { value: "" },
      showPassword: false,
      PosterOpen: true,
      display: false,
      displayPassword: false,
      disabled: false,
      arncode: "",
      flag: "",
      MobileNo: "",
      email: "",
    };
  }

  changeValue = (label) => (event) => {
    if (event.target.value.length <= 30) {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };
  strongRegex = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
  );

  showError = (label) => async (event) => {
    const {
      [label]: { value },
    } = this.state;
    this.setState({
      [label]: {
        value,
        isError: !value,
        errorMessage: `${
          label === "username"
            ? "User ID"
            : label === "password"
            ? "Password"
            : label
        } is mandatory`,
      },
    });
    if (!!this.state.password.value) {
      if (!this.strongRegex.test(this.state.password.value)) {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage:
              "Password should be minimum 8 characters in length and should consist of at least One Capital alphabet, One Small alphabet, One Special Character and One numeric number",
          },
        });
      }
    }
  };
  keyDown = (label) => (event) => {
    if (event.key === "Enter") {
      if (label === "username") {
        this.otpField.focus();
      } else if (label === "otp") {
        this.validateOtp();
      } else if (label === "password") {
        this.signIn();
      }
      event.preventDefault();
    }
  };
  signIn = async () => {
    const {
      showPassword,
      PosterOpen,
      display,
      disabled,
      MobileNo,
      otp,
      displayPassword,
      flag,
      arncode,
      email,
      nonce,
      OTPnonce,
      ...others
    } = this.state;
    const keys = Object.keys(others);
    const Noerrors = keys.every((key) => {
      return !!this.state[key].value;
    });
    if (!Noerrors) {
      keys.forEach((key) => {
        !this.state[key].value &&
          this.setState({
            [key]: {
              ...this.state[key],
              isError: true,
              errorMessage: `${
                key === "username"
                  ? "User ID"
                  : key === "password"
                  ? "Password"
                  : key
              } is mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !this.state[key].value);
      switch (firstErroredKey) {
        case "username":
          this.usernameField.focus();
          break;
        case "password":
          this.passwordField.focus();
          break;
      }
    } else {
      const nonce = Math.floor(100000 + Math.random() * 900000);
      this.setState({
        display: true,
        nonce,
      });
      // const params = {
      //   Uid: this.state.username.value,
      //   Password:this.state.password.value,
      // };
      // const responsedata = await this.props.passwordPolicy_JMF(params);
      const payload = {
        username: encrypt(this.state.username.value),
        password: encrypt(this.state.password.value),
        ReqBy: "M",
        nonce: encrypt(nonce),
        token:token2.token
      };
      const response = await this.props.userLogin(payload);
      if (response && String(response?.nonce) !== String(this.state.nonce)) {
        this.setState({
          display: false,
          username: { value: "" },
          password: { value: "" },
        });
        alert("Unauthorized Request");
        return;
      }

      if (
        !!response &&
        !response.error &&
        !!response.DtData &&
        response.DtData.length > 0
      ) {
        const DtData = response.DtData;
        if (DtData[0].tran_enable_flag === "N") {
          this.props.history.push(DASHBOARD_URL);
        } else {
          this.props.history.push(`${process.env.PUBLIC_URL}/new-transaction`);
        }
      } else if (!response) {
        this.setState({
          display: false,

          password: {
            value: "",
            isError: true,
            errorMessage: "Incorrect Password",
          },
        });
      }
    }
  };
  closePoster = () => {
    this.setState({
      PosterOpen: false,
    });
  };
  generateOtp = async () => {
    const userName = this.state.username.value;
    if (!!userName) {
      try {
        this.setState({ display: true });
        const payload = {
          Userid: encrypt(userName),
        };
        const response = await this.props.generateLoginOtp(payload);
        console.log("response", response);
        token1 = response;
      } catch (e) {
        alert(e);
      } finally {
        this.setState({ display: false });
      }
    } else {
      this.setState({
        username: {
          value: "",
          isError: true,
          errorMessage: "User ID is mandatory",
        },
      });
      this.usernameField.focus();
    }
  };
  validateOtp = async () => {
    const otp = this.state.otp.value;
    if (!!otp) {
      try {
        const OTPnonce = Math.floor(100000 + Math.random() * 900000);
        this.setState({ display: true ,OTPnonce});
        const payload = {
          Otp: encrypt(otp),
          token: token1.token,
          OTPnonce:encrypt(OTPnonce)
        };
        const response = await this.props.validateLoginOtp(payload);
        if (!!response && !!response?.OTPnonce && String(response?.OTPnonce) !== String(this.state.OTPnonce)) {
          this.setState({
            display: false,
            username: { value: "" },
            password: { value: "" },
            otp : {value : ""}
          });
          alert("Unauthorized Request");
          return;
        }
        if(!response.error){
          this.setState({ displayPassword: true, disabled: true });
          token2 = response;
        }
      } catch (e) {
        alert(e);
      } finally {
        this.setState({ display: false });
      }
    }
  };
  render() {
    const { username, password, otp } = this.state;
    return (
      <div className="login-container" style={{ overflow: "hidden" }}>
        {/* <PosterDialog open={this.state.PosterOpen} close={this.closePoster} /> */}
        <Appbar />
        {/* <Footer /> */}
        <CustomLoader display={!!this.state.display} />
        {/* <Redtoolbar /> */}
        <div className="login-without-appbar">
          <DemoCarousel />
          <div className="login-right-div" style={{ overflow: "scroll" }}>
            <div>
              <div className="login-title">SIGN IN</div>
              <Input
                name={"User ID"}
                // autoFocus={true}
                bordercolor="rgba(0, 0, 0, 0.5)"
                image={"/images/id-card.svg"}
                value={username.value}
                disabled={this.state.disabled}
                onChange={this.changeValue("username")}
                onBlur={this.generateOtp}
                isShowError={username.isError}
                errorMessage={username.errorMessage}
                inputref={(ele) => (this.usernameField = ele)}
                onKeyDown={this.keyDown("username")}
              />

              <div
                className="login-forgot mouse-pointer"
                onClick={() =>
                  this.props.history.push(BUILD_URL + "/forgot-userid")
                }
              >
                Forgot User ID?
              </div>

              <div className="input-margin">
                <Input
                  name={"OTP"}
                  value={this.state.otp.value}
                  // autoFocus={true}
                  onChange={this.changeValue("otp")}
                  bordercolor="rgba(0, 0, 0, 0.5)"
                  image={"/images/id-card.svg"}
                  disabled={this.state.disabled}
                  isShowError={otp.isError}
                  type="number"
                  errorMessage={otp.errorMessage}
                  inputref={(ele) => (this.otpField = ele)}
                  onKeyDown={this.keyDown("otp")}
                />
              </div>

              {this.state.displayPassword && (
                <>
                  <Input
                    type={!!this.state.showPassword ? "text" : "password"}
                    name={"Password"}
                    errorMessage={password.errorMessage}
                    bordercolor="rgba(0, 0, 0, 0.5)"
                    image={"/images/locked.svg"}
                    image2={
                      !!this.state.showPassword ? EYE_ICON : EYE_ICON_HIDDEN
                    }
                    height={15}
                    value={password.value}
                    onChange={this.changeValue("password")}
                    onBlur={this.showError("password")}
                    onClick={() => {
                      this.setState({
                        showPassword: !this.state.showPassword,
                      });
                    }}
                    isShowError={password.isError}
                    inputref={(ele) => (this.passwordField = ele)}
                    onKeyDown={this.keyDown("password")}
                  />
                  <div
                    className="login-forgot mouse-pointer"
                    onClick={() =>
                      this.props.history.push(BUILD_URL + "/forgot-password")
                    }
                  >
                    Forgot Password?
                  </div>
                </>
              )}
              {this.state.displayPassword ? (
                <Signbutton buttontitle="SIGN IN" onClick={this.signIn} />
              ) : (
                <Signbutton
                  buttontitle="Submit OTP"
                  onClick={this.validateOtp}
                />
              )}

              <div className="login-no-account">
                Don't have an account?{" "}
                <span
                  className="login-no-account-span mouse-pointer"
                  onClick={() =>
                    this.props.history.push(BUILD_URL + "/register")
                  }
                >
                  SIGN UP
                </span>
              </div>
              <p
                className="Request-text"
                style={{ marginTop: 20, color: "black" }}
              >
                If you have access to Kfintech common portal then request you to
                use the same credentials to Sign In
              </p>

              <div
                style={{
                  display: "flex",
                  marginTop: 130,
                }}
              >
                <a
                  href="https://mfs.kfintech.com/mfs/Generalpages/KFinKart_termsconditions.aspx"
                  target="_blank"
                  className="terms-conditions"
                  style={{ textAlign: "left" }}
                >
                  Terms and Conditions*
                </a>
                <div
                  style={{
                    color: "#1f317f",
                    fontSize: 14,
                  }}
                >
                  Support:
                  <a
                    href="mailto:distributorcare@jmfl.com"
                    className="terms-conditions"
                  >
                    distributorcare@jmfl.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { token, UserId, userLogout } = authReducer;
    return { token };
  },
  {
    userLogin,
    getProfileData,
    generateLoginOtp,
    validateLoginOtp,
    passwordPolicy_JMF,
  }
)(Login);

export class Redtoolbar extends Component {
  render() {
    const features = [
      { name: "Home" },
      { name: "About Us" },
      { name: "NAVs and Dividends" },
      { name: "Products" },
      { name: "Home" },
      { name: "Downloads" },
      { name: "Knowledge center" },
      { name: "Distributor Corner" },
      { name: "Transact Online" },
      { name: "Customer Services" },
    ];
    return (
      <div className="red-tool-bar">
        {features.map((ele, index) => (
          <div key={index} className="flex">
            <div className="white-line"></div>
            <div className="name">{ele.name}</div>
          </div>
        ))}
        <div className="white-line"></div>
      </div>
    );
  }
}

function encrypt(text) {
  const str = "acgredf7e2e";
  const str1 = "hseckahdfis234te";
  const decode = btoa(text);
  const encrpytion1 = str + decode + str1;
  const encrpyted = btoa(encrpytion1);
  return encrpyted;
}
