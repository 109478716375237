import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import CustomizedSelect from "../transactions/customizedSelect";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "../../forms/input";
import Signbutton from "../../forms/button";
import CustomizedDialogs from "../../home/customModal";
import { MBRStatusDialog } from "../../home/customModal";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ALL } from "shared/store/types";
import {
  getInvestorDetails,
  getNewPurchaseFundList,
} from "shared/store/actions";
import {
  getMailbackList,
  GetDistMailbackRequest,
} from "shared/store/actions/mailback";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { SundaramMutualFund } from "../transactions/newPurchaseForm";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../../forms/appEssentials";
export const MAILBACK_URL = `${process.env.PUBLIC_URL}/mailBackRequest`;
class MailBack extends Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = {
      fund: { value: "" },
      reports: [],
      status: {
        value: false,
      },
      report:
        !!state && !!state.value
          ? {
              value: {
                label: state.value.KDM_Repdesc,
                value: state.value.KDM_Repcode,
              },
            }
          : { value: "" },
      reportType: { value: "" },
      fromDate: { value: null, error: false },
      toDate: { value: null, error: false },
      password: { value: "" },
      showPassword: false,
      open: false,
      disableReportType: !!state && !!state.value,
      refrenceNumber: { value: "" },
    };
  }

  async componentDidMount() {
    const response = await this.props.getMailbackList();
    if (response) {
      this.setState({
        reports: response.DtData,
      });
    }
  }
  handleChangeReports = (value) => {
    this.setState({
      report: { value },
    });
  };
  handleChangeReportTypes = (event) => {
    this.setState({
      reportType: { value: event.target.value },
    });
  };
  handleChangeFunds = (value) => {
    this.setState({
      fund: { value },
    });
  };
  handleChangePassword = (event) => {
    this.setState({
      password: { value: event.target.value },
    });
  };
  onDateChange = (label, event) => {
    if (label === "toDate" && !!this.state.fromDate.value && !!event) {
      const reformtStDt = this.state.fromDate.value.toLocaleDateString(
        "en-US",
        {
          month: "2-digit",
          year: "numeric",
          day: "2-digit",
        }
      );

      const changeEndDt = event.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });

      const d2 = new Date(reformtStDt).setHours(12, 0, 0, 0);
      const d3 = new Date(changeEndDt).setHours(12, 0, 0, 0);
      if (d2 > d3 || d2 === d3) {
        this.setState({
          [label]: {
            value: null,
            error: true,
            errorMessage: "Start Date should be earlier than End Date",
          },
        });
      } else {
        this.setState({
          [label]: { value: event, errorMessage: "", error: false },
        });
      }
    } else if (label === "fromDate") {
      this.setState({
        [label]: { value: event },
        toDate: { value: null, error: "", errorMessage: "" },
      });
    }
    // this.setState({ [label]: { value: event } });
  };

  submit = async () => {
    let toDateValue;
    let fromDateValue;
    if (this.state.fromDate.value) {
      fromDateValue = this.state.fromDate.value.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });
    }
    if (this.state.toDate.value) {
      toDateValue = this.state.toDate.value.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });
    }
    const {
      funds,
      fund,
      reports,
      fromDate,
      status,
      toDate,
      showPassword,
      open,
      refrenceNumber,
      disableReportType,
      ...others
    } = this.state;

    const keys = Object.keys(others);
    const d2 = new Date(this.state.fromDate.value).setHours(12, 0, 0, 0);
    const d3 = new Date(this.state.toDate.value).setHours(12, 0, 0, 0);

    const findError = keys.every((key) => !!others[key].value);

    if (!this.state.toDate.value) {
      this.setState({
        toDate: {
          value: null,
          error: true,
          errorMessage: "End date is mandatory",
        },
      });
    }

    if (!this.state.fromDate.value) {
      this.setState({
        fromDate: {
          value: null,
          error: true,
          errorMessage: "Start date is mandatory",
        },
      });
    }

    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "report"
                  ? "Report"
                  : key === "reportType"
                  ? "Report type"
                  : key === "password"
                  ? "Password"
                  : key === "fund"
                  ? "Fund"
                  : ""
              } is mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "report": {
          this.reportField.focus();
          break;
        }
        case "reportType": {
          this.reportTypeField.focus();
          break;
        }
        case "fromDate": {
          this.fromDateField.focus();
          break;
        }
        case "toDate": {
          this.toDateField.focus();
          break;
        }
      }
    } else if (
      this.state.password.value === "" ||
      !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.state.password.value
      )
    ) {
      if (
        !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          this.state.password.value
        )
      ) {
        this.setState({
          password: {
            value: this.state.password.value,
            isError: true,
            errorMessage: `Password should be atleast 8 characters in length and should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)`,
          },
        });
        return;
      } else if (this.state.password.value === "") {
        this.setState({
          password: {
            value: this.state.password.value,
            isError: true,
            errorMessage: `Password is mandatory`,
          },
        });
        return;
      }
    } else if (!this.state.fromDate.value) {
      this.setState({
        fromDate: {
          value: null,
          error: true,
          errorMessage: "Start Date is mandatory",
        },
      });
      return;
    } else if (!this.state.toDate.value) {
      this.setState({
        toDate: {
          value: null,
          error: true,
          errorMessage: "End date is mandatory",
        },
      });
      return;
    } else if (d2 > d3 || d2 === d3) {
      this.setState({
        toDate: {
          value: null,
          error: true,
          errorMessage: "Start date should be earlier than End date",
        },
      });
    } else {
      const params = {
        Userid: this.props.userId,
        ReportNumber: this.state.report.value.value,
        ZipPwd: this.state.password.value,
        Fund: "105",
        toDate: toDateValue,
        fromDate: fromDateValue,
        ReqBy: ALL,
        Agent: this.props.token,
        reportType: this.state.reportType.value === "Excel" ? "Excel" : "DBF",
      };
      const response = await this.props.GetDistMailbackRequest(params);
      if (response) {
        this.setState({
          open: true,
          refrenceNumber: { value: response.DtData[0].Refno },
        });
      }
    }
  };

  closeModal = () => {
    this.setState({
      open: false,
      fund: { value: "" },
      report: { value: "" },
      reportType: { value: "" },
      fromDate: { value: null, error: false },
      toDate: { value: null, error: false },
      password: { value: "" },
      refrenceNumber: { value: "" },
      disableReportType: false,
    });
  };
  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "") {
      this.setState({
        [label]: {
          value: "",
          isError: this.state.selfPassword.value === "" ? true : !value,
          errorMessage:
            this.state.selfPassword.value === ""
              ? `(selfPassword is mandatory)`
              : "",
        },
      });
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };
  close() {
    this.setState({
      status: {
        value: false,
      },
    });
  }
  gotoblur = () => {
    if (
      this.state.password.value === "" ||
      !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.state.password.value
      )
    ) {
      if (
        !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          this.state.password.value
        )
      ) {
        this.setState({
          password: {
            value: this.state.password.value,
            isError: true,
            errorMessage: `Password should be atleast 8 characters in length and should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)`,
          },
        });
        return;
      } else if (this.state.password.value === "") {
        this.setState({
          password: {
            value: this.state.password.value,
            isError: true,
            errorMessage: `Password is mandatory`,
          },
        });
        return;
      }
    }
  };

  render() {
    const { fundsList, location } = this.props;
    const { fund, reports, report, reportType, password } = this.state;
    const { state } = location;
    return (
      <div className="mailback-myself">
        <div className="investor-title">My Reports</div>

        <Paper className="right-bar-container-paper">
          <div
            className="digital-form-Right-div"
            style={{ marginTop: 50, borderLeft: 0 }}
          >
            <div className="transaction-details">
              <div className="input-margin flex two-div ">
                <div className="appbar-div dual-margin">
                  <CustomizedSelect
                    name={"Report Type"}
                    handleChange={this.handleChangeReportTypes}
                    value={reportType.value}
                    inputRef={(ele) => (this.reportTypeField = ele)}
                    isError={reportType.isError}
                    errorMessage={reportType.errorMessage}
                    isShowError={reportType.isError}
                    placeholder="Select Report Type"
                  >
                    <MenuItem value="Excel" key="Excel">
                      Excel
                    </MenuItem>
                    <MenuItem value="DBF" key="DBF">
                      DBF
                    </MenuItem>
                  </CustomizedSelect>
                </div>
                <div className="appbar-div dual-margin ">
                  <div className="input-name">Select Report</div>
                  <IntegrationReactSelect
                    suggestions={reports.map((item) => ({
                      label: item.KDM_Repdesc.split("-")[1].trim(),
                      value: item.KDM_Repcode,
                    }))}
                    value={report.value}
                    placeholder="Select Report"
                    errorMessage={report.errorMessage}
                    maxDate={new Date()}
                    disabled={this.state.disableReportType}
                    inputRef={(ele) => (this.reportField = ele)}
                    isError={report.isError}
                    isShowError={report.isError}
                    handleChange={this.handleChangeReports}
                  />
                </div>
              </div>

              <div className="input-margin flex two-div">
                <div className="appbar-div dual-margin">
                  <div className="input-name">Start Date</div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required={true}
                      className="selects"
                      style={{
                        border: !!this.state.fromDate.error
                          ? "1px solid red"
                          : "",
                        borderRadius: 7,
                      }}
                      onChange={(e) => this.onDateChange("fromDate", e)}
                      // openTo={true}
                      disableFuture={true}
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      // animateYearScrolling={true}
                      id="mui-pickers-date"
                      value={this.state.fromDate.value}
                    />
                    {!!this.state.fromDate.errorMessage ? (
                      <div
                        style={{ color: "red", fontFamily: "Ubuntu-Italic" }}
                      >
                        {this.state.fromDate.errorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </MuiPickersUtilsProvider>
                </div>
                <div className="appbar-div dual-margin">
                  <div className="input-name">End Date</div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required={true}
                      className="selects"
                      style={{
                        border: !!this.state.toDate.error
                          ? "1px solid red"
                          : "",

                        borderRadius: 7,
                      }}
                      onChange={(e) => this.onDateChange("toDate", e)}
                      // onBlur={}
                      // openTo={true}
                      disabled={!this.state.fromDate.value ? true : false}
                      disableFuture={true}
                      format="dd/MM/yyyy"
                      errorMessage={this.state.toDate.errorMessage}
                      maxDate={new Date()}
                      // animateYearScrolling={true}
                      id="mui-pickers-date"
                      value={this.state.toDate.value}
                    />
                    {!!this.state.toDate.errorMessage ? (
                      <div
                        style={{ color: "red", fontFamily: "Ubuntu-Italic" }}
                      >
                        {this.state.toDate.errorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="input-margin flex two-div">
                <div className="appbar-div dual-margin">
                  <Input
                    name="Password to Open"
                    onChange={this.handleChangePassword}
                    type={!!this.state.showPassword ? "text" : "password"}
                    onBlur={this.gotoblur}
                    onClick={() => {
                      this.setState({
                        showPassword: !this.state.showPassword,
                      });
                    }}
                    image2={
                      !!this.state.showPassword ? EYE_ICON : EYE_ICON_HIDDEN
                    }
                    height={15}
                    value={password.value}
                    inputRef={(ele) => (this.passwordField = ele)}
                    isShowError={password.isError}
                    errorMessage={password.errorMessage}
                  />
                  {!password.isError && (
                    <div
                      style={{
                        fontSize: 13,
                        fontFamily: "SourceSansPro-Regular",
                        marginTop: 4,
                      }}
                    >
                      Password should be at least 8 characters in length and
                      should contain at least 1 uppercase letter (A-Z), 1
                      special character and 1 numeric value (0-9)
                    </div>
                  )}
                </div>
                <div className="appbar-div dual-margin"></div>
              </div>

              <div className="digital-form-button-next-div">
                <Signbutton
                  buttontitle="Submit"
                  onClick={this.submit}
                  width={135}
                />
              </div>

              <CustomizedDialogs
                open={this.state.open}
                refNo={this.state.refrenceNumber.value}
                closeModal={this.closeModal}
                myself={false}
                componentName="MailBacKRequest"
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const { investorData, userId, token } = authReducer;
    const { fundsList } = transactions;
    return { investorData, userId, token, fundsList };
  },
  {
    getNewPurchaseFundList,
    getInvestorDetails,
    getMailbackList,
    GetDistMailbackRequest,
  }
)(MailBack);
