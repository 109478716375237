import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { FormatCurrency } from "../../home/currencyFormatter";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  getInvestorDetails,
  favouriteInvestor,
  updateFavouriteInvestor,
  getUpComingSips,
  getInvestors,
} from "shared/store/actions";
import {
  YES,
  TRUE,
  FALSE,
  INVESTMENTS,
  REQ_BY,
  UPCOMING_SIP,
} from "shared/store/types";
import InvestmentTab from "./investmentsTab";
import Investments from "./investments";
import PieDiagram from "../../home/dashboard/piechart";
import { BUILD_URL } from "../home";
import CustomLoader from "../../forms/customLoader";
import { Debtbutton, DebtbuttonActive } from "../../forms/button";

class InvestorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      noData: false,
      count: 3,
      active: INVESTMENTS,
    };
  }

  async componentDidMount() {
    await this.props.getInvestors({
      ARN: this.props.token,
      AllFavFlag: "A",
    });
    const { location,investorData } = this.props;
    const { state } = location;
    const params = { PAN: state || investorData.PAN, Uid: this.props.userId, ReqBy: REQ_BY };
    const response = await this.props.getInvestorDetails(params);

    if (response) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false, noData: true });
    }

    if (this.props.investorData) {
      const Params = {
        Pan: this.props.investorData.PAN,
        YearFlag: new Date().getFullYear(),
      };
      await this.props.getUpComingSips(Params);
    }
  }

  favourite = async () => {
    let { fav, PAN: InvPan, invname: Name } = this.props.investorData;

    const FavFlag = fav === TRUE ? FALSE : TRUE;
    const params = { InvPan, FavFlag, Name, AgentCode: this.props.token };
    const response = await this.props.favouriteInvestor(params);

    if(!!response && !!response.Dtinformation && !!response.Dtinformation[0] && !!response.Dtinformation[0].Error_Message){

      alert(`${response.Dtinformation[0].Error_Message}`)
     await this.props.updateFavouriteInvestor({ InvPan, FavFlag });
      await this.props.getInvestors({
        ARN: this.props.token,
        AllFavFlag: "A",
      });
    }else if(!!response && !!response.error) {
      alert(`${response.error}`)
      await this.props.updateFavouriteInvestor({ InvPan, FavFlag });
      await this.props.getInvestors({
        ARN: this.props.token,
        AllFavFlag: "A",
      });
    }else if (!response) {
      this.setState({
        errorMessage: "Already this pan ID member is added as a Favorite",
      });
    }
  };

  changeActive = (active) => () => {
    this.setState({ active });
  };

  render() {
    const {
      investorData,
      communicationDetails = [],
      pieData,
      investors,
      investments,
      upComingSipDetails,
    } = this.props;
    const detailsEmail = communicationDetails.find(
      (detail) => detail.PAN === investorData.PAN && !!detail.email
    );
    const detailsMobile = communicationDetails.find(
      (detail) => detail.PAN === investorData.PAN && !!detail.Mobile
    );

    const fatca = this.props.investors.find(
      (ele) =>
        !!investorData && !!investorData.PAN && ele.Pan === investorData.PAN
    );
    const { isLoading, noData } = this.state;





    return (
      <div>
        {!isLoading ? (
          <React.Fragment>
            {!noData ? (
              <React.Fragment>
                <div>
                  <Paper style={{boxShadow:"0px 0px 3px 0px rgba(211, 211, 211)"}}>
                    <div className="investment_details_main_top_div">
                      <div className="inv_detail_left_col">
                        <div>
                        <div className="investor-details-left-container">
                          <div>
                            <div>
                              <img
                                src={BUILD_URL + "/images/investor_profile.svg"}
                                style={{ height: 50 }}
                                alt="investor_profile"
                              />
                            </div>
                          </div>
                          <div style={{ marginLeft: 20 }}>
                            <div className="investor-details-name">
                              {investorData.invname}
                            </div>
                            <div className="investor-details-pan">
                              PAN: {investorData.PAN}
                            </div>
                            <div className="flex">
                              <div className="investor-details-vefication-div">
                                <img
                                  src={
                                    investorData.KYC === YES
                                      ? BUILD_URL +
                                        "/images/checkmark_investors.svg"
                                      : BUILD_URL +
                                        "/images/cross_investors.svg"
                                  }
                                  alt={
                                    investorData.KYC === YES
                                      ? "checkmark"
                                      : "cross"
                                  }
                                  style={{ height: 8, marginLeft: 5 }}
                                />
                                <span className="investor-details-vefication-names">
                                  KYC
                                </span>
                                <img
                                  src={
                                    !!fatca && fatca.Fatca === true
                                      ? BUILD_URL +
                                        "/images/checkmark_investors.svg"
                                      : BUILD_URL +
                                        "/images/cross_investors.svg"
                                  }
                                  style={{ height: 8, marginLeft: 10 }}
                                  alt="cross"
                                />
                                <span className="investor-details-vefication-names">
                                  FATCA
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="investors-details-mail-div">
                          <img
                            src={
                              BUILD_URL + "/images/mail_investor-details.svg"
                            }
                            style={{ height: 15, marginRight: 7 }}
                            alt="mail"
                          />
                          <div className="investors-details-email">
                            {!!detailsEmail && detailsEmail.email
                              ? detailsEmail.email
                              : ""}
                          </div>
                          <img
                            src={
                              BUILD_URL + "/images/call_investor-details.svg"
                            }
                            style={{ height: 15, marginRight: 7 }}
                            alt="call"
                          />
                          <div className="investor-details-phone-no">
                            {!!detailsMobile && !!detailsMobile.Mobile
                              ? detailsMobile.Mobile
                              : ""}
                          </div>
                        </div>
                        </div>
                        <div className="investors-details-fav-button-container">
                          <div
                            className={
                              !!investors && investors.find((ele)=>ele.Pan === investorData.PAN && !!ele.Favorite && ele.Favorite==="T")
                                ? "investors-details-fav-button-div mouse-pointer button-highlight"
                                : "investors-details-fav-button-div mouse-pointer"
                            }
                            onClick={this.favourite}
                          >
                            <img
                              src={BUILD_URL + "/images/star_fav.svg"}
                              style={{ height: 15, marginRight: 10 }}
                              alt="star_fav"
                            />
                            <div>{!!investors && investors.find((ele)=>ele.Pan === investorData.PAN && !!ele.Favorite && ele.Favorite==="T")?"Favourite Investor":"Favourite"}</div>
                          </div>
                        </div>

                        {!!this.state.errorMessage ? (
                          <div
                            style={{
                              color: "red",
                              fontFamily: "Ubuntu-Italic",
                              textAlign: "center",
                              fontSize: 12,
                            }}
                          >
                            {this.state.errorMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="investors-details-right-container" style={{display:'flex',flexDirection:"column"}}>
                      <div className="flex responsive-split-one" style={{ justifyContent: "center" }}>
                          <div className="inv-right-div-split-one ">
                            <div className="investors-details-total-investment-div ">
                              <div style={{ margin: "10px 10px" }}>
                                <div className="investors-details-total-investment-name">
                                  Cost
                                </div>
                                <div className="investors-details-total-investment-value">

                                  {!!this.props.investorTotal &&
                                  !!this.props.investorTotal.CostValue
                                    ? <FormatCurrency value={this.props.investorTotal.CostValue.split(".")[0]} />
                                    : "--NA--"}
                                </div>
                              </div>
                              <div>
                                <img
                                  src={
                                    BUILD_URL +
                                    "/images/su_custom_graph.svg"
                                  }
                                  style={{ height: 75 }}
                                  alt="profile_graph"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="inv-right-div-split-two">
                            <div className="investors-details-current-value">
                              <div className="investors-details-change-name">
                              Investment Value
                              </div>
                              <div className="investors-details-change-percent">
                                ₹{!!this.props.investorTotal &&
                                !!this.props.investorTotal.CurrentValue
                                  ? this.props.investorTotal.CurrentValue
                                  : "--NA--"}
                              </div>
                            </div>
                            <div className="investors-details-appreciation-div">
                              <div className="investors-details-change-name">
                                Overall Gain
                              </div>
                              <div className="investors-details-change-percent">

                                ₹{!!this.props.investorTotal &&
                                !!this.props.investorTotal.GainVal
                                  ? this.props.investorTotal.GainVal
                                  : "--NA--"}

                                <img
                                  src={
                                    this.props.investorTotal &&
                                    this.props.investorTotal.Gain
                                      ? this.props.investorTotal.Gain === "N"
                                        ? BUILD_URL +
                                          "/images/down_investors.svg"
                                        : BUILD_URL +
                                          "/images/up_investor-details.svg"
                                      : null
                                  }
                                  style={{ height: 15, marginLeft: 5 }}
                                  // alt="rise"
                                />
                              </div>
                            </div>

                            <div className="investors-details-change-box">
                              <div className="investors-details-change-name">
                                Today's Gain
                              </div>
                              <div className="investors-details-change-percent">
                                {!!this.props.investorTotal &&
                                !!this.props.investorTotal.todaygain
                                  ? this.props.investorTotal.todaygain
                                  : "--NA--"}
                                <span style={{fontSize:12}}>(%)</span>

                                <img
                                  src={
                                    this.props.investorTotal &&
                                    !!this.props.investorTotal.todaygain
                                      ? this.props.investorTotal.todaygain
                                        ? BUILD_URL +
                                          "/images/down_investors.svg"
                                        : BUILD_URL +
                                          "/images/up_investor-details.svg"
                                      : null
                                  }
                                  style={{ height: 15, marginLeft: 5 }}
                                  // alt="rise"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="investors-details-buttons-div" style={{flexGrow:1,alignItems:"flex-end",marginBottom:4}}>
                          <Debtbutton
                            onClick={() =>
                              this.props.history.push({
                                pathname:
                                  BUILD_URL +
                                  "/new-transaction/transction-types",
                                state: {
                                  investorData: investorData,
                                },
                              })
                            }
                            display={!!investorData.PAN &&
                              investorData.PAN.charAt(3).toUpperCase() === "P"
                                ? "block"
                                : "none"}
                            buttontitle={"Initiate Investment"}
                            width={150}
                          />
                          <DebtbuttonActive
                            onClick={() =>
                              this.props.history.push({
                                pathname:
                                  BUILD_URL +
                                  "/mailBackRequest/investor",
                                state: {
                                  investorData: investorData,
                                },
                              })
                            }
                            buttontitle={"Send Reports"}
                            width={150}
                          />
                        </div>
                      </div>
                    </div>
                  </Paper>
                  <InvestmentTab
                    active={this.state.active}
                    onChange={this.changeActive}
                  />
                  <Paper>
                    <div className="investor-investments-container">
                      <Investments
                        count={this.state.count}
                        active={this.state.active}
                      />
                      {this.state.active === INVESTMENTS ? (
                        <div className="investor-details-fund-allocation-container">
                          <div className="investor-investments-fund-allocation-container">
                            <div className="investor-investments-fund-allocation-title">
                              Asset Allocation
                            </div>
                     <div>
                            <PieDiagram
                              data={pieData}
                              width={250}
                              height={200}
                              cx={120}
                              cy={80}
                              innerRadius={30}
                              outerRadius={75}
                            />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {(upComingSipDetails.length > 3 &&
                      this.state.active === UPCOMING_SIP) ||
                    (this.state.active === INVESTMENTS &&
                      investments.length > 3) ? (
                      <div
                        className="investors-investemnts-see-more mouse-pointer"
                        onClick={() =>
                          this.state.active === INVESTMENTS
                            ? this.setState({ count:parseFloat(this.state.count)===3?  investments.length:3 })
                            : this.setState({
                                count: parseFloat(this.state.count)===3? upComingSipDetails.length:3,
                              })
                        }
                      >
                        {parseFloat(this.state.count)===3?"See more":"See Less"}
                        {parseFloat(this.state.count)===3?  <ExpandMore />: <ExpandLess />}
                      </div>
                    ) : (
                      <div className="investors-investemnts-see-more" />
                    )}
                  </Paper>
                </div>
              </React.Fragment>
            ) : (
              <div>No Investor PAN found. Please submit investor PAN.</div>
            )}
          </React.Fragment>
        ) : (
          <CustomLoader display={true} />
        )}
      </div>
    );
  }
}

export default connect(
  ({ authReducer, investor, loading }) => {
    const { userId, token, communicationDetails, investorData } = authReducer;
    const {
      fundAllocation,
      pieData,
      investments,
      investors,
      investorTotal,
      upComingSipDetails,
    } = investor;
    const { count } = loading;
    return {
      userId,
      investorData,
      fundAllocation,
      pieData,
      communicationDetails,
      token,
      investors,
      investorTotal,
      investments,
      upComingSipDetails,
      count,
    };
  },
  {
    getInvestorDetails,
    favouriteInvestor,
    updateFavouriteInvestor,
    getUpComingSips,
    getInvestors,
  }
)(InvestorDetails);
