import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-bootstrap";
import {
  carouselImage1,
  carouselImage6
} from "../forms/appEssentials";

export default class DemoCarousel extends Component {
  render() {
    const CarsouelArray = [
      {
        title: "",
        desci:
          "",
        image: carouselImage6,
      },
      {
        title: "VIEW VALUE ACROSS ASSET CLASSES",
        desci:
          "See holdings across asset classes - Equity, Debt, Liquid - and across funds and categories",
        image: carouselImage1,

      },
      // {
      //   title: "DO MORE WITH EASE AND SPEED",
      //   desci:
      //     "A bunch of features that will allow you to do everything and deliever better service to customers",
      //   image: carouselImage2,
      // },
      // {
      //   title: "DASHBOARD FOR EVERYTHING",
      //   desci:
      //     "Instantly access investor portfolios, glide through transactions and generate statements on the move",
      //   image: carouselImage3,
      // },
      // {
      //   title: "ONE VIEW MANY INSIGHTS",
      //   desci:
      //     "Choose a mutual fund scheme and check its ups and downs and performance across years",
      //   image: carouselImage4,
      // },
    ];
    return (
      <div className="carsouel-div" style={{ backgroundColor: "white" }}>
        <Carousel className="carsol" controls={false} interval={7000} >
          {CarsouelArray.map((ele, index) => (
            <Carousel.Item style={{ zIndex: 1 }} key={index} style={{ backgroundColor: "#1f317f" }}>
              <div className="login-main-text">
                {ele.title}
              </div>
              <div className="login-sub-text">
                {ele.desci}
              </div>

              <img className="carsouel" src={ele.image} alt="" style={{ height: "auto" }} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }
}
