import React, { Component } from "react";
import { DebtbuttonNowidth } from "../../forms/button";
import { Input } from "../../forms/input";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { PasswordDialog } from "../customModal";
import {
  getProfileData,
  resetPassword,
  KARVY_STOCK_BROKING_LIMITED,
  SAMCO_STOCK
} from "shared/store/actions/profile";
import { BUILD_URL } from "../home";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPasswordModal: false
    };
  }
  PasswordModal() {
    this.setState({
      openPasswordModal: true
    });
  }
  closeModal() {
    this.setState({
      openPasswordModal: false
    });
  }

  componentDidMount() {
    this.props.getProfileData({
      Agent: this.props.token,
      UserId: this.props.userId
    });
  }
  render() {
    const { profileData, reviewList, token } = this.props;
    const {
      Distributor_Name = "",
      City = "",
      MobileNo = "",
      PAN = "",
      Email = ""
    } = profileData;

    return (
      <div className="profile-setting-page">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: 20
          }}
        >
          <div className="profile-settings-title">Profile Settings</div>
          <DebtbuttonNowidth
            buttontitle="Change Password"
            onClick={() => this.PasswordModal()}
          />
        </div>
        <PasswordDialog
          openPasswordModal={this.state.openPasswordModal}
          closeModal={() => this.closeModal()}
          history={this.props.history}
        />
        <Paper className="flex profile-flex">
          <div className="profile-settings-left-container">
            <div className="input-less-margin">
              <Input
                name="ARN Code"
                height={15}
                value={token.split("-")[1]}
                bordercolor="rgba(0,0,0,0.3)"
                disabled={true}
              />
            </div>

            <div className="input-less-margin">
              <Input
                name="Name"
                bordercolor="rgba(0,0,0,0.3)"
                disabled={true}
                value={
                  // Distributor_Name === KARVY_STOCK_BROKING_LIMITED
                  //   ? SAMCO_STOCK :
                  Distributor_Name || ""
                }
              />
            </div>

            <div className="input-less-margin">
              <Input
                name="City"
                bordercolor="rgba(0,0,0,0.3)"
                disabled={true}
                value={City || ""}
              />
            </div>

            <div className="input-less-margin">
              <Input
                name="Mobile"
                bordercolor="rgba(0,0,0,0.3)"
                disabled={true}
                value={MobileNo || ""}
              />
            </div>

            <div className="input-less-margin">
              <Input
                name="Email ID"
                bordercolor="rgba(0,0,0,0.3)"
                disabled={true}
                value={Email || ""}
              />
            </div>

            <div className="input-less-margin">
              <Input
                name="PAN"
                bordercolor="rgba(0,0,0,0.3)"
                disabled={true}
                value={PAN || ""}
              />
            </div>
          </div>
          <div className="profile-settings-right-container">
            <div className="profile-settings-review">Review</div>
            <div className="flex" style={{ marginBottom: 20 }}>
              <div
                style={{
                  width: "50%",
                  marginLeft: 30,
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 14
                }}
              >
                Year
              </div>
              <div
                style={{
                  width: "50%",
                  marginLeft: 20,
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 14
                }}
              >
                Status
              </div>
            </div>
            {reviewList.slice(0, 9).map((item, index) => (
              <React.Fragment key={index}>
                <SubmitList
                  fromYear={item.From_Year}
                  toYear={item.To_Year}
                  confirmation={`${item["Self Declaration Status"]}`}
                />
                {index !== reviewList.length - 1 && index < 8 && <BlueLine />}
              </React.Fragment>
            ))}
          </div>
        </Paper>
      </div>
    );
  }
}

export class SubmitList extends Component {
  render() {
    const { fromYear, toYear, confirmation } = this.props;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <img
            src={BUILD_URL + "/images/su_timer_icon.svg"}
            alt="timer_background"
            style={{ height: 20, margin: "0px 5px" }}
          />
        </div>
        <div className="profile-settings-submit-year">
          {fromYear} - {toYear}
        </div>

        <div className="profile-settings-submit-container">
          <img
            src={
              confirmation === "Submitted"
                ? BUILD_URL + "/images/tick.png"
                : BUILD_URL + "/images/cross.png"
            }
            alt={confirmation === "Submitted" ? "submitted" : "not_submitted"}
            style={{ height: 10, marginRight: 10 }}
          />

          <div
            style={{
              fontSize: 12,
              fontFamily: "SourceSansPro-Regular",
              color: confirmation === "Submitted" ? "#00579b" : "#df7b71"
            }}
          >
            {confirmation==="Submitted"?`Self Declaration ${confirmation}`:confirmation}
          </div>
        </div>
      </div>
    );
  }
}

export class BlueLine extends Component {
  render() {
    return (
      <div
        style={{
          width: 3,
          height: 30,
          backgroundColor: "#e9f6ff",
          margin: "-3px 13px"
        }}
      />
    );
  }
}

const MapStatetoProps = ({ authReducer, profileReducer }) => {
  const { token, userId } = authReducer;
  const { profileData, reviewList } = profileReducer;
  return { token, userId, profileData, reviewList };
};

export default connect(MapStatetoProps, { getProfileData, resetPassword })(
  Settings
);
