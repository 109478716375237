export var USER_LOGIN = "USER_LOGIN";
export var USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export var USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export var USER_LOGOUT = "USER_LOGOUT";
export var LOCAL_AUTHENTICATION_SUCCESS = "LOCAL_AUTHENTICATION_SUCCESS";
export var SKIP_LOCAL_AUTHENTICATION_SUCCESS = "SKIP_LOCAL_AUTHENTICATION_SUCCESS";
export var LOCAL_AUTH_USERNAME_SUCCESS = "LOCAL_AUTH_USERNAME_SUCCESS";
export var LOCAL_AUTH_PASSWORD_SUCCESS = "LOCAL_AUTH_PASSWORD_SUCCESS";
export var FIRST_TIME_INSTALLATION_SUCCESS = "FIRST_TIME_INSTALLATION_SUCCESS";
export var AUTO_SELECT = "AUTO_SELECT";
export var REMEMBER_ME = "REMEMBER_ME";
export var USER_PAN = "USER_PAN";
export var USER_LOGIN_REFRESH_FAILED = "USER_LOGIN_REFRESH_FAILED";
export var USER_LOGIN_REFRESH_SUCCESS = "USER_LOGIN_REFRESH_SUCCESS";
export var USER_LOGIN_REFRESH = "USER_LOGIN_REFRESH";
export var POSTER_OPEN = "POSTER_OPEN";