import React, { Component } from "react";
import CommonBreadCrumb from "../breadCrumbs";
import HistoryRouter from "./historyRouter";
export const HISTORY_URL = `${process.env.PUBLIC_URL}/transaction-history`;

const breadcrumbNameMap = {
  "/transaction-history": {
    title: "Transaction History",
    to: HISTORY_URL
  },
  "/transaction-history/details": {
    title: "Transactions List",
    to: HISTORY_URL + "/details"
  }
};

export default class TransactionHistory extends Component {
  render() {
    const { location } = this.props;
    const pathnames = location.pathname.includes("/details")
      ? location.pathname
          .split("/")
          .filter(name => name)
          .slice(0, 2)
      : location.pathname.split("/").filter(name => name);
    return (
      <div>
        <CommonBreadCrumb
          hideFirst={true}
          pathnames={pathnames}
          breadcrumbNameMap={breadcrumbNameMap}
        />
        <HistoryRouter />
      </div>
    );
  }
}
