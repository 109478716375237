import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { GET_PROFILE_DATA_SUCCESS } from "../types/profile";
export function profileReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    profileData: {},
    reviewList: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_PROFILE_DATA_SUCCESS:
      {
        var _action$body = action.body,
            DtData = _action$body.DtData,
            _action$body$Table = _action$body.Table2,
            reviewList = _action$body$Table === void 0 ? [] : _action$body$Table;

        var _DtData = _slicedToArray(DtData, 1),
            profileData = _DtData[0];

        return _objectSpread({}, state, {
          profileData: profileData,
          reviewList: reviewList
        });
      }

    default:
      return state;
  }
}