import React, { Component } from "react";
import TabHeader from "../../forms/tabHeader";
import { INVESTMENTS, UPCOMING_SIP } from "shared/store/types";

export default class InvestmentTab extends Component {
  render() {
    return (
      <div
        className="tab-container"
        style={{
          justifyContent: "flex-start",
          margin: "25px 20px 15px 20px",
          borderBottom: "1px solid #7dbbe2"
        }}
      >
        <TabHeader
          value="Investments"
          active={this.props.active === INVESTMENTS}
          onClick={this.props.onChange(INVESTMENTS)}
        />
        <TabHeader
          value="Upcoming SIPs"
          active={this.props.active === UPCOMING_SIP}
          onClick={this.props.onChange(UPCOMING_SIP)}
        />
      </div>
    );
  }
}
