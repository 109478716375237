import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboardmain";
import TabContainer from "./tabs";
import ListOfInvestorsTabs from "./listofInvestorTabs";
import SipSummaryTabContainer from "./SipSummaryTabContainer";
import NfoFunds from "./nfoFunds";
import NfoTransact from "./NfoTransaction";
import MyEarnings from '../myEarnings/index'

export default class DashboardRouter extends Component {
  render() {
    const { history, location } = this.props;
    return (
      <Switch location={location} history={history}>
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard/transaction-summary`}
          component={TabContainer}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard/investorType`}
          component={ListOfInvestorsTabs}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/dashboard/SipSummaryTypes`}
          component={SipSummaryTabContainer}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard`}
          exact
          component={Dashboard}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard/nfo-funds`}
          component={NfoFunds}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard/nfo-funds/nfo-transact`}
          component={NfoTransact}
          exact
        />
        {/* <Route
          path={`${process.env.PUBLIC_URL}/my-earnings/index`}
          component={MyEarnings}
          exact
        /> */}
        {/* <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route> */}
      </Switch>
    );
  }
}
