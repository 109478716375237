import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class CustomLoading extends Component {
  render() {
    return (
      <div
        style={{
          display: this.props.display || "flex",
          justifyContent: "center"
        }}
      >
        <Loader
          type={this.props.type || "Grid"}
          color="#00579b"
          height={this.props.height}
          width={this.props.width}
          style={{
            display: "flex"
          }}
        />
      </div>
    );
  }
}
