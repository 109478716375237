import React, { Component } from "react";
import CommonTemplate from "./commonTemplate";
import { BUILD_URL } from "../home/home";
import Signbutton from "../forms/button";
import { Input } from "../forms/input";
import { connect } from "react-redux";
import { forgotUserId } from "shared/store/actions";
import CustomizedDialogs from "../home/customModal";

class ForgotUserId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: { value: "" },
      open: false,
    };
  }
  closeModal = () => {
    this.setState({
      open: false,
    });
    this.props.history.push(BUILD_URL + "/");
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.sendUserId();
      e.preventDefault();
    }
  };

  changeValue = (event) => {
    if(event.target.value.length <= 160)
    this.setState({
      emailId: { value: event.target.value },
    });
  };


  sendUserId = async () => {
    const {
      emailId: { value },
    } = this.state;

    if (!value) {
      this.emailIdField.focus();
      this.setState({
        emailId: {
          value,
          isError: !value,
          errorMessage: "Email ID is mandatory",
        },
      });
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
      this.setState({
        emailId: {
          value: "",
          isError: true,
          errorMessage: "Please enter a valid Email ID",
        },
      });
    } else {
      const params = {
        ReqBy: "a",
        Emailid: value,
      };
      const response = await this.props.forgotUserId(params);

      if (!!response && !!response.error) {
        alert(`${response.error}`)
        this.setState({
          emailId: {
            value: "",
            isError: true,
            errorMessage:response.error,
          },
        });
        return;
      } else if (!!response && !response.error) {
        this.setState({ open: true, emailId: { value: "" } });
      }
    }
  };

  render() {
    const { emailId } = this.state;
    return (
      <CommonTemplate
        title="Forgot User ID"
        textOne="Please enter your registered Email ID"
        textTwo=""
      >
        <Input
          name="Email ID"
          value={emailId.value}
          bordercolor="rgba(0, 0, 0, 0.5)"
          onChange={this.changeValue}
          onBlur={this.showError}
          errorMessage={emailId.errorMessage}
          isShowError={emailId.isError}
          inputref={(ele) => (this.emailIdField = ele)}
          errorMessage={emailId.errorMessage}
          onKeyPress={this.keyPress}
        />
        <Signbutton buttontitle="Submit" onClick={this.sendUserId} />
        <CustomizedDialogs
          open={this.state.open}
          closeModal={this.closeModal}
          passwordSucess={true}
          message={
            "Your UserId has been sent to your Email Id and Mobile number"
          }
        />
      </CommonTemplate>
    );
  }
}

export default connect(null, { forgotUserId })(ForgotUserId);
