import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { BUILD_URL } from "../home";

export default class Amc extends Component {
  render() {
    return (
      <div style={{ margin: "20px 40px" }}>
        <div className="investor-title">AMCs</div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap"
          }}
        >
          <AMCS
            bordercolor="#af245f"
            image={"/images/axis.png"}
            name="AXIS Mutual Fund"
            aum="AUM : 6000cr"
          />

          <AMCS
            bordercolor="#ec5500"
            image={"/images/BOB.png"}
            name="BOB Mutual Fund"
            aum="AUM : 3000cr"
          />

          <AMCS
            bordercolor="#f14124"
            image={"/images/BOI.png"}
            name="BOI Mutual Fund"
            aum="AUM : 4000cr"
          />

          <AMCS
            bordercolor="#00aeef"
            image={"/images/Canara.png"}
            name="CANARA Mutual Fund"
            aum="AUM : 2000cr"
          />

          <AMCS
            bordercolor="#e21e23"
            image={"/images/dhfl.png"}
            name="DHFL Mutual Fund"
            aum="AUM : 4000cr"
          />

          <AMCS
            bordercolor="#034ea1"
            image={"/images/edelwiess.png"}
            name="EDELWIESS Mutual Fund"
            aum="AUM : 5000cr"
          />

          <AMCS
            bordercolor="#353b90"
            image={"/images/essel.png"}
            name="ESSEL Mutual Fund"
            aum="AUM : 6000cr"
          />

          <AMCS
            bordercolor="#f5821f"
            image={"/images/idbi.png"}
            name="IDBI Mutual Fund"
            aum="AUM : 6000cr"
          />

          <AMCS
            bordercolor="#029539"
            image={"/images/Indiabulls-logo_campaign_new.png"}
            name="INDIABULLS Mutual Fund"
            aum="AUM : 6000cr"
          />

          <AMCS
            bordercolor="#001a7b"
            image={"/images/inv_logo_mf.png"}
            name="INVESCO Mutual Fund"
            aum="AUM : 4000cr"
          />

          <AMCS
            bordercolor="#dc3b27"
            image={"/images/jm.png"}
            name="JM Mutual Fund"
            aum="AUM : 6000cr"
          />

          <AMCS
            bordercolor="#004184"
            image={"/images/lic.png"}
            name="LIC Mutual Fund"
            aum="AUM : 6000cr"
          />

          <AMCS
            bordercolor="#023e7c"
            image={"/images/mirae.png"}
            name="MIRAE Mutual Fund"
            aum="AUM : 1000cr"
          />

          <AMCS
            bordercolor="#fcc400"
            image={"/images/motilal.png"}
            name="MOTILAL Mutual Fund"
            aum="AUM : 2000cr"
          />

          <AMCS
            bordercolor="#0184ca"
            image={
              BUILD_URL +
              "/images/Lippincott-logo-design-Principal-financial-services.png"
            }
            name="PRINCIPAL Mutual Fund"
            aum="AUM : 6000cr"
          />
        </div>
      </div>
    );
  }
}

export class AMCS extends Component {
  render() {
    return (
      <Paper
        className="amcs-paper"
        style={{ borderColor: this.props.bordercolor }}
      >
        <img
          src={`${BUILD_URL}${this.props.image}`}
          style={{ height: 30 }}
          alt="amc_image"
        />
        <div className="amcs-name">{this.props.name}</div>
        <div className="amcs-aum">{this.props.aum}</div>
      </Paper>
    );
  }
}
