import React from "react";
import "./App.scss";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store, { persistor, history } from "./store";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Home from "./components/home/home";
import OTP from "./components/auth/otp";
import ProtectedRoute from "./protectedRoute";
import ForgotUserId from "./components/auth/forgotUserId";
import ForgotPassword from "./components/auth/forgotPassword";
import ResetPassword from "./components/auth/resetPassword";
import { DefaultProvider, init } from "shared/components/utils";

class AppProvider extends DefaultProvider {
  showNotification(message) {
    alert(message);
  }
}

init(new AppProvider());

const Routes = () => {
  return (
    <Router history={history} basename={"/dit"}>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
        <Route path={`${process.env.PUBLIC_URL}/otp`} component={OTP} />
        <Route
          path={`${process.env.PUBLIC_URL}/forgot-userid`}
          component={ForgotUserId}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forgot-password`}
          component={ForgotPassword}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/reset-password`}
          component={ResetPassword}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/register`}
          component={Register}
        />
        <ProtectedRoute path={`${process.env.PUBLIC_URL}/`} component={Home} />
      </Switch>
    </Router>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
