import React, { Component } from "react";
import TabHeader from "../../forms/tabHeader";
import { GRAPH, TABLE } from "shared/store/types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import moment from "moment";

class NavData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: GRAPH
    };
  }

  onClick = type => () => {
    this.setState({
      active: type
    });
  };

  render() {
    const { navData } = this.props;
    const navSortData = navData.sort(function(a, b) {
    //   var xDate = a.fn_nav
    //   var yDate = b.fn_nav
    //   var keyA = parseFloat(xDate),
    //   keyB = parseFloat(yDate);
    // return  keyA - keyB;
      var xDate = new Date(a.fn_fromdt.split("/")[2], a.fn_fromdt.split("/")[1], a.fn_fromdt.split("/")[0])
      var yDate =new Date(b.fn_fromdt.split("/")[2], b.fn_fromdt.split("/")[1], b.fn_fromdt.split("/")[0])
      // var keyA = parseFloat(xDate),
      //   keyB = parseFloat(yDate);
      return yDate > xDate ;

    });

    const graphData = navSortData.map(item => ({
      name: moment(item.fn_fromdt, "DD/MM/YYYY").format("DD MMM YYYY"),
      value: Number(item.fn_nav).toFixed(4)
    }));


    const range = navData.map(item => item.fn_nav);
    const min = Math.min.apply(null, range);
    const max = Math.max.apply(null, range);

    return (
      <div>
        <div
          className="tab-container nav-tabs-container"
          style={{
            justifyContent: "flex-start",
            margin: "25px 20px 15px 20px",
            borderBottom: "1px solid #7dbbe2"
          }}
        >
          <TabHeader
            value="NAV Graph"
            active={this.state.active === GRAPH}
            onClick={this.onClick(GRAPH)}
          />

          <TabHeader
            value="NAV Table"
            active={this.state.active === TABLE}
            onClick={this.onClick(TABLE)}
          />
        </div>
        {!!navData.length ? (
          <React.Fragment>
            {this.state.active === GRAPH ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <AreaChart
                  width={450}
                  height={300}
                  data={graphData}
                  animationDuration={10}

                  className="nav-garphs"
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#da251d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#da251d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    height={60}
                    tick={<CustomizedAxisTick />}
                  />
                  <YAxis type="number" domain={[min, max]}  />
                  <CartesianGrid vertical={false} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#da251d"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              </div>
            ) : (
              <div style={{ display: "flex",overflow:"auto",height:300 }} className="nav-table-margin">
                <Table>
                  <TableHead
                    style={{
                      backgroundColor: "rgba(236, 242, 247, 0.7)",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          color: "#00579b",
                          fontSize: 13,
                          fontFamily: "SourceSansPro-SemiBold",
                          padding: "7px 40px 7px 16px"
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#00579b",
                          fontSize: 13,
                          fontFamily: "SourceSansPro-SemiBold",
                          padding: "10px 40px 7px 16px"
                        }}
                      >
                        Nav (₹)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {graphData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{
                            fontSize: 15,
                            fontFamily: "SourceSansPro-Regular"
                          }}
                        >
                          {item.name}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{
                            fontSize: 15,
                            fontFamily: "SourceSansPro-Regular"
                          }}
                        >
                          {item.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 15,
              height: 300,
              fontFamily: "SourceSansPro-SemiBold",
              color: "#00579B"
            }}
          >
            Please fill all the details to display {this.state.active===GRAPH ?"NAV Graph":"NAV Table"}
          </div>
        )}
      </div>
    );
  }
}

export default connect(({ transactionHistory }) => {
  const { navData } = transactionHistory;
  return { navData };
})(NavData);

class CustomizedAxisTick extends Component {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}
