export var GET_FUNDS_LIST_PAN = "GET_FUNDS_LIST_PAN";
export var GET_FUNDS_LIST_PAN_SUCCESS = "GET_FUNDS_LIST_PAN_SUCCESS";
export var GET_FUNDS_LIST_PAN_FAILED = "GET_FUNDS_LIST_PAN_FAILED";
export var GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export var GET_TRANSACTION_HISTORY_SUCCESS = "GET_TRANSACTION_HISTORY_SUCCESS";
export var GET_TRANSACTION_HISTORY_FAILED = "GET_TRANSACTION_HISTORY_FAILED";
export var GET_NAV_DATA = "GET_NAV_DATA";
export var GET_NAV_DATA_SUCCESS = "GET_NAV_DATA_SUCCESS";
export var GET_NAV_DATA_FAILED = "GET_NAV_DATA_FAILED";
export var REMOVE_NAV_DATA = "REMOVE_NAV_DATA";
export var REMOVE_TRANSACTION_HISTORY_DATA = "REMOVE_TRANSACTION_HISTORY_DATA";