import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { LOCAL_AUTHENTICATION_SUCCESS, SKIP_LOCAL_AUTHENTICATION_SUCCESS, LOCAL_AUTH_USERNAME_SUCCESS, LOCAL_AUTH_PASSWORD_SUCCESS } from "../types/index";
var initialState = {
  authID: null,
  skipAuth: null,
  userName: null,
  password: null
};
export function biomtricAuth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOCAL_AUTHENTICATION_SUCCESS:
      {
        return _objectSpread({}, state, {
          authID: action.authID
        });
      }

    case SKIP_LOCAL_AUTHENTICATION_SUCCESS:
      {
        return _objectSpread({}, state, {
          skipAuth: action.skipAuth
        });
      }

    case LOCAL_AUTH_USERNAME_SUCCESS:
      {
        return _objectSpread({}, state, {
          userName: action.userName
        });
      }

    case LOCAL_AUTH_PASSWORD_SUCCESS:
      {
        return _objectSpread({}, state, {
          password: action.password
        });
      }

    default:
      return state;
  }
}