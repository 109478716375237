import React, { Component } from "react";
import { connect } from "react-redux";
import { getNfoSchemes } from "shared/store/actions/dashboard";
import Signbutton from "../../forms/button";
import Tooltip from "@material-ui/core/Tooltip";
import copy from "copy-to-clipboard";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  TwitterShareButton,
  EmailIcon,
  TwitterIcon,
  WhatsappShareButton,
} from "react-share";
import { BUILD_URL } from "../home";

class NfoFunds extends Component {
  async componentDidMount() {
    const params = {
      customfund: "105",
    };
    const response = await this.props.getNfoSchemes(params);
  }
  gotoTransact = (fund, scheme) => () => {
    this.props.history.push({
      pathname: `${process.env.PUBLIC_URL}/dashboard/nfo-funds/nfo-transact`,
      fund: fund,
      scheme: scheme.toUpperCase(),
    });
  };
  render() {
    const { location } = this.props;

    const checkParam = !!location && !!location.fund;

    return (
      <div className="nfo-funds">
        {!!this.props.nfoSchemes &&
        !!this.props.nfoSchemes.DtData &&
        this.props.nfoSchemes.DtData.length > 0 ? (
          this.props.nfoSchemes.DtData.map((ele, index) => (
            <div className="cardDiv" key={index}>
              {ele.fund === "105" && (
                <NfoFundCard
                  fund={ele.fund_desc}
                  history={this.props.history}
                  link={
                    !!checkParam &&
                    location.fund.toString() === ele.fund.toString() &&
                    location.link
                      ? location.link
                      : ""
                  }
                  LocationScheme={
                    !!checkParam &&
                    location.fund.toString() === ele.fund.toString() &&
                    !!location.scheme
                      ? location.scheme
                      : ""
                  }
                  onClick={this.gotoTransact("105", ele.scheme_desc)}
                  scheme={ele.scheme_desc}
                  closingDate={ele.closing_Date}
                  OpeningDate={ele.opening_date}
                />
              )}
            </div>
          ))
        ) : (
          <div
            style={{
              fontSize: 15,
              fontFamily: "OpenSans-SemiBold",
              textAlign: "center",
              color: "red",
              minHeight: 300,
              width: "100%",
              marginTop: 50,
            }}
          >
            Currently there are no active NFOs available.
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ dashboard }) => {
    const { nfoSchemes } = dashboard;
    return {
      nfoSchemes,
    };
  },
  { getNfoSchemes }
)(NfoFunds);

export class NfoFundCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openShare: false,
    };
  }

  copyCodeToClipboard = () => {
    this.setState({
      openShare: false,
    });
    copy(this.props.link);
    alert("Link copied successfully");
  };
  OpenShareOptions = () => {
    this.setState({
      openShare: !this.state.openShare,
    });
  };

  closeShare = () => {
    this.setState({
      openShare: false,
    });
  };

  render() {
    const { fund, OpeningDate, closingDate, scheme, link, LocationScheme } =
      this.props;
    console.log(link, LocationScheme, "sldfjkldsf");
    return (
      <div
        style={{
          border: "1px solid rgba(211,211,211,0.75)",
          borderRadius: 5,
          margin: 10,
          background: "white",
          display: "flex",
          flexDirection: "column",
          width: this.props.width ? this.props.width : "100%",
          minHeight: 200,
          maxHeight: 350,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "center",
            background: "rgba(0,118,203,0.1)",
          }}
        >
          <div
            style={{
              fontFamily: "SourceSansPro-SemiBold",
              fontSize: 14,
              wordBreak: "break-word",
            }}
          >
            {fund}
          </div>

          <Signbutton
            buttontitle="Link"
            margin={1}
            padding={"7px 25px"}
            onClick={this.props.onClick}
          />
        </div>
        <div style={{ padding: "5px 10px", flexGrow: 1 }}>
          <div
            style={{
              color: "black",
              fontFamily: "SourceSansPro-Regular",
              fontSize: 14,
            }}
          >
            Scheme
          </div>
          <div
            style={{
              color: "#00579b",
              fontFamily: "SourceSansPro-SemiBold",
              fontSize: 14,
            }}
          >
            {scheme}
          </div>
        </div>
        <div style={{ display: "flex", padding: 10 }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div
              style={{
                color: "black",
                fontFamily: "SourceSansPro-Regular",
                fontSize: 14,
              }}
            >
              Opening Date
            </div>
            <div
              style={{
                color: "#00579b",
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 14,
                marginTop: 5,
              }}
            >
              {OpeningDate}
            </div>
          </div>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div
              style={{
                color: "black",
                fontFamily: "SourceSansPro-Regular",
                fontSize: 14,
              }}
            >
              Closing Date
            </div>
            <div
              style={{
                color: "#00579b",
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 14,
                marginTop: 5,
              }}
            >
              {closingDate}
            </div>
          </div>
        </div>

        {!!this.props.LocationScheme && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
              alignItems: "center",
              // background: "rgba(0,118,203,0.1)",
            }}
          >
            <div
              style={{
                // minHeight: 48,
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 14,
                marginRight: 10,
              }}
            >
              {this.props.LocationScheme} - NFO Prefilled Link
            </div>
            <Tooltip title="Copy">
              <img
                src={BUILD_URL + "/images/dit_copy.svg"}
                style={{ height: 25, marginRight: 10 }}
                onClick={this.copyCodeToClipboard}
              />
            </Tooltip>

            <div>
              {/* <Tooltip title="Share">
                <img
                  src={BUILD_URL + "/images/dit_share.svg"}
                  style={{ height: 25 }}
                  onClick={this.OpenShareOptions}
                />
              </Tooltip> */}
              {/* <Signbutton
                buttontitle="share"
                margin={1}
                padding={"5px 15px"}
                onClick={this.copyCodeToClipboard}
              /> */}

              <div
                style={{
                  position: "absolute",
                  background: "white",
                  padding: 10,
                  borderRadius: 4,
                  display: !!this.state.openShare ? "flex" : "none",
                  // flexDirection: "column",
                }}
              >
                <div style={{ marginTop: 3 }}>
                  <FacebookShareButton
                    url={this.props.link}
                    quote={"Checkout the NFO Link"}
                  >
                    <FacebookIcon
                      size={32}
                      round={true}
                      onClick={this.closeShare}
                    />
                  </FacebookShareButton>
                </div>
                <div style={{ marginTop: 3 }}>
                  <WhatsappShareButton
                    url={this.props.link}
                    quote={"Checkout the NFO Link"}
                  >
                    <WhatsappIcon
                      size={32}
                      round={true}
                      onClick={this.closeShare}
                    />
                  </WhatsappShareButton>
                </div>
                <div style={{ marginTop: 3 }}>
                  <EmailShareButton
                    subject={`NFO Link`}
                    body={`Check out NFO Link : ${this.props.link}`}
                  >
                    <EmailIcon
                      size={32}
                      round={true}
                      onClick={this.closeShare}
                    />
                  </EmailShareButton>
                </div>

                <div style={{ marginTop: 3 }}>
                  <TwitterShareButton
                    url={this.props.link}
                    quote={"Checkout the NFO Link"}
                  >
                    <TwitterIcon
                      size={32}
                      round={true}
                      onClick={this.closeShare}
                    />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
