import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";
import Rating from "material-ui-rating";

export default class StarRating extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      value
    };
  }

  changeValue = value => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const style = { color: this.props.color };
    return (
      <Rating
        value={value}
        max={5}
        // onChange={value => {
        //   this.setState({ value });
        //   this.props.onSubmit(value);
        // }}
        iconFilled={<Icon style={style}>star</Icon>}
        iconHovered={<Icon style={style}>star_border</Icon>}
        iconNormal={<Icon style={style}>star_border</Icon>}
        readOnly={this.props.readOnly}
      />
    );
  }
}
