import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import AppRouter from "./appRoutes";
import { drawerStyles } from "./appCss";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { userLogout } from "shared/store/actions";
import { getProfileData } from "shared/store/actions/profile";
import { INVESTOR_URL } from "./investors";
import { NEW_TRANSACTION_URL } from "./transactions";
import { MAILBACK_URL } from "./mailBackRequest";
import { NAV_URL } from "./nav";
import { HISTORY_URL } from "./transactionHistory";
import CustomLoading from "../home/CustomLoader";
import { MyEarnings_URL } from "./myEarnings";

import {
  dashboardActive,
  investorsActive,
  newTransactionActive,
  navActive,
  mailBackActive,
  transactionHistoryActive,
  ekycActive,
  earningsActive,
  supportActive,
  settingsActive,
  dashboardInActive,
  investorsInActive,
  newTransactionInActive,
  navInActive,
  mailBackInActive,
  transactionHistoryInActive,
  ekycInActive,
  earningsInActive,
  supportInActive,
  settingsInactive,
  LOGOUT_ICON,
  APP_LOGO,
  second_app_logo,
} from "../forms/appEssentials";
import Footer from "../auth/footer";

export const BUILD_URL = process.env.PUBLIC_URL;
export const EKYC_URL = `${process.env.PUBLIC_URL}/e-KYC`;
class PermanentDrawerLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pathname: "",
      responsiveDrawer: false,
    };
  }
  componentDidMount() {
    this.props.getProfileData({
      Agent: this.props.token,
      UserId: this.props.userId,
    });
  }
  handleClick = () => {
    this.setState({
      open: !this.state.open,
    });
    this.props.history.push(MAILBACK_URL);
  };

  goToRoute = (path) => () => {
    this.props.history.push(path);
    // if (!path.includes(MAILBACK_URL)) {
    this.setState({
      open: false,
      responsiveDrawer: false,
    });
    // }
  };

  logOut = () => {
    this.props.userLogout();
    this.props.history.replace(BUILD_URL + "/");
  };

  openResponsiveDrawer = () => {
    this.setState({
      responsiveDrawer: !this.state.responsiveDrawer,
    });
  };
  render() {
    const { classes, location, tran_enable_flag } = this.props;
    const { responsiveDrawer } = this.state;
    const { pathname } = location;
    return (
      <div className="home-root">
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img
              src={BUILD_URL + "/images/menu.svg"}
              onClick={this.openResponsiveDrawer}
              alt="ham_icon"
              className="menu-logo"
            />
            <div
              className="username-disabled"
              style={{
                fontSize: 14,
                fontFamily: "SourceSansPro-Regular",
                color: "#003d7b",
                marginRight: 50,
              }}
            >
              Logged as :{" "}
              <span
                className={classes.lastloginsub}
                style={{ fontSize: 14, color: "gray" }}
              >
                {" "}
                {this.props.userId}
              </span>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              {" "}
              <img src={second_app_logo} alt="logo" className="second_logo" />
              <div className={classes.appbaricons}>
                <Tooltip title="Logout">
                  <img
                    src={LOGOUT_ICON}
                    alt="logout"
                    className={classes.appabarlogout}
                    onClick={this.logOut}
                  />
                </Tooltip>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <div
          style={{
            position: "fixed",
            background: "rgba(0, 0, 0, 0.1)",
            width: 992,
            // top: 65,
            display: !!responsiveDrawer ? "block" : "none",

            height: "-webkit-fill-available",
            zIndex: 99999,
          }}
        >
          <div
            style={{
              position: "absolute",
              overflow: "scroll",
              // top: 65,
              display: "block",
              background: "white",
              height: "-webkit-fill-available",

              width: 254,
              color: "white",
              zIndex: 99998,
              left: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
                marginBottom: 20,
                borderBottom: "1px solid rgba(211,211,211,0.4)",
                maxHeight: 65,
              }}
            >
              <img src={second_app_logo} alt="logo" className="logo" />
              <img
                onClick={this.openResponsiveDrawer}
                src={BUILD_URL + "/images/close.png"}
                alt="logo"
                style={{ height: 15 }}
              />
            </div>

            <div className={classes.profilediv}>
              <div className={classes.profile}>
                <div>
                  <div className={classes.profileRole}>
                    {/* <div className={classes.profileRoletext}>Logged in as</div>
                   <div className={classes.profileRolesubtext}>Distributor</div> */}
                  </div>
                </div>
                <div className={classes.profileimage}>
                  {!!this.props.userId
                    ? this.props.userId
                      .split(" ")
                      .reduce(
                        (p, c) => p.concat(c.charAt(0).toUpperCase()),
                        ""
                      )
                    : ""}
                </div>
                <div onClick={this.goToRoute(BUILD_URL + "/settings")}>
                  <img
                    src={
                      pathname === "/settings"
                        ? settingsActive
                        : settingsInactive
                    }
                    alt="settings"
                    className={classes.profileSettingsicon}
                  />
                </div>
              </div>
              <div className={classes.profileName}>
                {this.props.profileData.Distributor_Name}
              </div>
              <div className={classes.profilenametext}>
                <div className={classes.combined}>
                  <div className={classes.profilenameMaintext}>ARN Code:</div>
                </div>

                <div className={classes.combined}>
                  <div className={classes.profilenamesubtext}>
                    {this.props.token}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            {tran_enable_flag === "N" ? (
              <div className={classes.list}>
                <div
                  className={
                    pathname.includes("/dashboard")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(BUILD_URL + "/")}
                >
                  <img
                    src={
                      pathname.includes("/dashboard")
                        ? dashboardActive
                        : dashboardInActive
                    }
                    alt="home"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/dashboard")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    Dashboard
                  </div>
                </div>
                <div
                  className={
                    pathname.includes("/investors")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(INVESTOR_URL)}
                >
                  <img
                    src={
                      pathname.includes("/investors")
                        ? investorsActive
                        : investorsInActive
                    }
                    alt="investor"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/investors")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    Investors
                  </div>
                </div>
                <div
                  className={
                    pathname.includes("/new-transaction")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(NEW_TRANSACTION_URL)}
                >
                  <img
                    src={
                      pathname.includes("/new-transaction")
                        ? newTransactionActive
                        : newTransactionInActive
                    }
                    alt="transact"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/new-transaction")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    New Transaction
                  </div>
                </div>
                <div
                  className={
                    pathname.includes("/e-KYC")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(EKYC_URL)}
                >
                  <img
                    src={
                      pathname.includes("/e-KYC") ? ekycActive : ekycInActive
                    }
                    alt="transact"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/e-KYC")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    eKYC
                  </div>
                </div>

                <div
                  className={
                    pathname.includes("/mailBackRequest")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.handleClick}
                >
                  <img
                    src={
                      pathname.includes("/mailBackRequest")
                        ? mailBackActive
                        : mailBackInActive
                    }
                    alt="mailback"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/mailBackRequest")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    Mailback Request
                  </div>
                  <div
                    className={
                      pathname.includes("/mailBackRequest")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                  </div>
                </div>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className="list-div">
                    <ListItem
                      button
                      onClick={this.goToRoute(MAILBACK_URL)}
                      style={{
                        backgroundColor:
                          pathname.includes("/mailBackRequest") &&
                            !pathname.includes("/mailBackRequest/investor")
                            ? "#ecf2f7"
                            : "#FFF",
                      }}
                    >
                      <ListItemText
                        primary="My Reports"
                        className="list-item-text"
                        style={{
                          color:
                            pathname.includes("/mailBackRequest") &&
                              !pathname.includes("/mailBackRequest/investor")
                              ? "#00579b"
                              : "#586a82",
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={this.goToRoute(MAILBACK_URL + "/investor")}
                      style={{
                        backgroundColor: pathname.includes(
                          "/mailBackRequest/investor"
                        )
                          ? "#ecf2f7"
                          : "#FFF",
                      }}
                    >
                      <ListItemText
                        className="list-item-text"
                        primary="Investor"
                        style={{
                          color: pathname.includes("/mailBackRequest/investor")
                            ? "#00579b"
                            : "#586a82",
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <div
                  className={
                    pathname.includes("/transaction-history")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(HISTORY_URL)}
                >
                  <img
                    alt="history"
                    src={
                      pathname.includes("/transaction-history")
                        ? transactionHistoryActive
                        : transactionHistoryInActive
                    }
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/transaction-history")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    Transaction History
                  </div>
                </div>
                <div
                  className={
                    pathname.includes("/nav") ? "list-item-active" : "list-item"
                  }
                  onClick={this.goToRoute(NAV_URL)}
                >
                  <img
                    src={pathname.includes("/nav") ? navActive : navInActive}
                    alt="nav"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/nav")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    NAV
                  </div>
                </div>
                <div
                  className={
                    pathname.includes("/nfo-funds")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(
                    `${process.env.PUBLIC_URL}/nfo-funds`
                  )}
                >
                  <img
                    src={
                      pathname.includes("/nfo-funds")
                        ? newTransactionActive
                        : newTransactionInActive
                    }
                    alt="nfo"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/nfo-funds")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    Create NFO Link
                  </div>
                </div>

                <div
                  className={
                    pathname.includes("/my-earnings")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(MyEarnings_URL)}
                >
                  <img
                    alt="my-earnings"
                    src={
                      pathname.includes("/my-earnings")
                        ? earningsActive
                        : earningsInActive
                    }
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/my-earnings")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    View my Earnings
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.list}>
                <div
                  className={
                    pathname.includes("/new-transaction")
                      ? "list-item-active"
                      : "list-item"
                  }
                  onClick={this.goToRoute(NEW_TRANSACTION_URL)}
                >
                  <img
                    src={
                      pathname.includes("/new-transaction")
                        ? BUILD_URL + "/images/newtransaction_active.png"
                        : BUILD_URL + "/images/new_transaction.svg"
                    }
                    alt="transact"
                    className={classes.listImage}
                  />
                  <div
                    className={
                      pathname.includes("/new-transaction")
                        ? classes.listTextActive
                        : classes.listText
                    }
                  >
                    New Transaction
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Drawer
          // className={classes.drawer}
          className="drawer"
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.profilediv}>
            <div className={classes.profile}>
              <div>
                <div className={classes.profileRole}></div>
              </div>
              <div className={classes.profileimage}>
                {!!this.props.userId
                  ? this.props.userId
                    .split(" ")
                    .reduce((p, c) => p.concat(c.charAt(0).toUpperCase()), "")
                  : ""}
              </div>
              <div onClick={this.goToRoute(BUILD_URL + "/settings")}>
                <img
                  src={
                    pathname === "/settings" ? settingsActive : settingsInactive
                  }
                  alt="settings"
                  className={classes.profileSettingsicon}
                />
              </div>
            </div>
            <div className={classes.profileName}>
              {this.props.profileData.Distributor_Name}
            </div>
            <div className={classes.profilenametext}>
              <div className={classes.combined}>
                <div className={classes.profilenameMaintext}>ARN Code:</div>
              </div>

              <div className={classes.combined}>
                <div className={classes.profilenamesubtext}>
                  {this.props.token}
                </div>
              </div>
            </div>
          </div>
          <Divider />
          {tran_enable_flag === "N" ? (
            <div className={classes.list}>
              <div
                className={
                  pathname.includes("/dashboard")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(BUILD_URL + "/")}
              >
                <img
                  src={
                    pathname.includes("/dashboard")
                      ? dashboardActive
                      : dashboardInActive
                  }
                  alt="home"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/dashboard")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  Dashboard
                </div>
              </div>
              <div
                className={
                  pathname.includes("/investors")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(INVESTOR_URL)}
              >
                <img
                  src={
                    pathname.includes("/investors")
                      ? investorsActive
                      : investorsInActive
                  }
                  alt="investor"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/investors")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  Investors
                </div>
              </div>
              <div
                className={
                  pathname.includes("/new-transaction")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(NEW_TRANSACTION_URL)}
              >
                <img
                  src={
                    pathname.includes("/new-transaction")
                      ? newTransactionActive
                      : newTransactionInActive
                  }
                  alt="transact"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/new-transaction")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  New Transaction
                </div>
              </div>
              <div
                className={
                  pathname.includes("/e-KYC") ? "list-item-active" : "list-item"
                }
                onClick={this.goToRoute(EKYC_URL)}
              >
                <img
                  src={pathname.includes("/e-KYC") ? ekycActive : ekycInActive}
                  alt="transact"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/e-KYC")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  eKYC
                </div>
              </div>
              <div
                className={
                  pathname.includes("/mailBackRequest")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.handleClick}
              >
                <img
                  src={
                    pathname.includes("/mailBackRequest")
                      ? mailBackActive
                      : mailBackInActive
                  }
                  alt="mailback"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/mailBackRequest")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  Mailback Request
                </div>
                <div
                  className={
                    pathname.includes("/mailBackRequest")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </div>
              </div>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="list-div">
                  <ListItem
                    button
                    onClick={this.goToRoute(MAILBACK_URL)}
                    style={{
                      backgroundColor:
                        pathname.includes("/mailBackRequest") &&
                          !pathname.includes("/mailBackRequest/investor")
                          ? "#ecf2f7"
                          : "#FFF",
                    }}
                  >
                    <ListItemText
                      primary="My Reports"
                      className="list-item-text"
                      style={{
                        color:
                          pathname.includes("/mailBackRequest") &&
                            !pathname.includes("/mailBackRequest/investor")
                            ? "#00579b"
                            : "#586a82",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={this.goToRoute(MAILBACK_URL + "/investor")}
                    style={{
                      backgroundColor: pathname.includes(
                        "/mailBackRequest/investor"
                      )
                        ? "#ecf2f7"
                        : "#FFF",
                    }}
                  >
                    <ListItemText
                      className="list-item-text"
                      primary="Investor"
                      style={{
                        color: pathname.includes("/mailBackRequest/investor")
                          ? "#00579b"
                          : "#586a82",
                      }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <div
                className={
                  pathname.includes("/transaction-history")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(HISTORY_URL)}
              >
                <img
                  alt="history"
                  src={
                    pathname.includes("/transaction-history")
                      ? transactionHistoryActive
                      : transactionHistoryInActive
                  }
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/transaction-history")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  Transaction History
                </div>
              </div>
              <div
                className={
                  pathname.includes("/nav") ? "list-item-active" : "list-item"
                }
                onClick={this.goToRoute(NAV_URL)}
              >
                <img
                  src={pathname.includes("/nav") ? navActive : navInActive}
                  alt="nav"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/nav")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  NAV
                </div>
              </div>

              <div
                className={
                  pathname.includes("/nfo-funds")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(`${process.env.PUBLIC_URL}/nfo-funds`)}
              >
                <img
                  src={
                    pathname.includes("/nfo-funds")
                      ? newTransactionActive
                      : newTransactionInActive
                  }
                  alt="nfo"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/nfo-funds")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  Create NFO Link
                </div>
              </div>

              <div
                className={
                  pathname.includes("/my-earnings")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(MyEarnings_URL)}
              >
                <img
                  alt="my-earnings"
                  src={
                    pathname.includes("/my-earnings")
                      ? earningsActive
                      : earningsInActive
                  }
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/my-earnings")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  View my Earnings
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.list}>
              <div
                className={
                  pathname.includes("/investors")
                    ? "list-item-active"
                    : "list-item"
                }
                onClick={this.goToRoute(INVESTOR_URL)}
              >
                <img
                  src={
                    pathname.includes("/investors")
                      ? BUILD_URL + "/images/newtransaction_active.png"
                      : BUILD_URL + "/images/new_transaction.svg"
                  }
                  alt="transact"
                  className={classes.listImage}
                />
                <div
                  className={
                    pathname.includes("/investors")
                      ? classes.listTextActive
                      : classes.listText
                  }
                >
                  Transact
                </div>
              </div>
            </div>
          )}
        </Drawer>
        <main className="home-content">
          <AppRouter />
        </main>
        <Footer />
      </div>
    );
  }
}

PermanentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default connect(
  ({ authReducer, profileReducer }) => {
    const { userId, token, lastLogin, tran_enable_flag } = authReducer;
    const { profileData } = profileReducer;
    return { userId, token, lastLogin, profileData, tran_enable_flag };
  },
  { userLogout, getProfileData }
)(withStyles(drawerStyles)(PermanentDrawerLeft));
