import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";

export default class CustomizedSelect extends Component {
  render() {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }} className="customized-input">
          <div className="input-name">{this.props.name}</div>
          {this.props.uptext && (
            <div
              className="input-name"
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 15,
                marginLeft: 10,
                color: "red",
              }}
            >
              {this.props.uptext}
            </div>
          )}
        </div>
        <div className="customized-input">
        <Select
          value={this.props.value}
          onChange={this.props.handleChange}
          displayEmpty
          disabled={this.props.disabled}
          inputRef={this.props.inputRef}

            // style={{

            //     border: "1px solid rgba(211, 211, 211, 1)",
            //     borderColor: this.props.isError
            //       ? "red"
            //       : "rgba(211, 211, 211, 1)",
            //     borderRadius: 5,
            //     width: "100%",
            //     minHeight:48,
            //     // padding: "7px 50px 7px 15px",
            //     margin: "0px 0px 0px",
            //     // cursor
            //   }}

          input={
            <InputBase
            onChange={this.props.handleChange}
              name="age"
              id="age-customized-select"
              style={{
                border: "1px solid rgba(211, 211, 211, 1)",
                borderColor: this.props.isError
                  ? "red"
                  : "rgba(211, 211, 211, 1)",

                borderRadius: 5,
                width: "100%",
                // minHeight:43,
                padding: "7px 0px 7px 15px",
                margin: "0px 0px 0px",
              }}
            />
          }
        >
          {!!this.props.placeholder && (
            <MenuItem value="">{this.props.placeholder}</MenuItem>
          )}
          {this.props.children}
        </Select>
        </div>
        {!!this.props.errorMessage && (
          <div
            className="input-name"
            style={{ color: "red", fontFamily:"SourceSansPro-Regular",fontSize:14 }}
          >
            {this.props.errorMessage}
          </div>
        )}
      </div>
    );
  }
}
