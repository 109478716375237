import { combineReducers } from "redux"; // import { connectRouter } from "connected-react-router";

import { authReducer } from "./auth";
import { dashboard } from "./dashboard";
import { investor } from "./investors";
import { transactions } from "./transactions";
import { profileReducer } from "./profile";
import { transactionHistory } from "./history";
import { support } from "./support";
import { loading } from "./loader";
import { biomtricAuth } from "./bioAuthentication";
export default (function (history) {
  return combineReducers({
    router: history,
    authReducer: authReducer,
    dashboard: dashboard,
    investor: investor,
    transactions: transactions,
    profileReducer: profileReducer,
    transactionHistory: transactionHistory,
    support: support,
    loading: loading,
    biomtricAuth: biomtricAuth
  });
});