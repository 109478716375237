import React, { Component } from "react";
import { Input } from "../../forms/input";
import Signbutton from "../../forms/button";
import { connect } from "react-redux";
import CustomizedSelect from "./customizedSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getNewPurchaseFundList,
  validateSubBroker,
  getInvestorDetails,
  submitPurchaseData,
  showPurchaseConfirmation,
} from "shared/store/actions";
import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_SCHEME_LIST,
  GET_SCHEME_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAILED,
  GET_SCHEME_LIST_FAILED,
  PURCHASE,
  ALL,
  REGULAR,
  NO,
  YES,
  NEW_PURCHASE,
  NEW,
  NEWP,
} from "shared/store/types";
import { Checkbox } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { NEW_TRANSACTION_URL } from ".";
import { INVESTOR_URL } from "../investors";
class NewPurchase extends Component {
  constructor(props) {
    super(props);

    const { investorData, communicationDetails = [], token } = this.props;
    const revCD =
      !!communicationDetails && communicationDetails.length > 0
        ? communicationDetails.reverse()
        : "";
    const Xmobile = (revCD || []).find(
      (detail) => detail.PAN === investorData.PAN && !!detail.Mobile
    );
    const Xemail = (revCD || []).find(
      (detail) => detail.PAN === investorData.PAN && !!detail.email
    );
    const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";
    const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";

    this.state = {
      emailId: { value: Email || "" },
      mobile: { value: Mobile || "" },
      arnCode: { value: token },
      invName: { value: this.props.investorData.invname || "" },
      fund: { value: "105" },
      category: { value: "" },
      scheme: { value: "" },
      minAmount: { value: "" },
      subArnCode: { value: "" },
      euinNo: { value: "" },
      radioValue: { value: "no" },
      subBrokerCode: { value: "" },
      isCombo: false,
      combo: { flag: "", disclamier: "", label: "" },
      showSmart: false,
      smart: { flag: "yes" },
      code: { value: "+91" },
      Family_Code_Email: { value: "" },
      Family_Code_Phone: { value: "" },
    };
  }

  async componentDidMount() {
    const params = {
      opt: "AT",
      plntype: REGULAR,
      fund: "105",
    };

    const types = [
      GET_CATEGORY_LIST,
      GET_CATEGORY_LIST_SUCCESS,
      GET_CATEGORY_LIST_FAILED,
    ];
    await this.props.getNewPurchaseFundList(params, types);
  }
  FamilyDescriptionEmail = (event) => {
    this.setState({
      Family_Code_Email: { value: event },
    });
  };
  FamilyDescriptionPhone = (event) => {
    this.setState({
      Family_Code_Phone: { value: event },
    });
  };
  handleChange = (label) => async (value) => {
    this.setState({ [label]: { value } });
    if (!value) {
      this.setState({
        [label]: { value: "" },
        category: { value: "" },
        scheme: { value: "" },
        minCost: "",
        euinNo: { value: "" },
        showSmart: false,
      });
    } else if (value.value === this.state.fund.value.value) {
      return;
    } else {
      let params = { plntype: REGULAR };
      params = { ...params, opt: "AT", fund: value.value };
      const types = [
        GET_CATEGORY_LIST,
        GET_CATEGORY_LIST_SUCCESS,
        GET_CATEGORY_LIST_FAILED,
      ];
      const response = await this.props.getNewPurchaseFundList(params, types);
      if (response) {
        this.setState({
          [label]: { value },
          category: { value: "" },
          scheme: { value: "" },
          minCost: "",
          euinNo: { value: "" },
          showSmart: false,
        });
      }
    }
  };

  validateBrokerCode = async () => {
    if (
      !!this.state.subArnCode.value &&
      this.state.subArnCode.value === this.state.arnCode.value.split("-")[1]
    ) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: "ARN and Sub ARN Code should not be the same",
        },
      });
      return;
    }

    const { fund, subArnCode, arnCode } = this.state;
    const params = {
      Fund: "105",
      SubAgentCd: !!subArnCode.value ? `ARN-${subArnCode.value}` : "",
      AgentCd: arnCode.value,
    };
    const response = await this.props.validateSubBroker(params);
    // if (!response) {
    //   alert(`${response}`);
    //   // this.setState({
    //   //   subArnCode: {
    //   //     value: "",
    //   //     isError: true,
    //   //     errorMessage: ``,
    //   //   },
    //   // });
    // }
  };

  handleCategoryChange = (label) => async (event) => {
    this.setState({ [label]: { value: event.target.value } });
    if (!!event.target.value) {
      let params = { plntype: REGULAR };
      params = {
        ...params,
        opt: "S",
        schemetype: event.target.value,
        fund: "105",
      };
      const types = [
        GET_SCHEME_LIST,
        GET_SCHEME_LIST_SUCCESS,
        GET_SCHEME_LIST_FAILED,
      ];
      const response = await this.props.getNewPurchaseFundList(params, types);
      if (response) {
        this.setState({
          [label]: { value: event.target.value },
          scheme: { value: "" },
          minCost: "",
        });
      }
    } else {
      this.setState({
        [label]: { value: event.target.value },
        scheme: { value: "" },
        minCost: "",
      });
    }
  };

  changeScheme = (value) => {
    const comboScheme =
      !!value &&
      !!value.value &&
      this.props.schemeList.find((ele) => ele.Scheme_Plan === value.value);
    this.setState({
      scheme: { value: value, isError: false, errorMessage: "" },
      minAmount: { value: "", isError: false, errorMessage: "" },
      minCost: !!comboScheme && !!comboScheme.MinAmt ? comboScheme.MinAmt : "",
      multiples:
        !!comboScheme && !!comboScheme.multiples ? comboScheme.multiples : "",
    });
    if (!!value) {
      this.validateBrokerCode();
    }
  };

  changeValue = (label) => (event) => {
    if (label === "minAmount" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value.replace(/^0+/, "") },
      });
    } else if (label === "mobile" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label === "subArnCode" || label === "subBrokerCode") {
      if (/^\d{0,15}$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
        return;
      } else {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: "Enter a valid Number",
          },
        });
        return;
      }
    } else if (label === "invName") {
      if (/^[A-Za-z ]{0,50}$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: `Enter a valid Name`,
          },
        });
      }
    } else if (label === "emailId") {
      if (this.state.emailId.value.length > 50) {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: "Enter a valid Email Address *",
          },
        });
      } else {
        this.setState({
          [label]: {
            value: event.target.value,
            isError: false,
            errorMessage: "",
          },
        });
      }
    } else if (
      label !== "mobile" &&
      label !== "minAmount" &&
      label !== "subArnCode" &&
      label !== "subBrokerCode"
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  changeEuin = (label) => (value) => {
    this.setState({
      [label]: { value },
    });
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;
    if (label === "minAmount") {
      if (!this.state.minAmount.value) {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: `Investment Amount is Mandatory*`,
          },
        });
      } else if (this.state.minCost > this.state.minAmount.value) {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `(Amount should be atleast ₹${this.state.minCost})`,
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: "",
          },
        });
      }
    } else if (label === "emailId") {
      if (!this.state.emailId.value) {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: "EmailId is Mandatory*",
          },
        });
      } else if (
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
          this.state.emailId.value
        )
      ) {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: "",
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `Enter a valid Email Address*`,
          },
        });
      }
    } else if (label === "mobile") {
      if (
        /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
          this.state.mobile.value
        )
      ) {
        this.setState({
          [label]: {
            value,
            isError: false,
            errorMessage: "",
          },
        });
      } else if (this.state.mobile.value === "") {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `Mobile is Mandatory*`,
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `Enter a valid Mobile Number *`,
          },
        });
      }
    } else if (label === "invName") {
      if (!/^[A-Za-z ]{4,50}$/.test(this.state.invName.value)) {
        this.setState({
          [label]: {
            value,
            isError: true,
            errorMessage: `Enter a valid Name`,
          },
        });
      } else {
        this.setState({
          [label]: {
            value,
            isError: this.state.invName.value === "" ? true : false,
            errorMessage:
              this.state.invName.isError && `Investor Name is Mandatory*`,
          },
        });
      }
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };

  submit = async () => {
    const { location } = this.props;
    const { state } = location;

    const {
      arnCode,
      combo,
      subArnCode,
      isCombo,
      radioValue,
      invName,
      euinNo,
      minCost,
      subBrokerCode,
      showSmart,
      fund,
      multiples,
      smart,
      code,
      Family_Code_Email,
      Family_Code_Phone,
      ...others
    } = this.state;
    const arn = this.state.arnCode.value.split("-");
    if (this.state.euinNo.value === "") {
      this.setState({
        euinNo: {
          value: "",
          isError: true,
          errorMessage: "EUIN Declaration is Mandatory*",
        },
      });
    }

    if (this.state.invName.value === "") {
      this.showError("invName");
      this.invNameField.focus();
    }

    const keys = Object.keys(others);
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "category"
                  ? "Category"
                  : key === "scheme"
                  ? "Scheme"
                  : key === "minAmount"
                  ? "Investment Amount"
                  : key === "mobile"
                  ? "Mobile "
                  : key[0].toUpperCase() + key.substr(1)
              } is Mandatory*`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "emailId": {
          this.emailIdField.focus();
          break;
        }
        case "mobile": {
          this.mobileField.focus();
          break;
        }
        // case "fund": {
        //   this.fundField.focus();
        //   break;
        // }
        case "category": {
          this.categoryField.focus();
          break;
        }
        case "scheme": {
          this.schemeField.focus();
          break;
        }
        case "minAmount": {
          this.minAmountField.focus();
          break;
        }
      }
    } else if (invName.value === "") {
      this.showError("invName");
    } else if (radioValue.value === "no" && !euinNo.value) {
      this.setState({
        euinNo: { ...euinNo, isError: true },
      });
      this.euinField.focus();
      return;
    } else if (minCost > others.minAmount.value) {
      this.setState({
        minAmount: {
          value: "",
          isError: true,
          errorMessage: `Minimum amount should be ₹${minCost} `,
        },
      });
      this.minAmountField.focus();
    } else if (
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.state.emailId.value
      ) === false
    ) {
      this.setState({
        emailId: {
          ...others["emailId"],
          isError: true,
          errorMessage: `Enter a Vaild Email address`,
        },
      });
      this.emailIdField.focus();
      return;
    } else if (
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
        this.state.mobile.value
      ) === false
    ) {
      this.setState({
        mobile: {
          ...others["mobile"],
          isError: true,
          errorMessage: `Enter a Vaild phone Number`,
        },
      });
      this.mobileField.focus();
      return;
    } else if (arn[1] === this.state.subArnCode.value) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `and ARN code should  not match `,
        },
      });
    } else if (
      !/^[+]\d{1,3}/.test(this.state.code.value) &&
      (this.state.sipType.value === NEW || this.state.sipType.value === NEWP)
    ) {
      this.setState({
        code: {
          value: "",
          isError: true,
          errorMessage: !code.value
            ? "Country Code is Mandatory"
            : "Enter a valid Country Code",
        },
      });
      return;
    } else {
      const plan = this.state.scheme.value.value.split("~");

      const params = {
        InvestorName: this.state.invName.value,
        i_entby: this.props.userId,
        trtype: PURCHASE,
        Desci: "Yes~A~",
        SubbrokerArn: !!this.state.subArnCode.value
          ? `ARN-${subArnCode.value}`
          : "",
        ARNCode: this.state.arnCode.value,
        EuinCode:
          this.state.radioValue.value === "no"
            ? this.state.euinNo.value.value
            : "",
        i_Userid: this.props.userId,
        Mobile: this.state.mobile.value,
        PanNo: this.props.investorData.PAN || state.pan,
        Email: this.state.emailId.value,
        i_Acno: "0",
        Subbroker: this.state.subBrokerCode.value,
        i_Fund: "105",
        i_UntAmtValue: this.state.minAmount.value,
        EUINFlag: this.state.radioValue.value === "no" ? NO : YES,
        EuinValid: this.state.radioValue.value === "no" ? NO : YES,
        i_Scheme: plan[1],
        i_Plan: plan[2],
        i_Option: plan[3],
        i_oldihno: "0",
        i_InvDistFlag: "P",
        ModuleName: "Webile",
        countrycode: this.state.code.value,
        fEmailFlag: this.state.Family_Code_Email.value.value,
        fMobileFlag: this.state.Family_Code_Phone.value.value,
      };

      const response = await this.props.submitPurchaseData(params);

      if (!!response && !response.error) {
        const {
          DtData: [purchaseData],
        } = response;
        const confirmationParams = {
          Trtype: PURCHASE,
          fund: "105",
          Refno: purchaseData.refno,
          ModuleName: "Webile",
        };
        const confirmationResponse = await this.props.showPurchaseConfirmation(
          confirmationParams
        );

        if (!!confirmationResponse && !confirmationResponse.error) {
          this.props.history.push({
            pathname:
              INVESTOR_URL + "/transction-types/nfo-new-purchase/confirm",
            state: {
              title: "NEW FOLIO PURCHASE SUMMARY",
              pan: !!state && !!state.pan ? state.pan : "",
              InvestorEmail: this.state.emailId.value,
              orginalScheme: this.state.scheme.value.label,
              InvestorMobile: this.state.mobile.value,
              scheme: { Fund: "105" },
              type: NEW_PURCHASE,
              arnCode: this.state.arnCode.value,
            },
          });
        } else if (!!confirmationResponse.error) {
          alert(`${confirmationResponse.error}`);
        }
      } else if (!!response && !!response.error) {
        alert(`${response.error}`);
      }
    }
  };

  ComboChange = () => {
    this.setState({
      isCombo: !this.state.isCombo,
    });
  };

  changeSmart = (event) => {
    this.setState({
      smart: { flag: event.target.value },
    });
  };

  render() {
    const { fundsList, schemeList, categoryList, euinList } = this.props;
    const {
      emailId,
      mobile,
      arnCode,
      fund,
      invName,
      category,
      combo,
      scheme,
      subArnCode,
      radioValue,
      euinNo,
      minAmount,
      subBrokerCode,
      smart,
      showSmart,
      isCombo,
      Family_Code_Email,
      Family_Code_Phone,
      code,
    } = this.state;
    const { location } = this.props;
    const { state } = location;

    return (
      <React.Fragment>
        <div className="transaction-details">
          <div className="title">New Folio Purchase</div>

          {/* <div className="input-margin">
          <div className="input-name ">AMC</div>
          <SundaramMutualFund />
          </div> */}

          {/* {!!state && !!state.pan && ( */}
          <div className="input-margin">
            <Input
              name="Investor Name*"
              value={invName.value}
              onChange={this.changeValue("invName")}
              onBlur={this.showError("invName")}
              errorMessage={invName.errorMessage}
              isShowError={invName.isError}
              inputref={(ele) => (this.invNameField = ele)}
            />
          </div>

          <div className="input-margin">
            <div className="input-name input-integrated-label">
              Email Family Description
            </div>
            <IntegrationReactSelect
              suggestions={family_description_codes.map((item) => ({
                label: item.family_description,
                value: item.family_code,
              }))}
              value={Family_Code_Email.value}
              placeholder="Select Family Description"
              handleChange={this.FamilyDescriptionEmail}
            />
          </div>
          {/* )} */}
          <div className="input-margin">
            <Input
              name="Email ID*"
              value={emailId.value}
              bordercolor="rgba(0, 0, 0, 0.5)"
              onChange={this.changeValue("emailId")}
              onBlur={this.showError("emailId")}
              isShowError={emailId.isError}
              inputref={(ele) => (this.emailIdField = ele)}
              errorMessage={this.state.emailId.errorMessage}
            />
          </div>
          <div className="input-margin">
            <div className="input-name input-integrated-label">
              Mobile Family Description
            </div>
            <IntegrationReactSelect
              suggestions={family_description_codes.map((item) => ({
                label: item.family_description,
                value: item.family_code,
              }))}
              value={Family_Code_Phone.value}
              placeholder="Select Phone Description"
              handleChange={this.FamilyDescriptionPhone}
            />
          </div>

          <div className="input-margin">
            <Input
              name="Country Code"
              bordercolor="#a6a6a6"
              value={this.state.code.value}
              onChange={this.changeValue("code")}
              onBlur={this.showError("code")}
              isShowError={this.state.code.isError}
              errorMessage={this.state.code.errorMessage}
              inputref={(ele) => (this.codeField = ele)}
            />
          </div>
          <div className="input-margin">
            <Input
              name="Mobile*"
              type="number"
              bordercolor="rgba(0, 0, 0, 0.5)"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                evt.preventDefault()
              }
              value={mobile.value}
              onChange={this.changeValue("mobile")}
              onBlur={this.showError("mobile")}
              isShowError={mobile.isError}
              errorMessage={mobile.errorMessage}
              inputref={(ele) => (this.mobileField = ele)}
            />
          </div>

          <div className="input-margin">
            <CustomizedSelect
              name="Scheme Category*"
              bordercolor="rgba(0, 0, 0, 0.5)"
              handleChange={this.handleCategoryChange("category")}
              value={category.value}
              placeholder="Select Category"
              disabled={!fund.value}
              inputRef={(ele) => (this.categoryField = ele)}
              isError={category.isError}
              errorMessage={category.errorMessage}
            >
              {categoryList.map((item, index) => (
                <MenuItem value={item.fm_subcategory} key={index}>
                  {item.fm_subcategory}
                </MenuItem>
              ))}
            </CustomizedSelect>
          </div>

          <div className="input-margin">
            <div className="input-name ">Scheme*</div>
            <IntegrationReactSelect
              suggestions={schemeList.map((item) => ({
                label: item.Scheme_Desc,
                value: item.Scheme_Plan,
              }))}
              value={scheme.value}
              placeholder="Select Scheme"
              handleChange={this.changeScheme}
              disabled={!category.value}
              inputRef={(ele) => (this.schemeField = ele)}
              isError={scheme.isError}
              errorMessage={scheme.errorMessage}
            />
          </div>

          <div className="input-margin">
            <Input
              name={`Investment Amount* (Minimum: ₹${
                !!this.state.minCost ? this.state.minCost : ""
              })`}
              bordercolor="rgba(0, 0, 0, 0.5)"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                evt.preventDefault()
              }
              placeholder="Amount"
              step="1"
              type="number"
              value={minAmount.value}
              onChange={this.changeValue("minAmount")}
              onBlur={this.showError("minAmount")}
              errorMessage={minAmount.errorMessage}
              isShowError={minAmount.isError}
              inputref={(ele) => (this.minAmountField = ele)}
            />
          </div>

          <div className="input-margin">
            <Input
              name="ARN Code"
              bordercolor="rgba(0, 0, 0, 0.5)"
              value={arnCode.value.split("-")[1]}
              disabled={true}
            />
          </div>

          <div className="input-margin">
            <Input
              name="Sub ARN Code"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                evt.preventDefault()
              }
              bordercolor="rgba(0, 0, 0, 0.5)"
              value={subArnCode.value}
              isShowError={subArnCode.isError}
              errorMessage={subArnCode.errorMessage}
              onBlur={this.validateBrokerCode}
              onChange={this.changeValue("subArnCode")}
              inputref={(ele) => (this.subArnField = ele)}
            />
          </div>

          <div className="input-margin">
            <Input
              name="Sub Broker Code"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                evt.preventDefault()
              }
              isShowError={subBrokerCode.isError}
              errorMessage={subBrokerCode.errorMessage}
              bordercolor="rgba(0, 0, 0, 0.5)"
              value={subBrokerCode.value}
              onChange={this.changeValue("subBrokerCode")}
              inputref={(ele) => (this.subBrokerField = ele)}
            />
          </div>
          <div className="input-field-container">
            <div className="input-name">EUIN Declaration*</div>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Search"
                name="search"
                value={radioValue.value}
                style={{ flexDirection: "row" }}
                onChange={this.changeValue("radioValue")}
              >
                <div className="investor-radio-button">
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        className="investor-search-radio"
                        style={{ color: "#00579b" }}
                      />
                    }
                    className="investor-formcontrol-label"
                    style={{ margin: 0 }}
                  />
                  <div className="investor-form-label">YES</div>
                </div>
                <div className="investor-radio-button">
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        className="investor-search-radio"
                        style={{ color: "#00579b" }}
                      />
                    }
                    className="investor-formcontrol-label"
                    style={{ margin: 0 }}
                  />
                  <div className="investor-form-label">NO</div>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          {radioValue.value === "no" ? (
            <div className="input-margin">
              <IntegrationReactSelect
                suggestions={euinList.map((item) => ({
                  label: item.abm_agent,
                  value: item.abm_agent,
                }))}
                value={euinNo.value}
                errorMessage={euinNo.errorMessage}
                placeholder="Choose EUIN"
                handleChange={this.changeEuin("euinNo")}
                disabled={!euinList.length}
                inputRef={(ele) => (this.euinField = ele)}
                isError={euinNo.isError}
              />
            </div>
          ) : (
            <div className="euin-text">
              I/We hereby confirm that the EUIN Declaration box has been
              intentionally left the blank by me/us as this is an
              'execution-only' transaction without any interaction or advice by
              any personnel of the above distributor or notwithstanding the
              advice of in-appropriateness,if any provided by any personnel of
              the distributor and the distributor has not charged any advisory
              fees on this transaction
            </div>
          )}

          <div className="digital-form-button-next-div">
            <Signbutton
              buttontitle="Submit"
              onClick={this.submit}
              width={150}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const { investorData, communicationDetails, token, userId } = authReducer;
    const { fundsList, schemeList, categoryList, euinList } = transactions;
    return {
      investorData,
      communicationDetails,
      token,
      fundsList,
      schemeList,
      categoryList,
      euinList,
      userId,
    };
  },
  {
    getNewPurchaseFundList,
    getInvestorDetails,
    validateSubBroker,
    submitPurchaseData,
    showPurchaseConfirmation,
  }
)(NewPurchase);

export class SundaramMutualFund extends Component {
  render() {
    return (
      <div
        style={{
          // border: "1px solid rgba(166,166,166,0.9)",
          background: "rgba(236, 239, 244, 0.5)",
          display: "flex",
          alignItems: "center",
          borderRadius: 3,
          padding: "10px 20px",
          minHeight: 48,
          maxHeight: 48,
        }}
      >
        <div
          style={{
            fontFamily: "SourceSansPro-Bold",
            fontSize: 15,
            letterSpacing: 0.5,
            color: "#293139",
          }}
        >
          Sundaram Mutual
        </div>
      </div>
    );
  }
}

export const family_description_codes = [
  {
    family_description: "Self",
    family_code: "SE",
  },
  {
    family_description: "Spouse",
    family_code: "SP",
  },
  {
    family_description: "Dependent Children",
    family_code: "DC",
  },
  {
    family_description: "Dependent Siblings",
    family_code: "DS",
  },
  {
    family_description: "Dependent Parents",
    family_code: "DP",
  },
  {
    family_description: "Guardian",
    family_code: "GD",
  },
];
