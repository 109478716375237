import React, { Component } from "react";
import ListOfInvestors from "./ListofInvestors";
// import TableContainer from "./table";
import {
  INACTIVE_INVESTORS,
  NEW_INVESTORS,
  ACTIVE_INVESTORS,
} from "shared/store/types";
import { connect } from "react-redux";
class ListOfInvestorsTabs extends Component {
  constructor(props) {
    super(props);
    const { active, value } = this.props.location.state;
    this.state = {
      active: active || INACTIVE_INVESTORS,
      value,
      tableData: [],
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData = () => {
    const { active, value } = this.state;
    const { dashboardAUMData, sipSummaryData } = this.props;
    const data = !!dashboardAUMData
      ? active === NEW_INVESTORS
        ? dashboardAUMData.Table7
        : active === ACTIVE_INVESTORS
        ? dashboardAUMData.Table5
        : dashboardAUMData.Table6
      : [];
    const { Type } = this.props.location.state;
    const SipData = !!sipSummaryData
      ? active === NEW_INVESTORS
        ? sipSummaryData.Table6
        : active === ACTIVE_INVESTORS
        ? sipSummaryData.Table4
        : sipSummaryData.Table5
      : [];
    const tableData =
      Type === "SIP_SUMMARY"
        ? SipData.filter((item) => {
            return item.fund.trim() === value.Fund.trim();
          })
        : data.filter((item) => {
            return item.fund.trim() === value.fund_code.trim();
          });
    this.setState({
      tableData,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { active } = prevState;
    if (active !== this.state.active) {
      this.getData();
    }
  }

  onChangeActive = (active) => () => {
    this.setState({
      active,
    });
  };

  render() {
    const { tableData } = this.state;
    const tablearray = [
      "Investor Name",
      "AMC",
      "PAN",
      "Mobile Number",
      "Email ID",
    ];
    return (
      <div  className ="investors-inactive-active-responsive">
        <ListOfInvestors
          active={this.state.active}
          onClick={this.onChangeActive}
        />
        <div className="investor-types-table">
          {!!tableData.length && (
            <div
              style={{
                backgroundColor: "rgba(0, 118, 203, 0.2)",
                display: "flex",
                padding: 10,
                paddingTop: 13,
                paddingBottom: 13,
              }}
            >
              {tablearray.map((ele, index) => (
                <div
                  key={index}
                  style={{
                    width:
                      ele === "AMC" || ele === "Email ID"
                        ? "25%"
                        : ele === "PAN" || ele === "Mobile Number"
                        ? "15%"
                        : "20%",
                    fontFamily: "SourceSansPro-SemiBold",
                    fontSize: 14,
                  }}
                >
                  {ele}
                </div>
              ))}
            </div>
          )}

          {tableData.length > 0 ? tableData.map((ele, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                backgroundColor: index % 2 === 0 ? "white" : "aliceblue",
                padding: 10,
                paddingTop: 13,
                paddingBottom: 13,
              }}
            >
              <div style={{ width: "20%" }}>
                {ele.name}
              </div>

              <div style={{ width: "25%" }}>
                {ele.funddesc}
              </div>

              <div style={{ width: "15%" }}>
                {ele.pangir}
              </div>

              <div style={{ width: "15%" }}>
                {ele.mobile}
              </div>

              <div style={{ width: "25%" }}>
                {ele.email}
              </div>
            </div>
          )): <div
          style={{
            fontFamily: "SourceSansPro-SemiBold",
            fontSize: 14,
            textAlign: "center",
            color: "red",
          }}
        >
          No Data Found
        </div>}

        </div>
        <div className="mobile-aum-list">
          {tableData.length > 0 ? (
            tableData.map((ele, index) => (
              <InvestorTableCard
                key={index}
                invName={ele.name}
                mobile={ele.mobile}
                email={ele.email}
                pan={ele.pangir}
              />
            ))
          ) : (
            <div
              style={{ 
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 14,
                textAlign: "center",
                color: "red",
              }}
            >
              {" "}
              No Data Found
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ dashboard }) => {
  const { dashboardAUMData, sipSummaryData } = dashboard;
  return { dashboardAUMData, sipSummaryData };
}, null)(ListOfInvestorsTabs);


export class InvestorTableCard extends Component {
  render() {
    const { invName, pan, mobile, email } = this.props;
    return (
      <div
        style={{
          background: "white",
          border: "1px solid rgba(211,211,211,0.3)",
          padding: "7px 10px",
          margin: "10px 0px",
        }}
      >
        <div
          style={{
            fontFamily: "SourceSansPro-SemiBold",
            color: "#00579b",
            fontSize: 14,
            paddingBottom: 5,
            borderBottom: "1px solid rgba(211,211,211,0.3)",
          }}
        >
          {invName}
        </div>

        <div style={{ display: "flex", padding: "8px 0px" }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
              PAN
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!pan ? pan : "NA"}
            </div>
          </div>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
              Mobile
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!mobile ? mobile : "NA"}
            </div>
          </div>
        </div>
        <div>
          <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
            Email
          </div>
          <div
            style={{
              fontFamily: "SourceSansPro-SemiBold",
              fontSize: 13,
              color: "#293139",
            }}
          >
            {!!email ? email : "NA"}
          </div>
        </div>
      </div>
    );
  }
}

