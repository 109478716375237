import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import StarRating from "../../../../src/starRating";
import TextField from "@material-ui/core/TextField";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import { connect } from "react-redux";
import { DebtbuttonActive } from "../../forms/button";
import { getComplaint } from "shared/store/actions/support";

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
class Feedback extends Component {
  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#insertion-point-jss")
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      active: { value: "", errorMessage: "" },
      selected: false,
      comments: ""
    };
  }

  changeValue = value => () => {
    this.setState({
      active: { value: value },
      selected: true
    });
  };
  handleChangeComments = event => {
    this.setState({
      comments: event.target.value
    });
  };
  submit = async () => {
    if (this.state.active.value === "") {
      this.setState({
        active: {
          value: "",
          errorMessage:
            "please give us rating. we improve to reach your standards"
        }
      });
    } else {
      const body = {
        APKVer: "Mi43Ljc5\n",
        AcNo: "MA==",
        Adminpassword: "a2FydnkxMjM0JTI0",
        Adminusername: "c21hcnRzZXJ2aWNl",
        Agent: "",
        AppNo: "MA==",
        ChqNo: "MA==",
        Comments: btoa(this.state.comments.value),
        EmailID: "AA==",
        Fund: "MA==",
        IMEI: "MzU4MTg4MDcyNjA3MDE0\n",
        InvName: "AA==",
        OS: "QW5kcm9pZA==\n",
        QrySub: "AA==",
        ReqBy: "QQ==\n",
        Selqry: "MA==",
        TrnDate: "AA==",
        convenience: btoa(this.state.active.value),
        transaction: "",
        experience: ""
      };
      const response = await this.props.getComplaint(body);

      this.setState({
        supportQueries: { value: "" },
        comments: { value: "" }
      });
    }
  };

  render() {
    return (
      <div className="container-margin">
        <div className="investor-title">Feedback</div>
        <Paper style={{ padding: 20 }}>
          <div
            style={{
              backgroundColor: "#00579b",

              padding: 20,
              color: "white",
              textAlign: "center",
              fontSize: 15,
              fontFamily: "SourceSansPro-SemiBold"
            }}
            className="feedback-margin"
          >
            Your Feedback is important to us. This way we can keep improving our
            Website
          </div>

          <div
            style={{ border: "1px solid #e7f2f9" }}
            className="feedback-margin"
          >
            <div
              style={{
                display: "flex",
                padding: "15px 100px",
                borderBottom: "1px solid #e7f2f9"
              }}
            >
              <div style={{ width: "50%" }}>
                <div
                  style={{
                    color: "#3f4860",
                    fontSize: 15,
                    fontFamily: "SourceSansPro-SemiBold",
                    padding: "10px 20px"
                  }}
                >
                  Convenience
                </div>
                <StarRating
                  // onSubmit={val => this.setState({ agentRating: val })}
                  value={0}
                  color="gold"
                />
              </div>

              <div style={{ width: "50%" }}>
                <div
                  style={{
                    color: "#3f4860",
                    fontSize: 15,
                    fontFamily: "SourceSansPro-SemiBold",
                    padding: "10px 20px"
                  }}
                >
                  Transaction
                </div>
                <StarRating
                  // onSubmit={val => this.setState({ agentRating: val })}
                  value={0}
                  color="gold"
                />
              </div>
            </div>
            <div className="number-rating">
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "SourceSansPro-SemiBold",
                  padding: "10px 20px"
                }}
              >
                Overall Experience
              </div>

              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {numbers.map((item, index) => (
                    <div
                      key={index}
                      value={item}
                      style={{
                        padding: "7px 15px",
                        backgroundColor:
                          this.state.selected === true &&
                          this.state.active.value === item
                            ? "#013a7d"
                            : "#f3f8fb",
                        borderRadius: 5,
                        color:
                          this.state.selected === true &&
                          this.state.active.value === item
                            ? "white"
                            : "black",
                        fontFamily: "SourceSansPro-SemiBold",
                        fontSize: 18,
                        margin: 10,
                        cursor: "pointer"
                      }}
                      onClick={this.changeValue(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 15px"
                  }}
                >
                  <div>Not Satisfied</div>
                  <div>Really Satisfied</div>
                </div>
                <div
                  style={{
                    fontSize: 13,
                    color: "red",
                    fontFamily: "Ubuntu-Italic"
                  }}
                >
                  {!!this.state.active.errorMessage
                    ? `(*${this.state.active.errorMessage})`
                    : ""}
                </div>
              </div>
            </div>

            <div
              style={{ padding: "15px 100px 20px" }}
              className="feedback-margin"
            >
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "SourceSansPro-SemiBold",
                  padding: "15px 10px"
                }}
              >
                Please Leave your Feedback below
              </div>
              <div>
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  rowsMax="3"
                  fullWidth={true}
                  onChange={this.handleChangeComments}
                  variant="outlined"
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DebtbuttonActive buttontitle="Submit" onClick={this.submit} />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { token, userId } = authReducer;
    return {
      token,
      userId
    };
  },
  { getComplaint }
)(Feedback);
