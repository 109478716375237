import React, { Component } from "react";
import TableData from "../tableData";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Search from "../searchComponent";
import { ALL } from "shared/store/types";
import { connect } from "react-redux";
import { getInvestors } from "shared/store/actions";
import { MAILBACK_URL } from ".";
import { BUILD_URL } from "../home";
import { dashboardActive } from "../../forms/appEssentials";

class InvestorSelection extends Component {
  componentDidMount() {
    this.props.getInvestors({ ARN: this.props.token, AllFavFlag: "P" });
  }

  render() {
    return (
      <div className="container-margin">
        <div className="tab-breadcrumbs-container" style={{ marginTop: 0 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="Breadcrumb"
          >
            <div className="flex">
              <img
                src={dashboardActive}
                alt="web-page-home"
                style={{ height: 20, marginRight: 10 }}
                alt="web-page-home"
              />

            <a
              onClick={() => this.props.history.push(MAILBACK_URL + "/investor")}
              className="breadcrumbs-unselect-label"
              style={{cursor:"pointer"}}
            >
                Mail Back Types
            </a>
            </div>


            <div className="transaction-summary-label">Investors Selection</div>
          </Breadcrumbs>
        </div>
        <Search history={this.props.history} />
        <TableData
          history={this.props.history}
          location={this.props.location}
          name="mailback"
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { token } = authReducer;
    return { token };
  },
  { getInvestors }
)(InvestorSelection);
