import React, { Component } from "react";
import InvestorData from "./investorData";
export default class Investor extends Component {
  render() {
    return (
      <div style={{ margin: "20px 8px 30px 8px" }}>
        <div className="investor-title">Investors</div>
        <InvestorData
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
