import React, { Component } from "react";
import { List } from "../transactions/nfoAndNewPurchase";
import Signbutton, { Debtbutton } from "../../forms/button";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { Input } from "../../forms/input";
import { BUILD_URL } from "../home";
import { getNetworthPortfolio } from "shared/store/actions/mailback";
import CustomizedDialogs from "../../home/customModal";
import "react-datepicker/dist/react-datepicker.css";
import { MAILBACK_URL } from ".";
import { formatDate } from "../../forms/captalize";
import { MailBackBreadCrumb } from "./mailback";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../../forms/appEssentials";

class Networth extends Component {
  constructor(props) {
    super(props);
    const GetEmail = this.props.communicationDetails.find((ele)=>ele.PAN === this.props.investorData.PAN && !!ele.email)
    this.state = {
      startDate: {
        value: new Date(),
        error: "",
      },
      pan: {
        value: "",
        isError: false,
      },
      endDate: { value: new Date(), error: "" },
      ConfirmSelfPassword: { value: "" },
      selfPassword: { value: "" },
      email: { value: GetEmail.email },
      open: false,
      Mode: "Email",
      showPassword: false,
      confirmShowPassword: false,
    };
  }

  handleChangeSelfPassword = (event) => {
    this.setState({
      selfPassword: { value: event.target.value },
    });
  };
  handleChangeConfirmSelfPassword = (event) => {
    this.setState({
      ConfirmSelfPassword: { value: event.target.value },
    });
  };
  handleChangeEmail = (event) => {
    this.setState({
      email: { value: event.target.value },
    });
  };

  handleChangePan = (event) => {
    if (event.target.value.length <= 10) {
      this.setState({
        pan: {
          value: event.target.value.toUpperCase(),
        },
      });
    }
  };

  panvadilation = (event) => {
    if (/[A-Za-z]{5}\d{4}[A-Za-z]{1}/.test(event.target.value) === false) {
      this.setState({
        pan: {
          value: "",
          isError: true,
          errorMessage: "Enter a valid PAN",
        },
      });
    }
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "selfPassword") {
      if (this.state.selfPassword.value === "" || !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.state.selfPassword.value)) {
        if(!/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.state.selfPassword.value)){
          this.setState({
            selfPassword: {

              isError: true,
              errorMessage: `Password should be atleast 8 characters in length and should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)`,
            },
          });
          return;
        }
      }else if(this.state.selfPassword.value === "") {
          this.setState({
            selfPassword: {
              value:"",
              isError: true,
              errorMessage: `Password is Mandatory`,
            },
          });
          return;
        }
    }
    // else if (label === "email") {
    //   this.setState({
    //     [label]: {
    //       value,
    //       isError:
    //         /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
    //           this.state.email.value
    //         ) === false
    //           ? true
    //           : !value,
    //       errorMessage:
    //         /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
    //           this.state.email.value
    //         ) === false
    //           ? `Enter a valid email address`
    //           : "",
    //     },
    //   });
    // }
    else if (label === "ConfirmSelfPassword") {
      this.setState({
        [label]: {
          value,
          isError:
            this.state.selfPassword.value !==
              this.state.ConfirmSelfPassword.value ||
            this.state.ConfirmSelfPassword.value === ""
              ? true
              : !value,
          errorMessage:
            this.state.selfPassword.value !==
              this.state.ConfirmSelfPassword.value ||
            this.state.ConfirmSelfPassword.value === ""
              ? `The Password and Confirm Password do not match`
              : "",
        },
      });
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };

  changeDateOn = (event) => () => {
    this.setState({
      Mode: event,
      selfPassword: { value: "" },
      ConfirmSelfPassword: { value: "" },
      // email: { value: "" },
      pan:{value:""}
    });
  };

  closeYesModal = () => {
    this.setState({
      ConfirmSelfPassword: { value: "" },
      selfPassword: { value: "" },
      // email: { value: "" },
      open: false,
    });
    this.props.history.replace(
      MAILBACK_URL + "/investor/investor-selection/Networth"
    );
  };
  closeNoModal = () => {
    this.setState({
      open: false,
    });
    this.props.history.replace(BUILD_URL + "/");
  };

  onDateChange = (label, event) => {
    this.setState({ [label]: { value: event } });
  };

  submit = async () => {
    const { ConfirmSelfPassword, selfPassword, email, showPassword,
      confirmShowPassword, ...others } = this.state;
    if (this.state.Mode === "Email") {
      if (
        !this.state.selfPassword.value ||
        !this.state.ConfirmSelfPassword.value ||
        // !this.state.email.value ||
        // !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        //   this.state.email.value
        // ) ||
        !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          this.state.selfPassword.value
        ) ||
        !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          this.state.ConfirmSelfPassword.value
        ) ||
        this.state.selfPassword.value !== this.state.ConfirmSelfPassword.value
      ) {
        if (
          !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
            this.state.selfPassword.value
          )
        ) {
          this.setState({
            selfPassword: {
              value: "",
              isShowPasswordError: true,
            },
          });
        }
        if (
          !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
            this.state.ConfirmSelfPassword.value
          )
        ) {
          this.setState({
            ConfirmSelfPassword: {
              value: "",
              isShowPasswordError: true,
            },
          });
        }
        if (!this.state.selfPassword.value) {
          this.setState({
            selfPassword: {
              value: "",
              isError: true,
              errorMessage: `Password is Mandatory`,
            },
          });
        }
        if (!this.state.ConfirmSelfPassword.value) {
          this.setState({
            ConfirmSelfPassword: {
              value: "",
              isError: true,
              errorMessage: `Confirm Self Extraction Password is Mandatory`,
            },
          });
        }
        // if (
        //   !this.state.email.value ||
        //   !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        //     this.state.email.value
        //   )
        // ) {
        //   if (!this.state.email.value) {
        //     this.setState({
        //       email: {
        //         value: "",
        //         isError: true,
        //         errorMessage: `Email is Mandatory`,
        //       },
        //     });
        //   } else {
        //     this.setState({
        //       email: {
        //         value: "",
        //         isError: true,
        //         errorMessage: `Enter a valid email address`,
        //       },
        //     });
        //   }
        // }
        if (
          !!this.state.ConfirmSelfPassword &&
          !!this.state.selfPassword &&
          this.state.selfPassword.value !== this.state.ConfirmSelfPassword.value
        ) {
          this.setState({
            ConfirmSelfPassword: {
              value: "",
              isError: true,
              errorMessage: `Password does not match`,
            },
          });
        }
      } else {
        let startDateValue;
        startDateValue = new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          year: "numeric",
          day: "numeric",
        });

        const GetEmail = this.props.communicationDetails.find((ele)=>ele.PAN === this.props.investorData.PAN && !!ele.email)

        const params = {
          fromdate: "01/01/1970",
          todate: formatDate(new Date()),
          mylp: "",
          emailid: GetEmail.email,
          distCode: this.props.token,
          pswd: this.state.selfPassword.value,
          funds: "",
          pagetype: "Email",
          distUid: this.props.userId,
        };

        const response = await this.props.getNetworthPortfolio(params);

        if (response) {
          this.setState({
            open: true,
          });
        }
      }
    } else if (this.state.Mode === "PAN") {
      if (
        !this.state.pan.value ||
        !/[A-Za-z]{5}\d{4}[A-Za-z]{1}/.test(this.state.pan.value)
      ) {
        if (!/[A-Za-z]{5}\d{4}[A-Za-z]{1}/.test(this.state.pan.value)) {
          this.setState({
            pan: {
              value: "",
              isError: true,
              errorMessage: "Enter a valid pan",
            },
          });
        }
      } else {
        let startDateValue;
        const params = {
          fromdate: "01/01/1970",
          todate: formatDate(new Date()),
          mylp: "",
          emailid: "",
          pan: this.state.pan.value,
          distCode: this.props.token,
          pswd: this.state.pan.value,
          funds: "",
          pagetype: "PAN",
          distUid: this.props.userId,
        };

        const response = await this.props.getNetworthPortfolio(params);

        if (response) {
          this.setState({
            open: true,
            error: "Data inserted Successfully",
          });
        }
      }
    }
  };

  render() {
    const { investorData } = this.props;
    const { selfPassword, ConfirmSelfPassword, email, pan, Mode } = this.state;
    return (
      <div>
         <MailBackBreadCrumb history={this.props.history} investorData={this.props.investorData} name="Networth Report"/>
        <Paper
          className="right-bar-container-paper"
          style={{display:'flex'}}
        >
          <div className="digital-form-left-div">
            <List investorData={investorData} />
          </div>
          <div className="digital-form-Right-div">
            <div className="transaction-details">
              <div className="title">Networth Report </div>
              <div className="radio-buttons-div">
                <Debtbutton
                  buttontitle="Email"
                  width={150}
                  active={Mode === "Email"}
                  onClick={this.changeDateOn("Email")}
                />
                <Debtbutton
                  buttontitle="PAN"
                  width={150}
                  active={Mode === "PAN"}
                  onClick={this.changeDateOn("PAN")}
                />
              </div>
              {Mode === "PAN" ? (
                <div className="input-margin">
                  <Input
                    name="PAN"
                    onChange={this.handleChangePan}
                    onBlur={this.panvadilation}
                    value={pan.value}
                    bordercolor="rgba(0, 0, 0, 0.5)"
                    isShowError={pan.isError}
                    errorMessage={pan.errorMessage}
                  />
                  <div className="digital-form-button-next-div">
                    <Signbutton buttontitle="Submit" onClick={this.submit} />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="input-margin">
                    <Input
                      name="Email ID"
                      onChange={this.handleChangeEmail}
                      value={email.value}
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      onBlur={this.showError("email")}
                      isShowError={email.isError}
                      errorMessage={email.errorMessage}
                    />
                  </div>
                  <div className="input-margin">
                    <Input
                      name="Self Extraction Password"
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      isShowPasswordError={
                        this.state.selfPassword.isShowPasswordError
                      }
                      image2={
                        !!this.state.showPassword
                          ? EYE_ICON
                          : EYE_ICON_HIDDEN
                      }
                      type={!!this.state.showPassword ? "text" : "password"}
                      height={15}
                      onClick={() =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                      onChange={this.handleChangeSelfPassword}
                      value={selfPassword.value}
                      onBlur={this.showError("selfPassword")}
                      isShowError={selfPassword.isError}
                      errorMessage={selfPassword.errorMessage}

                    />
                    <div style={{fontSize:14,fontFamily:"SourceSansPro-Regular",marginTop:4}}>
                  Password should be at least 8 characters in length and should contain at least 1 uppercase letter (A-Z), 1 special character and 1 numeric value (0-9)
                  </div>
                  </div>
                  <div className="input-margin">
                    <Input
                      name="Confirm Self Extraction Password"
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      isShowPasswordError={
                        this.state.ConfirmSelfPassword.isShowPasswordError
                      }
                      image2={
                        !!this.state.confirmShowPassword
                          ? EYE_ICON
                          : EYE_ICON_HIDDEN
                      }
                      onClick={() =>
                        this.setState({
                          confirmShowPassword: !this.state.confirmShowPassword,
                        })
                      }
                      type={!!this.state.confirmShowPassword ? "text" : "password"}
                      height={15}
                      onChange={this.handleChangeConfirmSelfPassword}
                      value={ConfirmSelfPassword.value}
                      onBlur={this.showError("ConfirmSelfPassword")}
                      isShowError={ConfirmSelfPassword.isError}
                      errorMessage={ConfirmSelfPassword.errorMessage}

                    />
                  </div>
                  <div className="digital-form-button-next-div">
                    <Signbutton buttontitle="Submit" onClick={this.submit} width={150}/>
                  </div>
                   
                </div>
              )}
              <CustomizedDialogs
                cas={true}
                open={this.state.open}
                closeYesModal={this.closeYesModal}
                closeNoModal={this.closeNoModal}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { investorData, userId, token,communicationDetails } = authReducer;
    return { investorData, userId, token,communicationDetails };
  },
  { getNetworthPortfolio }
)(Networth);
