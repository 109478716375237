export var GET_INVESTORS_LIST = "GET_INVESTORS_LIST";
export var GET_INVESTORS_LIST_SUCCESS = "GET_INVESTORS_LIST_SUCCESS";
export var GET_INVESTORS_LIST_FAILED = "GET_INVESTORS_LIST_FAILED";
export var GET_INVESTOR_DETAILS = "GET_INVESTOR_DETAIL";
export var GET_INVESTOR_DETAILS_SUCCESS = "GET_INVESTOR_DETAIL_SUCCESS";
export var GET_INVESTOR_DETAILS_FAILED = "GET_INVESTOR_DETAIL_FAILED";
export var FAVOURITE_INVESTOR = "FAVOURITE_INVESTOR";
export var FAVOURITE_INVESTOR_SUCCESS = "FAVOURITE_INVESTOR_SUCCESS";
export var FAVOURITE_INVESTOR_FAILED = "FAVOURITE_INVESTOR_FAILED";
export var SEARCH_INVESTORS = "SEARCH_INVESTORS";
export var SEARCH_INVESTORS_SUCCESS = "SEARCH_INVESTORS_SUCCESS";
export var SEARCH_INVESTORS_FAILED = "SEARCH_INVESTORS_FAILED";
export var GET_FUNDS_LIST = "GET_FUNDS_LIST";
export var GET_FUNDS_LIST_SUCCESS = "GET_FUNDS_LIST_SUCCESS";
export var GET_FUNDS_LIST_FAILED = "GET_FUNDS_LIST_FAILED";
export var GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export var GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export var GET_CATEGORY_LIST_FAILED = "GET_CATEGORY_LIST_FAILED";
export var GET_SCHEME_LIST = "GET_SCHEME_LIST";
export var GET_SCHEME_LIST_SUCCESS = "GET_SCHEME_LIST_SUCCESS";
export var GET_SCHEME_LIST_FAILED = "GET_SCHEME_LIST_FAILED";
export var VALIDATE_SUB_BROKER = "VALIDATE_SUB_BROKER";
export var VALIDATE_SUB_BROKER_SUCCESS = "VALIDATE_SUB_BROKER_SUCCESS";
export var VALIDATE_SUB_BROKER_FAILED = "VALIDATE_SUB_BROKER_FAILED";
export var STORE_TYPE = "STORE_TYPe";
export var GET_UPCOMING_SIP_DETAILS = "GET_UPCOMING_SIP_DETAILS";
export var GET_UPCOMING_SIP_DETAILS_SUCCESS = "GET_UPCOMING_SIP_DETAILS_SUCCESS";
export var GET_UPCOMING_SIP_DETAILS_FAILED = "GET_UPCOMING_SIP_DETAILS_FAILED";
export var GET_FOLIOS_CANCELLATION = "GET_FOLIOS_CANCELLATION";
export var GET_FOLIOS_CANCELLATION_FAILED = "GET_FOLIOS_CANCELLATION_FAILED";
export var GET_FOLIOS_CANCELLATION_SUCCESS = "GET_FOLIOS_CANCELLATION_SUCCESS";
export var SUBMIT_GET_FOLIOS_CANCELLATION = "SUBMIT_GET_FOLIOS_CANCELLATION";
export var SUBMIT_GET_FOLIOS_CANCELLATION_SUCCESS = "SUBMIT_GET_FOLIOS_CANCELLATION_SUCCESS";
export var SUBMIT_GET_FOLIOS_CANCELLATION_FAILED = "SUBMIT_GET_FOLIOS_CANCELLATION_FAILED";
export var SEND_MAIL_KYC = "SEND_MAIL_KYC";
export var SEND_MAIL_KYC_SUCCESS = "SEND_MAIL_KYC_SUCCESS";
export var SEND_MAIL_KYC_FAILED = "SEND_MAIL_KYC_FAILED";
export var PAUSE_SIP_DATES = "PAUSE_SIP_DATES";
export var PAUSE_SIP_DATES_SUCCESS = "PAUSE_SIP_DATES_SUCCESS";
export var PAUSE_SIP_DATES_FAILED = "PAUSE_SIP_DATES_FAILED";
export var PAUSE_END_SIP_DATES = "PAUSE_END_SIP_DATES";
export var PAUSE_END_SIP_DATES_SUCCESS = "PAUSE_END_SIP_DATES_SUCCESS";
export var PAUSE_END_SIP_DATES_FAILED = "PAUSE_END_SIP_DATES_FAILED";
export var PAUSE_SIP_VALIDATOR = "PAUSE_SIP_VALIDATOR";
export var PAUSE_SIP_VALIDATOR_SUCCESS = "PAUSE_SIP_VALIDATOR_SUCCESS";
export var PAUSE_SIP_VALIDATOR_FAILED = "PAUSE_SIP_VALIDATOR_FAILED";
export var FINAL_SIP_PAUSE = "FINAL_SIP_PAUSE";
export var FINAL_SIP_PAUSE_SUCCESS = "FINAL_SIP_PAUSE_SUCCESS";
export var FINAL_SIP_PAUSE_FAILED = "FINAL_SIP_PAUSE_FAILED";
export var REMOVE_SEARCH_INVESTOR_LIST = "REMOVE_SEARCH_INVESTOR_LIST";
export var SUBMIT_GET_STP_FOLIOS_CANCELLATION = "SUBMIT_GET_STP_FOLIOS_CANCELLATION";
export var SUBMIT_GET__STP_FOLIOS_CANCELLATION_SUCCESS = "SUBMIT_GET__STP_FOLIOS_CANCELLATION_SUCCESS";
export var SUBMIT_GET_STP_FOLIOS_CANCELLATION_FAILED = "SUBMIT_GET_STP_FOLIOS_CANCELLATION_FAILED";