import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Investors from "../investors/InvestorMain";
import HistoryDetail from "./historyDetail";

export default class HistoryRouter extends Component {
  render() {
    const { history, location } = this.props;
    return (
      <Switch location={location} history={history}>
        <Route
          path={`${process.env.PUBLIC_URL}/transaction-history/details/:PAN`}
          component={HistoryDetail}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/transaction-history`}
          exact
          component={Investors}
        />
      </Switch>
    );
  }
}
