import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { getFundWiseEarnings } from "shared/store/actions";
import { ALL } from "shared/store/types";
import CustomLoading from "../CustomLoader";
import { FormatCurrency } from "../currencyFormatter";
import CustomLoader from "../../forms/customLoader";

export const MyEarnings_URL = `${process.env.PUBLIC_URL}/my-earnings`;

class MyEarnings extends Component {
  async componentDidMount() {
    this.getEarnings();
  }

  getEarnings = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const params = {
      ReqBy: ALL,
      Agent: this.props.token,
      Fund: "105",
    };
    await this.props.getFundWiseEarnings(params);
  };

  get total() {
    const { earnings = [] } = this.props;
    return earnings.reduce((prev, curr) => prev + curr.Amount, 0);
  }

  render() {
    const { earnings = [], count } = this.props;
    return (
      <div className="Earnings">
        <div className="right-bar-title">My Earnings</div>
        {count > 0 && !earnings.length ? (
          <CustomLoader
            display={count > 0 && !earnings.length ? true : false}
            display={true}
          />
        ) : (
          <Paper className="right-bar-container-paper">
            <div className="digital-form-Right-div">
              <div className="flex-row-center-between title-block">
                <div className="heading">
                  Brokerage Details<span>(Last 6 Months)</span>
                </div>
                <div className="total-amount">
                  Total: <FormatCurrency value={this.total} />
                </div>
              </div>
              {!!earnings.length ? (
                earnings.map((earning, index) => (
                  <EarningsBlock earning={earning} index={index} />
                ))
              ) : (
                <div>No Data Found</div>
              )}
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

export default connect(
  ({ transactions, authReducer, loading }) => {
    const { earnings } = transactions;
    const { token } = authReducer;
    const { count } = loading;
    return { token, earnings, count };
  },
  { getFundWiseEarnings }
)(MyEarnings);

export class EarningsBlock extends Component {
  render() {
    const { earning, index } = this.props;
    return (
      <div key={index} className="earnings_block">
        <div className="desci">{earning.BankName}</div>
        <div className="flex-row-center-between inner-block">
          <div>
            <div className="light-header">Period:</div>
            <div className="period-value">
              {earning.Fromdate} to {earning.ToDate}
            </div>
          </div>
          <div>
            <div className="light-header float-right">Amount</div>
            <div className="amount-value">
              <FormatCurrency value={earning.Amount} />
            </div>
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            margin: "10px 0px",
          }}
        />
      </div>
    );
  }
}
