import React, { Component } from "react";
var CurrencyFormat = require("react-currency-format");
export class FormatCurrency extends Component {
  render() {
    return (
      <CurrencyFormat
        value={this.props.value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₹"}
        thousandSpacing="2s"
        decimalScale={0}
      />
    );
  }
}
