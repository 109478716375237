export * from "./auth";
export * from "./dashboard";
export * from "./investors";
export * from "./transactions";
export * from "./history";
export var ALL = "A";
export var FAVOURITE = "FAV";
export var RECENT = "R";
export var YES = "Y";
export var NO = "N";
export var PANALL = "ALL";
export var TRUE = "T";
export var FALSE = "F";
export var AUTHORIZATION_PENDING = "P";
export var AUTHORIZATION_APPROVED = "A";
export var AUTHORIZATION_EXPIRED = "E";
export var FMPS_MATURITY = "FMPS_MATURITY";
export var SIPS = "SIPS";
export var PHYSICAL_TRANSACTIONS = "PHYSICAL_TRANSACTIONS";
export var INVESTMENTS = "INVESTMENTS";
export var UPCOMING_SIP = "UPCOMING_SIP";
export var EXISTING = "EXISTING";
export var EXISTINGP = "EXISTINGP";
export var NEW = "NEW";
export var NEWP = "NEWP";
export var PURCHASE = "P";
export var SIP_TRANS_TYPE = "I";
export var REQ_BY = "a";
export var REGULAR = "Regular";
export var PAYMENT_TYPE = "DCB";
export var NEW_PURCHASE = "NEW_PURCHASE";
export var ADDITIONAL_PURCHASE = "ADDITIONAL_PURCHASE";
export var PARTIAL = "PARTIAL";
export var FULL = "FULL";
export var UNITS = "UNITS";
export var AMOUNT = "AMOUNT";
export var REDEMPTION = "REDEMPTION";
export var U = "U";
export var SWITCH = "SWITCH";
export var SWP = "SWP";
export var STP = "STP";
export var SIP = "SIP";
export var PHYSICAL_NEW = "new";
export var PHYSICAL_ADD = "add";
export var PHYSICAL_RED = "red";
export var PHYSICAL_SWITCH = "swop";
export var PHYSICAL_SIP = "isip";
export var GRAPH = "GRAPH";
export var TABLE = "TABLE";
export var PAYMENT_MODE = "PM";
export var INVESTMENT_FREQUENCY = "IF";
export var SIP_DAY = "D";
export var START_LOADER = "START_LOADER";
export var STOP_LOADER = "STOP_LOADER";
export var INACTIVE_INVESTORS = "INACTIVE_INVESTORS";
export var ACTIVE_INVESTORS = "ACTIVE_INVESTORS";
export var NEW_INVESTORS = "NEW_INVESTORS";
export var GET_SIP_SUMMARY_DATA = "GET_SIP_SUMMARY_DATA";
export var GET_SIP_SUMMARY_DATA_SUCCESS = "GET_SIP_SUMMARY_DATA_SUCCESS";
export var GET_SIP_SUMMARY_DATA_FAILED = "GET_SIP_SUMMARY_DATA_FAILED";
export var EXPIRING_NEXT_MONTH = "EXPIRING_NEXT_MONTH";
export var CLOSED_THIS_MONTH = "CLOSED_THIS_MONTH";
export var SIP_INVESTMENTS = "SIP_INVESTMENTS";
export var CWS = "CWS";
export var CWOS = "CWOS";
export var GET_NFO_SCHEMES = "GET_NFO_SCHEMES";
export var GET_NFO_SCHEMES_SUCCESS = "GET_NFO_SCHEMES_SUCCESS";
export var GET_NFO_SCHEMES_FAILED = "GET_NFO_SCHEMES_FAILED";
export var REMOVE_NFO_SCHEMES = "REMOVE_NFO_SCHEMES";