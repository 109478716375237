import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import NewPurchase from "./newPurchaseForm";
import NewPurchaseConfirm from "./newPurchaseConfirm";
import AdditionalPurchase from "./additionalPurchaseForm";
import Redemption from "./redemption";
import switchCmponent from "./switch";
import Sip from "./sip";
import Swp from "./swp";
import Stp from "./stp";
import NfoPhysicalMode from "./nfoPhysicalMode";
import { BUILD_URL } from "../home";
import SipCancellation from "./sipCancellation";
import NctType from "./nctType";
import ENACH from "./eNach";
import { userPan } from "shared/store/actions/auth";

class NFOAndNewPurchaseDigital extends Component {
  async componentDidMount() {
    if (!!this.props.location.state && !!this.props.location.state.pan) {
      await this.props.userPan(this.props.location.state.pan);
    }
  }
  render() {
    const { investorData } = this.props;
    return (
      <div>
        <Paper
          className="right-bar-container-paper"
          style={{ boxShadow: "0px 0px 2px 0px lightgray",marginLeft:10,marginRight:10,display:"flex" }}
        >
          <div className="digital-form-left-div">
            <List
              investorData={investorData}
              pan={!!this.props.user_pan ? this.props.user_pan : ""}
            />
          </div>
          <div className="digital-form-Right-div">
            <Switch history={this.props.history} location={this.props.location}>

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/nfo-new-purchase`}
                component={NewPurchase}
                pan={!!this.props.user_pan ? this.props.user_pan : ""}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/nfo-new-purchase/confirm`}
                component={NewPurchaseConfirm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/additional-purchase`}
                component={AdditionalPurchase}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/additional-purchase/confirm`}
                component={NewPurchaseConfirm}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/redemption`}
                component={Redemption}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/redemption/confirm`}
                component={NewPurchaseConfirm}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/switch`}
                component={switchCmponent}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/switch/confirm`}
                component={NewPurchaseConfirm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/sip`}
                component={Sip}
                pan={!!this.props.user_pan ? this.props.user_pan : ""}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/sip/confirm`}
                component={NewPurchaseConfirm}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/swp`}
                component={Swp}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/swp/confirm`}
                component={NewPurchaseConfirm}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/stp`}
                component={Stp}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/stp/confirm`}
                component={NewPurchaseConfirm}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation`}
                component={SipCancellation}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/transction-types/sip-cancellation/confirm`}
                component={NewPurchaseConfirm}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/nct-type/digital/transact`}
                component={NctType}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/nct-type/digital/transact/confirm`}
                component={NewPurchaseConfirm}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/eNACH/digital/transact`}
                component={ENACH}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/investors/eNACH/digital/transact/confirm`}
                component={NewPurchaseConfirm}
              />
            </Switch>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { investorData, user_pan } = authReducer;
    return { investorData, user_pan };
  },
  { userPan }
)(NFOAndNewPurchaseDigital);

export class List extends Component {
  render() {
    const { invname, PAN } = this.props.investorData;
    return (
      <div
        className="list-investors-tm"
      >
        <div className="digital-form-investor-list">
          <img
            src={BUILD_URL + "/images/user.png"}
            alt="user_icon"
            style={{ height: 45 }}
          />
          <div className="digital-form-text-container">
            <div className="digital-form-main-text">{invname}</div>
            <div className="digital-form-sub-text">
              {" "}
              PAN: {PAN || this.props.pan}
            </div>
          </div>
          {!!this.props.image && (
            <div>
              <img
                alt="secondary_image"
                src={`${BUILD_URL}${this.props.image}`}
                height={this.props.height}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
