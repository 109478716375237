import React, { Component } from "react";
import { connect } from "react-redux";
import { INVESTMENTS } from "shared/store/types";
import { BUILD_URL } from "../home";
import { FormatCurrency } from "../../home/currencyFormatter";

class Investments extends Component {
  render() {
    const { investments, upComingSipDetails } = this.props;
    return (
      <div className="appbar-div" >
        {this.props.active === INVESTMENTS &&
          investments.length > 0 &&
          investments.slice(0, this.props.count).map((investment, index) => (
            <React.Fragment key={index}>
              <div className="investor-investments-container-title">
                {investment.SchDesc} - {investment.plndesc}
              </div>
              <div className="investor-investments-sub-container">
                <div>
                  <div className="investor-investments-text">Cost</div>
                  <div className="investor-investments-sub-text">
                    <FormatCurrency
                      value={
                        !!investment.CostValue
                          ? investment.CostValue.split(".")[0]
                          : 0
                      }
                    />
                    {!!investment.CostValue
                      ? `.${investment.CostValue.split(".")[1]}`
                      : `.00`}
                  </div>
                </div>
                <div>
                  <div className="investor-investments-text">Current Value</div>
                  <div className="investor-investments-sub-text">
                    <FormatCurrency
                      value={
                        !!investment.CurrentValue
                          ? investment.CurrentValue.split(".")[0]
                          : 0
                      }
                    />
                    {!!investment.CurrentValue
                      ? `.${investment.CurrentValue.split(".")[1]}`
                      : `.00`}
                  </div>
                </div>
                <div>
                  <div className="investor-investments-text">Gain</div>
                  <div className="investor-investments-sub-text">
                    {investment.GainPercent}{" "}
                    <img
                      src={
                        investment.GainPercent.includes("-")
                          ? BUILD_URL + "/images/down_investors.svg"
                          : BUILD_URL + "/images/rise.png"
                      }
                      alt="rise"
                      style={{ height: 15 }}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}

        {this.props.active !== INVESTMENTS && !!upComingSipDetails.length
          ? upComingSipDetails.slice(0, this.props.count).map((sip, index) => (
              <React.Fragment key={index}>
                <div className="investor-investments-sub-container">
                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">Fund Name</div>
                    <div className="investor-investments-sub-text">
                      {`${sip.schname}-${sip.plnname}`}
                    </div>
                  </div>
                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">
                      Folio Number
                    </div>
                    <div className="investor-investments-sub-text">
                      {sip.accno}
                    </div>
                  </div>

                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">Sip Date</div>
                    <div className="investor-investments-sub-text">
                      {sip.sipdt}
                    </div>
                  </div>

                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">
                      Sip Start Date
                    </div>
                    <div className="investor-investments-sub-text">
                      {sip.sipstartdt}
                    </div>
                  </div>

                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">
                      Sip End Date
                    </div>
                    <div className="investor-investments-sub-text">
                      {sip.sipenddt}
                    </div>
                  </div>
                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">Sip Amount</div>
                    <div className="investor-investments-sub-text">
                      <FormatCurrency
                        value={!!sip.amt ? sip.amt : 0}
                      />
                    </div>
                  </div>

                  <div style={{ padding: "0px 10px" }}>
                    <div className="investor-investments-text">Status</div>
                    <div className="investor-investments-sub-text">
                      {sip.Status}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          : this.props.active !== INVESTMENTS && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                  fontFamily: "SourceSansPro-SemiBold",
                }}
              >
                No Upcoming Sips
              </div>
            )}
      </div>
    );
  }
}

export default connect(({ investor }) => {
  const { investments, upComingSipDetails } = investor;
  return { investments, upComingSipDetails };
})(Investments);
