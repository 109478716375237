import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { Input } from "../../forms/input";
import CustomizedSelect from "../../home/transactions/customizedSelect";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { SucesssPopUp } from "../../home/customModal";
import Signbutton from "../../forms/button";
import { getDistPostQuery, getComplaint } from "shared/store/actions/support";
const BUILD_URL = process.env.PUBLIC_URL;
export class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportQueries: { value: "" },
      comments: { value: "" },
      open: false,
      response: ""
    };
  }

  componentDidMount() {
    this.props.getDistPostQuery();
  }

  handleChangeSupportQueries = event => {
    this.setState({
      supportQueries: { value: event.target.value }
    });
  };
  handleChangeCommentsChange = event => {
    this.setState({
      comments: { value: event.target.value }
    });
  };
  closeModal() {
    this.setState({
      open: false
    });
    this.props.history.push(BUILD_URL + "/");
  }

  submit = async () => {
    const {
      supportQueries: { value }
    } = this.state;
    if (!value) {
      this.setState({
        supportQueries: {
          value,
          isError: true,
          errorMessage: "Please select support query"
        }
      });
      return;
    }
    if (!this.state.comments.value) {
      this.setState({
        comments: {
          value: "",
          isError: true,
          errorMessage: "Please enter a query message"
        }
      });
      return;
    }

    const body = {
      APKVer: "Mi43Ljc5\n",
      AcNo: "MA==",
      Adminpassword: "a2FydnkxMjM0JTI0",
      Adminusername: "c21hcnRzZXJ2aWNl",
      Agent: "",
      AppNo: "MA==",
      ChqNo: "MA==",
      Comments: btoa(this.state.comments.value),
      EmailID: "AA==",
      Fund: "MA==",
      IMEI: "MzU4MTg4MDcyNjA3MDE0\n",
      InvName: "AA==",
      OS: "QW5kcm9pZA==\n",
      QrySub: "AA==",
      ReqBy: "QQ==\n",
      Selqry: btoa(this.state.supportQueries.value),
      TrnDate: "AA==",
      convenience: "",
      transaction: "",
      experience: ""
    };
    const response = await this.props.getComplaint(body);
    if (response) {
      const {
        Dtinformation: [info]
      } = response;

      this.setState({
        supportQueries: { value: "" },
        open: true,
        response: response.Dtinformation[0].Error_Message,
        comments: { value: "" }
      });
    }
  };

  render() {
    const { supportQueries } = this.state;
    return (
      <div className="container-margin transaction-details">
        <Paper style={{ padding: "20px 100px" }}>
          <div className="flex" style={{ alignItems: "center" }}>
            <img
              src={BUILD_URL + "/images/raise_a_query.svg"}
              alt="complaint icon"
              style={{ width: 50, height: 50, paddingRight: 20 }}
            />
            <div className="title">Raise a Query</div>
          </div>

          <div className="input-margin">
            <Input
              name="ARN Code"
              value={this.props.token.split("-")[1]}
              disabled={true}
            />
          </div>
          <div className="input-margin">
            <div className="input-name">Query Option </div>
            <CustomizedSelect
              handleChange={this.handleChangeSupportQueries}
              value={supportQueries.value}
              placeholder="Select Support Query"
              isError={supportQueries.isError}
              errorMessage={supportQueries.errorMessage}
            >
              {this.props.supportQueries.map((item, index) => (
                <MenuItem value={item.MenuItem} key={index}>
                  {item.MenuItem}
                </MenuItem>
              ))}
            </CustomizedSelect>
          </div>
          <div className="input-margin">
            <div className="input-name">Query Message (optional)</div>
            <TextField
              id="outlined-multiline-flexible"
              multiline
              rowsMax="3"
              fullWidth={true}
              variant="outlined"
              value={this.state.comments.value}
              onChange={this.handleChangeCommentsChange}
            />
            {!!this.state.comments.errorMessage ? (
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "Ubuntu-Italic",
                  color: "red"
                }}
              >
                {this.state.comments.errorMessage}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="digital-form-button-next-div">
            <Signbutton buttontitle="Submit" onClick={this.submit} />
          </div>
        </Paper>
        <SucesssPopUp
          open={this.state.open}
          response={this.state.response}
          closeSucessModal={() => this.closeModal()}
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer, dashboard, investor, support }) => {
    const { token, userId } = authReducer;
    const { supportQueries } = support;

    return {
      token,
      userId,
      supportQueries
    };
  },
  { getDistPostQuery, getComplaint }
)(Support);
