import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { START_LOADER, STOP_LOADER } from "../types";
export function loading() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    count: 0
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case START_LOADER:
      {
        return _objectSpread({}, state, {
          count: state.count + 1
        });
      }

    case STOP_LOADER:
      {
        return _objectSpread({}, state, {
          count: Math.max(0, state.count - 1)
        });
      }

    default:
      {
        return state;
      }
  }
}