import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { BUILD_URL } from "../home";

export default class TransactionMode extends Component {
  render() {
    return (
      <div className="flex">
        <Paper className="transaction-digital-mode-paper">
          <div className="transaction-mode-img-div">
            <div className="transaction-mode-img-bg-div">
              <img
                src={BUILD_URL + "/images/pc.png"}
                alt="digital_mode"
                style={{ height: 50 }}
              />
            </div>
          </div>
          <div className="transction-mode-title">Digital Mode</div>
          <div className="transaction-mode-content">
            A mutual fund is a professionally managed investment fund that pools
            money from many investors to purchase securities. These investors
            may be retail or institutional in nature.
          </div>
          <div
            className="transaction-mode-name"
            onClick={() =>
              this.props.history.push({
                pathname: this.props.location.state.digital
              })
            }
          >
            Select digital Mode
            <span>
              <img
                src={BUILD_URL + "/images/right_arrow.png"}
                style={{ height: 25, marginLeft: 20 }}
                alt="right_arrow"
              />
            </span>
          </div>
        </Paper>

        <Paper className="transaction-physical-mode-paper">
          <div className="transaction-mode-img-div">
            <div className="transaction-mode-img-bg-div">
              <img
                src={BUILD_URL + "/images/resume.png"}
                alt="Physical_mode"
                style={{ height: 50 }}
              />
            </div>
          </div>
          <div className="transction-mode-title">Physical Mode</div>
          <div>
            <div className="transctionomode-physical-note">
              Please Follow The Below Steps
            </div>
            <div className="transaction-mode-img-div">
              <div>
                <img
                  src={BUILD_URL + "/images/stages.png"}
                  alt="stages"
                  style={{ width: 8, height: 80, margin: "5px 0px 3px 0px" }}
                />
              </div>
              <div className="transaction-mode-physical-upload-div">
                <div className="transaction-mode-physical-upload">
                  Upload Order form
                </div>
                <div className="transaction-mode-physical-upload">
                  Upload Cheque
                </div>
                <div className="transaction-mode-physical-upload">
                  Sumbit Cheque at nearest Karvy Branch
                </div>
              </div>
            </div>
          </div>
          <div
            className="transaction-mode-name"
            onClick={() =>
              this.props.history.push({
                pathname: this.props.location.state.physical,
                state: {
                  type: this.props.location.state.type,
                  flag: this.props.location.state.flag
                }
              })
            }
          >
            Select Physical Mode
            <span>
              <img
                src={BUILD_URL + "/images/right_arrow.png"}
                style={{ height: 25, marginLeft: 20 }}
                alt="right_arrow"
              />
            </span>
          </div>
        </Paper>
      </div>
    );
  }
}
