import React, { Component } from "react";
import TabHeader from "../../forms/tabHeader";
import {
  EXPIRING_NEXT_MONTH,
  CLOSED_THIS_MONTH,
  SIP_INVESTMENTS,
} from "shared/store/types";

export default class SipSummaryMainTabs extends Component {
  render() {
    const { active } = this.props;
    return (
      <div
        className="tab-container"
        style={{
          justifyContent: "space-between",
          margin: "25px 20px 15px 20px",
        }}
      >
        <TabHeader
          value="SIPs Expiring Next Month"
          active={active === EXPIRING_NEXT_MONTH}
          onClick={this.props.onClick(EXPIRING_NEXT_MONTH)}
        />
        <TabHeader
          value="SIPs Closed This Month"
          active={active === CLOSED_THIS_MONTH}
          onClick={this.props.onClick(CLOSED_THIS_MONTH)}
        />
        <TabHeader
          value="SIP Investments"
          active={active === SIP_INVESTMENTS}
          onClick={this.props.onClick(SIP_INVESTMENTS)}
        />
      </div>
    );
  }
}
