import React, { Component } from "react";
import { List } from "../transactions/nfoAndNewPurchase";
import Signbutton, { Debtbutton } from "../../forms/button";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { Input } from "../../forms/input";
import DateFnsUtils from "@date-io/date-fns";
import { BUILD_URL } from "../home";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getcaptialgainStatment } from "shared/store/actions/mailback";
import {
  SUMMARY,
  DETAILED,
  CURRENTFINANCIALYEAR,
  PREVIOUSFINANCIALYEAR,
  SPECIFICPERIOD,
} from "shared/store/types/mailBack";
import CustomizedDialogs from "../../home/customModal";
import "react-datepicker/dist/react-datepicker.css";
import { NEW_TRANSACTION_URL } from "../transactions";
import { MAILBACK_URL } from ".";
import { formatDate } from "../../forms/captalize";
import { MailBackBreadCrumb } from "./mailback";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../../forms/appEssentials";

class CGS extends Component {
  constructor(props) {
    super(props);
    const GetEmail = this.props.communicationDetails.find((ele)=>ele.PAN === this.props.investorData.PAN && !!ele.email)
    this.state = {
      startDate: {
        value: null,
        error: "",
      },
      endDate: { value: null, error: "" },
      ConfirmSelfPassword: { value: "" },
      selfPassword: { value: "" },
      email: { value:GetEmail.email },
      open: false,
      showPassword: false,
      confirmShowPassword: false,
    };
  }

  handleChangeSelfPassword = (event) => {
    this.setState({
      selfPassword: { value: event.target.value },
    });
  };
  handleChangeConfirmSelfPassword = (event) => {
    this.setState({
      ConfirmSelfPassword: { value: event.target.value },
    });
  };
  handleChangeEmail = (event) => {
    this.setState({
      email: { value: event.target.value },
    });
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "selfPassword") {
      if (this.state.selfPassword.value === "" || !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.state.selfPassword.value)) {
        if(!/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.state.selfPassword.value)){
          this.setState({
            selfPassword: {
              isError: true,
              errorMessage: `Password should be atleast 8 characters in length and should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)`,
            },
          });
          return;
        }
      }else if(this.state.selfPassword.value === "") {
          this.setState({
            selfPassword: {
              value:"",
              isError: true,
              errorMessage: `Password is Mandatory`,
            },
          });
          return;
        }
    }
    //  else if (label === "email") {
    //   this.setState({
    //     [label]: {
    //       value,
    //       isError:
    //         /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
    //           this.state.email.value
    //         ) === false
    //           ? true
    //           : !value,
    //       errorMessage:
    //         /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
    //           this.state.email.value
    //         ) === false
    //           ? `Enter a valid email address`
    //           : "",
    //     },
    //   });
    // }
    else if (label === "ConfirmSelfPassword") {
      this.setState({
        [label]: {
          value,
          isError:
            this.state.selfPassword.value !==
              this.state.ConfirmSelfPassword.value ||
            this.state.ConfirmSelfPassword.value === ""
              ? true
              : !value,
          errorMessage:
            this.state.selfPassword.value !==
              this.state.ConfirmSelfPassword.value ||
            this.state.ConfirmSelfPassword.value === ""
              ? `The Password and Confirm Password do not match`
              : "",
        },
      });
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };
  closeYesModal = () => {
    this.setState({
      startDate: {
        value: new Date(),
        error: "",
      },
      endDate: { value: new Date(), error: "" },
      ConfirmSelfPassword: { value: "" },
      selfPassword: { value: "" },
      // email: { value: "" },
      open: false,
    });
    this.props.history.replace(
      MAILBACK_URL + "/investor/investor-selection/CGS"
    );
  };
  closeNoModal = () => {
    this.setState({
      open: false,
    });
    this.props.history.replace(BUILD_URL + "/");
  };

  onDateChange = (label, event) => {
    if (label === "endDate" && !!this.state.startDate.value && !!event  )  {
      const reformtStDt = this.state.startDate.value.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });

    const changeEndDt = event.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });


         const d2 = new Date(reformtStDt).setHours(12, 0, 0, 0);
         const d3 = new Date(changeEndDt).setHours(12, 0, 0, 0);
         if ( d2>d3|| d2 === d3) {
           this.setState({
             [label]: {
               value: null,
               error: true,
               errorMessage: "Start Date should be earlier than End Date",
             },
           });
         } else  {
           this.setState({
             [label]: { value: event, errorMessage: "", error: false },
           });
         }
       } else if (label === "startDate") {
         this.setState({
           [label]: { value: event },
           endDate: { value: null, error: "", errorMessage: "" },
         });
       }
    // this.setState({ [label]: { value: event } });
  };

  submit = async () => {
    let startDateValue, endDateValue;

    if (this.state.startDate.value) {
      startDateValue = this.state.startDate.value.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });
    }
    if (this.state.endDate.value) {
      endDateValue = this.state.endDate.value.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });
    }

    const d2 = new Date(this.state.startDate.value).setHours(12, 0, 0, 0);
    const d3 = new Date(this.state.endDate.value).setHours(12, 0, 0, 0);

    const {
      AsDate,
      startDate,
      endDate,
      ConfirmSelfPassword,
      selfPassword,
      showPassword,
      confirmShowPassword,
      email,
      ...others
    } = this.state;

    if (
      !this.state.selfPassword.value ||
      !this.state.ConfirmSelfPassword.value ||
      // !this.state.email.value ||
      // !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      //   this.state.email.value
      // ) ||
      !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.state.selfPassword.value
      ) ||
      !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        this.state.ConfirmSelfPassword.value
      ) ||
      this.state.selfPassword.value !== this.state.ConfirmSelfPassword.value
    ) {
      if (
        !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          this.state.selfPassword.value
        )
      ) {
        this.setState({
          selfPassword: {
            value: "",
            isShowPasswordError: true,
          },
        });
      }
      if (
        !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          this.state.ConfirmSelfPassword.value
        )
      ) {
        this.setState({
          ConfirmSelfPassword: {
            value: "",
            isShowPasswordError: true,
          },
        });
      }
      if (!this.state.selfPassword.value) {
        this.setState({
          selfPassword: {
            value: "",
            isError: true,
            errorMessage: `Password is Mandatory`,
          },
        });
      }
      if (!this.state.ConfirmSelfPassword.value) {
        this.setState({
          ConfirmSelfPassword: {
            value: "",
            isError: true,
            errorMessage: `ConfirmSelfPassword is Mandatory`,
          },
        });
      }
      // if (
      //   !this.state.email.value ||
      //   !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
      //     this.state.email.value
      //   )
      // ) {
      //   if (!this.state.email.value) {
      //     this.setState({
      //       email: {
      //         value: "",
      //         isError: true,
      //         errorMessage: `Email is Mandatory`,
      //       },
      //     });
      //   } else {
      //     this.setState({
      //       email: {
      //         value: "",
      //         isError: true,
      //         errorMessage: `Enter a valid email address`,
      //       },
      //     });
      //   }
      // }
      if (
        !!this.state.ConfirmSelfPassword &&
        !!this.state.selfPassword &&
        this.state.selfPassword.value !== this.state.ConfirmSelfPassword.value
      ) {
        this.setState({
          ConfirmSelfPassword: {
            value: "",
            isError: true,
            errorMessage: `Password does not match`,
          },
        });
      }
    } else if (!this.state.startDate.value) {
      this.setState({
        startDate: {
          value: null,
          error: true,
          errorMessage: "*From Date is Mandatory",
        },
      });
      return;
    } else if (!this.state.endDate.value) {
      this.setState({
        startDate: {
          value: null,
          error: true,
          errorMessage: "*To Date is Mandatory",
        },
      });
      return;
    } else if (d2 > d3 || d2 === d3) {
      this.setState({
        startDate: {
          value: null,
          error: true,
          errorMessage: "(*From Date Should less than to Date)",
        },
      });
    } else {
      const {investorData} = this.props;
      const GetEmail = this.props.communicationDetails.find((ele)=>ele.PAN === this.props.investorData.PAN && !!ele.email)
      const params = {
        fromdt: formatDate(this.state.startDate.value),
        todate: formatDate(this.state.endDate.value),
        trtype: "WBCAP",
        PAN: !!investorData && !!investorData.PAN ?investorData.PAN:"",
        Email: GetEmail.email,
        distCode: this.props.token,
        Password: this.state.selfPassword.value,
        distUid: this.props.userId,
      };
      const response = await this.props.getcaptialgainStatment(params);

      if (response) {
        this.setState({
          open: true,
        });
      }
    }
  };

  render() {
    const { investorData } = this.props;
    const { selfPassword, ConfirmSelfPassword, email } = this.state;
    return (
      <div>
        <MailBackBreadCrumb history={this.props.history} investorData={this.props.investorData} name="Capital Gains Account Statement"/>
        <Paper
         className="right-bar-container-paper"
         style={{display:'flex'}}
        >
          <div className="digital-form-left-div">
            <List investorData={investorData} />
          </div>
          <div className="digital-form-Right-div">
            <div className="transaction-details">
              <div className="title">Capital Gains Account Statement</div>
              <div className="input-margin">
                <Input
                  name="Email ID"
                  onChange={this.handleChangeEmail}
                  value={email.value}
disabled={true}
                  bordercolor="rgba(0, 0, 0, 0.5)"
                  // onBlur={this.showError("email")}
                  // isShowError={email.isError}
                  // errorMessage={email.errorMessage}
                />
              </div>
              <div className="input-margin">
                <Input
                  name="Self Extraction Password"
                  bordercolor="rgba(0, 0, 0, 0.5)"
                  isShowPasswordError={
                    this.state.selfPassword.isShowPasswordError
                  }
                  image2={
                    !!this.state.showPassword
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  type={!!this.state.showPassword ? "text" : "password"}
                  height={15}
                  onClick={() =>
                    this.setState({
                      showPassword: !this.state.showPassword,
                    })
                  }
                  onChange={this.handleChangeSelfPassword}
                  value={selfPassword.value}
                  onBlur={this.showError("selfPassword")}
                  isShowError={selfPassword.isError}
                  errorMessage={selfPassword.errorMessage}

                />
                <div style={{fontSize:14,fontFamily:"SourceSansPro-Regular",marginTop:4}}>
                  Password should be at least 8 characters in length and should contain at least 1 uppercase letter (A-Z), 1 special character and 1 numeric value (0-9)
                  </div>
              </div>
              <div className="input-margin">
                <Input
                  name="Confirm Self Extraction Password"
                  bordercolor="rgba(0, 0, 0, 0.5)"
                  isShowPasswordError={
                    this.state.ConfirmSelfPassword.isShowPasswordError
                  }
                  image2={
                    !!this.state.confirmShowPassword
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  onClick={() =>
                    this.setState({
                      confirmShowPassword: !this.state.confirmShowPassword,
                    })
                  }
                  type={!!this.state.confirmShowPassword ? "text" : "password"}
                  height={15}
                  onChange={this.handleChangeConfirmSelfPassword}
                  value={ConfirmSelfPassword.value}
                  onBlur={this.showError("ConfirmSelfPassword")}
                  isShowError={ConfirmSelfPassword.isError}
                  errorMessage={ConfirmSelfPassword.errorMessage}

                />
              </div>
              <div className="input-margin">
                <div className="input-name">Start Date</div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required={true}
                    className="selects"
                    style={{
                      margin: "0px 0px 20px 0px",
                      border: !!this.state.startDate.error
                        ? "1px solid red"
                        : "1px solid rgba(211,211,211)",

                      borderRadius: !!this.state.startDate.error ? 7 : 0,
                      marginTop:0,
                      marginBottom:10
                    }}
                    onChange={(e) => this.onDateChange("startDate", e)}
                    // openTo={true}
                    disableFuture={true}
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    // animateYearScrolling={true}
                    id="mui-pickers-date"
                    value={this.state.startDate.value}
                  />
                  {!!this.state.startDate.errorMessage ? (
                    <div
                      style={{ color: "red", fontFamily: "Ubuntu-Italic" }}
                    >
                      {this.state.startDate.errorMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </MuiPickersUtilsProvider>
              </div>
              <div className="input-margin">
                <div className="input-name">End Date</div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required={true}
                    className="selects"
                    style={{
                      // margin: "0px 0px 20px 0px",
                      border: !!this.state.endDate.error
                        ? "1px solid red"
                        : "1px solid rgba(211,211,211)",
                      borderRadius: !!this.state.endDate.error ? "7px" : 0,
                    }}
                    onChange={(e) => this.onDateChange("endDate", e)}
                    // openTo={true}
                    disabled={!this.state.startDate.value ? true : false}
                    disableFuture={true}
                    format="dd/MM/yyyy"
                    errorMessage={this.state.endDate.errorMessage}
                    maxDate={new Date()}
                    // animateYearScrolling={true}
                    id="mui-pickers-date"
                    value={this.state.endDate.value}
                  />
                  {!!this.state.endDate.errorMessage ? (
                    <div
                      style={{ color: "red", fontFamily: "Ubuntu-Italic" }}
                    >
                      {this.state.endDate.errorMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </MuiPickersUtilsProvider>
              </div>
              <div className="digital-form-button-next-div">
                <Signbutton buttontitle="Submit" onClick={this.submit} width={150}/>
              </div>
               
              <CustomizedDialogs
                cas={true}
                open={this.state.open}
                closeYesModal={this.closeYesModal}
                closeNoModal={this.closeNoModal}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { investorData, userId, token ,communicationDetails} = authReducer;
    return { investorData, userId, token,communicationDetails };
  },
  { getcaptialgainStatment }
)(CGS);
