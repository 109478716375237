import React, { Component } from "react";
import TabHeader from "../../forms/tabHeader";
import {
  AUTHORIZATION_PENDING,
  AUTHORIZATION_APPROVED,
  FMPS_MATURITY,
  SIPS,
  PHYSICAL_TRANSACTIONS,
  AUTHORIZATION_EXPIRED
} from "shared/store/types";

export default class TransactionSummary extends Component {
  render() {
    const { active } = this.props;
    return (
      <div
      className="tab-container"
      style={{
        justifyContent: "space-between",
        // margin: "25px 20px 15px 20px"
      }}
    >
        <TabHeader
          value="Authorization Pending"
          active={active === AUTHORIZATION_PENDING}
          onClick={this.props.onClick(AUTHORIZATION_PENDING)}
        />
        <TabHeader
          value="Authorization Approved"
          active={active === AUTHORIZATION_APPROVED}
          onClick={this.props.onClick(AUTHORIZATION_APPROVED)}
        />
        <TabHeader
          value="Authorization Expired"
          active={active === AUTHORIZATION_EXPIRED}
          onClick={this.props.onClick(AUTHORIZATION_EXPIRED)}
        />
        {/* <TabHeader
          value="FMPs Maturity"
          active={active === FMPS_MATURITY}
          onClick={this.props.onClick(FMPS_MATURITY)}
        />
        <TabHeader
          value="SIPs"
          active={active === SIPS}
          onClick={this.props.onClick(SIPS)}
        />
        <TabHeader
          value="Physical Transactions"
          active={active === PHYSICAL_TRANSACTIONS}
          onClick={this.props.onClick(PHYSICAL_TRANSACTIONS)}
        /> */}
      </div>
    );
  }
}
