import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import MailBackInvestor from "./investor";
import InvestorSelection from "./InvestorSelection";
import SubmitRequest from "./submitRequest";
import MailBackSoa from "./mailBackSoa";
import Cas from "./consolidatedAccountStatement";
import ELSS from "./ELSS";
import CAN from "./CAN";
import CGS from "./CGS";
import GAS from "./GAS";
import Networth from "./networth";

export default class MailbackRouter extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection`}
          component={InvestorSelection}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/submit-request`}
          component={SubmitRequest}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor`}
          exact
          strict
          component={MailBackInvestor}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/mail-back-soa`}
          exact
          strict
          component={MailBackSoa}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/consolidated-account-statement`}
          exact
          strict
          component={Cas}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/ELSS`}
          exact
          strict
          component={ELSS}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/CAN`}
          exact
          strict
          component={CAN}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/CGS`}
          exact
          strict
          component={CGS}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/GAS`}
          exact
          strict
          component={GAS}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor/investor-selection/Networth`}
          exact
          strict
          component={Networth}
        />
      </Switch>
    );
  }
}
