import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizedSelect from "./customizedSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { YES } from "shared/store/types";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { getNctType, getNctSave } from "shared/store/actions/transactions";
import { Input } from "../../forms/input";
import UploadFile from "../uploadFile";
import Signbutton from "../../forms/button";
import CustomizedDialogs from "../customModal";
import { NEW_TRANSACTION_URL } from ".";

class NctType extends Component {
  constructor(props) {
    super(props);
    const { fundList } = this.props;
    const funds = fundList.reduce((prev, curr) => {
      const findIndex =
        prev.findIndex((fund) => fund.Fund === curr.Fund) !== -1;
      if (!findIndex) {
        return [
          ...prev,
          { Fund: curr.Fund, FundDesc: curr.FundDesc, scheme: curr.Sch },
        ];
      }

      return prev;
    }, []);
    this.state = {
      funds: funds,
      fund: { value: "" },
      ihno: "",
      folios: [],
      folio: { value: "" },
      NctTypes: [],
      NctType: { value: "" },
      emailId: { value: "" },
      mobile: { value: "" },
      upload: { errorMessage: "" },
      file: {},
      submitting: false,
    };
  }

  handleFundChange = (value) => {
    const fund = !!value ? value.value : "";
    if (!value) {
      this.setState({
        fund: { value: "" },
        folios: [],
        ihno: "",
        NctTypes: [],
        NctType: { value: "" },
        folio: { value: "" },
        emailId: { value: "" },
        mobile: { value: "" },
        ihno: "",
        upload: { errorMessage: "" },
        file: {},
        submitting: false,
      });
      return;
    } else if (value.value === this.state.fund.value.value) {
      return;
    } else {
      const folios = this.props.fundList.reduce((prev, curr) => {
        if (curr.Fund === fund) {
          return [...prev, curr.Acno];
        }
        return prev;
      }, []);
      this.setState({
        fund: { value },
        ihno: "",
        NctTypes: [],
        folios: Array.from(new Set(folios)),
        folio: { value: "" },
        NctType: { value: "" },
        emailId: { value: "" },
        mobile: { value: "" },
        upload: { errorMessage: "" },
        file: {},
        submitting: false,
      });
    }
  };

  handleFolioChange = async (value) => {
    const folio = !!value ? value.value : "";
    if (!folio) {
      this.setState({
        folio: { value: "" },
        emailId: { value: "" },
        mobile: { value: "" },
        ihno: "",
        NctType: { value: "" },
        upload: { errorMessage: "" },
        file: {},
        submitting: false,
      });
    } else if (value.value === this.state.folio.value) {
      return;
    } else {
      this.setState({
        folio: { value },
      });
      const params = {
        fund: this.state.fund.value.value,
      };
      const response = await this.props.getNctType(params);
      if (!!response && !!response.DtData && response.DtData.length > 0) {
        this.setState({
          NctTypes: response.DtData,
          ihno: "",
          folio: { value: value },
          upload: { errorMessage: "" },
          file: {},
          submitting: false,
        });
      }
    }
  };

  handleNctTypeChange = async (value) => {
    const nctType = !!value ? value.value : "";
    if (!nctType) {
      this.setState({
        emailId: { value: "" },
        mobile: { value: "" },
        ihno: "",
        NctType: { value: "" },
        upload: { errorMessage: "" },
        file: {},
        submitting: false,
      });
    } else if (value.value === this.state.NctType.value) {
      return;
    } else {
      this.setState({
        NctType: { value },
      });
      const { confirmationData, investorData } = this.props;
      const Xemail = (this.props.communicationDetails || []).find(
        (detail) =>
          detail.Fund.trim() === this.state.fund.value.value.trim() &&
          detail.Acno === this.state.folio.value.value &&
          detail.PAN === investorData.PAN &&
          !!detail.email
      );
      const Xmobile = (this.props.communicationDetails || []).find(
        (detail) =>
          detail.Fund.trim() === this.state.fund.value.value.trim() &&
          detail.Acno === this.state.folio.value.value &&
          detail.PAN === investorData.PAN &&
          !!detail.Mobile
      );
      const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";

      const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";
      this.setState({
        emailId: { value: Email },
        ihno: "",
        mobile: { value: Mobile },
        NctType: { value },
        upload: { errorMessage: "" },
        open: false,
        file: {},
        submitting: false,
      });
    }
  };

  changeValue = (label) => (event) => {
    if (label === "mobile" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label !== "mobile") {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };
  fileUpload = (label) => (e) => {
    if (!!e.target.files.length) {
      const file = e.target.files[0];
      this.setState({
        [label]: file.name,
      });

      var fileReader = new FileReader();
      var base64;
      // Onload of file read the file content
      fileReader.onload = (fileLoadedEvent) => {
        base64 = fileLoadedEvent.target.result;
        // Print data in console
        this.setState({
          file: { value: base64.split(",")[1] },
        });
      };
      // Convert data to base64
      fileReader.readAsDataURL(file);
      this.setState({
        upload: { errorMessage: "Upload Successfully" },
      });
    }
  };

  submit = async () => {
    const {
      upload_file,
      funds,
      open,
      folios,
      ihno,
      submitting,
      NctTypes,
      file,
      upload,
      ...others
    } = this.state;
    const keys = Object.keys(others);
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${key.toUpperCase()} is Mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "emailId": {
          this.emailIdField.focus();
          break;
        }
        case "NctType": {
          this.NctTypeField.focus();
          break;
        }
        case "folio": {
          this.folioField.focus();
          break;
        }
        case "mobile": {
          this.mobileField.focus();
          break;
        }
        case "fund": {
          this.fundField.focus();
          break;
        }
      }
    } else if (
      !!this.state.emailId.value &&
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.state.emailId.value
      ) === false
    ) {
      this.setState({
        emailId: {
          ...others["emailId"],
          isError: true,
          errorMessage: `Enter a vaild Email address`,
        },
      });
      return;
    } else if (
      !!this.state.mobile.value &&
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
        this.state.mobile.value
      ) === false
    ) {
      this.setState({
        mobile: {
          ...others["mobile"],
          isError: true,
          errorMessage: `Enter a vaild Phone number`,
        },
      });
      return;
    } else {
      const payload = {
        email: others.emailId.value,
        folio: this.state.folio.value.value,
        fund: this.state.fund.value.value,
        mobile: this.state.mobile.value,
        pan: this.props.investorData.PAN || "",
        Pdffile: this.state.file.value,
        userid: this.props.userId,
        reqtype: "nct",
        nctdescription: this.state.NctType.value.label.toUpperCase(),
        ncttype: this.state.NctType.value.value,
      };
      this.setState({ submitting: true });

      const response = await this.props.getNctSave(payload);

      if (
        !!response &&
        !!response.DtData &&
        !!response.DtData[0] &&
        !!response.DtData[0].Ihno
      ) {
        this.setState({
          open: true,
          ihno: response.DtData[0].Ihno,
        });
      }
      this.setState({ submitting: false });
    }
  };
  closeModal = () => {
    this.setState({
      open: false,
    });
    this.props.history.replace(NEW_TRANSACTION_URL);
  };

  render() {
    const {
      funds,
      folios,
      fund,
      folio,
      NctTypes,
      NctType,
      emailId,
      mobile,
    } = this.state;
    return (
      <div className="transaction-details" style={{ minHeight: "75vh" }}>
        <div className="title">NCT</div>
        <div className="input-margin">
          <div className="input-name ">Fund</div>
          <IntegrationReactSelect
            suggestions={funds.map((item) => ({
              label: item.FundDesc,
              value: item.Fund,
            }))}
            value={fund.value}
            placeholder="Select Fund"
            handleChange={this.handleFundChange}
            inputRef={(ele) => (this.fundField = ele)}
            isError={fund.isError}
            errorMessage={fund.errorMessage}
          />
        </div>

        <div className="input-margin">
          <div className="input-name ">Folio</div>
          <IntegrationReactSelect
            suggestions={folios.map((item) => ({
              label: item,
              value: item,
            }))}
            value={folio.value}
            placeholder="Select Folio"
            handleChange={this.handleFolioChange}
            inputRef={(ele) => (this.folioField = ele)}
            isError={folio.isError}
            disabled={!fund.value}
            errorMessage={folio.errorMessage}
          />
        </div>

        <div className="input-margin">
          <div className="input-name ">NCT Type</div>
          <IntegrationReactSelect
            suggestions={NctTypes.map((item) => ({
              label: item.nm_description,
              value: item.nm_code,
            }))}
            value={NctType.value}
            placeholder="Select NCT Type"
            handleChange={this.handleNctTypeChange}
            inputRef={(ele) => (this.NctTypeField = ele)}
            isError={NctType.isError}
            disabled={!folio.value}
            errorMessage={NctType.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Email ID"
            value={emailId.value}
            bordercolor="rgba(0, 0, 0, 0.5)"
            onChange={this.changeValue("emailId")}
            // onBlur={this.showError("emailId")}
            isShowError={emailId.isError}
            inputref={(ele) => (this.emailIdField = ele)}
            errorMessage={this.state.emailId.errorMessage}
          />
        </div>
        <div className="input-margin">
          <Input
            name="Mobile No"
            type="number"
            bordercolor="rgba(0, 0, 0, 0.5)"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            value={mobile.value}
            onChange={this.changeValue("mobile")}
            // onBlur={this.showError("mobile")}
            isShowError={mobile.isError}
            errorMessage={mobile.errorMessage}
            inputref={(ele) => (this.mobileField = ele)}
          />
        </div>

        <div className="input-margin">
          {!!this.state.upload.errorMessage ? (
            <div
              style={{
                color:
                  !!this.state.upload.errorMessage &&
                  this.state.upload.errorMessage == "Upload Successfully"
                    ? "green"
                    : "red",
                fontFamily: "Ubuntu-Italic",
                fontSize: 15,
              }}
            >
              {this.state.upload.errorMessage}
            </div>
          ) : (
            ""
          )}
          <UploadFile
            id="upload_file"
            uploadFile={this.fileUpload}
            accept={"application/pdf"}
            fileName={this.state.upload_file}
            isShowError={this.state.file.isError}
          />
          <div className="euin-text">
            only pdf files are allowed, File size to not exceed 24 MB
          </div>

          <div className="digital-form-button-next-div">
            <Signbutton
              buttontitle={this.state.submitting ? "Submitting..." : "Submit"}
              disabled={this.state.submitting}
              onClick={this.submit}
            />
          </div>
          <CustomizedDialogs
            open={this.state.open}
            Nctihno={this.state.ihno}
            closeModal={this.closeModal}
            reqtype={"NCT"}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }, props) => {
    const { location } = props;
    const { investorData, communicationDetails, token, userId } = authReducer;
    //   const { fundsList } = transactions;
    const { fundList } = authReducer;

    return {
      investorData,
      communicationDetails,
      token,

      fundList,
      userId,
    };
  },
  { getNctType, getNctSave }
)(NctType);
