import React, { Component } from "react";
import TransactionRouter from "./TransactionRoutes";
import CommonBreadCrumb from "../breadCrumbs";

export const NEW_TRANSACTION_URL = `${process.env.PUBLIC_URL}/new-transaction`;

const breadcrumbNameMap = {
  "/new-transaction": { title: "New Transaction", to: NEW_TRANSACTION_URL },
  "/new-transaction/new-investor/ekyc": {
    title: "EKYC",
    to: NEW_TRANSACTION_URL + "/new-investor/ekyc",
  },
  "/new-transaction/new-investor": {
    title: "New Investor",
    to: NEW_TRANSACTION_URL,
  },
  "/new-transaction/transction-types": {
    title: "Transaction Type",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/nfo-new-purchase": {
    title: "New Purchase",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  // "/new-transaction/nfo-new-purchase/transaction-modes": {
  //   title: "Mode of Transaction",
  //   to: NEW_TRANSACTION_URL + "/nfo-new-purchase/transaction-modes"
  // },
  "/new-transaction/nfo-new-purchase/physical": {
    title: "Phygital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
    // to: NEW_TRANSACTION_URL + "/nfo-new-purchase/transaction-modes"
  },
  "/new-transaction/nfo-new-purchase/physical/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/nfo-new-purchase/physical/transact",
  },
  "/new-transaction/additional-purchase/physical": {
    title: "Phygital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
    // to: NEW_TRANSACTION_URL + "/additional-purchase/transaction-modes"
  },
  "/new-transaction/additional-purchase/physical/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/additional-purchase/physical/transact",
  },

  "/new-transaction/redemption/physical": {
    title: "Phygital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
    // to: NEW_TRANSACTION_URL + "/redemption/transaction-modes"
  },
  "/new-transaction/redemption/physical/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/redemption/physical/transact",
  },
  "/new-transaction/switch/physical": {
    title: "Phygital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
    // to: NEW_TRANSACTION_URL + "/switch/transaction-modes"
  },
  "/new-transaction/switch/physical/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/switch/physical/transact",
  },
  "/new-transaction/sip/physical": {
    title: "Phygital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
    // to: NEW_TRANSACTION_URL + "/sip/transaction-modes"
  },
  "/new-transaction/sip/physical/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/sip/physical/transact",
  },

  "/new-transaction/nfo-new-purchase/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/nfo-new-purchase/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/nfo-new-purchase/digital/transact",
  },
  "/new-transaction/nfo-new-purchase/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/nfo-new-purchase/digital/transact/confirm",
  },

  "/new-transaction/additional-purchase": {
    title: "Additional Purchase",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/additional-purchase/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/additional-purchase/transaction-modes",
  },
  "/new-transaction/additional-purchase/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/additional-purchase/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/additional-purchase/digital/transact",
  },
  "/new-transaction/additional-purchase/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/additional-purchase/digital/transact/confirm",
  },

  "/new-transaction/redemption": {
    title: "Redemption",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/redemption/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/redemption/transaction-modes",
  },
  "/new-transaction/redemption/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/redemption/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/redemption/digital/transact",
  },
  "/new-transaction/redemption/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/redemption/digital/transact/confirm",
  },

  "/new-transaction/switch": {
    title: "SWITCH",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/switch/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/switch/transaction-modes",
  },
  "/new-transaction/switch/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/switch/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/switch/digital/transact",
  },
  "/new-transaction/switch/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/switch/digital/transact/confirm",
  },

  "/new-transaction/sip": {
    title: "SIP",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/sip/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/sip/transaction-modes",
  },
  "/new-transaction/sip/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/sip/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/sip/digital/transact",
  },
  "/new-transaction/sip/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/sip/digital/transact/confirm",
  },

  "/new-transaction/swp": {
    title: "SWP",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/swp/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/swp/transaction-modes",
  },
  "/new-transaction/swp/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/swp/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/swp/digital/transact",
  },
  "/new-transaction/swp/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/swp/digital/transact/confirm",
  },

  "/new-transaction/stp": {
    title: "STP",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/stp/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/stp/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/stp/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/stp/digital/transact",
  },
  "/new-transaction/stp/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/stp/digital/transact/confirm",
  },

  "/new-transaction/sip-cancellation": {
    title: "SIP Cancellation",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/sip-cancellation/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/sip-cancellation/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/sip-cancellation/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/sip-cancellation/digital/transact",
  },
  "/new-transaction/sip-cancellation/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/sip-cancellation/digital/transact/confirm",
  },
  "/new-transaction/nct-type": {
    title: "NCT",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/nct-type/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/nct-type/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/nct-type/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/nct-type/digital/transact",
  },
  "/new-transaction/nct-type/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/nct-type/digital/transact/confirm",
  },

  "/new-transaction/eNACH": {
    title: "eNACH",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/eNACH/transaction-modes": {
    title: "Mode of Transaction",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/eNACH/digital": {
    title: "Digital Mode",
    to: NEW_TRANSACTION_URL + "/transction-types",
  },
  "/new-transaction/eNACH/digital/transact": {
    title: "Transact",
    to: NEW_TRANSACTION_URL + "/eNACH/digital/transact",
  },
  "/new-transaction/eNACH/digital/transact/confirm": {
    title: "Confirm",
    to: NEW_TRANSACTION_URL + "/eNACH/digital/transact/confirm",
  },
};

export default class Transactions extends Component {
  render() {
    const { location } = this.props;
    const pathnames = location.pathname.includes("/ekyc")
      ? location.pathname
          .split("/")
          .filter((name) => name)
          .slice(0, 2)
      : location.pathname.split("/").filter((name) => name);
    return (
      <div>
        <CommonBreadCrumb
          hideFirst={true}
          pathnames={pathnames}
          breadcrumbNameMap={breadcrumbNameMap}
        />
        <TransactionRouter />
      </div>
    );
  }
}
