import React, { Component } from "react";
import Signbutton from "../forms/button";
import { OtpInput } from "../forms/input";
import { registration, generateOtp } from "shared/store/actions";
import { connect } from "react-redux";
import { BUILD_URL } from "../home/home";
import CommonTemplate from "./commonTemplate";
class OTP extends Component {
  constructor(props) {
    super(props);
    const { OTPMsg, UserId, ARNCode, password } =
      !!this.props.location.state && !!this.props.location.state.payload
        ? this.props.location.state.payload
        : {};
    this.state = {
      otpMsg: OTPMsg,
      UserId,
      ARNCode,
      password
    };
  }

  regenerteOtp = async () => {
    const { UserId, ARNCode } = this.state;
    const OTPMsg = Math.floor(100000 + Math.random() * 900000);
    const params = {
      UserId,
      ARNCode,
      OTPMsg
    };
    const response = await this.props.generateOtp(params);
    if (response) {
      this.setState({
        otpMsg: OTPMsg
      });
      alert("An Otp has been resent to your registered Mobile Number");
    }
  };

  submitOtp = async enteredOtp => {
    const { otpMsg, UserId, password, ARNCode } = this.state;
    if (Number(enteredOtp.join("")) === otpMsg) {
      const params = {
        Username: UserId,
        DistCode: ARNCode,
        Password: password
      };
      const response = await this.props.registration(params);
      if (response) {
        this.props.history.push(BUILD_URL + "/login");
      }
    } else {
      alert("Please enter valid otp");
    }
  };

  render() {
    return (
      <CommonTemplate
        title="Sign Up"
        textOne="An OTP has been sent to your Registered Phone number."
        textTwo="Please Check the OTP below"
      >
        <CommonOtp
          submitOtp={this.submitOtp}
          regenerteOtp={this.regenerteOtp}
        />
      </CommonTemplate>
    );
  }
}

export default connect(
  null,
  { registration, generateOtp }
)(OTP);

export class CommonOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredOtp: ["", "", "", "", "", ""],
      errorMessage: ""
    };
  }

  changeValue = index => event => {
    const { enteredOtp } = this.state;
    if (event.target.value.length <= 1) {
      this.setState({
        enteredOtp: [
          ...enteredOtp.slice(0, index),
          event.target.value,
          ...enteredOtp.slice(index + 1)
        ]
      });
      if (index !== 5 && !!event.target.value) {
        this[`textInput${index + 1}`].focus();
      }
    }
  };

  keyPress = e => {
    if (e.key === "Enter") {
      this.props.submitOtp(this.state.enteredOtp);
      e.preventDefault();
    }
  };

  keyDown = index => event => {
    const key = event.keyCode || event.charCode;
    const { enteredOtp } = this.state;
    if (key === 8 || key === 46) {
      if (!enteredOtp[index]) {
        this[`textInput${index - 1}`].focus();
        event.preventDefault();
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="otp">
          {Array(6)
            .fill(0)
            .map((item, index) => (
              <OtpInput
                value={this.state.enteredOtp[index]}
                key={index}
                onChange={this.changeValue(index)}
                inputref={ele => (this[`textInput${index}`] = ele)}
                onKeyPress={this.keyPress}
                onKeyDown={this.keyDown(index)}
              />
            ))}
        </div>
        <Signbutton
          buttontitle="Submit"
          onClick={() => {
            this.props.submitOtp(this.state.enteredOtp);
            this.setState({ enteredOtp: ["", "", "", "", "", ""] });
          }}
        />
        <div
          className="regenerate_otp mouse-pointer"
          onClick={() => {
            this.props.regenerteOtp();
            this.setState({
              enteredOtp: ["", "", "", "", "", ""],
              errorMessage:
                "OTP has been resent to registered Email ID and Mobile Number"
            });
          }}
        >
          REGENERATE OTP
        </div>
        <div
          style={{
            color: "black",
            fontSize: 15,
            fontFamily: "SourceSansPro-SemiBold"
          }}
        >
          {!!this.state.errorMessage ? this.state.errorMessage : ""}
        </div>
      </React.Fragment>
    );
  }
}
