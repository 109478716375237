import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { USER_LOGIN_SUCCESS, USER_LOGOUT, REMEMBER_ME, USER_PAN, USER_LOGIN_REFRESH_SUCCESS } from "../types/auth";
import { GET_INVESTOR_DETAILS, GET_INVESTOR_DETAILS_SUCCESS, FIRST_TIME_INSTALLATION_SUCCESS, AUTO_SELECT, POSTER_OPEN } from "../types";
var initialState = {
  token: null,
  showAll: false,
  remeberMe: false,
  posterStatus: 0,
  tran_enable_flag: "",
  lastLogin: "",
  installed: null,
  auth_token: null,
  dist_force_update: "",
  dist_ver: "",
  dist_ver_update_text: "",
  user_pan: "",
  autoSelect: {}
};
export function authReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      {
        var _action$body = action.body,
            DtData = _action$body.DtData,
            Dtinformation = _action$body.Dtinformation,
            token = _action$body.token;

        var _DtData = _slicedToArray(DtData, 1),
            data = _DtData[0];

        var ARNCode = data.ARNCode,
            Login_MailID = data.Login_MailID,
            Regflag = data.Regflag,
            dist_force_update = data.dist_force_update,
            dist_update_text = data.dist_update_text,
            tran_enable_flag = data.tran_enable_flag,
            dist_ver = data.dist_ver;
        return _objectSpread({}, state, {
          token: ARNCode,
          auth_token: token,
          userId: Login_MailID,
          tran_enable_flag: tran_enable_flag,
          lastLogin: Dtinformation[0].last_login,
          dist_force_update: dist_force_update,
          dist_update_text: dist_update_text,
          dist_ver: dist_ver,
          Regflag: Regflag
        });
      }

    case POSTER_OPEN:
      {
        return _objectSpread({}, state, {
          posterStatus: action.body
        });
      }

    case USER_LOGIN_REFRESH_SUCCESS:
      {
        return _objectSpread({}, state, {
          auth_token: action.body.token
        });
      }

    case USER_PAN:
      {
        return _objectSpread({}, state, {
          user_pan: action.body
        });
      }

    case GET_INVESTOR_DETAILS:
      {
        return _objectSpread({}, state, {
          investorData: {},
          showAll: false,
          communicationDetails: [],
          bankDetails: [],
          fundList: []
        });
      }

    case GET_INVESTOR_DETAILS_SUCCESS:
      {
        var _action$body2 = action.body,
            communicationDetails = _action$body2.Table14,
            bankDetails = _action$body2.Table7,
            Table11 = _action$body2.Table11,
            fundList = _action$body2.Table14;

        var _Table = _slicedToArray(Table11, 1),
            investorData = _Table[0];

        return _objectSpread({}, state, {
          showAll: true,
          investorData: investorData,
          communicationDetails: communicationDetails,
          bankDetails: bankDetails,
          fundList: fundList
        });
      }

    case AUTO_SELECT:
      {
        return _objectSpread({}, state, {
          autoSelect: action.body
        });
      }

    case FIRST_TIME_INSTALLATION_SUCCESS:
      {
        return _objectSpread({}, state, {
          installed: action.installed
        });
      }

    case USER_LOGOUT:
      {
        return initialState; // installed: state.installed,
        // remeberMe: state.remeberMe,
      }

    case REMEMBER_ME:
      {
        return {
          initialState: initialState,
          remeberMe: action.value
        };
      }

    default:
      return state;
  }
}