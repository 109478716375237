import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Transactions from "./TransactionMain";
import TransactionType from "./TransactionTypes";
import TransactionMode from "./TransactionModes";
import NFOAndNewPurchaseDigital from "./nfoAndNewPurchase";
import EKYC from "./ekycForm";

export default class TransactionRouter extends Component {
  render() {
    const { history, location } = this.props;
    return (
      <Switch location={location} history={history}>
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction`}
          exact
          component={Transactions}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/new-investor/ekyc/:pan`}
          component={EKYC}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/transction-types`}
          component={TransactionType}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/nfo-new-purchase/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/nfo-new-purchase/digital`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/nfo-new-purchase/physical`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/additional-purchase/physical`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/redemption/physical`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/switch/physical`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/sip/physical`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/additional-purchase/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/additional-purchase/digital`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/redemption/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/redemption/digital`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/switch/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/switch/digital`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/sip/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/sip/digital`}
          component={NFOAndNewPurchaseDigital}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/swp/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/swp/digital`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/stp/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/stp/digital`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/sip-cancellation/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/sip-cancellation/digital`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/nct-type/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/nct-type/digital`}
          component={NFOAndNewPurchaseDigital}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-transaction/eNACH/transaction-modes`}
          component={TransactionMode}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction/eNACH/digital`}
          component={NFOAndNewPurchaseDigital}
        />
      </Switch>
    );
  }
}
