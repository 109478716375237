import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { dashboardStyles } from "../appCss";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { BUILD_URL } from "../home";
import { connect } from "react-redux";
import CustomLoading from "../CustomLoader";

class DisplayCards extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Card
        className="display-cards-paper"
        style={{
          transition: "0.3s",
          margin: "10px 0px",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0px 0px 0px 0px",
        }}
      >
        <div className="display-cards">
          <img
            src={`${BUILD_URL}${this.props.image}`}
            className="image"
            alt={this.props.name}
          />
        </div>
        <div className="cards-details">
          <CardContent className="cards-content">
            <div className="cards-name" >
              {this.props.name}
            </div>
            {!!this.props.values ? (
              <div className="cards-value">{this.props.values}</div>
            ) : (
              <CustomLoading
                type={this.props.count > 0 ? "ThreeDots" : ""}
                width={this.props.count > 0 ? 25 : 0}
                height={this.props.count > 0 ? 25 : 0}
              />
            )}
          </CardContent>
        </div>
      </Card>
    );
  }
}

DisplayCards.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default connect(({ loading }) => {
  const { count } = loading;
  return { count };
})(withStyles(dashboardStyles)(DisplayCards));
