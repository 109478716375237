import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import CustomLoading from "../CustomLoader";
import { FormatCurrency } from "../currencyFormatter";
import { ContactMail } from "@material-ui/icons";

class TableContainer extends Component {
  render() {
    const { summaryData } = this.props;
    return (
      <React.Fragment>
        {!!summaryData.length ? (
          <Paper className="trasacation-summary-responsive">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 20
              }}
              
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "30px 10px 5px 20px",
                      color: "#2f3953",
                      fontSize: 15,
                      fontFamily: "SourceSansPro-SemiBold",
                      textAlign: "center"
                    }}
                  >
                    INVESTOR
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "30px 10px 5px 20px",
                      color: "#2f3953",
                      fontSize: 15,
                      fontFamily: "SourceSansPro-SemiBold",
                      textAlign: "center"
                    }}
                  >
                    CONTACT
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "30px 10px 5px 20px",
                      color: "#2f3953",
                      fontSize: 15,
                      fontFamily: "SourceSansPro-SemiBold",
                      textAlign: "center"
                    }}
                  >
                    SCHEME
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "30px 10px 5px 20px",
                      color: "#2f3953",
                      fontSize: 15,
                      fontFamily: "SourceSansPro-SemiBold",
                      textAlign: "center"
                    }}
                  >
                    TYPE
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "30px 10px 5px 20px",
                      color: "#2f3953",
                      fontSize: 15,
                      fontFamily: "SourceSansPro-SemiBold",
                      textAlign: "center"
                    }}
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "30px 10px 5px 20px",
                      color: "#2f3953",
                      fontSize: 15,
                      fontFamily: "SourceSansPro-SemiBold",
                      textAlign: "center"
                    }}
                  >
                    AMOUNT/UNITS
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      className="table-cell "
                      style={{
                        padding: "30px 10px 30px 20px",
                        color: "#2f3953",
                        fontSize: 15,
                        fontFamily: "SourceSansPro-Regular",
                        textAlign: "center"
                      }}
                    >
                      {item.InvName}
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        padding: "30px 10px  30px 20px",
                        color: "#2f3953",
                        fontSize: 15,
                        fontFamily: "SourceSansPro-Regular",
                        textAlign: "center"
                      }}
                    >
                      {item.mobile}
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        padding: "30px 10px 30px 20px ",
                        color: "#2f3953",
                        fontSize: 15,
                        fontFamily: "SourceSansPro-Regular",
                        textTransform: "UpperCase",
                        textAlign: "center"
                      }}
                    >
                      {item.scheme}
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        padding: "30px 10px 30px 20px",
                        color: "#2f3953",
                        fontSize: 15,
                        fontFamily: "SourceSansPro-Regular",
                        textAlign: "center"
                      }}
                    >
                      {item.trtype}
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        width:"13%",
                        padding: "30px 10px 30px 20px",
                        color: "#2f3953",
                        fontSize: 15,
                        fontFamily: "SourceSansPro-Regular",
                        textAlign: "center"
                      }}
                    >
                      {item.Entdate}
                    </TableCell>
                    <TableCell
                      className="table-cell"
                      style={{
                        padding: "30px 10px 30px 20px",
                        color: "#2f3953",
                        fontSize: 15,
                        fontFamily: "SourceSansPro-Regular",
                        textAlign: "center"
                      }}
                    >
                      <FormatCurrency
                        value={
                          (item.trtype === "SIP" ? item.sipamount : item.amount)||0
                        }
                      />
                      /{!!item.units ? item.units : 0}
                    </TableCell>
                    {/* {pathname ===
              "/dashboard/transaction-summary/physical-transactions" ? (
                <TableCell
                  className="table-cell"
                  style={{
                    padding: "30px 10px 30px 20px",
                    color: "#2f3953",
                    fontSize: 15,
                    fontFamily: "SourceSansPro-Regular"
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#00579b",
                      padding: "7px 15px",
                      color: "white",
                      borderRadius: 3,
                      fontSize: 12,
                      fontFamily: "SourceSansPro-Regular"
                    }}
                  >
                    Re initate
                  </span>
                </TableCell>
              ) : (
                ""
              )} */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ) : null
            }
        <div className="mobile-aum-list">
          {summaryData.length > 0 ? (
            summaryData.map((ele, index) => (
              <InvestorTableCard
                key={index}
                InvName={ele.InvName}
                contact={ele.mobile}
                scheme={ele.scheme}
                type={ele.trtype}
                date={ele.Entdate}
                amount={ele.amount}
              />
            ))
          ) : (
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 14,
                textAlign: "center",
                color: "red",
              }}
            >
              {" "}
              No Data Found
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(({ dashboard, loading }) => {
  const { summaryData } = dashboard;
  const { count } = loading;
  return { summaryData, count };
})(TableContainer);
export class InvestorTableCard extends Component {
  render() {
    const { InvName, contact, scheme, type,date,amount } = this.props;
    return (
      <div
        style={{
          background: "white",
          border: "1px solid rgba(211,211,211,0.3)",
          padding: "7px 10px",
          margin: "10px 0px",
        }}
      >
        <div
          style={{
            fontFamily: "SourceSansPro-SemiBold",
            color: "#00579b",
            fontSize: 14,
            paddingBottom: 5,
            borderBottom: "1px solid rgba(211,211,211,0.3)",
          }}
        >
          {scheme}
        </div>
      
        <div style={{ display: "flex", padding: "8px 0px" }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
              Name
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!InvName ? InvName : "NA"}
            </div>
          </div>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
              Date
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!date ? date : "NA"}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", padding: "8px 0px" }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
             Type
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!type ? type : "NA"}
            </div>
          </div>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
              Contact
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!contact ? contact : "NA"}
            </div>
          </div>
        </div>
        <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 12 }}>
              Amount
            </div>
            <div
              style={{
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 13,
                color: "#293139",
              }}
            >
              {!!amount ? amount : "NA"}
            </div>
       
       
      </div>
    );
  }
}

