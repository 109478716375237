import React, { Component } from "react";
import Search from "../searchComponent";
import { ALL, FAVOURITE, RECENT } from "shared/store/types";
import TableData from "../tableData";
import { connect } from "react-redux";
import { getInvestors, storeType } from "shared/store/actions";
import TabHeader from "../../forms/tabHeader";

class InvestorData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: ALL,
      activeText: "All"
    };
  }

  async componentDidMount() {
    const response = await this.props.getInvestors({
      ARN: this.props.token,
      AllFavFlag: ALL
    });
  }

  selectTab = tabName => async () => {
    this.props.storeType(tabName);
    this.setState({
      activeTab: tabName,
      activeText:
        tabName === ALL ? "All" : tabName === RECENT ? "Recent" : "Favourite"
    });

    await this.props.getInvestors({
      ARN: this.props.token,
      AllFavFlag: tabName
    });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div className="investor-serach-container">
        <Search history={this.props.history} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
          className="all-investors-view"
        >
          <div
            className="tab-container"
            style={{
              justifyContent: "flex-start",
              margin: "25px 20px 15px 20px",
              borderBottom:"0px",
              flexGrow:1,
              flexBasis:0
            }}
          >
            <TabHeader
              value="ALL"
              active={activeTab === ALL}
              onClick={this.selectTab(ALL)}
            />
            <TabHeader
              value="FAVOURITES"
              active={activeTab === FAVOURITE}
              onClick={this.selectTab(FAVOURITE)}
            />
            <TabHeader
              value="RECENT"
              active={activeTab === RECENT}
              onClick={this.selectTab(RECENT)}
            />
          </div>
          <div
            style={{
              color: "#00579B",
              fontSize: 14,
              fontFamily: "SourceSansPro-Bold",
              backgroundColor: "#EFFCF7",
              padding: "7px 30px"
            }}
          >
            {this.state.activeText}: {this.props.count} Investors
          </div>
        </div>
        <TableData
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer, investor }) => {
    const { token } = authReducer;
    const { count } = investor;
    return { token, count };
  },
  { getInvestors, storeType }
)(InvestorData);
