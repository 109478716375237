import React, { Component } from "react";
import CustomizedSelect from "./customizedSelect";
import { Input } from "../../forms/input";
import Signbutton, { Debtbutton } from "../../forms/button";
import { connect } from "react-redux";
import {
  YES,
  PARTIAL,
  FULL,
  UNITS,
  AMOUNT,
  PURCHASE,
  REDEMPTION,
} from "shared/store/types";
import MenuItem from "@material-ui/core/MenuItem";
import {
  getBankDetails,
  getRedemptionPaymentDetails,
} from "shared/store/actions/transactions";
import moment from "moment";

import IntegrationReactSelect from "../../forms/autoCompleteInput";

import { INVESTOR_URL } from "../investors";

class Redemption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // funds,
      fund: { value: { value: "105" } },
      folios: [],
      folio: { value: "" },
      schemes: [],
      scheme: { value: "" },
      bank: { value: "", errorMessage: "" },
      amount: { value: "" },
      redemptionType: PARTIAL,
      redemptionMode: UNITS,
      banks: [],
    };
  }

  handleFundChange = (value) => {
    const fund = !!value ? value.value : "";
    if (!value) {
      this.setState({
        fund: { value: "" },
        folios: [],
        scheme: { value: "" },
        folio: { value: "" },
        redemptionType: PARTIAL,
        redemptionMode: UNITS,
        amount: { value: "" },
      });
      return;
    } else if (value.value === this.state.fund.value.value) {
      return;
    } else {
      const folios = this.props.fundList.reduce((prev, curr) => {
        if (curr.rSchFlg === YES && curr.Fund === fund) {
          return [...prev, curr.Acno];
        }
        return prev;
      }, []);
      this.setState({
        fund: { value },
        folios: Array.from(new Set(folios)),
        scheme: { value: "" },
        folio: { value: "" },
        redemptionType: PARTIAL,
        redemptionMode: UNITS,
      });
    }
  };

  async componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    this.getfolios();
  }

  getfolios = () => {
    const folios = this.props.fundList.reduce((prev, curr) => {
      if (curr.rSchFlg === YES && curr.Fund.trim() === "105") {
        return [...prev, curr.Acno];
      }
      return prev;
    }, []);
    this.setState({
      fund: { value: { value: "105" } },
      folios: Array.from(new Set(folios)),
      scheme: { value: "" },
      folio: { value: "" },
      redemptionType: PARTIAL,
      redemptionMode: UNITS,
    });
  };

  handleFolioChange = async (value) => {
    const folio = !!value ? value.value : "";
    if (!folio) {
      this.setState({
        folio: { value: "" },
        scheme: { value: "" },
        schemes: [],
        banks: [],
        bank: { value: "" },
        redemptionType: PARTIAL,
        redemptionMode: UNITS,
        amount: { value: "" },
      });
    } else if (value.value === this.state.folio.value) {
      return;
    } else {
      const params = {
        Acno: folio,
        Trtype: "",
        Fund: "105",
      };
      let schemes = this.schemes(folio);
      schemes = schemes.filter((scheme) => scheme.BalUnits !== 0);
      const response = await this.props.getBankDetails(params);

      if (response) {
        const banks = response.Table1;
        this.setState({
          folio: {
            value,
            isError: !schemes.length,
            errorMessage: !schemes.length
              ? "Redemption in zero balance folios is not allowed"
              : "",
          },
          scheme: { value: "" },
          schemes,
          banks,
          bank: { value: "" },
          redemptionType: PARTIAL,
          redemptionMode: UNITS,
        });
      }
    }
  };

  schemes = (folio) => {
    return this.props.fundList.reduce((prev, curr) => {
      if (
        curr.rSchFlg === YES &&
        curr.Fund === this.state.fund.value.value &&
        curr.Acno === folio &&
        !!curr.BalUnits
      ) {
        return [...prev, curr];
      }
      return prev;
    }, []);
  };
  handleSchemeChange = (value) => {
    const scheme = !!value ? value.value : "";

    if (!scheme) {
      this.setState({
        scheme: { value: "" },
        minAmount: "",
        amount: { value: "" },
        redemptionType: PARTIAL,
        redemptionMode: UNITS,
      });
    } else if (value.value === this.state.scheme.value.value) {
      return;
    } else {
      this.setState({
        scheme: { value },
        minAmount: !!scheme ? scheme.RedMinAmt || scheme.fm_newpur : "",
        amount: { value: "" },
        redemptionType: PARTIAL,
        redemptionMode: UNITS,
      });
    }
  };

  showError = (label) => () => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "amount") {
      if (!!this.state.scheme && !!this.state.scheme.value) {
        this.setState({
          [label]: {
            value:
              this.state.redemptionMode === AMOUNT &&
              this.state.scheme.value.value.RedMinAmt > this.state.amount.value
                ? ""
                : this.state.redemptionMode === AMOUNT &&
                  this.state.amount.value >
                    parseFloat(
                      this.state.scheme.value.value.CurValue.replace(/,/g, "")
                    )
                ? ""
                : this.state.redemptionMode === UNITS &&
                  this.state.scheme.value.value.BalUnits <
                    this.state.amount.value
                ? ""
                : this.state.amount.value,
            isError:
              this.state.redemptionMode === AMOUNT &&
              this.state.scheme.value.value.RedMinAmt > this.state.amount.value
                ? true
                : this.state.redemptionMode === AMOUNT &&
                  this.state.amount.value >
                    parseFloat(
                      this.state.scheme.value.value.CurValue.replace(/,/g, "")
                    )
                ? true
                : this.state.redemptionMode === UNITS &&
                  this.state.scheme.value.value.BalUnits <
                    this.state.amount.value
                ? true
                : !value,
            errorMessage:
              this.state.redemptionMode === AMOUNT &&
              this.state.scheme.value.value.RedMinAmt > this.state.amount.value
                ? `Minimum amount is ₹ ${this.state.scheme.value.value.RedMinAmt} `
                : this.state.redemptionMode === AMOUNT &&
                  parseFloat(this.state.amount.value) >
                    parseFloat(
                      this.state.scheme.value.value.CurValue.replace(/,/g, "")
                    )
                ? `Maximum amount you can redeem is ₹ ${this.state.scheme.value.value.CurValue} `
                : this.state.scheme.value.value.BalUnits <
                  this.state.amount.value
                ? `You don't have enough Units To Redeem!`
                : "",
          },
        });
      }
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };
  handleRedemptionValueChange(event) {
    const { redemptionMode, redemptionType } = this.state;
    if (event.target.value.length <= 10) {
      if (redemptionMode === UNITS && redemptionType === PARTIAL) {
        if (
          /^[0-9]*$/.test(event.target.value) ||
          /[-+]?[0-9]*(\.[0-9]{0,3}$)/.test(event.target.value)
        ) {
          this.setState({
            amount: { value: event.target.value },
          });
        }
      } else {
        this.setState({
          amount: { value: event.target.value.replace(/^0+/, "") },
        });
      }
    }
  }

  changeRedemptionType = (value) => () => {
    if (value === FULL) {
      this.setState({
        redemptionType: FULL,
        redemptionMode: UNITS,
        amount: {
          value: !!this.state.scheme.value
            ? this.state.scheme.value.value.BalUnits
            : "",
        },
      });
    }
    if (value === PARTIAL) {
      this.setState({
        redemptionType: PARTIAL,
        amount: { value: "" },
      });
    }
  };
  changeRedemptionMode = (value) => () => {
    if (value === AMOUNT) {
      this.setState({
        redemptionMode: AMOUNT,
        amount: { value: "" },
      });
    }
    if (value === UNITS) {
      this.setState({
        redemptionMode: UNITS,
        amount: { value: "" },
      });
    }
  };
  handlefolioBankDetailsChange = (event) => {
    this.setState({
      bank: { value: event.target.value },
    });
  };

  submit = async () => {
    const {
      funds,
      banks,
      schemes,
      folios,
      redemptionType,
      redemptionMode,
      minAmount,
      fund,
      bank,
      ...others
    } = this.state;
    if (this.state.bank.value === "") {
      this.setState({
        bank: {
          value: "",
          isError: true,
          errorMessage: "Bank details are Mandatory*",
        },
      });
    }
    let currValue;
    if (!!this.state.scheme.value) {
      currValue = !!this.state.scheme.value.value
        ? this.state.scheme.value.value.Fund_CurValue
        : "";
    }
    const keys = Object.keys(others);
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "folio"
                  ? "Folio"
                  : key === "scheme"
                  ? "Scheme"
                  : key === "amount"
                  ? "Redemption value"
                  : key
              } is Mandatory*`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        // case "fund": {
        //   this.fundField.focus();
        //   break;
        // }
        case "folio": {
          this.folioField.focus();
          break;
        }
        case "scheme": {
          this.schemeField.focus();
          break;
        }
        case "amount": {
          this.amountField.focus();
          break;
        }
        // case "bank": {
        //   this.bankField.focus();
        //   break;
        // }
      }
    } else if (
      this.state.redemptionMode === AMOUNT &&
      this.state.scheme.value.value.RedMinAmt > this.state.amount.value
    ) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `Minimum amount shoud be ₹ ${this.state.scheme.value.value.RedMinAmt} `,
        },
      });
    } else if (
      this.state.redemptionMode !== AMOUNT &&
      this.state.scheme.value.value.BalUnits < this.state.amount.value
    ) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `You dont have enough points to redeem`,
        },
      });
    } else if (parseInt(this.state.amount.value) < 2) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `Invalid Redemption Value`,
        },
      });
    } else if (this.state.bank.value === "") {
      this.setState({
        bank: {
          ...others["bank"],
          isError: true,
          errorMessage: `Please select the bank`,
        },
      });
      this.amountField.focus();
      return;
    } else {
      const params = {
        amount: redemptionMode === AMOUNT ? others.amount.value : "0",
        Fund: "105",
        plan: others.scheme.value.value.Pln,
        scheme: others.scheme.value.value.Sch,
        acno: others.scheme.value.value.Acno,
        trdt: moment(new Date()).format("MM/DD/YYYY"),
        units: redemptionMode === UNITS ? others.amount.value : "0",
        ttrtype: PURCHASE,
      };
      const response = await this.props.getRedemptionPaymentDetails(params);
      if (response) {
        this.props.history.push({
          pathname: INVESTOR_URL + "/transction-types/redemption/confirm",
          state: {
            title: "Redemption Payment Summary",
            type: REDEMPTION,
            scheme: others.scheme.value.value,
            redemptionMode,
            Folio: { value: others.scheme.value.value.Acno },
            redemptionType,
            amount: others.amount.value,
            bank: this.state.bank.value,
          },
        });
      }
    }
  };

  render() {
    const {
      fund,
      funds = [],
      folio,
      folios = [],
      schemes = [],
      scheme,
      bank,
      banks,
      amount,
      redemptionType,
      redemptionMode,
    } = this.state;

    const checkMultiBrokerFlag =
      !!this.state.scheme &&
      !!this.state.scheme.value &&
      !!this.state.scheme.value.value &&
      !!this.state.scheme.value.value.multibrokflg &&
      this.state.scheme.value.value.multibrokflg === "Y";

    return (
      <div className="transaction-details">
        <div className="title">Redemption</div>

        <div className="input-margin">
          <div className="input-name ">Folio*</div>
          <IntegrationReactSelect
            suggestions={folios.map((item) => ({
              label: item,
              value: item,
            }))}
            value={folio.value}
            placeholder="Select Folio"
            handleChange={this.handleFolioChange}
            inputRef={(ele) => (this.folioField = ele)}
            isError={folio.isError}
            errorMessage={folio.errorMessage}
          />
        </div>

        <div className="input-name">
          <div className="input-name ">Scheme*</div>
          <IntegrationReactSelect
            suggestions={schemes.map((item) => ({
              label: item.PlnDesc
                ? `${item.SchDesc1} - ${item.PlnDesc} - ${item.OptDesc}`
                : item.fm_schdesc,
              value: item,
            }))}
            value={scheme.value}
            placeholder="Select Scheme"
            handleChange={this.handleSchemeChange}
            inputRef={(ele) => (this.schemeField = ele)}
            isError={scheme.isError}
            disabled={!folio.value}
            errorMessage={scheme.errorMessage}
          />
        </div>

        <div className="redemption-details">
          <div className="keys">
            {!checkMultiBrokerFlag && <div>Balance Units</div>}
            {!checkMultiBrokerFlag && <div>Current Value</div>}
            <div>
              NAV as on {!!scheme.value ? scheme.value.value.LastNavDate : ""}
            </div>
            <div>Minimum Amount</div>
          </div>
          <div className="values">
            {!checkMultiBrokerFlag && (
              <div>{!!scheme.value ? scheme.value.value.BalUnits : ""}</div>
            )}
            {!checkMultiBrokerFlag && (
              <div>
                {!!scheme.value ? `₹ ${scheme.value.value.CurValue}` : ""}
              </div>
            )}
            <div>{!!scheme.value ? scheme.value.value.LastNAV : ""}</div>
            <div>
              {!!scheme.value ? `₹ ${scheme.value.value.RedMinAmt}` : "₹"}
            </div>
          </div>
        </div>

        {!!checkMultiBrokerFlag && (
          <div
            style={{
              fontFamily: "SourceSansPro-SemiBold",
              fontSize: 13,
              color: "red",
              margin: 10,
            }}
          >
            {" "}
            Note: This folio has multiple distributors. Hence, the balance units
            and current value will not be displayed. In case of full redemption,
            all the units in the investor's portfolio will be redeemed.
          </div>
        )}

        <div className="input-name">Redemption Type</div>
        <div className="radio-buttons-div">
          <Debtbutton
            buttontitle="Partial"
            width={150}
            active={redemptionType === PARTIAL}
            onClick={this.changeRedemptionType(PARTIAL)}
          />
          <Debtbutton
            buttontitle="Full"
            width={150}
            active={redemptionType === FULL}
            onClick={this.changeRedemptionType(FULL)}
          />
        </div>

        <div className="input-name">Redemption Mode</div>
        <div className="radio-buttons-div">
          <Debtbutton
            buttontitle="Units"
            width={150}
            active={redemptionMode === UNITS}
            onClick={this.changeRedemptionMode(UNITS)}
          />
          <Debtbutton
            buttontitle="Amount"
            width={150}
            disabled={redemptionType === FULL ? true : false}
            active={redemptionMode === AMOUNT}
            onClick={this.changeRedemptionMode(AMOUNT)}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Redemption (Amount/Units)*"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "-") && evt.preventDefault()
            }
            bordercolor="rgba(0, 0, 0, 0.5)"
            errorMessage={amount.errorMessage}
            isShowError={amount.isError}
            type="number"
            onBlur={this.showError("amount")}
            disabled={redemptionType === FULL ? true : false}
            inputref={(ele) => (this.amountField = ele)}
            onChange={(event) => this.handleRedemptionValueChange(event)}
            value={amount.value}
          />
        </div>

        <div className="input-margin">
          <CustomizedSelect
            placeholder="Select Bank"
            name="Bank*"
            isError={bank.isError}
            errorMessage={bank.errorMessage}
            handleChange={this.handlefolioBankDetailsChange}
            value={bank.value}
            bordercolor="rgba(0, 0, 0, 0.5)"
            disabled={!folio.value}
            inputRef={(ele) => (this.bankField = ele)}
          >
            {banks.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item.bnkname}-{item.bnkacno}
              </MenuItem>
            ))}
          </CustomizedSelect>
          {/* {!!bank.errorMessage ? <div>{bank.errorMessage}</div> : ""} */}
        </div>
        <div className="digital-form-button-next-div">
          <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const {
      fundList,
      token,
      userId,
      bankDetails,
      investorData,
      communicationDetails,
    } = authReducer;

    return {
      fundList,
      token,
      userId,
      bankDetails,
      investorData,
      communicationDetails,
    };
  },
  { getBankDetails, getRedemptionPaymentDetails }
)(Redemption);
