import React, { Component } from "react";
import { BUILD_URL } from "../home/home";
import { fontFamily } from "@material-ui/system";

export class Input extends Component {
  render() {
    const {
      name,
      image,
      image2,
      height,
      placeholder,
      isShowError,
      inputref,
      label,
      bordercolor,
      isShowPasswordError,
      onClick,
      type,
      errorMessage,
      ...rest
    } = this.props;
    return (
      <div>
        <div className="input-name">
          {name} {label}{" "}
        </div>
        <div
          className="Input-field-div"
          style={{
            borderColor:
              isShowError || isShowPasswordError
                ? "red"
                : !!bordercolor
                ? bordercolor
                : "lightgray",
          }}
        >
          {/* {!!image && (
            <img
              src={`${BUILD_URL}${image}`}
              alt="input-label"
              style={{ height: 20, marginRight: 15 }}
            />
          )} */}
          {name?.includes("ARN") ? (
            <label className="arn_label">ARN -</label>
          ) : (
            ""
          )}
          <input
            className="input-without-arn-label number-input"
            placeholder={placeholder}
            ref={inputref}
            onKeyDown={this.props.onKeyDown}
            onBlur={this.props.onBlur}
            type={type || "text"}
            step={this.props.step}
            disabled={this.props.disabled}
            {...rest}
          />

          <div onClick={this.props.onClick}>
            {!!image2 && (
              <img src={`${image2}`} alt="field_image" height={height || 10} />
            )}
          </div>
        </div>
        <div
          style={{
            color: "red",
            fontFamily: "SourceSansPro-Regular",
            fontSize: 14,
          }}
        >
          {isShowError
            ? errorMessage ||
              ` ${
                name[0]?.toUpperCase() + name?.substr(1).replace("*", "")
              } is Mandatory*.`
            : isShowPasswordError
            ? "Password should be at least 8 characters in length and should contain at least 1 uppercase letter (A-Z), 1 special character and 1 numeric value (0-9)"
            : ""}
        </div>
      </div>
    );
  }
}

export class OtpInput extends Component {
  render() {
    const { inputref, ...rest } = this.props;
    return (
      <div>
        <input
          className="otp_input number-input"
          type="number"
          style={{ textAlign: "center" }}
          ref={inputref}
          {...rest}
        />
      </div>
    );
  }
}
