export var PURCHASE_DATA = "PURCHASE_DATA";
export var PURCHASE_DATA_SUCCESS = "PURCHASE_DATA_SUCCESS";
export var PURCHASE_DATA_FAILED = "PURCHASE_DATA_FAILED";
export var SHOW_PURCHASE_CONFIRMATION = "SHOW_PURCHASE_CONFIRMATION";
export var SHOW_PURCHASE_CONFIRMATION_SUCCESS = "SHOW_PURCHASE_CONFIRMATION_SUCCESS";
export var SHOW_PURCHASE_CONFIRMATION_FAILED = "SHOW_PURCHASE_CONFIRMATION_FAILED";
export var CONFIRM_PURCHASE = "CONFIRM_PURCHASE";
export var CONFIRM_PURCHASE_SUCCESS = "CONFIRM_PURCHASE_SUCCESS";
export var CONFIRM_PURCHASE_FAILED = "CONFIRM_PURCHASE_FAILED";
export var SET_SIP_DETAILS = "SET_SIP_DETAILS";
export var SIP_SAVE_DATA = "SIP_SAVE_DATA";
export var SIP_SAVE_DATA_SUCCESS = "SIP_SAVE_DATA_SUCCESS";
export var SIP_SAVE_DATA_FAILED = "SIP_SAVE_DATA_FAILED";
export var SET_SWITCH_DETAILS = "SET_SWITCH_DETAILS";
export var SWITCH_SAVE_DATA = "SWITCH_SAVE_DATA";
export var SWITCH_SAVE_DATA_SUCCESS = "SWITCH_SAVE_DATA_SUCCESS";
export var SWITCH_SAVE_DATA_FAILED = "SWITCH_SAVE_DATA_FAILED";
export var SET_STP_DETAILS = "SET_STP_DETAILS";
export var STP_SAVE_DATA = "STP_SAVE_DATA";
export var STP_SAVE_DATA_SUCCESS = "STP_SAVE_DATA_SUCCESS";
export var STP_SAVE_DATA_FAILED = "STP_SAVE_DATA_FAILED";
export var GET_EARNINGS = "GET_EARNINGS";
export var GET_EARNINGS_SUCCESS = "GET_EARNINGS_SUCCESS";
export var GET_EARNINGS_FAILED = "GET_EARNINGS_FAILED";