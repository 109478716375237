import React, { Component } from "react";
import TransactionSummary from "./transactionSummary";
import TableContainer from "./table";
import { AUTHORIZATION_PENDING } from "shared/store/types";
import { connect } from "react-redux";
import { getTransactionData } from "shared/store/actions";

class TabContainer extends Component {
  constructor(props) {
    super(props);
    const { active } = this.props.location.state;
    this.state = {
      active: active || AUTHORIZATION_PENDING
    };
  }

  componentDidMount() {
    this.props.getTransactionData({
      transflag: this.state.active,
      Arn: this.props.token
    });
  }

  onChangeActive = active => () => {
    this.setState({
      active
    });
    this.props.getTransactionData({ transflag: active, Arn: this.props.token });
  };

  render() {
    return (
      <div className="transcation-summary-responsive-page">
        <TransactionSummary
          active={this.state.active}
          onClick={this.onChangeActive}
        />
        <TableContainer />
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { token } = authReducer;
    return { token };
  },
  { getTransactionData }
)(TabContainer);
