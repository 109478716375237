import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";
import { getInvestorDetails, checkKyc } from "shared/store/actions";
import { REQ_BY } from "shared/store/types";
import { NEW_TRANSACTION_URL } from ".";
import { KycNotDoneDialog } from "../customModal";
import CustomLoader from "../../forms/Loader";
import Signbutton from "../../forms/button";

class NewInvestorSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      openModal: false,
      display: false,
      errorMessage: "",
      displaySearch: false,
    };
  }

  changeInput = (event) => {
    // if (
    //   !!event.target.value.charAt(3) &&
    //   (event.target.value.charAt(3) === "p" ||
    //     event.target.value.charAt(3) === "P")
    // ) {
    this.setState({
      inputValue: event.target.value.toUpperCase(),
      errorMessage: "",
    });
    // } else {
    //   this.search({
    //     errorMessage: ""
    //   });
    // }
  };

  keyPress = (e) => {
    if (e.key === "Enter") {
      this.search();
    }
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };
  showSearch = () => {
    this.setState({
      displaySearch: true,
    });
  };
  continue = () => {
    this.closeModal();
    this.props.history.push({
      pathname:
        NEW_TRANSACTION_URL + `/new-investor/ekyc/${this.state.inputValue}`,
    });
  };

  search = async () => {
    const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!this.state.inputValue || !regpan.test(this.state.inputValue)) {
      this.setState({ errorMessage: "Please enter valid PAN" });
    } else if (this.state.inputValue.charAt(3) !== "P") {
      this.setState({
        errorMessage: "Non Indivdual PAN is not allowed to transact",
      });
    } else {
      this.setState({
        display: true,
      });
      const params = {
        Uid: this.props.userId,
        ReqBy: REQ_BY,
        PAN: this.state.inputValue.toUpperCase(),
      };
      const response = await this.props.getInvestorDetails(params);
      if (!!response && !response.error) {
        this.props.history.push({
          pathname: NEW_TRANSACTION_URL + "/transction-types",
          pan: this.state.inputValue,
        });
      } else {
        const kycResponse = await this.props.checkKyc({
          fund: "105",
          userid: this.props.userId,
          i_pan: this.state.inputValue,
        });
        const {
          DtData: [info],
        } = kycResponse;
        if (info.KYCBlock === "N") {
          this.props.history.push({
            pathname: NEW_TRANSACTION_URL + "/transction-types",
            state: {
              pan: !!this.state.inputValue ? this.state.inputValue : "",
            },
          });
        } else {
          this.setState({ openModal: true, display: false });
        }
      }
      this.setState({
        display: false,
      });
    }
  };

  render() {
    return (
      <div>
        <CustomLoader display={this.state.display} />
        {!this.state.displaySearch && (
          <Signbutton
            buttontitle="Create New Folio"
            onClick={this.showSearch}
            width="20%"
          />
        )}
        {this.state.displaySearch && (
          <Paper
            className="investor-paper"
            style={{ boxShadow: "0px 1px 3px 0px lavender", marginBottom: 20 }}
          >
            <div className="investor-input-div">
              <SearchIcon className="investor-input-searchicon" />
              <InputBase
                value={this.state.inputValue}
                placeholder="Search by PAN"
                className="investor-input-inputbase"
                onChange={this.changeInput}
                onKeyPress={this.keyPress}
                inputProps={{
                  "aria-label": "Search",
                  maxLength: "10",
                }}
              />
            </div>
            <div className="investor-search-button" onClick={this.search}>
              Search
            </div>
          </Paper>
        )}
        {!!this.state.errorMessage && (
          <div
            style={{
              fontSize: 15,
              fontFamily: "Ubuntu-Italic",
              color: "red",
            }}
          >
            {this.state.errorMessage}
          </div>
        )}
        <KycNotDoneDialog
          open={this.state.openModal}
          close={this.closeModal}
          continue={this.continue}
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { userId } = authReducer;
    return { userId };
  },
  { getInvestorDetails, checkKyc }
)(NewInvestorSearch);
