import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { Hidden } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    cursor: "pointer",
    height: 50,
    paddingLeft: 15,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 14,
    fontFamily: "SourceSansPro-SemiBold",
    color: "#293139",
  },
  placeholder: {
    position: "absolute",
    // left: 2,
    fontSize: 16,
    // padding: 10
  },
  paper: {
    position: "absolute",
    overflowY: "auto",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  notchedOutlineError: {
    borderColor: "red !important",
  },
  notchedOutline: {
    borderColor: "#rgba(211, 211, 211) !important",
    borderRadius: 5,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className="singlevalue"
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        classes: {
          notchedOutline: !!props.selectProps.isError
            ? props.selectProps.classes.notchedOutlineError
            : props.selectProps.classes.notchedOutline,
        },
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
      margin="normal"
      variant="outlined"
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: "pre-wrap",
        textTransform: "uppercase",
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      style={{ textTransform: "uppercase" }}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ textTransform: "uppercase" }}
    >
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      style={{ textTransform: "uppercase" }}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  state = {
    single: null,
  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        marginTop:"-20px !important",
        cursor: "pointer",
        "& input": {
          font: "inherit",
        },
      }),
    };

    return (
      <div className="integrated-select" style={{ flexGrow: 1 }}>
        <NoSsr>
          <Select
            isDisabled={this.props.disabled}
            ref={this.props.inputRef}
            classes={classes}
            styles={selectStyles}
            options={this.props.suggestions}
            components={components}
            value={this.props.value}
            onChange={this.props.handleChange}
            placeholder={this.props.placeholder}
            isClearable
            onKeyDown={this.props.onKeyDown}
            isError={this.props.isError}
          />
          <div
            style={{
              color: "red",
              fontFamily: "SourceSansPro-Regular",
              fontSize: 14,
            }}
          >
            {!!this.props.errorMessage ? this.props.errorMessage : ""}
          </div>
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
