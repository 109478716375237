import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Signbutton, { DebtbuttonActive } from "../forms/button";
import { Input } from "../forms/input";
import { Debtbutton } from "../forms/button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { resetPassword } from "shared/store/actions/profile";
import { getMailBackRequestStatus } from "shared/store/actions/mailback";
import { REQ_BY } from "shared/store/types";
import { BUILD_URL } from "./home";
import { formatDate } from "../forms/captalize";
import Image from "../../Image";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Paper from "@material-ui/core/Paper";
import { userLogout } from "shared/store/actions";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../forms/appEssentials";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" style={{ marginRight: 60 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export class SucesssPopUp extends Component {
  render() {
    return (
      <Dialog
        onClose={this.props.closeSucessModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.closeSucessModal}
        />

        <DialogContent style={{ paddingBottom: 100 }}>
          <div>
            <div className="modal-content-div">
              <img
                src={BUILD_URL + "/images/tick.png"}
                style={{ width: 100, height: 100 }}
                alt="tick"
              />
            </div>
            <div className="modal-succes-msg">Successfully raised a query</div>
            {!!this.props.response ? (
              <div
                style={{
                  fontSize: 15,
                  fontFamily: "SourceSansPro-SemiBold",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {this.props.response}
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Signbutton
              buttontitle="continue"
              onClick={this.props.closeSucessModal}
              width={100}
              padding="10px 30px"
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
export class PasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: {
        value: "",
        isShowError: false,
        isShowPasswordError: false,
      },
      showPassword: false,
      showOldPassword: false,
      confirmShowPassword: false,
      newPassword: {
        value: "",
        isShowError: false,
        isShowPasswordError: false,
      },
      confirmPassword: {
        value: "",
        isShowError: false,
        isShowPasswordError: false,
      },
      openSuccessPasword: false,
    };
  }

  componentDidMount() {
    this.setState({
      oldPassword: {
        value: "",
        isShowError: false,
        isShowPasswordError: false,
      },
      showPassword: false,
      showOldPassword: false,
      confirmShowPassword: false,
      newPassword: {
        value: "",
        isShowError: false,
        isShowPasswordError: false,
      },
      confirmPassword: {
        value: "",
        isShowError: false,
        isShowPasswordError: false,
      },
      openSuccessPasword: false,
    });
  }

  onBlurInput = (eve, label) => {
    var strongRegex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    if (strongRegex.test(eve.target.value) === false) {
      if (label === "oldPassword") {
        this.setState({
          [label]: { ...this.state.oldPassword, isShowPasswordError: true },
        });
      } else if (label === "newPassword") {
        this.setState({
          [label]: { ...this.state.newPassword, isShowPasswordError: true },
        });
      } else if (label === "confirmPassword") {
        this.setState({
          [label]: { ...this.state.confirmPassword, isShowPasswordError: true },
        });
      }
    }
  };

  onChangeInput = (label) => (event) => {
    this.setState({
      [label]: { value: event.target.value },
    });
  };

  submit = async () => {
    if (
      !this.state.oldPassword.value ||
      !this.state.newPassword.value ||
      !this.state.confirmPassword.value
    ) {
      if (!this.state.oldPassword.value) {
        this.setState({
          oldPassword: {
            ...this.state.oldPassword,
            isShowError: true,
            errorMessage: "(old Password is mandatory)",
          },
        });
      }
      if (!this.state.newPassword.value) {
        this.setState({
          newPassword: {
            ...this.state.newPassword,
            isShowError: true,
            errorMessage: "(new Password is mandatory)",
          },
        });
      }
      if (!this.state.confirmPassword.value) {
        this.setState({
          confirmPassword: {
            ...this.state.confirmPassword,
            isShowError: true,
            errorMessage: "(confirm Password is mandatory)",
          },
        });
      }
    } else if (this.state.oldPassword.value === this.state.newPassword.value) {
      this.setState({
        newPassword: {
          ...this.state.newPassword,
          isShowError: true,
          errorMessage: "(*Old password and New Password should not be Same)",
        },
      });
    } else if (
      this.state.newPassword.value !== this.state.confirmPassword.value
    ) {
      this.setState({
        confirmPassword: {
          ...this.state.confirmPassword,
          isShowError: true,
          errorMessage: "(Password does not match)",
        },
      });
    } else {
      const response = await this.props.resetPassword({
        newPassword: this.state.newPassword.value,
        oldpassword: this.state.oldPassword.value,
        ReqBy: REQ_BY,
        userid: this.props.userid,
      });
      if (!!response && !response.error) {
        this.props.closeModal();
        // this.props.history.replace(BUILD_URL + "/Login");
        this.setState({
          openSuccessPasword: true,
        });
      } else if (!!response && !!response.error) {
        alert(`${response.error}`);
        this.setState({
          confirmPassword: { value: "" },
          newPassword: { value: "" },
          oldPassword: { value: "" },
        });
      }
    }
  };
  closePasswordModal = () => {
    this.props.userLogout();
    this.props.history.replace(BUILD_URL + "/Login");
    this.setState({
      openSuccessPasword: false,
    });
  };

  closeModalPopUp = () => {
    this.props.closeModal();
    this.setState({
      oldPassword: { value: "" },
      newPassword: { value: "" },
      confirmPassword: { value: "" },
    });
  };

  render() {
    return (
      <div>
        <Dialog
          onClose={this.props.closeModal}
          aria-labelledby="customized-dialog-title"
          open={this.props.openPasswordModal}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.closeModalPopUp}
          >
            <div
              style={{
                marginLeft: "15%",
                fontFamily: "SourceSansPro-SemiBold",
              }}
            >
              Change Password
            </div>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 100 }}>
            <div>
              <div className="input-margin">
                <Input
                  name="Old Password"
                  value={this.state.oldPassword.value}
                  type={!!this.state.showOldPassword ? "text" : "password"}
                  onChange={this.onChangeInput("oldPassword")}
                  onBlur={(value) => this.onBlurInput(value, "oldPassword")}
                  height={18}
                  image2={
                    !!this.state.showOldPassword &&
                    !this.state.showOldPassword.value
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  onClick={() => {
                    this.setState({
                      showOldPassword: !this.state.showOldPassword,
                    });
                  }}
                  isShowError={this.state.oldPassword.isShowError}
                  errorMessage={this.state.oldPassword.errorMessage}
                />
              </div>
              <div className="input-margin">
                <Input
                  type={!!this.state.showPassword ? "text" : "password"}
                  name="New Password"
                  image2={
                    !!this.state.showPassword && !this.state.showPassword.value
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  height={18}
                  value={this.state.newPassword.value}
                  onChange={this.onChangeInput("newPassword")}
                  onBlur={(value) => this.onBlurInput(value, "newPassword")}
                  onClick={() => {
                    this.setState({
                      showPassword: !this.state.showPassword,
                    });
                  }}
                  isShowError={this.state.newPassword.isShowError}
                  isShowPasswordError={
                    this.state.newPassword.isShowPasswordError
                  }
                  errorMessage={this.state.newPassword.errorMessage}
                />
              </div>
              <div className="input-margin">
                <Input
                  name="Confirm Password"
                  type={!!this.state.confirmShowPassword ? "text" : "password"}
                  image2={
                    !!this.state.confirmShowPassword &&
                    !this.state.confirmShowPassword.value
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  height={18}
                  onClick={() => {
                    this.setState({
                      confirmShowPassword: !this.state.confirmShowPassword,
                    });
                  }}
                  value={this.state.confirmPassword.value}
                  onChange={this.onChangeInput("confirmPassword")}
                  onBlur={(eve) => this.onBlurInput(eve, "confirmPassword")}
                  isShowError={this.state.confirmPassword.isShowError}
                  isShowPasswordError={
                    this.state.confirmPassword.isShowPasswordError
                  }
                  errorMessage={this.state.confirmPassword.errorMessage}
                />
              </div>
              <div
                className="flex"
                style={{ margin: "30px 0px", justifyContent: "center" }}
              >
                <DebtbuttonActive
                  buttontitle="SUBMIT"
                  width={150}
                  onClick={() => this.submit()}
                />
                <Debtbutton
                  buttontitle="CANCEL"
                  onClick={this.closeModalPopUp}
                  isShowError={this.state.isShowError}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <CustomizedDialogs
          open={this.state.openSuccessPasword}
          closeModal={() => this.closePasswordModal()}
          passwordSucess={this.state.openSuccessPasword === true ? true : false}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    userid: authReducer.userId,
  };
};

export const PasswordDialog = connect(mapStateToProps, {
  resetPassword,
  userLogout,
})(PasswordModal);

export class NonLoginServicesDialog extends Component {
  render() {
    return (
      <Dialog
        onClose={this.props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}
        fullScreen={true}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.closeModal}
        >
          Distributor Non-Login Services
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 100 }}>
          <div>
            <div>
              <div
                style={{
                  backgroundColor: "#e5f2f9",
                  color: "#003d7b",
                  padding: "7px 10px",
                  fontSize: 14,
                  fontFamily: "SourceSansPro-SemiBold",
                  borderRadius: 3,
                  margin: "10px 0px",
                }}
              >
                Services
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <NonLoginServices
                  name="PAN Based Services"
                  src={BUILD_URL + "/images/nl_icon_s.svg"}
                  link="https://mfs.kfintech.com/mfs/GeneralPages/ItPAN.aspx"
                />
                <NonLoginServices
                  name="Know Your investor Transaction Status"
                  src={BUILD_URL + "/images/nl_icon_s.svg"}
                  link="https://mfs.kfintech.com/mfs/InvestorServices/General/InvTransactionStatusnew.aspx"
                />
                <NonLoginServices
                  name="FinNet Login"
                  src={BUILD_URL + "/images/nl_icon_s.svg"}
                  link="https://finnet.kfintech.com/FinNet/(S(usvq1h3vasir0045tvi2kdjg))/FinNetLogin.aspx"
                />
                <NonLoginServices
                  name="Historical Nav"
                  src={BUILD_URL + "/images/nl_icon_s.svg"}
                  link="https://mfs.kfintech.com/mfs/NAVDividend/NAV_Dividend.aspx"
                />
              </div>
            </div>

            <div>
              <div
                style={{
                  backgroundColor: "#e5f2f9",
                  color: "#003d7b",
                  padding: "7px 10px",
                  fontSize: 14,
                  fontFamily: "SourceSansPro-SemiBold",
                  borderRadius: 3,
                  margin: "10px 0px",
                }}
              >
                Forms
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <NonLoginServices
                  name="Common Transaction Form"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/Distributor/COMMONTRANSACTIONFORM.pdf"
                />
                <NonLoginServices
                  name="CKYC Application Form"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/Downloads/CKYCApplicationForm.pdf"
                />
              </div>
            </div>

            <div>
              <div
                style={{
                  backgroundColor: "#e5f2f9",
                  color: "#003d7b",
                  padding: "7px 10px",
                  fontSize: 14,
                  fontFamily: "SourceSansPro-SemiBold",
                  borderRadius: 3,
                  margin: "10px 0px",
                }}
              >
                Downloads
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <NonLoginServices
                  name="Distributor manual"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/Distributor/Downloads/DistributorManual.pdf"
                />
                <NonLoginServices
                  name="Merger of Schemes in Edelweiss MF"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/distributor/Downloads/Edelweiss_Scheme_Master.xlsx"
                />
                <NonLoginServices
                  name="NACH Form mandates"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/NACH.aspx"
                />
                <NonLoginServices
                  name="
                  SEBI Circular-unclaimed amounts in liquid funds"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/SEBICircularDistributor.aspx"
                />
                <NonLoginServices
                  name="SIP/STP/SWP Cancellation Request"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/Distributor/RequestForCancellationOfSIP-STP-SWP.pdf"
                />
                <NonLoginServices
                  name="KFintech Easy SMS services guide"
                  src={BUILD_URL + "/images/nl_icon_f.svg"}
                  link="https://mfs.kfintech.com/mfs/KFintechEasySMSservicesguide.pdf"
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export class MailBacKRequestStatusPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      record: false,
    };
  }

  onDateChange = (event) => {
    this.setState({
      date: event,
    });
  };

  submit = async () => {
    const { date } = this.state;
    let startDateValue;
    if (date) {
      startDateValue = date.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "numeric",
      });
    }
    const params = {
      FromDate: startDateValue,
    };
    const response = await this.props.getMailBackRequestStatus(params);
    if (!response) {
      this.setState({
        record: true,
      });
    }
  };

  render() {
    return (
      <div>
        <Dialog
          onClose={this.props.close}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}

          // fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.props.close}>
            MailBack Request Status
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 100 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 20,
                fontFamily: "SourceSansPro-Bold",
                margin: "30px 10%",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required={true}
                  className="selects"
                  onChange={(e) => this.onDateChange(e)}
                  disableFuture={true}
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  id="mui-pickers-date"
                  value={this.state.date}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Signbutton
                buttontitle="continue"
                onClick={this.submit}
                width={100}
                padding="10px 30px"
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export class PosterDialog extends Component {
  render() {
    return (
      <>
        <div className="Banner">
          <Dialog
            onClose={this.props.close}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            className="posterDialog"
          // fullWidth={true}
          >
            <DialogContent
              style={{
                width: "100%",
                height: "65%",
                padding: 0,
              }}
            >
              {/* <Image
                src={"/images/newwebbanner.png"}
                style={{ width: "100%", height: "70%" }}
                onClick={() =>
                  window.open("https://bit.ly/49yOZfT")
                }
              /> */}
              <div>
                <h3 style={{ padding: 10, paddingBottom: 0,textAlign:"center" }}>
                  Important Regulatory Update on KYC Status
                </h3>
                <div class="custom-div">
                  As per the Master Circular issued by SEBI on KYC requirements,
                  Investors with ‘KYC Registered’ status are requested to do their KYC again to become ‘KYC Validated’ by visiting {" "}
                  <a href="https://mfs.kfintech.com/Investor/General/ValidateKYC/" target="_blank">https://mfs.kfintech.com/Investor/General/ValidateKYC/</a>  <br></br>
                  <a href="https://www.sebi.gov.in/legal/master-circulars/oct-2023/master-circular-on-know-your-client-kyc-norms-for-the-securities-market_77945.html" target="_blank">Click here</a> to know more about the circular.
                </div>                
              </div>
              <div
                style={{
                  position: "absolute",
                  zIndex: 999,
                  right: 5,
                  top: 5,
                  cursor: "pointer",
                }}
                onClick={this.props.close}
              >
                <Image
                  src={"/images/cross.png"}
                  style={{
                    height: 20,
                    width: 20,
                    cursor: "pointer",
                  }}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }
}

export const MBRStatusDialog = connect(null, { getMailBackRequestStatus })(
  MailBacKRequestStatusPopUp
);

export class NonLoginServices extends Component {
  render() {
    return (
      <Paper className="non-login-pop-up-paper">
        <img src={this.props.src} style={{ height: 30, width: 30 }} />
        <a
          className="pop-up-title"
          href={this.props.link}
          target="_blank"
          style={{
            color: "black",
            textDecoration: "none",
            fontSize: 14,
            fontFamily: "SourceSansPro-SemiBold",
          }}
        >
          {this.props.name}
        </a>
      </Paper>
    );
  }
}

export class KycNotDoneDialog extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          onClose={this.props.close}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.close}
          />
          <DialogContent style={{ paddingBottom: 100 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 20,
                fontFamily: "SourceSansPro-Bold",
                margin: "30px 10%",
              }}
            >
              {!!this.props.message
                ? this.props.message
                : "This Client is not KYC compliant, do you want to go for KYC"}
            </div>
          </DialogContent>
          <div
            className="modal-continue-button "
            style={{
              margin: "20px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <div className="dual-margin">
                <Signbutton
                  buttontitle="Continue"
                  onClick={this.props.continue}
                  width={100}
                  // padding="10px 30px"
                />
              </div>
              <div className="dual-margin">
                <Signbutton
                  buttontitle="Cancel"
                  onClick={this.props.close}
                  width={100}
                  padding="10px 30px"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default class CustomizedDialogs extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          onClose={this.props.closeModal}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.closeModal}
          />
          <DialogContent style={{ paddingBottom: 100 }}>
            <div className="modal-content-div">
              <img
                src={BUILD_URL + "/images/tick.png"}
                style={{ width: 100, height: 100 }}
                alt="tick"
              />
            </div>
            {this.props.nfoFunds === true ? (
              <div className="modal-text-container">
                <div className="modal-text">
                  The transaction for <strong> NFO</strong> has been initiated
                  Successfully.
                </div>
                <div className="modal-text">
                  Link for <strong>{this.props.scheme}</strong> scheme is
                  generated
                </div>
              </div>
            ) : this.props.cas === true ||
              this.props.Gas === true ||
              this.props.componentName === "MailBackSoa" ||
              this.props.passwordSucess ? (
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: "25px",
                  color: "green",
                }}
              >
                {!!this.props.passwordSucess
                  ? this.props.message || "Password Changed Successfully"
                  : !!this.props.Gas
                  ? "Your request has been successfully submitted"
                  : "Your request has been successfully submitted"}
                <div
                  style={{
                    color: "black",
                    fontFamily: "SourceSansPro-SemiBold",
                    fontSize: 15,
                    marginTop: 10,
                    padding: "10px 0px",
                    backgroundColor:
                      !!this.props.cas || !!this.props.Gas ? "aliceblue" : "",
                  }}
                >
                  {this.props.cas === true || this.props.Gas === true
                    ? "Investor will receive an email shortly."
                    : ""}
                </div>
              </div>
            ) : (this.props.componentName === "MailBacKRequest" &&
                this.props.refNo) ||
              (this.props.componentName === "SWP" && this.props.refNo) ||
              (this.props.componentName === "REDEMPTION" &&
                this.props.refNo) ? (
              <div>
                {!!this.props.componentName &&
                (this.props.componentName === "REDEMPTION" ||
                  this.props.componentName === "SWP") ? (
                  <div className="modal-succes-msg">
                    Transaction Successful
                  </div>
                ) : (
                  ""
                )}
                <div className="modal-text-container">
                  <div>
                    {!!this.props.componentName &&
                    (this.props.componentName === "REDEMPTION" ||
                      this.props.componentName === "SWP") ? (
                      <div className="modal-text">
                        The transaction for{" "}
                        <strong>{this.props.componentName}</strong> has been
                        initiated successfully.
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        textAlign: "center",
                        fontFamily: "SourceSansPro-SemiBold",
                        fontSize: "15px",
                        padding: "15px 5px",
                      }}
                    >
                      Your request is successfully registered with reference 
                      number
                      <strong style={{ padding: "0px 2px" }}>
                        {this.props.refNo}
                      </strong>
                      .Your investor will receive an email for approval. You will also receive a copy of the same mail for your information and records.
                     
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="modal-succes-msg">
                  {this.props.componentName === "Sip cancellation" ||
                  this.props.componentName === "SWP cancellation" ||
                  this.props.componentName === "STP cancellation"
                    ? `Successfully Cancelled ${this.props.componentName}`
                    : this.props.componentName === "Sip Pause"
                    ? `Successfully Sip Transaction has been paused`
                    : `Transaction Successful`}
                </div>

                {this.props.type === "STP" || this.props.type === "SWITCH" ? (
                  <div className="modal-text-container">
                    <div className="modal-text">
                      The transaction for <strong>{this.props.type}</strong> has
                      been initiated successfully.
                    </div>
                    <div className="modal-text">
                      You have initiated the transaction successfully with
                      reference number:
                      <strong style={{ padding: "0px 2px" }}>
                        {this.props.refNo}
                      </strong>
                      .Your investor will receive an email for approval. You will also receive a copy of the same mail for your information and records.
                    </div>
                  </div>
                ) : (
                  <div className="modal-text-container">
                    {!!this.props.reqtype ? (
                      <div className="modal-text">
                        the transaction for{" "}
                        <strong>{this.props.reqtype}</strong> with the IHNO : $
                        {this.props.Nctihno} has been initiated successfully
                      </div>
                    ) : (
                      this.props.componentName !== "Sip cancellation" ||
                      this.props.componentName !== "SWP cancellation" ||
                      this.props.componentName !== "STP cancellation" ||
                      (this.props.componentName !== "Sip Pause" && (
                        <div className="modal-text">
                          The transaction for{" "}
                          <strong>
                            {!!this.props.refrence
                              ? this.props.refrence.new_type
                              : ""}
                            {!!this.props.refNo ? "SIP" : ""}
                          </strong>{" "}
                          has been initiated successfully.
                        </div>
                      ))
                    )}
                    {!!this.props.reqtype ? (
                      <div className="modal-text">
                        Your investor will receive the details via an email and
                        SMS
                      </div>
                    ) : this.props.componentName === "Sip cancellation" ||
                      this.props.componentName === "SWP cancellation" ||
                      this.props.componentName === "STP cancellation" ? (
                      <div className="modal-text">
                        the transaction for{" "}
                        <strong>{this.props.componentName}</strong> with the
                        Ihno <strong>{this.props.refNo.value}</strong> has been
                        successful.
                      </div>
                    ) : this.props.componentName === "Sip Pause" ? (
                      this.props.refNo.errorMessage
                    ) : (
                      <div>
                        <div className="modal-text">
                          the transaction for{" "}
                          <strong>{this.props.transactName}</strong> has been
                          initiated successfully.
                        </div>
                        <div className="modal-text">
                          Your investor will receive the details via an email
                          and SMS with reference number
                          <strong style={{ margin: "0px 10px" }}>
                            {!!this.props.refrence
                              ? this.props.refrence.value
                              : ""}
                            {!!this.props.refNo ? this.props.refNo : ""}
                          </strong>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {!!this.props.Transaction && (
              <div
                style={{ fontFamily: "SourceSansPro-SemiBold", fontSize: 16,display:"flex",justifyContent:"center" }}
              >
                Would you like to do another transaction ?
              </div>
            )}

            <div className="modal-continue-button modal-responsive">
              {!!this.props.Transaction ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="dual-margin">
                    <Signbutton
                      buttontitle="Yes"
                      onClick={this.props.transactionStatus}
                      width={100}
                      padding="10px 30px"
                    />
                  </div>
                  <div className="dual-margin">
                    <Signbutton
                      buttontitle="No"
                      onClick={this.props.closeModal}
                      width={100}
                      padding="10px 30px"
                    />
                  </div>
                </div>
              ) : this.props.cas === true && !this.props.componentName ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Signbutton
                    buttontitle="Continue"
                    onClick={this.props.closeYesModal}
                    width={250}
                    padding="10px 30px"
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Signbutton
                    buttontitle={
                      this.props.componentName === "MailBackSoa"
                        ? "Ok"
                        : "Continue"
                    }
                    width={250}
                    padding="10px 30px"
                    onClick={this.props.closeModal}
                  />
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export class SipCancelModal extends Component {
  render() {
    return (
      <Dialog
        onClose={this.props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.open || this.props.rejectionreasonsOpen}
        fullWidth={true}
        style={{height:"90%"}}
      >
                  {!this.props.rejectionreasonsOpen &&(<>
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.closeModal}
        >
          SIP Cancellation
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              fontSize: 15,
              fontFamily: "SourceSansPro-SemiBold",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {this.props.message}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="dual-margin" style={{margin:"0px 15px"}}>
              <Signbutton
                buttontitle="Yes"
                onClick={this.props.closeYesModal}
                width={100}
                padding="10px 30px"
              />
            </div>
            <div className="dual-margin" style={{margin:"0px 15px"}}>
              <Signbutton
                buttontitle="No"
                onClick={this.props.closeNoModal}
                width={100}
                padding="10px 30px"
              />
            </div>
          </div>
        
        </DialogContent>
        </>
                  )}
          {this.props.rejectionreasonsOpen &&(
            <>
            <div >
              <RejectionReason {...this.props} />
            </div>
            <div style={{ display: "flex", justifyContent: "center",padding:7 }}>
           
            <div className="dual-margin" style={{margin:"0px 15px"}}>
              <Signbutton
                buttontitle="back"
                onClick={this.props.closeYesModal}
                width={100}
                padding="10px 30px"
                margin={5}
              />
            </div>
            <div className="dual-margin" style={{margin:"0px 15px"}}>
              <Signbutton
                buttontitle="proceed"
                onClick={this.props.closemodelafterrejecttion}
                width={100}
                padding="10px 30px"
                margin={5}
              />
            </div>
          </div>
          </>
          )

          }
      </Dialog>
    );
  }
}



export class EnachModal extends Component {
  render() {
    return (
      <Dialog
        onClose={this.props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}
      >
        <DialogContent style={{ paddingBottom: 100 }}>
          <div className="modal-content-div">
            <img
              src={BUILD_URL + "/images/tick.png"}
              style={{ width: 100, height: 100 }}
              alt="tick"
            />
          </div>
          <div
            style={{
              fontSize: 15,
              fontFamily: "SourceSansPro-SemiBold",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {this.props.message}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="dual-margin">
              <Signbutton
                buttontitle="OK"
                onClick={this.props.closeYesModal}
                width={100}
                padding="10px 30px"
              />
            </div>
            {/* <div className="dual-margin">
              <Signbutton
                buttontitle="No"
                onClick={this.props.closeNoModal}
                width={100}
                padding="10px 30px"
              />
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export class EnachAMCModal extends Component {
  render() {
    const FundList = [
      "AXIS MUTUAL FUND",
      "BOI AXA MUTUAL FUND",
      "CANARA ROBECO MUTUAL FUND",
      "ESSEL MUTUAL FUND",
      "IDBI MUTUAL FUND",
      "INVESCO MUTUAL FUND",
      "ITI MUTUAL FUND",
      "LIC MUTUAL FUND",
      "MOTILAL OSWAL MUTUAL FUND",
      "NIPPON INDIA MUTUAL FUND",
      "PGIM INDIA MUTUAL FUND",
      "QUANT MUTUAL FUND",
      "QUANTUM MUTUAL FUND",
      "SUNDARAM MUTUAL FUND",
    ];
    return (
      <Dialog
        onClose={this.props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}
      >
        <DialogContent style={{ paddingBottom: 100 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: 16,
                fontFamily: "SourceSansPro-SemiBold",
                color: "#00579b",
              }}
            >
              eNACH Participating AMCs{" "}
            </div>
            <img
              src={BUILD_URL + "/images/close.png"}
              style={{ height: 15 }}
              onClick={this.props.close}
            />
          </div>
          <div
            className="modal-content-div"
            style={{ flexDirection: "column" }}
          >
            <div></div>
            {FundList.map((ele, index) => (
              <div
                style={{
                  fontFamily: "SourceSansPro-Regular",
                  fontSize: 14,
                  padding: "5px 0px",
                  borderBottom: "1px solid rgba(211,211,211,0.5)",
                  marginVertical: 10,
                }}
                key={index}
              >
                {ele}
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export class PerformanceModal extends Component {
  render() {
    const { data } = this.props;
    return (
      <Dialog
        onClose={this.props.closeModal}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}
      >
        <DialogContent style={{ paddingBottom: 100 }}>
          <div className="first-section">
            <div
              style={{
                backgroundColor: "aliceblue",
                fontFamily: "SourceSansPro-SemiBold",
                fontSize: 16,
                padding: 10,
              }}
            >
              Transaction Details
            </div>
            <div style={{ flexDirection: "row", display: "flex", margin: 10 }}>
              <div style={{ flexGrow: 1, flexBasis: 0, marginBottom: 5 }}>
                <div
                  style={{ fontSize: 13, fontFamily: "SourceSansPro-Regular" }}
                >
                  Units
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "SourceSansPro-SemiBold",
                    color: "#013a7d",
                  }}
                >
                  {!!data && !!data[0] ? data[0].Units : ""}
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: 0 }}>
                <div
                  style={{ fontSize: 13, fontFamily: "SourceSansPro-Regular" }}
                >
                  Price (NAV)
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "SourceSansPro-SemiBold",
                    color: "#013a7d",
                  }}
                >
                  {!!data && !!data[0] ? data[0].NAV : ""}
                </div>
              </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", margin: 10 }}>
              <div style={{ flexGrow: 1, flexBasis: 0, marginBottom: 5 }}>
                <div
                  style={{ fontSize: 13, fontFamily: "SourceSansPro-Regular" }}
                >
                  Nav Date
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "SourceSansPro-SemiBold",
                    color: "#013a7d",
                  }}
                >
                  {!!data && !!data[0] ? data[0].NavDate : ""}
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: 0 }}>
                <div
                  style={{ fontSize: 13, fontFamily: "SourceSansPro-Regular" }}
                >
                  Folio Number
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontFamily: "SourceSansPro-SemiBold",
                    color: "#013a7d",
                  }}
                >
                  {!!data && !!data[0] ? data[0].trs_acno : ""}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="second-section" style={{ maxWidth: 250 }}>
              <div
                style={{
                  backgroundColor: "aliceblue",
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 16,
                  padding: 10,
                }}
              >
                Transaction Completion
              </div>
              <div className="circular">
                <CircularProgressbar
                  value={
                    !!data && !!data[0] && data[0].PercentageofCompletion
                      ? parseFloat(data[0].PercentageofCompletion.split("%")[0])
                      : ""
                  }
                  text={
                    !!data && !!data[0] ? data[0].PercentageofCompletion : "0%"
                  }
                  strokeWidth={12}
                />
              </div>
            </div>
            <div
              className="thrid-section"
              style={{ flexGrow: 1, flexBasis: 0 }}
            >
              <div
                style={{
                  backgroundColor: "aliceblue",
                  fontFamily: "SourceSansPro-SemiBold",
                  fontSize: 16,
                  padding: 10,
                }}
              >
                Transaction Status
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={
                        !!data && !!data[0] && data[0].MFPayment === "Received"
                          ? BUILD_URL + "/images/approved.png"
                          : BUILD_URL + "/images/oranageCircle.png"
                      }
                      style={{ height: 30, marginTop: 2 }}
                    />
                    <div
                      style={{
                        marginLeft: 10,
                        fontFamily: "SourceSansPro-Regular",
                      }}
                    >
                      <div style={{ fontSize: 14 }}>Payment Confirmation</div>
                      <div style={{ fontSize: 12, color: "#013a7d" }}>
                        {!!data && !!data[0] ? data[0].PaymentDate : ""}
                      </div>
                      <div style={{ fontSize: 10, color: "#013a7d" }}>
                        Via {!!data && !!data[0] ? data[0].MFPaymentMode : "-"}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: "SourceSansPro-Regular",
                      color:
                        !!data && !!data[0] && data[0].MFPayment === "Received"
                          ? "#81d742"
                          : "#ffa500",
                      marginTop: 5,
                      marginRight: 10,
                    }}
                  >
                    {!!data && !!data[0] ? data[0].MFPayment : "-"}
                  </div>
                </div>
                <div
                  style={{
                    background: "gray",
                    height: 90,
                    width: 1,
                    marginLeft: 14,
                    marginTop: -20,
                  }}
                ></div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={
                        !!data &&
                        !!data[0] &&
                        data[0].Unitsallotment === "In Progress"
                          ? BUILD_URL + "/images/oranageCircle.png"
                          : BUILD_URL + "/images/approved.png"
                      }
                      style={{ height: 30 }}
                    />
                    <div
                      style={{
                        marginLeft: 10,
                        fontFamily: "SourceSansPro-Regular",
                      }}
                    >
                      <div style={{ fontSize: 14 }}>Units Allotment</div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      fontFamily: "SourceSansPro-Regular",
                      color:
                        !!data &&
                        !!data[0] &&
                        data[0].Unitsallotment === "Completed"
                          ? "#81d742"
                          : "#ffa500",
                      marginTop: 5,
                      marginRight: 10,
                    }}
                  >
                    {!!data && !!data[0] ? data[0].Unitsallotment : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Signbutton
              buttontitle="OK"
              onClick={this.props.closeModal}
              width={150}
              padding="10px 30px"
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
export const RejectionReason = (props) => {
  return (
    <div style={{padding:20,paddingBottom:0}}>
 <h2>Cancellation Reasons</h2>
  {props.rejectionReasons.map((reason) => (
  <div key={reason.RejReason} style={{ display: 'flex', alignItems: 'center',padding:7 }}>
  <input
    type="radio"
    name="rejectionReason"
    value={reason.RejReason}
    checked={props.selectedReason === reason.RejReason}
    onChange={props.handleReasonChange}
    className="square-radio"
  />
  <label style={{margin:0}}>{reason.RejReason}</label>
</div>
 
  ))}
  {
    !!props.reasonMandatory  && <span style={{color:'red'}}>*Rejection reason is mandatory</span>
  }
  
  <p>Selected Reason: {props.selectedReason}</p>
  {props.selectedReason.includes("Others") && (
    <div style={{ marginBottom: 20 }}>
      <Input
        bordercolor="#a6a6a6"
        placeholder="Enter the Reason"
        value={props.othersText.value}
        isShowError={props.othersText.isError}
        errorMessage={props.othersText.errorMessage}
        onChange={props.selectedotherreason}
      />
    </div>
  )}
</div>

  );
};
