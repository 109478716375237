import React, { Component } from "react";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import Paper from "@material-ui/core/Paper";
import { Input } from "../../forms/input";
import Signbutton, { Debtbutton } from "../../forms/button";
import { connect } from "react-redux";
import { getNewPurchaseFundList, checkKyc } from "shared/store/actions";
import { EKYCMail } from "shared/store/actions/investors";
import CustomizedSelect from "./customizedSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {
  GET_FUNDS_LIST,
  GET_FUNDS_LIST_SUCCESS,
  GET_FUNDS_LIST_FAILED,
  ALL,
} from "shared/store/types";
import { KycNotDoneDialog } from "../customModal";
import { SundaramMutualFund } from "./newPurchaseForm";
import CustomLoader from "../../forms/Loader";
class EKYC extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      kycType: { value: "" },
      ipv: { value: "YES", errorMessage: "" },
      display: false,
      messageAlert: "",
      open: false,
      pan: {
        value:
          !!match && !!match.params && !!match.params.pan
            ? match.params.pan
            : "",
      },
      fund: { value: { value: "117" } },
      name: { value: "" },
      email: { value: "" },
      mobile: { value: "" },
    };
  }

  // componentDidMount() {
  //   const params = {
  //     opt: ALL,
  //     schemetype: this.props.userId,
  //   };
  //   const types = [
  //     GET_FUNDS_LIST,
  //     GET_FUNDS_LIST_SUCCESS,
  //     GET_FUNDS_LIST_FAILED,
  //   ];

  //   this.props.getNewPurchaseFundList(params, types);
  // }

  handleChange = (value) => {
    this.setState({
      fund: { value },
      pan: { value: this.state.pan.value },
    });
  };

  changeValue = (label) => (event) => {
    if (label === "pan" && event.target.value.length < 11) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label === "name") {
      if (!!event.target.value && /^[a-zA-Z ]*$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
      } else if (!event.target.value) {
        this.setState({
          [label]: { value: event.target.value },
        });
      }
    } else if (label === "mobile" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label != "pan" && label != "mobile" && label != "name") {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  changeIpvValue = (value) => () => {
    if (value === "YES") {
      this.setState({
        ipv: { value: "YES" },
      });
    } else if (value === "NO") {
      this.setState({
        ipv: {
          value: "NO",
          errorMessage: "(It is mandatory to perform IPV to proceed )",
        },
      });
    }
  };

  showError = (label) => (event) => {
    switch (label) {
      case "mobile":
        if (!event.target.value) {
          this.setState({
            [label]: {
              value: "",
              isError: true,
              errorMessage: "Mobile No is Mandatory*",
            },
          });
        } else if (!/^[6-9]\d{9}$/.test(event.target.value)) {
          this.setState({
            [label]: {
              value: "",
              isError: true,
              errorMessage: "Enter a valid Mobile Number",
            },
          });
        } else {
          this.setState({
            [label]: {
              value: event.target.value,
              isError: false,
              errorMessage: "",
            },
          });
        }
        break;
      case "email":
        if (!event.target.value) {
          this.setState({
            [label]: {
              value: "",
              isError: true,
              errorMessage: "Email id is Mandatory",
            },
          });
        } else if (
          !/^(?!.{51})([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?))*$/.test(
            event.target.value
          )
        ) {
          this.setState({
            [label]: {
              value: "",
              isError: true,
              errorMessage: "Enter a valid Email Id",
            },
          });
        } else {
          this.setState({
            [label]: {
              value: event.target.value,
              isError: false,
              errorMessage: "",
            },
          });
        }
        break;
      case "name":
        if (!event.target.value) {
          this.setState({
            [label]: {
              value: "",
              isError: true,
              errorMessage: "Name is Mandatory",
            },
          });
        } else if (!/^[a-zA-Z ]*$/.test(event.target.value)) {
          this.setState({
            [label]: {
              value: "",
              isError: true,
              errorMessage: "Enter a valid Name",
            },
          });
        } else {
          this.setState({
            [label]: {
              value: event.target.value,
              isError: false,
              errorMessage: "",
            },
          });
        }
        break;
      default:
        this.setState({
          [label]: {
            value: event.target.value,
          },
        });
        break;
    }
  };
  getKycDetails = async (pan) => {
    const params = {
      i_pan: pan,
      fund: "105",
      userid: this.props.userId,
    };
    const kycResponse = await this.props.checkKyc(params);
    return kycResponse;
  };

  submit = async () => {
    const { ipv, open, messageAlert, fund, display, kycType, ...others } =
      this.state;
    const keys = Object.keys(others);
    const findError = keys.every((key) => !!others[key].value);

    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "fund"
                  ? "Fund"
                  : key[0].toUpperCase() + key.substring(1)
              } is Mandatory*`,
            },
          });
      });
      return;
    }
    if (
      !!this.state.pan.value &&
      !/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.state.pan.value)
    ) {
      this.setState({
        pan: {
          value: "",
          isError: true,
          errorMessage: "please enter a valid PAN",
        },
      });
      return;
    }
    if (
      !!this.state.email.value &&
      !/^(?!.{51})([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?))*$/.test(
        this.state.email.value
      )
    ) {
      this.setState({
        email: {
          value: "",
          isError: true,
          errorMessage: "Please enter a valid Email Id",
        },
      });
      return;
    }
    if (
      !!this.state.mobile.value &&
      !/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
        this.state.mobile.value
      )
    ) {
      this.setState({
        mobile: {
          value: "",
          isError: true,
          errorMessage: `Please enter a valid Mobile Number`,
        },
      });
      return;
    } else if (
      this.state.ipv.value === "NO" &&
      this.state.kycType.value === "DKYC"
    ) {
      this.setState({
        ipv: {
          value: "NO",
          // isError: true,
          errorMessage: "(It is mandatory to perform IPV to proceed )",
        },
      });
    } else {
      // if (this.state.kycType.value === "IKYC") {
      const { fund, name, email, mobile, pan } = this.state;

      const params = {
        InvPan: this.state.pan.value,
        InvMobile: mobile.value,
        InvFund: "105",
        InvDistFlag: "P",
        Url: "",
        InvName: name.value,
        DistUserId: this.props.userId,
      };
      const addedParms = {
        IMEI: encodeURIComponent(btoa("127.0.0.1")),
        DistName: encodeURIComponent(btoa(this.props.userId)),
        ArnCode: encodeURIComponent(btoa(this.props.token)),
        APKVer: encodeURIComponent(btoa("1.0")),
        Adminpassword: encodeURIComponent(btoa("karvy1234%24")),
        InvEmail: encodeURIComponent(btoa(this.state.email.value)),
      };

      const response = await this.props.EKYCMail(params, addedParms);
      if (response && !response.error) {
        this.setState({
          open: true,
          messageAlert:
            "An email will be triggered to investor's email for uploading neccesary KYC documents and selfie video.please confirm",
        });
      }
      // }
      // else {
      //   const { fund, email, mobile, name } = this.state;
      //   window.open(
      //     `https://mfs.kfintech.com/ckyc/eKyc.aspx?UserID=2703&AgencyCode=105&AgencyTransactionID=123&Pan=${this.state.pan.value}&PanName=${name.value}&Mobile=${mobile.value}&EmailID=${email.value}&AuthType=3&ipv=y&EmpName=${this.props.userId}&EmpDesig=Manager&EmpCode=${this.props.token}&EmpLoc=Hyderabad&FundCode=105&fromType=DITWEB`,
      //     "_self"
      //   );
      // }
    }
  };

  closeModal = () => {
    this.setState({
      open: false,
      messageAlert: "",
    });
  };

  continue = () => {
    const { params } = this.props.match;
    this.setState({
      kycType: { value: "" },
      ipv: { value: "YES", errorMessage: "" },
      fund: { value: "" },
      pan: { value: !!params && !!params.pan ? params.pan : "" },
      open: false,
      messageAlert: "",
      name: { value: "" },
      email: { value: "" },
      mobile: { value: "" },
    });
    this.props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
  };

  handleChangeKycType = (event) => {
    const { match } = this.props;
    this.setState({
      kycType: { value: event.target.value },
      ipv: { value: "YES", errorMessage: "" },
      fund: { value: "" },
      pan: {
        value:
          !!match && !!match.params && !!match.params.pan
            ? match.params.pan
            : "",
      },
      name: { value: "" },
      email: { value: "" },
      mobile: { value: "" },
    });
  };
  handleKycStatus = async () => {
    if (!this.state.pan.value) {
      this.setState({
        pan: {
          value: "",
          isError: true,
          errorMessage: "PAN is Mandatory*",
        },
      });
      return;
    }
    if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.state.pan.value)) {
      this.setState({
        pan: {
          value: "",
          isError: true,
          errorMessage: "please enter a valid PAN",
        },
      });
      return;
    }
    this.setState({
      display: true,
    });
    const kycResponse = await this.getKycDetails(this.state.pan.value);
    if (!!kycResponse && !kycResponse.error) {
      const {
        DtData: [info],
      } = kycResponse;
      if (!!info && !!info.KYCBlock && info.KYCBlock === "N") {
        alert("PAN is KYC Registered");
        this.setState({
          pan: { value: "" },
          display: false,
        });
      } else {
        this.setState({
          display: false,
        });
      }
    }
  };
  render() {
    const { email, name, mobile, kycType, display } = this.state;
    const KYCTYPES = [
      // { key: "DKYC", label: "Distributor (eKYC)" },
      { key: "IKYC", label: "Online IPV with E-sign" },
    ];
    return (
      <div className="Ekyc">
        <div className="investor-title">eKYC Form</div>
        <CustomLoader display={!!display} />
        <Paper className="right-bar-container-paper">
          <div className="digital-form-Right-div">
            <div className="transaction-details">
              <div className="flex-row-center-between two-div">
                <div className="flex-grow-basis m-h-15 ekyc-margin">
                  <Input
                    name="KYC TYPE"
                    value={"Online IPV with E-sign"}
                    disable={true}
                  />
                  {/* <CustomizedSelect
                    name="KYC Type*"
                    handleChange={this.handleChangeKycType}
                    value={"IKYC"}
                    disabled={true}
                    isError={kycType.isError}
                    errorMessage={kycType.errorMessage}
                    isShowError={kycType.isError}
                    placeholder="Select KYC Type"
                  >
                    {KYCTYPES.map((ele) => (
                      <MenuItem value={ele.key} key={ele.key}>
                        {ele.label}
                      </MenuItem>
                    ))}
                  </CustomizedSelect> */}
                  <div className="no-data" style={{ textAlign: "left" }}>
                    {this.state.kycType.value === "IKYC"
                      ? ""
                      : this.state.kycType.value === "DKYC"
                      ? "Investor Presence Required*"
                      : ""}
                  </div>
                </div>
                <div className="flex-grow-basis m-h-15 ekyc-margin">
                  <div className="input-name">PAN*</div>
                  <div style={{ marginTop: 10 }}>
                    <Input
                      name=""
                      onChange={this.changeValue("pan")}
                      onBlur={this.handleKycStatus}
                      value={this.state.pan.value}
                      isError={name.isError}
                      isShowError={this.state.pan.isError}
                      errorMessage={this.state.pan.errorMessage}
                      disable={
                        !!this.props.match &&
                        !!this.props.match.params &&
                        !!this.props.match.params.pan
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="m-v-15 flex two-div">
                <div className="flex-grow-basis dual-margin ">
                  <Input
                    name="Email ID*"
                    onChange={this.changeValue("email")}
                    value={email.value}
                    isError={name.isError}
                    isShowError={email.isError}
                    errorMessage={email.errorMessage}
                    onBlur={this.showError("email")}
                  />
                </div>
                <div className="flex-grow-basis dual-margin">
                  <div className="input-name">Name*</div>
                  <div style={{ marginTop: 10 }}>
                    <Input
                      name=""
                      onChange={this.changeValue("name")}
                      onBlur={this.showError("name")}
                      value={name.value}
                      isShowError={name.isError}
                      isError={name.isError}
                      errorMessage={name.errorMessage}
                    />
                  </div>
                </div>
              </div>
              <div className="m-v-15 flex two-div">
                <div className="flex-grow-basis dual-margin ">
                  <Input
                    name="Mobile*"
                    onChange={this.changeValue("mobile")}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === "." || evt.key === "-") &&
                      evt.preventDefault()
                    }
                    type="number"
                    value={mobile.value}
                    onBlur={this.showError("mobile")}
                    isError={mobile.isError}
                    isShowError={mobile.isError}
                    errorMessage={mobile.errorMessage}
                  />
                </div>
                <div className="flex-grow-basis dual-margin "></div>
              </div>

              {this.state.kycType.value === "IKYC" && (
                <div className="instructions-box">
                  <div className="instruction-title">Instructions</div>
                  <ul>
                    <li className="instruction-mode">
                      This process will send an Email to your client for
                      completing the KYC process
                    </li>
                    <li className="instruction-mode">
                      Your client has to upload relevant documents and record a
                      video of self by showing the documents.{" "}
                    </li>
                    <li className="instruction-mode">
                      Please inform and guide your client accordingly.
                    </li>
                  </ul>
                </div>
              )}

              {this.state.kycType.value === "DKYC" && (
                <div className="m-v-15 flex">
                  <div className="flex-grow-basis dual-margin">
                    <div
                      style={{
                        fontSize: 15,
                        fontFamily: "SourceSansPro-SemiBold",
                      }}
                    >
                      Required Documents
                    </div>
                    <ul
                      className="redemption-details"
                      style={{
                        color: "#00579b",
                        backgroundColor: "#f4f4f4",
                        fontFamily: "SourceSansPro-SemiBold",
                        lineHeight: 5,
                      }}
                    >
                      <li>Aadhaar XML or PDF File</li>
                      <li>Share code</li>
                    </ul>
                  </div>
                  <div className="flex-grow-basis dual-margin"> </div>
                </div>
              )}

              {this.state.kycType.value === "DKYC" && (
                <div className="instructions-box">
                  <div className="instruction-title">Instructions</div>
                  <ul>
                    <li className="instruction-mode">
                      This process is based on e-Aadhaar, please keep the masked
                      e-Aadhaar copy and password ready before initiating.
                    </li>
                    <li className="instruction-mode">
                      First investment is allowed only towards the fund
                      selected.
                    </li>
                  </ul>
                </div>
              )}

              <div className="flex-grow-basis flex-row-center-center">
                <Signbutton
                  buttontitle="Submit"
                  onClick={this.submit}
                  width={350}
                />
              </div>
              <KycNotDoneDialog
                message={this.state.messageAlert}
                open={this.state.open}
                continue={this.continue}
                close={this.closeModal}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const { token, userId } = authReducer;
    const { fundsList = [] } = transactions;
    return {
      token,
      userId,
      fundsList,
    };
  },
  { getNewPurchaseFundList, EKYCMail, checkKyc }
)(EKYC);
