export const BUILD_URL = process.env.PUBLIC_URL;

function AddingbuildURL(label) {
  return BUILD_URL + label;
}
export const APP_LOGO = AddingbuildURL("/images/sundaram-logo.svg");
export const second_app_logo = AddingbuildURL("/images/JMF_logo.jpg");

// Login IMAGES

export const carouselImage4 = AddingbuildURL("/images/su_gbf.jpg");
export const carouselImage2 = AddingbuildURL("/images/jmmf_small_cap.jpg");
export const carouselImage3 = AddingbuildURL("/images/su_sf.jpg");
export const carouselImage1 = AddingbuildURL("/images/carousel_1.svg");
export const carouselImage5 = AddingbuildURL("/images/JMF_latest_banner.jpg");
export const carouselImage6 = AddingbuildURL("/images/JM_Flexicap_Distributor.jpg");

// HOME ICONS

export const dashboardActive = AddingbuildURL(
  "/images/jm_dashboard_active.svg"
);
export const investorsActive = AddingbuildURL("/images/jm_investor_active.svg");
export const newTransactionActive = AddingbuildURL(
  "/images/jm_new_transaction_active.svg"
);
export const navActive = AddingbuildURL("/images/jm_nav_active.svg");
export const mailBackActive = AddingbuildURL(
  "/images/jm_mailback_request_active.svg"
);
export const transactionHistoryActive = AddingbuildURL(
  "/images/su_transaction_history_color.svg"
);
export const ekycActive = AddingbuildURL("/images/jm_ekyc_active.svg");
export const earningsActive = AddingbuildURL(
  "/images/jm_my_earnings_active.svg"
);
export const supportActive = AddingbuildURL("/images/customer-service.svg");
export const settingsActive = AddingbuildURL("/images/settings_active.png");

//app-side-bar-inactive-images

export const dashboardInActive = AddingbuildURL(
  "/images/jm_dashboard_inactive.svg"
);
export const investorsInActive = AddingbuildURL(
  "/images/jm_investor_inactive.svg"
);
export const newTransactionInActive = AddingbuildURL(
  "/images/jm_new_transaction_inactive.svg"
);
export const navInActive = AddingbuildURL("/images/jm_nav_inactive.svg");
export const mailBackInActive = AddingbuildURL(
  "/images/jm_mailback_request_inactive.svg"
);
export const transactionHistoryInActive = AddingbuildURL(
  "/images/su_transaction_history.svg"
);
export const ekycInActive = AddingbuildURL("/images/jm_ekyc_inactive.svg");
export const earningsInActive = AddingbuildURL(
  "/images/jm_my-earnings_inactive.svg"
);
export const supportInActive = AddingbuildURL("/images/support.png");
export const settingsInactive = AddingbuildURL("/images/su_settings.svg");

export const LOGOUT_ICON = AddingbuildURL("/images/jm_logout_topnav.svg");

// MAILBACK ICONS

export const MailBackSoaIcon = AddingbuildURL("/images/jm_mailback_SOA.svg");
export const CASIcon = AddingbuildURL(
  "/images/jm_consolidated_account_statement.svg"
);
export const ELSSIcon = AddingbuildURL("/images/jm_ELSS_account_statement.svg");
export const CANIcon = AddingbuildURL("/images/jm_CAN_based_acc_statement.svg");
export const CGSIcon = AddingbuildURL("/images/jm_capital_gain_statement.svg");
export const GASIcon = AddingbuildURL(
  "/images/jm_grandfathering_account_statement.svg"
);
export const networthIcon = AddingbuildURL("/images/jm_networth_report.svg");
export const mailbackRightarrow = AddingbuildURL(
  "/images/arrow_right_grey.svg"
);

// Loader ICON :

export const Loader_icon = AddingbuildURL("/images/JMF_logo.jpg");

// Transaction ICONS

export const NEW_PURCHASE_ICON = AddingbuildURL("/images/jm_new_purchase.svg");
export const SIP_ICON = AddingbuildURL("/images/jm_SIP.svg");
export const ADDITIONAL_PURCHASE_ICON = AddingbuildURL(
  "/images/jm_additional_purchase.svg"
);
export const REDEMPTION_ICON = AddingbuildURL("/images/jm_redemption.svg");
export const SWITCH_ICON = AddingbuildURL("/images/jm_switch.svg");
export const STP_ICON = AddingbuildURL("/images/jm_stp.svg");
export const SWP_ICON = AddingbuildURL("/images/jm_swp.svg");
export const ENACH_ICON = AddingbuildURL("/images/nach.svg");
export const SIP_PAUSE_ICON = AddingbuildURL("/images/jm_SIP_cancel.svg");

// eye icons

export const EYE_ICON = AddingbuildURL("/images/su_eye_icon_unhidden.svg");
export const EYE_ICON_HIDDEN = AddingbuildURL("/images/su_eyeicon.svg");
