import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          color: "#606C78",
          backgroundColor: "white",
          zIndex: 99999999999,
          fontSize: 15,
          padding: "5px 0px",
          textAlign: "center",
          boxShadow: "0px 0px 3px 0px lightgray",
          fontFamily: "SourceSansPro-Regular",
        }}
      >
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          Mutual Fund investments are subject to market risks, read all scheme
          related documents carefully.
        </div>{" "}
        <div>
          © 2021, JM FINANCIAL MUTUAL FUND, All rights
          reserved.
        </div>
      </div>
    );
  }
}
