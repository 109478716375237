import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { SHOW_PURCHASE_CONFIRMATION_SUCCESS, GET_FUNDS_LIST_SUCCESS, GET_CATEGORY_LIST_SUCCESS, GET_SCHEME_LIST_SUCCESS, VALIDATE_SUB_BROKER_SUCCESS, CONFIRM_PURCHASE_SUCCESS, SET_SIP_DETAILS, SIP_SAVE_DATA_SUCCESS, SET_SWITCH_DETAILS, SWITCH_SAVE_DATA_SUCCESS, SET_STP_DETAILS, STP_SAVE_DATA_SUCCESS, GET_EARNINGS_SUCCESS, GET_EARNINGS } from "../types";
export function transactions() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    confirmationData: {},
    fundsList: [],
    categoryList: [],
    schemeList: [],
    euinList: [],
    sipDetails: {},
    switchDetails: {},
    stpDetails: {},
    earnings: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SHOW_PURCHASE_CONFIRMATION_SUCCESS:
      {
        var _action$body = action.body,
            DtData = _action$body.DtData,
            Dtdata = _action$body.Dtdata;

        var _ref = DtData || Dtdata,
            _ref2 = _slicedToArray(_ref, 1),
            data = _ref2[0];

        return _objectSpread({}, state, {
          confirmationData: _objectSpread({}, data)
        });
      }

    case GET_FUNDS_LIST_SUCCESS:
      {
        var _action$body$Dtinform = action.body.Dtinformation,
            fundsList = _action$body$Dtinform === void 0 ? [] : _action$body$Dtinform;
        return _objectSpread({}, state, {
          fundsList: fundsList,
          categoryList: [],
          schemeList: []
        });
      }

    case GET_CATEGORY_LIST_SUCCESS:
      {
        var _action$body$Dtinform2 = action.body.Dtinformation,
            categoryList = _action$body$Dtinform2 === void 0 ? [] : _action$body$Dtinform2;
        return _objectSpread({}, state, {
          categoryList: categoryList,
          schemeList: []
        });
      }

    case GET_SCHEME_LIST_SUCCESS:
      {
        var _action$body$Dtinform3 = action.body.Dtinformation,
            schemeList = _action$body$Dtinform3 === void 0 ? [] : _action$body$Dtinform3;
        return _objectSpread({}, state, {
          schemeList: schemeList
        });
      }

    case VALIDATE_SUB_BROKER_SUCCESS:
      {
        var _action$body$DtData = action.body.DtData,
            euinList = _action$body$DtData === void 0 ? [] : _action$body$DtData;
        return _objectSpread({}, state, {
          euinList: euinList
        });
      }

    case CONFIRM_PURCHASE_SUCCESS:
      {
        return _objectSpread({}, state, {
          confirmationData: {}
        });
      }

    case SET_SIP_DETAILS:
      {
        return _objectSpread({}, state, {
          sipDetails: action.body
        });
      }

    case SIP_SAVE_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          sipDetails: {}
        });
      }

    case SET_SWITCH_DETAILS:
      {
        return _objectSpread({}, state, {
          switchDetails: action.body
        });
      }

    case SWITCH_SAVE_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          switchDetails: {}
        });
      }

    case SET_STP_DETAILS:
      {
        return _objectSpread({}, state, {
          stpDetails: action.body
        });
      }

    case STP_SAVE_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          stpDetails: {}
        });
      }

    case GET_EARNINGS:
      {
        return _objectSpread({}, state, {
          earnings: []
        });
      }

    case GET_EARNINGS_SUCCESS:
      {
        var _action$body$DtData2 = action.body.DtData,
            earnings = _action$body$DtData2 === void 0 ? [] : _action$body$DtData2;
        return _objectSpread({}, state, {
          earnings: earnings
        });
      }

    default:
      return state;
  }
}