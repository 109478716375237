import React, { Component } from "react";
import CommonTemplate from "./commonTemplate";
import Signbutton from "../forms/button";
import { Input } from "../forms/input";
import { connect } from "react-redux";
import { resetPassword, passwordPolicy_JMF } from "shared/store/actions";
import CustomizedDialogs from "../home/customModal";
import { BUILD_URL } from "../home/home";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../forms/appEssentials";


const strongRegex = new RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      confirmShowPassword: false,
      password: { value: "" },
      confirmPassword: { value: "" },
      open: false
    };
  }

  changeValue = label => event => {
    this.setState({
      [label]: { value: event.target.value }
    });
  };

  showError = label => async () => {
    const {
      [label]: { value }
    } = this.state;
    if (label === "password") {
      if (!strongRegex.test(value)) {
        this.setState({
          [label]: { value, isShowPasswordError: true }
        });
      }
    }
    else {
      this.setState({
        [label]: { value, isError: !value }
      });
    }
  };

  keyPress = label => e => {
    if (e.key === "Enter") {
      if (label === "password") {
        this.confirmPasswordField.focus();
      } else {
        this.submit();
      }
      e.preventDefault();
    }
  };

  closeModal = () => {
    this.setState({
      open: false
    });
    this.props.history.push(BUILD_URL + "/");
  };

  submit = async () => {
    const { password, confirmPassword } = this.state;
    if (!password.value || !strongRegex.test(password.value)) {
      this.setState({
        password: { value: "", isShowPasswordError: true }
      });
      this.passwordField.focus();
      return;
    }
    if (!confirmPassword.value) {
      this.setState({
        confirmPassword: { value: "", isError: true }
      });
      this.confirmPasswordField.focus();
      return;
    }
    if (password.value !== confirmPassword.value) {
      this.setState({
        confirmPassword: {
          value: "",
          isError: true,
          errorMessage: "and Password must be same"
        }
      });
    } else {
      const params = {
        ReqBy: "a",
        newPassword: this.state.password.value,
        userid: this.props.location.state.userId.value
      };
      const response = await this.props.resetPassword(params);
      if (!!response && !response.error) {
        this.setState({
          open: true,
          showPassword: false,
          confirmShowPassword: false,
          password: { value: "" },
          confirmPassword: { value: "" }
        });
      }else if(!!response && !!response.error){
        alert(`${response.error}`)
        this.setState({
          showPassword: false,
          confirmShowPassword: false,
          password: { value: "" },
          confirmPassword: { value: "" }
        })
      }
    }
  };

  render() {
    const { password, confirmPassword, textOne } = this.state;
    return (
      <CommonTemplate title="Reset Password" textOne={textOne}>

        <Input
          name="Password"
          type={!!this.state.showPassword ? "text" : "password"}
          height={15}
          onClick={() =>
            this.setState({
              showPassword: !this.state.showPassword
            })
          }
          image={"/images/locked.svg"}
          image2={
            !!this.state.showPassword
              ? EYE_ICON
              : EYE_ICON_HIDDEN
          }
          value={password.value}
          onChange={this.changeValue("password")}
          onBlur={this.showError("password")}
          isShowError={password.isError}
          isShowPasswordError={password.isShowPasswordError}
          inputref={ele => (this.passwordField = ele)}
          onKeyPress={this.keyPress("password")}
          errorMessage={password.errorMessage}
        />
        <div className="password-regualations input-margin">
          <span className="pass-star">*</span> Password should be atleast 8
          characters length & should contain atleast 1 uppercase alphabet(A-Z),1
          special character& 1 numeric character
        </div>
        <Input
          name="Confirm Password"
          type={!!this.state.confirmShowPassword ? "text" : "password"}
          height={15}
          onClick={() =>
            this.setState({
              confirmShowPassword: !this.state.confirmShowPassword
            })
          }
          image={"/images/locked.svg"}
          image2={
            !!this.state.confirmShowPassword
              ? EYE_ICON
              : EYE_ICON_HIDDEN
          }
          value={confirmPassword.value}
          onChange={this.changeValue("confirmPassword")}
          onBlur={this.showError("confirmPassword")}
          isShowError={confirmPassword.isError}
          inputref={ele => (this.confirmPasswordField = ele)}
          onKeyPress={this.keyPress("confirmPassword")}
          errorMessage={confirmPassword.errorMessage}
        />
        <Signbutton buttontitle="Submit" onClick={this.submit} />
        <CustomizedDialogs
          open={this.state.open}
          closeModal={this.closeModal}
          passwordSucess={true}
          message={"Password Updated Successfully"}
        />
      </CommonTemplate>
    );
  }
}

export default connect(
  null,
  { resetPassword, passwordPolicy_JMF }
)(ResetPassword);
