import React, { Component } from "react";
import CustomizedSelect from "./customizedSelect";
import { Input } from "../../forms/input";
import Signbutton from "../../forms/button";
import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import {
  validateSubBroker,
  getAssetClasses,
  getOtherSchemes,
  swpOptions,
  CalcSIPEnddt,
  setStpDetails,
  getMinimumAmount,
} from "shared/store/actions";
import { REGULAR, PURCHASE, STP } from "shared/store/types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NEW_TRANSACTION_URL } from ".";
import { SundaramMutualFund } from "./newPurchaseForm";
import { INVESTOR_URL } from "../investors";
import { Checkbox } from "@material-ui/core";

class Stp extends Component {
  constructor(props) {
    super(props);
    const { stpDetails } = this.props;
    this.state = {
      fund: { value: { value: "105" } },
      folios: [],
      folio: { value: "" },
      schemes: [],
      scheme: { value: "" },
      radioValue: { value: "no" },
      arnCode: { value: this.props.token },
      subArnCode: { value: "" },
      euinNo: { value: "" },
      subBrokerCode: { value: "" },
      categories: [],
      category: { value: "" },
      switchInSchemes: [],
      switchInScheme: { value: "" },
      sipCycle: [],
      sipDay: { value: "" },
      transfers: { value: "" },
      sipEndDate: { value: "" },
      sipStartDate: { value: "" },
      stpDates: [],
      swpOptions: [],
      swpOption: { value: "" },
      amount: { value: "" },
      minAmount: "0",
      swpFrequency: { value: "" },
      minimumInstallments: "",
      sipdatetrueorfalse: true,
      perpetualSip: false,
      ...stpDetails,
    };
  }

  handleFundChange = (value) => {
    if (!value) {
      this.setState({
        fund: { value: "" },
        folios: [],
        folio: { value: "" },
        subArnCode: { value: "" },
        euinNo: { value: "" },
        subBrokerCode: { value: "" },
        categories: [],
        category: { value: "" },
        switchInSchemes: [],
        switchInScheme: { value: "" },
        sipCycle: [],
        sipDay: { value: "" },
        transfers: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        swpOptions: [],
        swpOption: { value: "" },
        amount: { value: "" },
        swpFrequency: { value: "" },
        schemes: [],
        scheme: { value: "" },
      });
      return;
    } else if (value.value === "105") {
      return;
    } else {
      const fund = value.value;
      const folios = this.props.fundList.reduce((prev, curr) => {
        if (curr.Fund === fund) {
          return [...prev, curr.Acno];
        }
        return prev;
      }, []);
      this.setState({
        fund: { value },
        folios: Array.from(new Set(folios)),
        folio: { value: "" },
        scheme: { value: "" },
        schemes: [],
        subArnCode: { value: "" },
        euinNo: { value: "" },
        subBrokerCode: { value: "" },
        categories: [],
        category: { value: "" },
        switchInSchemes: [],
        switchInScheme: { value: "" },
        sipCycle: [],
        sipDay: { value: "" },
        transfers: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        swpOptions: [],
        swpOption: { value: "" },
        amount: { value: "" },
        swpFrequency: { value: "" },
      });
      if (!!fund) {
        this.validateBrokerCode(fund)();
      }
    }
  };

  async componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    this.getFolios();
  }
  getFolios = () => {
    const folios = this.props.fundList.reduce((prev, curr) => {
      if (curr.Fund.trim() === "105") {
        return [...prev, curr.Acno];
      }
      return prev;
    }, []);

    this.setState({
      fund: { value: { value: "105" } },
      folios: Array.from(new Set(folios)),
      folio: { value: "" },
      scheme: { value: "" },
      schemes: [],
      subArnCode: { value: "" },
      euinNo: { value: "" },
      subBrokerCode: { value: "" },
      categories: [],
      category: { value: "" },
      switchInSchemes: [],
      switchInScheme: { value: "" },
      sipCycle: [],
      sipDay: { value: "" },
      transfers: { value: "" },
      sipEndDate: { value: "" },
      sipStartDate: { value: "" },
      swpOptions: [],
      swpOption: { value: "" },
      amount: { value: "" },
      swpFrequency: { value: "" },
    });
    this.validateBrokerCode("105")();
  };

  validateBrokerCode = (fund) => async () => {
    const { subArnCode, arnCode } = this.state;
    if (
      !!this.state.subArnCode.value &&
      this.state.subArnCode.value === this.state.arnCode.value.split("-")[1]
    ) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: "ARN and Sub ARN code should not be the same",
        },
      });
      return;
    }
    const params = {
      Fund: "105",
      SubAgentCd: !!subArnCode.value ? `ARN-${subArnCode.value}` : "",
      AgentCd: arnCode.value,
    };
    const response = await this.props.validateSubBroker(params);
    if (!response) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `Please enter a valid sub ARN code`,
        },
      });
    }
  };

  handleFolioChange = (value) => {
    if (!value) {
      this.setState({
        folio: { value: "" },
        schemes: [],
        scheme: { value: "" },
        subArnCode: { value: "" },
        euinNo: { value: "" },
        subBrokerCode: { value: "" },
        categories: [],
        category: { value: "" },
        switchInSchemes: [],
        switchInScheme: { value: "" },
        sipCycle: [],
        sipDay: { value: "" },
        transfers: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        swpOptions: [],
        swpOption: { value: "" },
        amount: { value: "" },
        swpFrequency: { value: "" },
      });
      return;
    } else if (value.value === this.state.folio.value.value) {
      return;
    } else {
      this.getAssetClasses(value.value);
      const schemes = this.schemes(value.value);

      this.setState({
        folio: { value },
        schemes,
        scheme: { value: "" },
      });
    }
  };

  getAssetClasses = async (value) => {
    const params = {
      Fund: "105",
      Folio: value,
    };
    const assetClasses = await this.props.getAssetClasses(params);
    if (assetClasses) {
      this.setState({
        categories: assetClasses.DtData,
      });
    }
  };

  schemes = (value) => {
    return this.props.fundList.reduce((prev, curr) => {
      if (curr.Fund.trim() === "105" && curr.Acno === value) {
        return [...prev, curr];
      }
      return prev;
    }, []);
  };
  handleSchemeChange = (value) => {
    if (!value) {
      this.setState({
        scheme: { value: "" },
        category: { value: "" },
        switchInSchemes: [],
        switchInScheme: { value: "" },
        sipCycle: [],
        sipDay: { value: "" },
        transfers: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        swpOptions: [],
        swpOption: { value: "" },
        amount: { value: "" },
        swpFrequency: { value: "" },
      });
      return;
    } else if (value.value === this.state.scheme.value.value) {
      return;
    } else {
      if (!value.value.BalUnits) {
        this.setState({
          folio: {
            value: "",
            isError: "true",
            errorMessage: "STP in Zero balance folios is not accepted",
          },
          scheme: { value: "" },
        });
      } else {
        this.setState({
          scheme: { value },
          category: { value: "" },
          switchInSchemes: [],
          switchInScheme: { value: "" },
          sipCycle: [],
          sipDay: { value: "" },
          transfers: { value: "" },
          sipEndDate: { value: "" },
          sipStartDate: { value: "" },
          swpOptions: [],
          swpOption: { value: "" },
          amount: { value: "" },
          swpFrequency: { value: "" },
        });
      }
    }
  };
  handleCategoryChange = (event) => {
    if (event.target.value) {
      this.getOtherSchemes(event.target.value);
      this.setState({
        category: { value: event.target.value },
        switchInScheme: { value: "" },
        sipCycle: [],
        sipDay: { value: "" },
        transfers: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        swpOptions: [],
        swpOption: { value: "" },
      });
    } else {
      this.setState({
        category: { value: "" },
        switchInScheme: { value: "" },
      });
    }
  };
  getOtherSchemes = async (value) => {
    const params = {
      schtype: REGULAR,
      category: value,
      acno: this.state.folio.value.value,
      fundCode: "105",
      trantype: PURCHASE,
    };
    const otherSchemes = await this.props.getOtherSchemes(params);

    if (otherSchemes) {
      this.setState({
        switchInSchemes: otherSchemes.DtData,
      });
    }
  };
  handleSwitchInScheme = async (value) => {
    if (!value) {
      this.setState({
        switchInScheme: { value: "" },
        // sipCycle: [],
        sipDay: { value: "" },
        transfers: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        swpOptions: [],
        swpOption: { value: "" },
      });
      return;
    } else if (value.value === this.state.switchInScheme.value.value) {
      return;
    } else {
      const params = {
        opt: "SF",
        fund: "105",
        astscheme: "",
      };
      const sipCycleResponse = await this.props.swpOptions(params);
      const sipCycleResponseOptions = !!sipCycleResponse
        ? sipCycleResponse.Dtinformation
        : "";
      this.setState({
        switchInScheme: { value },
        stpDates: sipCycleResponseOptions,
      });
    }
  };
  changeValue = (label) => (event) => {
    if (label === "subArnCode" || label === "subBrokerCode") {
      if (/^\d{0,15}$/.test(event.target.value)) {
        this.setState({
          [label]: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: {
            value: "",
            isError: true,
            errorMessage: "Enter a valid number",
          },
        });
      }
    } else if (label === "radioValue" && event.target.value === "no") {
      if (!this.state.euinNo.value) {
        this.setState({
          radioValue: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: { value: event.target.value },
          euinNo: { value: "" },
        });
      }
    } else if (
      label !== "subArnCode" &&
      label !== "subBrokerCode" &&
      label === "radioValue" &&
      event.target.value !== "no"
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  async handleSwpDay(event) {
    const day = event.target.value;
    const x = parseFloat(this.state.transfers.value.replace(/,/g, ""));
    if (
      this.state.transfers.value !== "" &&
      (x > parseFloat(this.state.minimumInstallments) ||
        x === parseFloat(this.state.minimumInstallments))
    ) {
      const params = {
        Fund: "105",
        StartDate: day,
        installments: this.state.transfers.value,
        Frequency: this.state.swpFrequency.value,
        trtype: "STP",
        sch: this.state.scheme.value.value.Sch,
      };

      const response = await this.props.CalcSIPEnddt(params);
      const sipStartDateResponse = response.DtData[0].SIP_StartDate;
      const sipEndDateResponse = response.DtData[0].SIP_EndDate;
      this.setState({
        sipDay: { value: day },
        sipStartDate: { value: sipStartDateResponse },
        sipEndDate: { value: sipEndDateResponse },
        // swpOption: { value: "" }
      });
    }
  }
  handlechangeSIPEndDateChange = () => {
    const { perpetualSip } = this.state;
    if (!perpetualSip) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sipEndDate: { value: "31/12/2099" },
        transfers: {
          value: "",
          placeholder: "Perpetual STP",
          disabled: true,
        },
      });
    } else if (!!perpetualSip) {
      this.setState({
        perpetualSip: !this.state.perpetualSip,
        sipDay: { value: "" },
        sipEndDate: { value: "" },
        sipStartDate: { value: "" },
        transfers: { value: "", placeholder: "", disabled: false },
      });
    }
  };
  handleChangeTransfers = async (event) => {
    const transferValue = event.target.value;
    const params = {
      fund: "105",
      opt: "SWPW",
    };
    if (!this.state.swpOption.value && event.target.value.length <= 4) {
      // const response = await this.props.swpOptions(params);
      // const swpOptionsResponse = response.Dtinformation;
      if (!!transferValue && parseFloat(transferValue) <= 9999) {
        this.setState({
          transfers: { value: transferValue },
          swpOptions: [],
          sipDay: { value: "" },
          sipEndDate: { value: "" },
          sipStartDate: { value: "" },
          swpOption: { value: "" },
        });
      } else if (!transferValue) {
        this.setState({
          transfers: {
            value: "",
            isError: true,
            errorMessage: !!transferValue
              ? "*No of transfers value should not be more than 9999 as stated above"
              : "*Transfers value is mandatory",
          },
          swpOptions: [],
          sipDay: { value: "" },
          sipEndDate: { value: "" },
          sipStartDate: { value: "" },
          swpOption: { value: "" },
        });
      }
    } else if (event.target.value.length <= 3) {
      this.setState({
        transfers: { value: transferValue },
        sipEndDate: { value: "" },
        sipDay: { value: "" },
        sipStartDate: { value: "" },
        swpOption: { value: "" },
      });
    }
  };
  handleSwpOptionChange = (event) => {
    const swpOption = event.target.value;

    this.setState({
      swpOption: { value: swpOption },
      amount: { value: "" },
    });
  };
  handleChangeTransferAmount = (event) => {
    const transferAmount = event.target.value;
    if (event.target.value.length <= 10) {
      this.setState({
        amount: { value: transferAmount.replace(/^0+/, "") },
      });
    }
  };
  changeEuin = (value) => {
    this.setState({
      euinNo: { value },
    });
  };
  gotosipdatetrueorfalse = () => {
    this.setState({
      sipdatetrueorfalse: false,
    });
  };
  submit = () => {
    let amountValue;
    const {
      amount,
      transfers,
      sipEndDate,
      swpOption,
      sipStartDate,
      funds,
      folios,
      schemes,
      categories,
      switchInSchemes,
      sipCycle,
      subBrokerCode,
      subArnCode,
      radioValue,
      euinNo,
      fund,
      arnCode,
      swpOptions,
      swpFrequency,
      minAmount,
      stpDates,
      minimumInstallments,
      sipdatetrueorfalse,
      perpetualSip,
      ...others
    } = this.state;
    const Dailyminamount = 100;
    const MinRegularamount = 500;
    if (this.state.swpFrequency.value === "") {
      this.setState({
        swpFrequency: {
          value: "",
          isError: true,
          showError: true,
          errorMessage: "*STP Frequency is Mandatory",
        },
      });
    }
    if (!!this.state.amount) {
      amountValue = parseFloat(this.state.amount.value.replace(/,/g, ""));
    }
    if (this.state.transfers.value === "" && !perpetualSip) {
      this.setState({
        transfers: {
          value: "",
          isError: true,
          errorMessage: "*Please enter the No. of transfers",
        },
      });
    }
    if (this.state.euinNo.value === "") {
      this.setState({
        euinNo: {
          value: "",
          isError: true,
          errorMessage: "*EUIN Declaration is Mandatory",
        },
      });
    }
    const arn = this.state.arnCode.value.split("-");
    const keys = Object.keys(others);

    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "sipDay"
                  ? "*STP Day"
                  : key === "swpOption"
                  ? "*STP Option"
                  : key === "folio"
                  ? "*Folio"
                  : key === "scheme"
                  ? "*Scheme"
                  : key === "category"
                  ? "*Category"
                  : key === "switchInScheme"
                  ? "*Transfer to Scheme"
                  : key
              } is Mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        // case "fund": {
        //   this.fundField.focus();
        //   break;
        // }
        case "folio": {
          this.folioField.focus();
          break;
        }
        case "scheme": {
          this.schemeField.focus();
          break;
        }
        case "category": {
          this.categoryField.focus();
          break;
        }
        case "switchInScheme": {
          this.switchInSchemeField.focus();
          break;
        }
        case "sipDay": {
          this.swpDateField.focus();
          break;
        }
        case "swpOption": {
          this.swpField.focus();
          break;
        }
      }
    } else if (
      !!this.state.subArnCode.value &&
      arn[1] === this.state.subArnCode.value
    ) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `and ARN code should not match `,
        },
      });
    } else if (
      !!subBrokerCode.value &&
      !/^\d{3,15}$/.test(subBrokerCode.value)
    ) {
      this.setState({
        subBrokerCode: {
          value: "",
          isError: true,
          errorMessage: "Invalid Sub Broker Code",
        },
      });
      this.subBrokerField.focus();
      return;
    } else if (
      (amountValue < minAmount || this.state.amount.value === "") &&
      this.state.swpOption.value.Description === "Fixed"
    ) {
      if (this.state.amount.value === "") {
        this.setState({
          amount: {
            ...others["amount"],
            isError: true,
            errorMessage: `*Transfer amount value is mandatory`,
          },
        });
      }

      // } else if (amountValue < minAmount) {
      //   this.setState({
      //     amount: {
      //       ...others["amount"],
      //       isError: true,
      //       errorMessage: `Minimum amount is 1000 (₹) for monthly stp frequency`,
      //     },
      //   });
      // }
      // else if (amountValue > currValue) {
      //   this.setState({
      //     amount: {
      //       ...others["amount"],
      //       isError: true,
      //       errorMessage: `(Transfer amount is more than the current Value)`
      //     }
      //   });
      // }
    } else if (
      this.state.radioValue.value === "no" &&
      !this.state.euinNo.value
    ) {
      this.euinField.focus();
      this.setState({
        euinNo: {
          value: "",
          isError: true,
        },
      });
    } else if (
      (this.state.transfers.value === "" ||
        parseFloat(this.state.transfers.value) <
          parseFloat(this.state.minimumInstallments)) &&
      !perpetualSip
    ) {
      this.setState({
        transfers: {
          value: "",
          isError: true,
          errorMessage:
            "*Please check the no of transfers value as stated above",
        },
      });
    } else if (this.state.amount.value === "") {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: "*Transfer amount per installment is Mandatory",
        },
      });
    } else if (
      this.state.swpFrequency.value === "Daily" &&
      amountValue < Dailyminamount
    ) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `*Minimum amount should be ₹100 as stated above`,
        },
      });
    } else if (
      (this.state.swpFrequency.value === "Weekly" ||
        this.state.swpFrequency.value === "Fortnightly" ||
        this.state.swpFrequency.value === "Monthly" ||
        this.state.swpFrequency.value === "Quarterly") &&
      MinRegularamount > amountValue
    ) {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `*Amount should be between ₹500 to ₹3000 as stated above`,
        },
      });
    }

    // else if (!this.state.swpOption.value) {
    //   this.setState({
    //     swpOption: {
    //       value: "",
    //       isError: true,
    //       errorMessage: "STP option is mandatory*",
    //     },
    //   });
    // }
    else {
      const {
        scheme,
        amount,
        transfers,
        sipEndDate,
        swpOption,
        sipStartDate,
        sipDay,
        euinNo,
        switchInScheme,
        stpDates,
        ...stpDetails
      } = this.state;

      this.props.setStpDetails(this.state);

      this.props.history.push({
        pathname: INVESTOR_URL + "/transction-types/stp/confirm",
        state: {
          title: "STP Registration Summary",
          type: STP,
          Folio: { value: this.state.scheme.value.value.Acno },
          scheme: { Fund: "105" },
          ...stpDetails,
        },
      });
    }
  };

  showError = (label) => () => {
    let amountValue, currValue;
    const {
      [label]: { value },
    } = this.state;
    if (!!this.state.amount) {
      amountValue = parseFloat(this.state.amount.value.replace(/,/g, ""));
    }
    if (!!this.state.scheme.value) {
      currValue = parseFloat(
        this.state.scheme.value.value.CurValue.replace(/,/g, "")
      );
    }
    if (label === "transfers" && !!this.state.transfers.value) {
      this.setState({
        [label]: {
          value,
          isError:
            parseFloat(this.state.transfers.value) <
            parseFloat(this.state.minimumInstallments)
              ? true
              : !value,
          errorMessage:
            parseFloat(this.state.transfers.value) <
              parseFloat(this.state.minimumInstallments) ||
            this.state.transfers.value === ""
              ? `*No. of transfers should be minimum ${this.state.minimumInstallments} as mentioned above`
              : "",
        },
      });
    } else if (label === "transfers" && this.state.transfers.value === "") {
      this.setState({
        [label]: {
          value: "",
          isError: true,
          errorMessage: `*Please enter the No. of transfers`,
        },
      });
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };
  getMinimumInstallments = async () => {
    if (!this.state.amount.value) {
      this.setState({
        amount: {
          value: this.state.amount.value,
          isError: true,
          errorMessage: `*Transfer amount per installment is Mandatory`,
        },
      });
    } else if (this.state.amount.value < this.state.minAmount) {
      this.setState({
        amount: {
          value: this.state.amount.value,
          isError: true,
          errorMessage: `*Minimum amount should be ₹${this.state.minAmount} as mentioned below`,
        },
      });
    } 
  };
  handleChangeStpFrequency = async (event) => {
    var minAmount;
    let minInstallment="";
    if (event.target.value === this.state.swpFrequency.value) {
      return;
    } else {
      const params = {
        opt: "STPD",
        fund: "105",
        astscheme: event.target.value,
      };
      const sipCycleResponse = await this.props.swpOptions(params);
      const sipCycleResponseOptions = !!sipCycleResponse
        ? sipCycleResponse.Dtinformation
        : "";

      this.setState({
        swpFrequency: { value: event.target.value },
        transfers: { value: "" },
        sipCycle: sipCycleResponseOptions,
      });
      const minParams = {
        Fund: "105",
        Scheme: this.state.switchInScheme.value.value.fm_scheme,
        Trtype: "STP",
        Frequency: event.target.value,
      };
      const response = await this.props.getMinimumAmount(minParams);

      if (response) {
        const { DtData } = response;
        minAmount = DtData[0].minamt;
        minInstallment = DtData[0].mininst;
        this.setState({
          minAmount: minAmount,
          minimumInstallments:minInstallment
        });
      }
    }
  };

  render() {
    const {
      funds,
      fund,
      folio,
      folios,
      scheme,
      schemes,
      categories,
      category,
      arnCode,
      subArnCode,
      subBrokerCode,
      switchInScheme,
      switchInSchemes,
      euinNo,
      radioValue,
      sipCycle,
      sipDay,
      transfers,
      sipStartDate,
      sipEndDate,
      swpOptions,
      swpOption,
      stpDates,
      amount,
    } = this.state;
    // let e;
    // e = parseFloat(this.state.transfers.value.replace(/,/g, ""));
    const { euinList } = this.props;
    return (
      <div className="transaction-details">
        <div className="title">STP Registration</div>

        <div className="input-margin">
          <div className="input-name ">Folio*</div>
          <IntegrationReactSelect
            suggestions={folios.map((item) => ({
              label: item,
              value: item,
            }))}
            type="number"
            value={folio.value}
            placeholder="Select Folio"
            handleChange={this.handleFolioChange}
            inputRef={(ele) => (this.folioField = ele)}
            disabled={!fund.value}
            isError={folio.isError}
            errorMessage={folio.errorMessage}
          />
        </div>
        <div className="redemption-details">
          <div className="keys">
            <div>Balance Units </div>
            <div>Current Value</div>
          </div>
          <div className="values">
            <div>{!!scheme.value.value ? scheme.value.value.BalUnits : ""}</div>
            <div>
              {!!scheme.value.value ? `₹ ${scheme.value.value.CurValue}` : ""}
            </div>
          </div>
        </div>
        <div className="input-margin">
          <div className="input-name">Transfer from*</div>
          <IntegrationReactSelect
            suggestions={schemes.map((item) => ({
              value: item,
              label: item.PlnDesc
                ? `${item.SchDesc1} - ${item.PlnDesc} - ${item.OptDesc}`
                : item.fm_schdesc,
            }))}
            value={scheme.value}
            placeholder="Select Scheme"
            handleChange={this.handleSchemeChange}
            disabled={!folio.value}
            inputRef={(ele) => (this.schemeField = ele)}
            isError={scheme.isError}
            errorMessage={scheme.errorMessage}
          />
        </div>
        <div className="input-margin">
          <CustomizedSelect
            name="Scheme Category*"
            placeholder="Select Category"
            handleChange={this.handleCategoryChange}
            value={category.value}
            inputRef={(ele) => (this.categoryField = ele)}
            isError={category.isError}
            disabled={!scheme.value}
            errorMessage={category.errorMessage}
          >
            {categories.map((item, index) => (
              <MenuItem value={item.CatValue} key={index}>
                {item.CatValue}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>
        <div className="input-margin">
          <div className="input-name">Transfer to*</div>
          <IntegrationReactSelect
            suggestions={switchInSchemes.map((item) => ({
              label: item.fm_schdesc,
              value: item,
            }))}
            label="Fund"
            handleChange={this.handleSwitchInScheme}
            value={switchInScheme.value}
            placeholder="Select Transfer To Scheme*"
            inputRef={(ele) => (this.switchInSchemeField = ele)}
            isError={switchInScheme.isError}
            errorMessage={switchInScheme.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="ARN Code"
            value={arnCode.value.split("-")[1]}
            disabled={true}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Sub ARN Code"
            value={subArnCode.value}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            disabled={!fund.value}
            isShowError={subArnCode.isError}
            errorMessage={subArnCode.errorMessage}
            onBlur={
              !!fund.value ? this.validateBrokerCode(fund.value.value.Fund) : ""
            }
            onChange={this.changeValue("subArnCode")}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Sub Broker Code"
            value={subBrokerCode.value}
            isShowError={subBrokerCode.isError}
            errorMessage={subBrokerCode.errorMessage}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            onChange={this.changeValue("subBrokerCode")}
          />
        </div>
        <div className="input-field-container">
          <div className="input-name">EUIN Declaration*</div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Search"
              name="search"
              value={radioValue.value}
              style={{ flexDirection: "row" }}
              onChange={this.changeValue("radioValue")}
            >
              <div className="investor-radio-button">
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">YES</div>
              </div>
              <div className="investor-radio-button">
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">NO</div>
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        {radioValue.value === "no" ? (
          <div className="input-margin">
            <IntegrationReactSelect
              suggestions={euinList.map((item) => ({
                label: item.abm_agent,
                value: item.abm_agent,
              }))}
              handleChange={this.changeEuin}
              errorMessage={euinNo.errorMessage}
              value={euinNo.value}
              disabled={!euinList.length}
              placeholder="Choose EUIN"
              inputRef={(ele) => (this.euinField = ele)}
              isError={euinNo.isError}
            />
          </div>
        ) : (
          <div className="euin-text">
            I/We hereby confirm that the EUIN Declaration box has been
            intentionally left the blank by me/us as this is an 'execution-only'
            transaction without any interaction or advice by any personnel of
            the above distributor or notwithstanding the advice of
            in-appropriateness,if any provided by any personnel of the
            distributor and the distributor has not charged any advisory fees on
            this transaction
          </div>
        )}
        <div className="input-margin">
          <CustomizedSelect
            name="STP Frequency*"
            placeholder="STP Frequency"
            disabled={!switchInScheme.value}
            inputRef={(ele) => (this.stpFrequencyField = ele)}
            handleChange={this.handleChangeStpFrequency}
            value={this.state.swpFrequency.value}
            isError={this.state.swpFrequency.isError}
            showError={this.state.swpFrequency.isError}
            errorMessage={this.state.swpFrequency.errorMessage}
          >
            {stpDates.length > 0
              ? stpDates.map((item, index) => (
                  <MenuItem value={item.FrequencyID} key={index}>
                    {item.FrequencyDescription}
                  </MenuItem>
                ))
              : ""}
            {/* {
              <MenuItem value={switchInScheme.value !== "" ? "Monthly" : ""}>
                {switchInScheme.value !== "" ? "Monthly" : ""}
              </MenuItem>
            } */}
          </CustomizedSelect>
        </div>
        <Input
          name="Transfer amount per installment* "
          onKeyDown={(evt) =>
            (evt.key === "e" || evt.key === "." || evt.key === "-") &&
            evt.preventDefault()
          }
          onChange={this.handleChangeTransferAmount}
          inputRef={(ele) => (this.amountField = ele)}
          value={amount.value}
          type="number"
          onBlur={this.getMinimumInstallments}
          isShowError={amount.isError}
          errorMessage={amount.errorMessage}
        />
        <div
          style={{
            fontFamily: "OpenSans-SemiBold",
            fontSize: 14,
            marginBottom: 20,
          }}
        >
          <span
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 14,
              marginTop: 15,
            }}
          >
            {`Minimum Amount Rs. ${this.state.minAmount}`}
          </span>
        </div>
        <div className="input-margin">
          <Input
            name={`No. of Transfers* (Minimum: ${
              !!this.state.minimumInstallments
                ? this.state.minimumInstallments
                : ""
            })`}
            value={transfers.value}
            type="number"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            onChange={this.handleChangeTransfers}
            onBlur={this.showError("transfers")}
            isShowError={transfers.isError}
            errorMessage={transfers.errorMessage}
            placeholder={transfers.placeholder}
          />
        </div>

        <div className="input-margin" onClick={this.gotosipdatetrueorfalse}>
          <CustomizedSelect
            name="STP Date*"
            inputRef={(ele) => (this.swpDateField = ele)}
            isError={sipDay.isError}
            placeholder="STP Date"
            disabled={!this.state.transfers.value}
            value={
              this.state.sipDay.value
              // this.state.transfers.value !== "" && (e > 60 || e === 60)
              //   ? this.state.sipDay.value
              //   : ""
            }
            onBlur={this.showError("transfers")}
            handleChange={(event) => this.handleSwpDay(event)}
            errorMessage={sipDay.errorMessage}
          >
            {sipCycle.map((item, index) => (
              <MenuItem value={item.sip_cycleid} key={index}>
                {item.sip_cycleid}
              </MenuItem>
            ))}
          </CustomizedSelect>
          {this.sipdatetrueorfalse ? (
            <div style={{ fontFamily: "Ubuntu-Italic", color: "red" }}>
              *Please fill all options
            </div>
          ) : null}
        </div>
        {!!sipDay && !!sipDay.value && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              onClick={this.handlechangeSIPEndDateChange}
              value={!!this.state.perpetualSip}
              checked={!!this.state.perpetualSip}
            />
            <div style={{ fontFamily: "OpenSans-SemiBold", fontSize: 14 }}>
              Perpetual STP
            </div>
          </div>
        )}
        <div className="input-name">Time Period</div>
        <div className="input-margin">
          <Input
            name="From"
            value={sipStartDate.value}
            disabled={true}
            image={"/images/date_cal.svg"}
          />
        </div>
        <div className="input-margin">
          <Input
            name="To"
            disabled={true}
            value={sipEndDate.value}
            image={"/images/date_cal.svg"}
          />
        </div>
        {/* <div className="input-margin">
          <Input
            name="STP Option"
            value={"Fixed Amount Withdrawal"}
            disabled={true}
            image={"/images/date_cal.svg"}
          />
          <CustomizedSelect
            name="STP Option"
            placeholder="STP Option"
            inputRef={(ele) => (this.swpField = ele)}
            isError={swpOption.isError}
            errorMessage={swpOption.errorMessage}
            value={swpOption.value}
            handleChange={this.handleSwpOptionChange}
            // disabled={!sipDay.value}
          >

            {swpOptions.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item.Description}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div> */}
        {/* {swpOption.value.Description === "Fixed" ? ( */}

        {/* ) : (
          ""
        )} */}
        <div className="digital-form-button-next-div">
          <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const {
      fundList,
      token,
      userId,
      bankDetails,
      investorData,
      communicationDetails,
    } = authReducer;
    const { euinList, stpDetails = {} } = transactions;
    return {
      fundList,
      token,
      userId,
      euinList,
      stpDetails,
      bankDetails,
      investorData,
      communicationDetails,
    };
  },
  {
    validateSubBroker,
    getAssetClasses,
    getOtherSchemes,
    swpOptions,
    setStpDetails,
    CalcSIPEnddt,
    getMinimumAmount,
  }
)(Stp);
