import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class ProtectedRoute extends Component {
  render() {
    const { component: Component, token,auth_token, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          !!token && !!auth_token? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: `${process.env.PUBLIC_URL}/login`,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

export default connect(({ authReducer }) => {
  // const { authReducer } = rootReducer;
  const { token ,auth_token} = authReducer;
  return { token,auth_token };
})(ProtectedRoute);
