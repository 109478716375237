import React, { Component } from "react";
import { List } from "../transactions/nfoAndNewPurchase";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import Signbutton, { Debtbutton } from "../../forms/button";
import { Input } from "../../forms/input";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import CustomizedDialogs from "../../home/customModal";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  ASON,
  BETWEEN,
  SINGLESOA,
  MULTISOA,
} from "shared/store/types/mailBack";
import {
  GET_FUNDS_LIST,
  GET_FUNDS_LIST_SUCCESS,
  GET_FUNDS_LIST_FAILED,
  ALL,
} from "shared/store/types";
import {
  getInvestorDetails,
  getNewPurchaseFundList,
} from "shared/store/actions";
import {
  getDistFoliobyPan,
  getMailbackSoaStatus,
  // getNewPurchaseFundList
} from "shared/store/actions/mailback";
import {
  Select,
  Checkbox,
  ListItemText,
  Input as MaterialInput,
} from "@material-ui/core";
import { DASHBOARD_URL } from "../dashboard";
import { MailBackBreadCrumb } from "./mailback";
import { SundaramMutualFund } from "../transactions/newPurchaseForm";
import { EYE_ICON, EYE_ICON_HIDDEN } from "../../forms/appEssentials";
class MailBackSoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      names: ["sai", "anil", "ravi"],
      selectedNames: [],
      asDate: { value: null, error: false },
      DateON: ASON,
      fromDate: { value: null, error: false, errorMessage: "" },
      toDate: { value: null, error: false, errorMessage: "" },
      funds: [],
      fund: { value: "" },
      folios: [],
      folio: { value: "" },
      soaValue: SINGLESOA,
      ConfirmSelfPassword: { value: "" },
      selfPassword: { value: "" },
      open: false,
      showPassword: false,
      confirmShowPassword: false,
    };
  }

  async componentDidMount() {
    const params = {
      PAN: this.props.investorData.PAN,
      Fund: "105",
      Agent: this.props.token,
    };
    const response = await this.props.getDistFoliobyPan(params);
    if (response) {
      this.setState({
        folios: response.DtData.reduce((prev, curr) => {
          const alreadyExist = prev.find((item) => item === curr.acno);
          if (alreadyExist) {
            return prev;
          }
          return [...prev, curr.acno];
        }, []),
      });
    }
  }
  changeDateOn = (event) => () => {
    this.setState({
      DateON: event,
      selfPassword: { value: "" },
      ConfirmSelfPassword: { value: "" },
      fund: { value: "" },
      selectedNames: [],
    });
  };
  changeSoaValue = (event) => () => {
    this.setState({
      soaValue: event,
      selfPassword: { value: "" },
      ConfirmSelfPassword: { value: "" },
    });
  };

  handlechange = (event) => {
    const { selectedNames } = this.state;
    const { value } = event.target;
    if (selectedNames.includes(value)) {
      this.setState({
        selectedNames: selectedNames.filter((item) => item !== value),
      });
    } else {
      this.setState({
        selectedNames: [...selectedNames, value],
      });
    }
  };

  handleChangeFunds = async (value) => {
    if (value.value === this.state.fund.value.value) {
      return;
    } else {
      this.setState({
        fund: { value },
        selectedNames: [],
        selfPassword: { value: "" },
        ConfirmSelfPassword: { value: "" },
      });
      if (!!value) {
        const params = {
          PAN: this.props.investorData.PAN,
          Fund: value.value,
          Agent: this.props.token,
        };
        const response = await this.props.getDistFoliobyPan(params);
        if (response) {
          this.setState({
            folios: response.DtData.reduce((prev, curr) => {
              const alreadyExist = prev.find((item) => item === curr.acno);
              if (alreadyExist) {
                return prev;
              }
              return [...prev, curr.acno];
            }, []),
          });
        }
      }
    }
  };
  handleChangefolio = (event) => {
    const folio = event.target.value;
    if (this.state.fund.value === "") {
      this.setState({
        selectedNames: [],
      });
    } else {
      this.setState({
        folio: { value: folio },
        soaValue: { value: "" },
        selfPassword: { value: "" },
        ConfirmSelfPassword: { value: "" },
      });
    }
  };
  handleChangeSelfextractPassWord = (event) => {
    this.setState({
      selfExtract: { value: event.target.value },
    });
  };
  handleChangeConfirmSelfextractPassWord = (event) => {
    this.setState({
      confirmSelfExtract: { value: event.target.value },
    });
  };

  handleChangeSelfPassword = (event) => {
    this.setState({
      selfPassword: { value: event.target.value },
    });
  };
  handleChangeConfirmSelfPassword = (event) => {
    this.setState({
      ConfirmSelfPassword: { value: event.target.value },
    });
  };

  submit = async () => {
    const {
      selectedNames,
      asDate,
      DateON,
      fromDate,
      toDate,
      funds,
      fund,
      folios,
      folio,
      soaValue,
      showPassword,
      confirmShowPassword,
      names,
      open,
      ...others
    } = this.state;
    const keys = Object.keys(others);
    const d2 = new Date(this.state.fromDate.value).setHours(12, 0, 0, 0);
    const d3 = new Date(this.state.toDate.value).setHours(12, 0, 0, 0);
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({ [key]: { ...others[key], isError: true ,errorMessage:`${key==="selfPassword"?"Self-Password":key==="ConfirmSelfPassword"?"Confirm Self-Password":""} is mandatory` } });
      });
      return;
    } else if (this.state.selectedNames === []) {
      this.setState({
        password: {
          ...others["folio"],
          isError: true,
        },
      });
      return;
    } else if (
      others.selfPassword.value === "" ||
      others.selfPassword.value !== others.ConfirmSelfPassword.value
    ) {
      if (others.selfPassword.value === "") {
        this.setState({
          password: {
            ...others["selfPassword"],
            isError: true,
            errorMessage: `password is mandatory`,
          },
        });
      } else if (
        others.selfPassword.value !== others.ConfirmSelfPassword.value
      ) {
        this.setState({
          password: {
            ...others["ConfirmSelfPassword"],
            isError: true,
            errorMessage: `password Mismatch`,
          },
        });
      }

      return;
    } else if (others.ConfirmSelfPassword.value === "") {
      this.setState({
        password: {
          ...others["ConfirmSelfPassword"],
          isError: true,
          errorMessage: `password is mandatory`,
        },
      });
      return;
    } else if (
      !!d2 &&
      !!d3 &&
      (d2 > d3 || d2 === d3) &&
      this.state.DateON !== ASON
    ) {
      this.setState({
        toDate: {
          value: null,
          error: true,
          errorMessage: "(*From Date Should less than to Date)",
        },
      });
    } else if (
      (this.state.fromDate.value === null ||
        this.state.toDate.value === null) &&
      this.state.DateON !== ASON
    ) {
      if (this.state.fromDate.value === null) {
        this.setState({
          fromDate: { error: true },
        });
      }
      if (this.state.toDate.value === null) {
        this.setState({
          toDate: { error: true },
        });
      }
    } else {
      const params = {
        UserId: this.props.userId,
        Zippwd: this.state.selfPassword.value,
        RepType: "SOA",
        Arn: this.props.token,
        Pan: this.props.investorData.PAN,
        Acno: this.state.selectedNames.join(),
        Freq: this.state.soaValue === SINGLESOA ? "S" : "M",
        Fund: "105",
        stdt:
          this.state.DateON === ASON
            ? ""
            : this.state.fromDate.value
            ? this.state.fromDate.value.toLocaleDateString("en-US", {
                month: "2-digit",
                year: "numeric",
                day: "numeric",
              })
            : "",
        EndDt:
          this.state.DateON === ASON
            ? ""
            : this.state.toDate.value.toLocaleDateString("en-US", {
                month: "2-digit",
                year: "numeric",
                day: "numeric",
              }),
      };



      const response = await this.props.getMailbackSoaStatus(params);
      if (response) {
        this.setState({
          open: true,
        });
      }
    }
  };

  showError = (label) => (event) => {
    const {
      [label]: { value },
    } = this.state;

    if (label === "selfPassword") {
      if (this.state.selfPassword.value === "" || !/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.state.selfPassword.value)) {
        if(!/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.state.selfPassword.value)){
          this.setState({
            selfPassword: {
              value:this.state.selfPassword.value,
              isError: true,
              errorMessage: `Password should be at least 8 characters in length and should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)`,
            },
          });
          return;
        }
      }else if(this.state.selfPassword.value === "") {
          this.setState({
            selfPassword: {
              value:"",
              isError: true,
              errorMessage: `Password is mandatory`,
            },
          });
          return;
        }

    } else if (label === "email") {
      this.setState({
        [label]: {
          value,
          isError:
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
              this.state.email.value
            ) === false
              ? true
              : !value,
          errorMessage:
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
              this.state.email.value
            ) === false
              ? `(enter an vaild email address )`
              : "",
        },
      });
    } else if (label === "ConfirmSelfPassword") {
      this.setState({
        [label]: {
          value,
          isError:
            this.state.selfPassword.value !==
              this.state.ConfirmSelfPassword.value ||
            this.state.ConfirmSelfPassword.value === ""
              ? true
              : !value,
          errorMessage:
            this.state.selfPassword.value !==
              this.state.ConfirmSelfPassword.value ||
            this.state.ConfirmSelfPassword.value === ""
              ? `The Password and Confirm Password do not match`
              : "",
        },
      });
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
    this.props.history.replace(DASHBOARD_URL);
  };

  onDateChange = (label, event) => {
    if (label === "toDate" && !!this.state.fromDate.value && !!event  )  {
      const reformtStDt = this.state.fromDate.value.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });

    const changeEndDt = event.toLocaleDateString("en-US", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });


         const d2 = new Date(reformtStDt).setHours(12, 0, 0, 0);
         const d3 = new Date(changeEndDt).setHours(12, 0, 0, 0);
         if ( d2>d3|| d2 === d3) {
           this.setState({
             [label]: {
               value: null,
               error: true,
               errorMessage: "Start date should be earlier than End date",
             },
           });
         } else  {
           this.setState({
             [label]: { value: event, errorMessage: "", error: false },
           });
         }
       } else if (label === "fromDate") {
         this.setState({
           [label]: { value: event },
           toDate: { value: null, error: "", errorMessage: "" },
         });
       }
    // this.setState({ [label]: { value: event } });
  };
  render() {
    const { investorData, fundsList } = this.props;
    const {
      selectedNames,
      DateON,
      fund,
      folios,
      soaValue,
      ConfirmSelfPassword,
      selfPassword,
    } = this.state;
    return (
      <div style={{ marginTop: 20 }}>
        <MailBackBreadCrumb history={this.props.history} investorData={this.props.investorData} name="Mailback SOA"/>
        <Paper
          className="right-bar-container-paper"
          style={{display:"flex"}}
        >
          <div className="digital-form-left-div">
            <List investorData={investorData} />
          </div>

          <div className="digital-form-Right-div">
            <div className="transaction-details">
              <div className="title">Mail Back SOA</div>
              <div className="radio-buttons-div">
                <Debtbutton
                  buttontitle="As on Date"
                  width={150}
                  active={DateON === ASON}
                  onClick={this.changeDateOn(ASON)}
                />
                <Debtbutton
                  buttontitle="Between Dates"
                  width={150}
                  active={DateON === BETWEEN}
                  onClick={this.changeDateOn(BETWEEN)}
                />
              </div>
              {DateON === ASON ? (
                <div className="input-margin">
                  <div className="input-name">As on Date</div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required={true}
                      style={{
                        border:
                          this.state.toDate.error === true
                            ? "1px solid red"
                            : "1px solid rgba(211, 211, 211)",

                        borderRadius: this.state.toDate.error === true ? 7 : 7,
                      }}
                      className="selects"
                      onChange={(e) => this.onDateChange("toDate", e)}
                      // openTo={true}
                      disableFuture={true}
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      // animateYearScrolling={true}
                      id="mui-pickers-date"
                      disabled={true}
                      value={new Date()}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <div className="input-name">Start Date</div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required={true}
                      className="selects"
                      onChange={(e) => this.onDateChange("fromDate", e)}
                      // openTo={true}
                      style={{
                        border:
                          this.state.fromDate.error === true
                            ? "1px solid red"
                            : "1px solid rgba(211, 211, 211)",

                        borderRadius:
                          this.state.fromDate.error === true ? 7 : 7,
                          marginTop:0,
                          marginBottom:10
                      }}
                      disableFuture={true}
                      format="dd/MM/yyyy"
                      maxDate={new Date()}
                      margin="normal"
                      // animateYearScrolling={true}
                      id="mui-pickers-date"
                      value={this.state.fromDate.value}
                    />
                  </MuiPickersUtilsProvider>

                  <div >
                    <div className="input-name">End Date</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        required={true}
                        className="selects"
                        style={{
                          border:
                            this.state.toDate.error === true
                              ? "1px solid red"
                              : "1px solid rgba(211, 211, 211)",
                          borderRadius:
                            this.state.toDate.error === true ? "7px" : "7px",
                            marginTop:0,
                            marginBottom:10
                        }}
                        onChange={(e) => this.onDateChange("toDate", e)}
                        disabled={!this.state.fromDate.value}
                        disableFuture={true}
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        margin="normal"
                        id="mui-pickers-date"
                        value={this.state.toDate.value}
                      />
                    </MuiPickersUtilsProvider>
                    {!!this.state.toDate.errorMessage ? (
                      <div
                        style={{
                          color: "red",
                          fontFamily: "Ubuntu-Italic",
                        }}
                      >
                        {this.state.toDate.errorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}


              <div className="selects" style={{ marginTop: 20 }}>
                <div className="input-name">Folio</div>
                <Select
                  onChange={this.handleMultiSelect}
                  multiple
                  fullWidth
                  style={{
                    border:
                      selectedNames === []
                        ? "1px solid red"
                        : "1px solid rgba(211, 211, 211)",
                    borderRadius: selectedNames === [] ? 7 : 7,
                  }}
                  className="jimjam"
                  value={selectedNames}
                  input={<MaterialInput id="select-multiple-checkbox" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {folios.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Checkbox
                        onClick={this.handlechange}
                        value={item}
                        checked={selectedNames.indexOf(item.toString()) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <div className="input-name">SOA Type</div>
                <div className="radio-buttons-div">
                  <Debtbutton
                    buttontitle="Single SOA"
                    width={150}
                    active={soaValue === SINGLESOA}
                    onClick={this.changeSoaValue(SINGLESOA)}
                  />
                  <Debtbutton
                    buttontitle="Multi SOA"
                    width={150}
                    active={soaValue === MULTISOA}
                    onClick={this.changeSoaValue(MULTISOA)}
                  />
                </div>
              </div>
              <div className="input-margin">
                <Input
                  name="Self Extraction Password"
                  onChange={this.handleChangeSelfPassword}
                  value={selfPassword.value}
                  image2={
                    !!this.state.showPassword
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  type={!!this.state.showPassword ? "text" : "password"}
                  height={15}
                  onClick={() =>
                    this.setState({
                      showPassword: !this.state.showPassword,
                    })
                  }
                  onBlur={this.showError("selfPassword")}
                  isShowError={selfPassword.isError}
                  errorMessage={selfPassword.errorMessage}
                  bordercolor="rgba(0, 0, 0, 0.5)"
                  inputref={(ele) => (this.selfPasswordField = ele)}
                />
                <div style={{fontSize:14,fontFamily:"SourceSansPro-Regular",marginTop:4}}>
                  Password should be at least 8 characters in length and should contain at least 1 uppercase letter (A-Z), 1 special character and 1 numeric value (0-9)
                  </div>
              </div>
              <div className="input-margin">
                <Input
                  name="Confirm Self Extraction Password"
                  image2={
                    !!this.state.confirmShowPassword
                      ? EYE_ICON
                      : EYE_ICON_HIDDEN
                  }
                  onClick={() =>
                    this.setState({
                      confirmShowPassword: !this.state.confirmShowPassword,
                    })
                  }
                  type={!!this.state.confirmShowPassword ? "text" : "password"}
                  height={15}
                  onChange={this.handleChangeConfirmSelfPassword}
                  value={ConfirmSelfPassword.value}
                  onBlur={this.showError("ConfirmSelfPassword")}
                  isShowError={ConfirmSelfPassword.isError}
                  errorMessage={ConfirmSelfPassword.errorMessage}

                  bordercolor="rgba(0, 0, 0, 0.5)"
                  inputref={(ele) => (this.ConfirmSelfPasswordField = ele)}
                />
              </div>
              <div className="digital-form-button-next-div">
                <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
              </div>
              <CustomizedDialogs
                cas={true}
                open={this.state.open}
                closeYesModal={this.closeModal}
                // componentName="MailBackSoa"
              />
               
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const { investorData, userId, token } = authReducer;
    const { fundsList } = transactions;
    return { investorData, userId, token, fundsList };
  },
  {
    getInvestorDetails,
    getNewPurchaseFundList,
    getDistFoliobyPan,
    getMailbackSoaStatus,
  }
)(MailBackSoa);
