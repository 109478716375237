import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _slicedToArray from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { GET_DASHBOARD_DATA_SUCCESS, GET_TRANSACTION_DATA_SUCCESS, GET_TRANSACTION_DATA, GET_AUM_DATA_SUCCESS, GET_FREQUENT_MAIL_BACK_REQUEST_SUCCESS, GET_DASHBOARD_AUM_DATA_SUCCESS, GET_SIP_SUMMARY_DATA_SUCCESS, GET_NFO_SCHEMES_SUCCESS, GET_NFO_SCHEMES, REMOVE_NFO_SCHEMES } from "../types";
export function dashboard() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    summaryData: [],
    aumData: [],
    nfoSchemes: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_DASHBOARD_DATA_SUCCESS:
      {
        var DtData = action.body.DtData;

        var _DtData = _slicedToArray(DtData, 1),
            data = _DtData[0];

        return _objectSpread({}, state, {
          transactionSummary: data
        });
      }

    case GET_DASHBOARD_AUM_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          dashboardAUMData: action.body
        });
      }

    case GET_SIP_SUMMARY_DATA_SUCCESS:
      {
        return _objectSpread({}, state, {
          sipSummaryData: action.body
        });
      }

    case GET_TRANSACTION_DATA:
      {
        return _objectSpread({}, state, {
          summaryData: []
        });
      }

    case GET_TRANSACTION_DATA_SUCCESS:
      {
        var _DtData2 = action.body.DtData;
        return _objectSpread({}, state, {
          summaryData: _DtData2
        });
      }

    case GET_NFO_SCHEMES_SUCCESS:
      {
        return _objectSpread({}, state, {
          nfoSchemes: action.body
        });
      }

    case REMOVE_NFO_SCHEMES:
      {
        return _objectSpread({}, state, {
          nfoSchemes: []
        });
      }

    case GET_AUM_DATA_SUCCESS:
      {
        var _action$body = action.body,
            aumData = _action$body.DtData,
            fundData = _action$body.Table2,
            _action$body$Table = _slicedToArray(_action$body.Table3, 1),
            _data = _action$body$Table[0];

        return _objectSpread({}, state, {
          aumData: aumData.map(function (item) {
            return {
              name: item.category,
              value: item.aum
            };
          }),
          fundData: fundData.sort(function (a, b) {
            return b.aum_percent - a.aum_percent;
          }).slice(0, 5).map(function (item) {
            return {
              name: item.fund,
              value: item.aum_percent
            };
          }),
          totalAum: action.body.Table2.map(function (item) {
            return {
              aum: item.aum
            };
          }).reduce(function (p, c) {
            return p + parseFloat(c.aum);
          }, 0),
          my_aum: _data.my_aum,
          numberofschemes: _data.Numberofschemes
        });
      }

    case GET_FREQUENT_MAIL_BACK_REQUEST_SUCCESS:
      {
        return state;
      }

    default:
      return state;
  }
}