import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { simpleCardsStyles } from "../appCss";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { BUILD_URL } from "../home";
import CustomLoading from "../CustomLoader";
import {
  NEW_INVESTORS,
  INACTIVE_INVESTORS,
  ACTIVE_INVESTORS,
  EXPIRING_NEXT_MONTH,
  CLOSED_THIS_MONTH,
  SIP_INVESTMENTS,
} from "shared/store/types";

class DisplaySimpleCards extends Component {
  render() {
    const { classes } = this.props;
    const x = this.props.count + "";
    const nameArray = [
      "Authorization Expired",
      "Authorization Approved",
      "Authorization Pending",
      "New Investors",
      "Active Investors",
      "Inactive Investors",
      NEW_INVESTORS,
      INACTIVE_INVESTORS,
      ACTIVE_INVESTORS,
      EXPIRING_NEXT_MONTH,
      CLOSED_THIS_MONTH,
      SIP_INVESTMENTS,
    ];

    return (
      <Card className={this.props.mainClassName || "dashboard-summary-card" }>
        <CardContent
          className={classes.content}
          style={{ paddingBottom: 0, width: "100%" }}
        >
          <div
            // className={classes.displaySimpleCard}
            className="display-simple-cards-dashboard"
            style={{ cursor: this.props.name !== "No. LIVE SIPs" && "pointer" }}
            onClick={this.props.onClick}
          >
            <div className={classes.simpletwo}>
              <div>
                <img
                  src={`${BUILD_URL}${this.props.image}`}
                  style={{ height: this.props.height, width: this.props.width }}
                  className="simple-display-card-image"
                  alt={this.props.name}
                />
              </div>
              <div className="simple-display-cards-details">
                <div
                  // className={classes.name}
                  className="simple-display-card-name"
                >
                  {this.props.name}
                </div>

                {this.props.count >= 0 ? (
                  <div className="simple-display-card-count">
                    {!!x && x.length === 1 && x !== "0" ? "0" + x : x}
                  </div>
                ) : (
                  <CustomLoading width={25} height={25} type="ThreeDots" />
                )}
              </div>
            </div>

            {nameArray.includes(this.props.name) ? (
              <div className="transaction-status-cards">
                <img
                  src={BUILD_URL + "/images/jm_next.svg"}
                  height={35}
                  alt="next"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

DisplaySimpleCards.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(simpleCardsStyles)(DisplaySimpleCards);
