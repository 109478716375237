import _objectSpread from "/builds/karvy/dit-react/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { GET_DIST_POST_QUERY_DATA_SUCCESS } from "../types/support";
export function support() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    supportQueries: []
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case GET_DIST_POST_QUERY_DATA_SUCCESS:
      {
        var Dtinformation = action.body.Dtinformation;
        return _objectSpread({}, state, {
          supportQueries: Dtinformation
        });
      }

    default:
      return state;
  }
}