import React, { Component } from "react";
import { BUILD_URL } from "./home";

export default class UploadFile extends Component {
  render() {
    const { id, isShowError, fileName } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            border: "0.3px dashed ",
            borderColor: isShowError ? "red" : "lightblue",
            margin: "10px 0px"
          }}
        >
          <label
            htmlFor={id}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "20px 50px",
              marginBottom: -1,
              backgroundColor: "#f0f8ff"
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src={BUILD_URL + "/images/upload.svg"}
                style={{ height: 15 }}
                alt="upload_icon"
              />
              <div
                style={{
                  fontSize: 14,
                  color: "#00579b",
                  fontFamily: "SourceSansPro-SemiBold",
                  marginLeft: 10
                }}
              >
                Upload
              </div>
            </div>
          </label>
          <div style={{ padding: "20px 20px 0px" }}> {fileName}</div>
        </div>
        <input
          type="file"
          id={id}
          accept=".pdf"
          style={{ display: "none" }}
          onChange={this.props.uploadFile(id)}
        />
      </React.Fragment>
    );
  }
}
