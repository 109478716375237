import React, { Component } from "react";
import { Paper } from "@material-ui/core";
// import { INVESTORS_URL } from ".";
import { BUILD_URL } from "../home";

const INVESTORS_URL = `${process.env.PUBLIC_URL}/investors`;
export default class TransactionTypeCards extends Component {
  constructor() {
    super();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  getUrlByName = (name) => {
    let url = `/`;
    switch (name) {
      case "New Folio Purchase":
        url = INVESTORS_URL + "/transction-types/nfo-new-purchase";
        break;
      case "Additional Purchase":
        url = INVESTORS_URL + "/transction-types/additional-purchase";
        break;
      case "Redemption":
        url = INVESTORS_URL + "/transction-types/redemption";
        break;
      case "SIP Registration":
        url = `${INVESTORS_URL}/transction-types/sip`;
        break;
      case "STP Registration":
        url = INVESTORS_URL + "/transction-types/stp";
        break;
      case "SWP Registration":
        url = INVESTORS_URL + "/transction-types/swp";
        break;
      case "Switch":
        url = INVESTORS_URL + "/transction-types/switch";
        break;

      case "SIP Pause/Cancellation":
        url = INVESTORS_URL + "/transction-types/sip-cancellation";
        break;
      case "NCT":
        url = INVESTORS_URL + "/nct-type/digital/transact";
        break;
      case "eNACH":
        url = INVESTORS_URL + "/eNACH/digital/transact";
        break;
    }
    return url;
  };
  render() {
    return (
      <Paper
        className="transaction-type-cards-paper ttcp-responsive"
        style={{
          boxShadow: "-1px 1px 3px 1px lavender",
          padding: "15px 0px 0px 0px",
          borderRadius: 8,
          cursor: "pointer",
        }}
        // onClick={this.props.onClick}
        onClick={() =>
          this.props.history.push({
            pathname: this.getUrlByName(this.props.name),
            state: {
              pan:
                !!this.props.pan &&
                (this.props.name === "SIP Registration" ||
                  this.props.name === "New Folio Purchase" ||
                  this.props.name === "eNACH")
                  ? this.props.pan
                  : "",
            },
          })
        }
      >
        <div className="otp otp-responsive justify-content-c">
          <img
            src={`${this.props.image}`}
            style={{ height: 80 }}
            alt={this.props.name}
          />
        </div>
        <div className="transaction-type-cards-name">{this.props.name}</div>
        {/* {this.props.name !== "Pause/Cancellation" &&
        this.props.name != "NCT" &&
        this.props.name != "eNACH" ? (
          <div
            style={{
              display: "flex",
              boxShadow: "1px -2px 2px -1px lavender",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
                fontSize: 13,
                color: "#00579b",
                fontFamily: "SourceSansPro-SemiBold",
                flexBasis: 0,
                padding: "15px 0px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.props.history.push({
                  pathname: this.getUrlByName(this.props.name),
                  state: {
                    pan:
                      !!this.props.pan &&
                      (this.props.name === "SIP" ||
                        this.props.name === "NFO & New Purchase")
                        ? this.props.pan
                        : "",
                  },
                })
              }
            >
              Digital Mode
            </div>
            {this.props.name === "STP" || this.props.name === "SWP" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  padding: "15px 0px",
                  justifyContent: "center",
                  color: "#00579b",
                  borderLeft: "2px solid #f8f8f8",
                  fontSize: 13,
                  fontFamily: "SourceSansPro-SemiBold",
                  flexGrow: 1,
                  flexBasis: 0,
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.props.history.push({
                    pathname: this.props.navigationProps.physical,
                    state: {
                      type: this.props.navigationProps.type,
                      flag: this.props.navigationProps.flag,
                      pan:
                        (!!this.props.pan &&
                          this.props.name === "NFO & New Purchase") ||
                        this.props.name === "SIP"
                          ? this.props.pan
                          : "",
                    },
                  })
                }
              >
                Phygital Mode
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              boxShadow: "1px -2px 2px -1px lavender",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "15px 0px",
                justifyContent: "center",
                cursor: "pointer",
                color: "#00579b",
                borderLeft: "2px solid #f8f8f8",
                fontSize: 13,
                fontFamily: "SourceSansPro-SemiBold",
                flexGrow: 1,
                flexBasis: 0,
              }}
              onClick={() =>
                this.props.history.push({
                  pathname: this.getUrlByName(this.props.name),
                  state: {
                    pan:
                      !!this.props.pan &&
                      (this.props.name === "eNACH")
                        ? this.props.pan
                        : "",
                  },
                })
              }
            >
              Proceed
            </div>
          </div>
        )} */}
      </Paper>
    );
  }
}
