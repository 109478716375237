export var GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export var GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export var GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";
export var GET_TRANSACTION_DATA = "GET_TRANSACTION_DATA";
export var GET_TRANSACTION_DATA_SUCCESS = "GET_TRANSACTION_DATA_SUCCESS";
export var GET_TRANSACTION_DATA_FAILED = "GET_TRANSACTION_DATA_FAILED";
export var GET_AUM_DATA = "GET_AUM_DATA";
export var GET_AUM_DATA_SUCCESS = "GET_AUM_DATA_SUCCESS";
export var GET_AUM_DATA_FAILED = "GET_AUM_DATA_FAILED";
export var GET_FREQUENT_MAIL_BACK_REQUEST = "GET_FREQUENT_MAIL_BACK_REQUEST";
export var GET_FREQUENT_MAIL_BACK_REQUEST_SUCCESS = "GET_FREQUENT_MAIL_BACK_REQUEST_SUCCESS";
export var GET_FREQUENT_MAIL_BACK_REQUEST_FAILED = "GET_FREQUENT_MAIL_BACK_REQUEST_SUCCESS";
export var GET_DASHBOARD_AUM_DATA = "GET_DASHBOARD_AUM_DATA";
export var GET_DASHBOARD_AUM_DATA_SUCCESS = "GET_DASHBOARD_AUM_DATA_SUCCESS";
export var GET_DASHBOARD_AUM_DATA_FAILED = "GET_DASHBOARD_AUM_DATA_FAILED";