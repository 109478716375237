import React, { Component } from "react";
import Search from "../searchComponent";
import TableData from "../tableData";
import { connect } from "react-redux";
import { getInvestors } from "shared/store/actions";
import { ALL, EXISTING, NEW } from "shared/store/types";
import TabHeader from "../../forms/tabHeader";
import NewInvestorSearch from "./newInvestorSearch";
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: NEW
    };
  }

  onClick = active => () => {
    this.setState({ active });
  };

  componentDidMount() {
    this.props.getInvestors({ ARN: this.props.token, AllFavFlag: ALL });
  }

  render() {
    return (
      <div className="investor-container">
        <div className="investor-title">New Transaction</div>

        {/* <div
          className="tab-container"
          style={{
            justifyContent: "flex-start",
            margin: "25px 20px 15px 20px",
            borderBottom: "1px solid #7dbbe2"
          }}
        >
          <TabHeader
            value="Existing Investor"
            active={this.state.active === EXISTING}
            onClick={this.onClick(EXISTING)}
          />

          <TabHeader
            value="New Investor"
            active={this.state.active === NEW}
            onClick={this.onClick(NEW)}
          />
        </div> */}
        {this.state.active === NEW ? (
          <NewInvestorSearch history={this.props.history} />
        ) : (
          <div>
            <Search history={this.props.history} />
            <TableData
              history={this.props.history}
              location={this.props.location}
            />
          </div>
        )}

        {/* <div className="otp">
          {this.props.investors.length > 0 && (
            <div
              className="investor-next-button"
              onClick={() =>
                this.props.history.push("/newTransaction/transction-types")
              }
            >
              Next
            </div>
          )}
        </div> */}
      </div>
    );
  }
}

export default connect(
  ({ authReducer, investor }) => {
    const { token } = authReducer;
    const { investors } = investor;
    return { investors, token };
  },
  { getInvestors }
)(Transactions);
