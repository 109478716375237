const drawerWidth = 254;

export const drawerStyles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    height: 80,
    minHeight: 80,
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    borderBottom: "1px solid #E8EFF6",
  },
  appBar: {
    width: `100%`,
    minHeight: 80,
    maxHeight: 80,
    marginLeft: drawerWidth,
    background: "white",
    boxShadow: "0px 0px 1px -1px lavender",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflow: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "0px 0px 3px -1px lavender",
    border: 0,
    overflow: "hidden",
  },
  content: {
    flexGrow: 1,
    margin: "100px 30px 100px 30px",
    backgroundColor: theme.palette.background.default,
  },
  appbaricons: {
    marginLeft: 20,
    marginTop: 20,
  },
  appabarlastlogin: {
    height: 20,
  },
  appabarring: {
    height: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  appabarlogout: {
    cursor: "pointer",
    height: 35,
    marginLeft: 5,
    marginRight: 20,
  },
  lastlogindiv: {
    display: "flex",
    marginRight: 50,
  },
  lastloginmain: {
    color: "gray",
    fontSize: 14,
    marginLeft: 5,
    fontFamily: "SourceSansPro-Regular",
  },
  lastloginsub: {
    color: "#003d7b",
  },
  profile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 25,
  },
  profileimage: {
    background: "rgba(218, 37, 29, 0.18)",
    display: "flex",
    color: "#1F317F",
    fontSize: 22,
    fontFamily: "SourceSansPro-SemiBold",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    width: 60,
    marginRight: 0,
    borderRadius: "50%",
  },
  profileRole: {
    // backgroundColor: "#e5f2f9",
    padding: "5px 20px 5px 10px",
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  profileRoletext: {
    color: "#586a82",
    fontFamily: "SourceSansPro-Regular",
    fontSize: 12,
  },
  profileRolesubtext: {
    color: "#137ebf",
    fontFamily: "SourceSansPro-Bold",
    fontSize: 12,
  },
  profileSettings: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    marginRight: 5,
    border: "1px solid rgba(0, 0, 0, 0.5px)",
    borderRadius: 5,
    boxShadow: "0px 0px 0px 1px lavender",
  },
  profileSettingsicon: {
    height: 30,
    width: 35,
    marginRight: 10,
  },
  profileName: {
    color: "#4E4E4E",
    fontSize: 15,
    marginTop: 10,
    fontFamily: "SourceSansPro-SemiBold",
    textAlign: "center",
  },
  profilenametext: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profilenameMaintext: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 13,
    fontFamily: "SourceSansPro-SemiBold",
    color: "rgba(24, 42, 70, 0.8)",
    textAlign: "center",
    marginTop: 2,
  },
  profilediv: {
    paddingBottom: 15,
  },
  profilenamesubtext: {
    color: "#1f317f",
    fontSize: 13,
    marginLeft: 5,
    marginTop: 2,
    fontFamily: "SourceSansPro-Regular",
  },
  combined: {
    margin: "10px 0px",
  },
  list: {
    margin: "0px 4px 0px 0px",

    padding: "20px 0px",
    paddingBottom: 120,
    overflow: "auto",
  },
  listImage: {
    marginLeft: 12,
    height: 15,
  },
  listTextActive: {
    fontSize: 14,
    marginLeft: 15,
    color: "#00579B",
    fontFamily: "SourceSansPro-SemiBold",
  },

  listText: {
    fontSize: 15,
    marginLeft: 12,
    color: "#2D3034",
    fontFamily: "SourceSansPro-Regular",
  },
});

export const dashboardStyles = (theme) => ({
  card: {
    display: "flex",
    width: "33%",
    maxWidth: "33%",
    height: 100,
    margin: "0px 10px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    display: "flex",
    paddingBottom: 0,
    flexDirection: "column",
    justifyContent: "center",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});

export const simpleCardsStyles = (theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    width: "33.33%",
    marginTop: 1,
    padding: "16px 15px",
    boxShadow: "0px 0px 3px 1px lavender",
    margin: "0px 10px",
    flexGrow: 1,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 0,
  },
  displaySimpleCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  simpletwo: {
    display: "flex",
    alignItems: "center",
  },
  details: {
    margin: "0px 20px",
  },
  name: {
    fontSize: 14,
    fontFamily: "SourceSansPro-Regular",
  },
  count: {
    fontFamily: "SourceSansPro-Bold",
    fontSize: 18,
  },
});
