import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, Sector } from "recharts";
import { FormatCurrency } from "../../home/currencyFormatter";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const COLORS = ["#AEC1E5","#17469E","#6390E4","#35DFDF"];

const renderActiveShape = (name) => (props) => {
  // const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    // midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default class PieDiagram extends PureComponent {
  state = {
    activeIndex: 0,
    values: [],
  };

  onPieEnter = (index) => {
    this.setState({
      activeIndex: index,
    });
  };
  currencyConverter = (value) => {
    if (Math.abs(Number(value)) >= 1.0e5) {
      return `${parseInt(Math.abs(Number(value)) / 1.0e5)} lakh`;
    } else if (Math.abs(Number(value)) >= 1.0e6) {
      return `${parseInt(Math.abs(Number(value)) / 1.0e6)} cr`;
    } else if (Math.abs(Number(value)) >= 1.0e3) {
      return `${parseInt(Math.abs(Number(value)) / 1.0e3)} thousand`;
    } else {
      return Math.abs(Number(value));
    }
  };

  render() {
    return (
      <div
        style={{
          display: !!this.props.name ? "flex" : "block",
          justifyContent: "center",
        }}
        className="piechart-div"
      >
        <PieChart
          width={this.props.width || 300}
          height={this.props.height || 325}
        >
          <Pie
            activeShape={renderActiveShape(this.props.name)}
            data={this.props.data || data}
            cx={this.props.cx || 200}
            cy={this.props.cy || 200}
            innerRadius={this.props.innerRadius || 40}
            outerRadius={this.props.outerRadius || 100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                fill={COLORS[index % COLORS.length]}
                name={entry.name.toUpperCase()}
                key={index}
              />
            ))}
          </Pie>
        </PieChart>
        <div className="whole-pie-data">
        {!!this.props.data && !this.props.name ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {!!this.props.data
              ? this.props.data.map((item, index) => (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: COLORS[index % COLORS.length],
                        height: 10,
                        width: 10,
                      }}
                    />
                    <div
                      style={{
                        paddingLeft: 5,
                        fontSize: 14,
                        fontFamily: "SourceSansPro-SemiBold",
                      }}
                    >
                      {item.name
                        .charAt(0)
                        .toUpperCase()
                        .concat(item.name.substring(1, item.name.length))}
                    </div>
                    <div style={{paddingLeft:5,fontFamily: "SourceSansPro-SemiBold",fontSize:13}}>{item.value} %</div>
                  </div>
                ))
              : ""}
          </div>
        ) : (
          <div className="pie-data">
            {!!this.props.data
              ? this.props.data.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        padding: "7px 0px",
                        flexGrow: 1,
                        flexBasis: 0,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                          height: 10,
                          marginTop: 4,
                          width: 10,
                          flexShrink: 0,
                        }}
                      />
                      <div
                        style={{
                          paddingLeft: 5,
                          paddingRight: 10,
                          fontSize: 13,
                          color: "#114A84",
                          textTransform: "capitalize",
                          fontFamily: "SourceSansPro-SemiBold",
                        }}
                      >
                        {!!item && !!item.name && this.props.name === "Fund"
                          ? `${item.name.split("MUTUAL FUND")[0]} MF`
                          : !!item && !!item.name && this.props.name !== "Fund"
                          ? item.name.toLowerCase()
                          : ""}
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        )}
        {!!this.props.data && !this.props.name ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingRight: 20,
              paddingBottom: 25,
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                color: "#114A84",
                fontSize: 15,
                fontFamily: "SourceSansPro-SemiBold",
              }}
            >
              {this.props.symbol === "%" && !!this.props.data
                ? "Percentage"
                : !!this.props.data
                ? "INR"
                : ""}
            </div>
            {!!this.props.data
              ? this.props.data.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "7px 0px 5px",
                      fontFamily: "SourceSansPro-SemiBold",
                    }}
                  >
                    {this.props.symbol === "%" ? (
                      `${Math.round(item.value)}${this.props.symbol}`
                    ) : (
                      <FormatCurrency value={item.value} />
                    )}
                  </div>
                ))
              : ""}
          </div>
        )}
        {this.props.symbol === "%" && !!this.props.aumpieData ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingRight: 20,
              paddingBottom: 25,
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                color: "#114A84",
                fontSize: 15,
                fontFamily: "SourceSansPro-SemiBold",
              }}
            >
              INR
            </div>
            {this.props.data.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "7px 0px 5px",
                  fontFamily: "SourceSansPro-SemiBold",
                }}
              >
                <FormatCurrency value={item.ruppess} />
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        </div>
      </div>
    );
  }
}

// class CustomizedAxisTick extends Component {
//   render() {
//     const { x, y, stroke, payload } = this.props;

//     return (
//       <g transform={`translate(${x},${y})`}>
//         <text
//           x={0}
//           y={0}
//           dy={16}
//           textAnchor="end"
//           fill="#666"
//           transform="rotate(-15)"
//         >
//           {payload.value}
//         </text>
//       </g>
//     );
//   }
// }
