import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Amc from "./amc";
import Nav from "./nav";
import MailBack from "./mailBackRequest";
import TransactionHistory from "./transactionHistory";
import Social from "./social";
import Support from "./support";
import Settings from "./settings";
import FeedBack from "./feedback";
import DashboardComponent from "./dashboard/index";
import InvestorComponent from "./investors/index";
import TransactionComponent from "./transactions/index";
import MailbackRouter from "./mailBackRequest/mailBackRoutes";
import MyEarnings from "./myEarnings/index";
import EKYC from "../home/transactions/ekycForm";
import { connect } from "react-redux";
import MailBackComponent from "./mailBackRequest/mailback";
import nfoFunds from "./dashboard/nfoFunds";
import NfoTransaction from "./dashboard/NfoTransaction";
class AppRouter extends Component {
  render() {
    return (
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={DashboardComponent}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/settings`}
          component={Settings}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/investors`}
          component={InvestorComponent}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/new-transaction`}
          component={TransactionComponent}
        />
        <Route path={`${process.env.PUBLIC_URL}/amc`} component={Amc} />
        <Route path={`${process.env.PUBLIC_URL}/nav`} component={Nav} />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest`}
          exact
          component={MailBack}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/mailBackRequest/investor`}
          component={MailBackComponent}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/transaction-history`}
          component={TransactionHistory}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/my-earnings`}
          component={MyEarnings}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/nfo-funds`}
          component={nfoFunds}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/nfo-funds/nfo-transact`}
          component={NfoTransaction}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/connectviaSocial`}
          component={Social}
        />
        <Route path={`${process.env.PUBLIC_URL}/support`} component={Support} />
        <Route
          path={`${process.env.PUBLIC_URL}/feedback`}
          component={FeedBack}
        />
        <Route path={`${process.env.PUBLIC_URL}/e-KYC`} component={EKYC} />
        <Route path={`${process.env.PUBLIC_URL}/`} exact>
          {!!this.props.tran_enable_flag &&
          this.props.tran_enable_flag === "N" ? (
            <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/new-transaction`} />
          )}
        </Route>
      </Switch>
    );
  }
}

export default connect(({ authReducer }) => {
  const { tran_enable_flag } = authReducer;
  return {
    tran_enable_flag,
  };
}, null)(AppRouter);
