import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
// import mailBackSoa from "./mailBackSoa";
import { MAILBACK_URL } from ".";
import { BUILD_URL } from "../home";
import {MailBackSoaIcon,CASIcon,ELSSIcon,CANIcon,CGSIcon,GASIcon,networthIcon,mailbackRightarrow} from "../../forms/appEssentials";

export default class MailBackInvestor extends Component {
  render() {
    return (
      <div className="mail-back-investor-modes-container">
        <div className="investor-title ">Mail Back Request – Investor</div>
        <div className="flex inner-mailback-investor" style={{ flexWrap: "wrap" }}>
          <RequestModes
            name="Mail Back SOA"
            image={MailBackSoaIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL +
                      "/investor/investor-selection/mail-back-soa"
                    : MAILBACK_URL + "/investor/investor-selection",

                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "mailBackSoa",
                        investorData: this.props.location.state.investorData
                      }
                    : { name: "mailBackSoa" }
              })
            }
          />
          <RequestModes
            name="Consolidated Account Statement"
            image={CASIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL +
                      "/investor/investor-selection/consolidated-account-statement"
                    : MAILBACK_URL + "/investor/investor-selection",
                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "consolidated",
                        investorData: this.props.location.state.investorData
                      }
                    : {
                        name: "consolidated"
                      }
              })
            }
          />
          <RequestModes
            name="ELSS Account Statement"
            image={ELSSIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL + "/investor/investor-selection/ELSS"
                    : MAILBACK_URL + "/investor/investor-selection",
                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "ELSS",
                        investorData: this.props.location.state.investorData
                      }
                    : {
                        name: "ELSS"
                      }
              })
            }
          />
          <RequestModes
            name="CAN Based Account Statement"
            image={CANIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL + "/investor/investor-selection/CAN"
                    : MAILBACK_URL + "/investor/investor-selection",
                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "CAN",
                        investorData: this.props.location.state.investorData
                      }
                    : {
                        name: "CAN"
                      }
              })
            }
          />
          <RequestModes
            name="Capital Gains Statement"
            image={CGSIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL + "/investor/investor-selection/CGS"
                    : MAILBACK_URL + "/investor/investor-selection",
                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "CGS",
                        investorData: this.props.location.state.investorData
                      }
                    : {
                        name: "CGS"
                      }
              })
            }
          />
          <RequestModes
            name="Grandfathering Account Statement"
            image={GASIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL + "/investor/investor-selection/GAS"
                    : MAILBACK_URL + "/investor/investor-selection",
                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "GAS",
                        investorData: this.props.location.state.investorData
                      }
                    : {
                        name: "GAS"
                      }
              })
            }
          />

          <RequestModes
            name="Networth Report"
            image={networthIcon}
            onClick={() =>
              this.props.history.push({
                pathname:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? MAILBACK_URL + "/investor/investor-selection/GAS"
                    : MAILBACK_URL + "/investor/investor-selection",
                state:
                  this.props.location.state &&
                  this.props.location.state.investorData
                    ? {
                        name: "Networth",
                        investorData: this.props.location.state.investorData
                      }
                    : {
                        name: "Networth"
                      }
              })
            }
          />
        </div>
      </div>
    );
  }
}

export class RequestModes extends Component {
  render() {
    return (
      <Paper
        className="mail-back-soa-paper"
        style={{
          boxShadow:
            "0px 1px 4px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -3px rgba(0,0,0,-0.88)"
        }}
      >
        <div className="mail-back-modes-investor-image">
          <img
            src={`${this.props.image}`}
            style={{ height: 35 }}
            alt={this.props.name}
          />
        </div>
        <div
          className="mail-back-investor-mode-sub-container"
          onClick={this.props.onClick}
        >
          <div className="mail-back-investor-mode-sub-title">
            {this.props.name}
          </div>
          <div className="transaction-status-cards-mailback">
            <img
              src={BUILD_URL + "/images/su_next.svg"}
              alt="next"
              style={{ height: 30 }}
            />
          </div>
        </div>
      </Paper>
    );
  }
}
