import React, { Component } from "react";
import DisplayCards from "./displaycards";
import DisplaySimpleCards from "./displaySimpleCards";
import TabHeader from "../../forms/tabHeader";
import PieDiagram from "./piechart";
import { ALL } from "shared/store/types";
import { Paper } from "@material-ui/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import { FormatCurrency } from "../currencyFormatter";
import {
  getDashboardData,
  getAumData,
  getInvestors,
  getFrequentMailBackRequest,
  getInvestorDetails,
  getDashboardDataAum,
  getSipSummary,
  PosterOpen,
} from "shared/store/actions";
import { getMailbackList } from "shared/store/actions/mailback";
import {
  getProfileData,
  KARVY_STOCK_BROKING_LIMITED,
  SAMCO_STOCK,
} from "shared/store/actions/profile";
import { connect } from "react-redux";
import {
  AUTHORIZATION_PENDING,
  AUTHORIZATION_APPROVED,
  AUTHORIZATION_EXPIRED,
  INACTIVE_INVESTORS,
  ACTIVE_INVESTORS,
  NEW_INVESTORS,
  EXPIRING_NEXT_MONTH,
  CLOSED_THIS_MONTH,
  SIP_INVESTMENTS,
} from "shared/store/types";
import { BUILD_URL } from "../home";
import { MyEarnings_URL } from "../myEarnings";
import { PosterDialog } from "../customModal";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().toLocaleDateString(),
      reports: [],
      report: { value: "" },
      Tab: "AUM_SUMMARY",
      PosterOpen:true,

    };
  }

  goToRoute = (path) => () => {
    this.props.history.push({
      pathname: BUILD_URL + "/dashboard/transaction-summary",
      state: {
        active: path,
      },
    });
  };
  closePoster = async () => {
    this.setState({
      PosterOpen: false,
    });
    await this.props.PosterOpen(1);
  };

  goToSIPRoute = (path) => () => {
    this.props.history.push({
      pathname: BUILD_URL + "/dashboard/SipSummaryTypes",
      state: {
        active: path,
      },
    });
  };

  async componentDidMount() {
    this.props.getProfileData({
      Agent: this.props.token,
      UserId: this.props.userId,
    });

    const body = {
      AgentCode: this.props.token,
      UserId: this.props.userId,
      DateVal: moment(new Date()).format("MM-DD-YYYY"),
    };
    this.props.getDashboardDataAum(body);

    this.props.getDashboardData({
      AgentCode: this.props.token,
    });

    this.props.getInvestors({
      ARN: this.props.token,
      AllFavFlag: ALL,
    });

    const sipParams = {
      Uid: this.props.userId,
      AgentCode: this.props.token,
      DateVal: moment(new Date()).format("MM-DD-YYYY"),
    };
    const api =
      this.props.token === "ARN-0018"
        ? ""
        : this.props.getSipSummary(sipParams);

    const response = await this.props.getMailbackList();
    if (response) {
      this.setState({
        reports: response.DtData,
      });
    }
  }
  handleChangeSubReports = (value) => () => {
    this.props.history.push({
      pathname: `${process.env.PUBLIC_URL}/mailBackRequest`,
      state: {
        value: value,
      },
    });
  };

  currencyConverter = (value) => {
    if (Math.abs(Number(value)) >= 1.0e5) {
      return `${parseInt(Math.abs(Number(value)) / 1.0e5)} Lacs`;
    } else if (Math.abs(Number(value)) >= 1.0e7) {
      return `${parseInt(Math.abs(Number(value)) / 1.0e7)} Cr`;
    } else if (Math.abs(Number(value)) >= 1.0e3) {
      return `${parseInt(Math.abs(Number(value)) / 1.0e3)} K`;
    } else {
      return Math.abs(Number(value));
    }
  };

  handleChangeTab = (label) => () => {
    this.setState({
      Tab: label,
    });
  };

  gotoNfo = () => {
    this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/nfo-funds`);
  };
  gotoEarnings = () => {
    this.props.history.push(`${process.env.PUBLIC_URL}/my-earnings`);
  };

  //transaction-summary // kebab case // route names , css class names
  //transactionSummary // camel case // variables, function names.
  //transaction_summary  // snake case //
  //TransactionSummary // PascalCase //Class names

  render() {
    const {
      transactionSummary,
      my_aum,
      numberofschemes,
      profileData,
      dashboardAUMData,
      sipSummaryData,
    } = this.props;
    const AmcAumList =
      !!dashboardAUMData && dashboardAUMData.Table4
        ? dashboardAUMData.Table2
        : [];

    const SipAumList =
      !!sipSummaryData && !!sipSummaryData.DtData ? sipSummaryData.DtData : [];

    const AUMDetails =
      !!dashboardAUMData && dashboardAUMData.Table3
        ? dashboardAUMData.Table3
        : [];
    const AUMPieData =
      !!dashboardAUMData && dashboardAUMData.DtData
        ? dashboardAUMData.DtData.map((ele) => ({
            name: ele.category,
            value: ele.aum_percent,
            ruppess: ele.aum,
          }))
        : [];
    return (
      <div className="dashboard-main">
          {this.props.posterStatus === 0 && (
          <PosterDialog open={this.state.PosterOpen} close={this.closePoster} />
        )}

        {/*
<div
          style={{
            position: "fixed",
            right: -20,
            zIndex: 100,
            top: 200,
          }}
        >
          <div
            style={{
              background: "#1f317f",
              // borderRadius: "50%",
              paddingLeft: 20,
              width: 210,
              display: "flex",
              justifyContent: "flex-start",

              fontFamily: "OpenSans-Bold",
              letterSpacing: 0.3,
              boxShadow: "0px 0px 3px 0px black",
              fontSize: 16,
              alignItems: "center",
              height: 40,
              color: "white",
              cursor: "pointer",
            }}
            onClick={this.gotoNfo}
          >
            <img
              src={BUILD_URL + "/images/newtransaction_active.png"}
              style={{ height: 18, marginRight: 7 }}
            />
           Create NFO Link
          </div>
        </div> */}

        <Paper className="dashboard-title">
          <div className="dashboard-title-company">
            {profileData.Distributor_Name} Portfolio
          </div>
          {!!this.props.tran_enable_flag &&
            this.props.tran_enable_flag === "N" && (
              <div
                className="earnings-icon-div"
                style={{
                  background: "#1f317f",
                  cursor: "pointer",
                  borderRadius: 5,
                }}
                onClick={() =>
                  this.props.history.push(
                    `${process.env.PUBLIC_URL}/my-earnings`
                  )
                }
              >
                <img
                  src={BUILD_URL + "/images/jm_view_my_earning.svg"}
                  alt="earnings"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.props.history.push(
                      `${process.env.PUBLIC_URL}/my-earnings`
                    )
                  }
                />
              </div>
            )}
          <div
            style={{ position: "absolute", right: -1, top: -21 }}
            className="jm_dashboard_banner"
          >
            <img
              src={BUILD_URL + "/images/jm_banner_dash.svg"}
              alt="earnings background"
            />
          </div>
        </Paper>

        <Paper className="dashboard-title-responsive">
          <div className="dashboard-title-company">
            {profileData.Distributor_Name} Portfolio
          </div>
          <div
            className="earnings-icon-div"
            style={{
              background: "#1f317f",
              cursor: "pointer",
              borderRadius: 5,
              textAlign: "center",
              color: "white",
              padding: "7px 0px",
              marginTop: 5,
              maxWidth: 150,
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() =>
              this.props.history.push(`${process.env.PUBLIC_URL}/my-earnings`)
            }
          >
            View My Earnings
          </div>
        </Paper>

        <div className="dashboard-my-aum">
          MY AUM
          <span className="dashboard-my-aum-span-1">
            (as on{" "}
            <span className="dashboard-my-aum-span-2">
              {moment(new Date(), "DD/MM/YYYY").format("MMM DD, YYYY")})
            </span>
          </span>
        </div>

        <div className="piecharts-dashboard">
          <div className="left-cards">
            <div className="display-small-front-cards">
              <DisplayCards
                image={"/images/jm_dashboard_aum.svg"}
                name="AUM"
                values={
                  !!AUMDetails && !!AUMDetails[0] && !!AUMDetails[0].my_aum
                    ? `₹ ${Math.round(AUMDetails[0].my_aum).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                        }
                      )}`
                    : "0"
                }
              />
              <DisplayCards
                image={"/images/jm_dashboard_schemes.svg"}
                name="Schemes"
                values={
                  !!AUMDetails &&
                  !!AUMDetails[0] &&
                  !!AUMDetails[0].Numberofschemes
                    ? AUMDetails[0].Numberofschemes
                    : "0"
                }
              />
              <DisplayCards
                image={"/images/jm_dashboard_investors.svg"}
                name="Investors"
                values={!!this.props.count ? this.props.count : "0"}
              />
            </div>
          </div>
          <div className="pie-charts-divider"></div>
          <div className="right-pie">
            <Paper className="pie-chart-paper aum">
              <div className="title_dashboard">
                Asset Class Wise Investment Break Up
              </div>
              {!!AUMPieData.length ? (
                <PieDiagram
                  name="AUM"
                  dataDiffer="AUM"
                  aumpieData={true}
                  symbol="%"
                  className="jing"
                  data={AUMPieData}
                  innerRadius={50}
                  outerRadius={75}
                  height={200}
                  width={180}
                  cy={100}
                  cx={85}
                />
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#da251d",
                    fontFamily: "SourceSansPro-SemiBold",
                    fontSize: 18,
                  }}
                >
                  No Data Found
                </div>
              )}
            </Paper>
          </div>
        </div>

        <div className="dashboard-my-aum">
          TRANSACTION SUMMARY
          <span className="dashboard-my-aum-span-1">
            (as on{" "}
            <span className="dashboard-my-aum-span-2">
              {moment(new Date(), "DD/MM/YYYY").format("MMM DD, YYYY")})
            </span>
          </span>
        </div>
        <div className="sales-container">
          <div className="simple-card-container">
            <div className="simple-card-div-aligning">
              <DisplaySimpleCards
                mainClassName={"dashboard-summary-card-aligning"}
                height={24}
                width={44}
                image={"/images/jm_authorization_expired.svg"}
                name="Authorization Expired"
                count={transactionSummary.ExpiredTransactions}
                className="simple-card"
                onClick={this.goToRoute(AUTHORIZATION_EXPIRED)}
              />
              <DisplaySimpleCards
                mainClassName={"dashboard-summary-card-aligning"}
                height={24}
                width={35}
                image={"/images/jm_authorization_approved.svg"}
                name="Authorization Approved"
                count={transactionSummary.AuthorisedTransaction}
                className="simple-card"
                onClick={this.goToRoute(AUTHORIZATION_APPROVED)}
              />
              <DisplaySimpleCards
                mainClassName={"dashboard-summary-card-aligning"}
                height={24}
                width={45}
                image={"/images/jm_authorization_pending.svg"}
                name="Authorization Pending"
                count={transactionSummary.AuthorisationPending}
                className="simple-card"
                onClick={this.goToRoute(AUTHORIZATION_PENDING)}
              />
              {/* <DisplaySimpleCards
                image={"/images/su_authorization_phygital.svg"}
                name="Phygital Transactions"
                count={transactionSummary.PhysicalTransactions}
                className="simple-card"
              /> */}
              <DisplaySimpleCards
                mainClassName={"dashboard-summary-card-aligning"}
                height={24}
                width={39}
                image={"/images/jm_SIPs.svg"}
                name="No. LIVE SIPs"
                count={transactionSummary.SIPTransactions}
                className="simple-card"
              />
            </div>
          </div>
        </div>

        <div
          className="tab-container"
          style={
            {
              // justifyContent: "center",
              // margin: "25px 20px 15px 20px",
            }
          }
        >
          <TabHeader
            value="AUM Summary"
            active={this.state.Tab === "AUM_SUMMARY"}
            onClick={this.handleChangeTab("AUM_SUMMARY")}
          />
          <TabHeader
            value="Reports(Mailback)"
            active={this.state.Tab === "FREQUENT_REPORTS"}
            onClick={this.handleChangeTab("FREQUENT_REPORTS")}
          />
          {this.props.token !== "ARN-0018" && (
            <TabHeader
              value="SIP SUMMARY"
              active={this.state.Tab === "SIP_SUMMARY"}
              onClick={this.handleChangeTab("SIP_SUMMARY")}
            />
          )}
        </div>
        {this.state.Tab === "FREQUENT_REPORTS" && (
          <div className="transaction-details">
            <Paper style={{ width: "100%" }} className="mailback-reports-div">
              {!!this.state.reports &&
                this.state.reports.map((item) => (
                  <SubReports
                    subtitle={
                      !!item && !!item.KDM_Repdesc
                        ? !!item.KDM_Repdesc.split("-")[2]
                          ? `${item.KDM_Repdesc.split("-")[1]}-${
                              item.KDM_Repdesc.split("-")[2]
                            }`
                          : item.KDM_Repdesc.split("-")[1]
                        : ""
                    }
                    key={item.KDM_Repcode}
                    value={item.KDM_Repcode}
                    onClick={this.handleChangeSubReports(item)}
                  />
                ))}
            </Paper>
          </div>
        )}

        {this.state.Tab === "SIP_SUMMARY" && (
          <div>
            <div className="simple-card-container">
              <div className="simple-card-div">
                <DisplaySimpleCards
                  image={"/images/sip_elm.svg"}
                  name="Expiring Next Month"
                  count={
                    !!sipSummaryData &&
                    !!sipSummaryData.Table2 &&
                    sipSummaryData.Table2[0]
                      ? sipSummaryData.Table2[0].SIP_expired_next_month
                      : 0
                  }
                  className="simple-card"
                  onClick={this.goToSIPRoute(EXPIRING_NEXT_MONTH)}
                />

                <DisplaySimpleCards
                  image={"/images/sip_closed.svg"}
                  name="Closed This Month"
                  count={
                    !!sipSummaryData &&
                    !!sipSummaryData.Table2 &&
                    sipSummaryData.Table2[0]
                      ? sipSummaryData.Table2[0].SIP_Closed_this_month
                      : 0
                  }
                  className="simple-card"
                  onClick={this.goToSIPRoute(CLOSED_THIS_MONTH)}
                />
                <DisplaySimpleCards
                  image={"/images/sip_im.svg"}
                  name="Investments"
                  count={
                    !!sipSummaryData &&
                    !!sipSummaryData.Table2 &&
                    sipSummaryData.Table2[0]
                      ? sipSummaryData.Table2[0].Sip_Investments
                      : 0
                  }
                  className="simple-card"
                  onClick={this.goToSIPRoute(SIP_INVESTMENTS)}
                />
              </div>
            </div>
            {SipAumList.map((item, index) => (
              <AmcAum
                value={item}
                sipSummary={true}
                key={index}
                history={this.props.history}
              />
            ))}
          </div>
        )}

        {this.state.Tab === "AUM_SUMMARY" && (
          <div>
            {!!AmcAumList && AmcAumList.length > 0 ? (
              AmcAumList.map((item, index) => (
                <AmcAum
                  value={item}
                  key={index}
                  history={this.props.history}
                  sipSummary={false}
                />
              ))
            ) : (
              <div
                style={{
                  fontFamily: "SourceSansPro-SemiBold",
                  color: "#da251d",
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                No Data Found
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ authReducer, dashboard, investor, profileReducer }) => {
    const { token, userId, tran_enable_flag,posterStatus } = authReducer;
    const { count } = investor;
    const { profileData } = profileReducer;
    const {
      transactionSummary = {},
      my_aum,
      numberofschemes,
      aumData,
      fundData,
      totalAum,
      sipSummaryData,
      dashboardAUMData,
    } = dashboard;
    return {
      token,
      dashboardAUMData,
      posterStatus,
      userId,
      transactionSummary,
      sipSummaryData,
      my_aum,
      count,
      profileData,
      totalAum,
      numberofschemes,
      aumData,
      fundData,
      tran_enable_flag,
    };
  },
  {
    getDashboardData,
    getDashboardDataAum,
    PosterOpen,
    getSipSummary,
    getAumData,
    getInvestors,
    getInvestorDetails,
    getFrequentMailBackRequest,
    getProfileData,
    getMailbackList,
  }
)(Dashboard);

export class SubReports extends Component {
  render() {
    return (
      <div
        className="mailback-reports-sub-reports"
        onClick={this.props.onClick}
        value={this.props.value}
      >
        {this.props.subtitle}
      </div>
    );
  }
}

export class MyAmcAum extends Component {
  goToRoute = (path, value) => () => {
    this.props.history.push({
      pathname: BUILD_URL + "/dashboard/investorType",
      state: {
        active: path,
        value: value,
        Type: !!this.props.sipSummary ? "SIP_SUMMARY" : "AUM_SUMMARY",
      },
    });
  };

  render() {
    const { value, dashboardAUMData, sipSummaryData, sipSummary } = this.props;

    const totalArray =
      !sipSummary &&
      !!dashboardAUMData &&
      dashboardAUMData.Table2 &&
      dashboardAUMData.Table2.reduce((prev, curr) => {
        const findItem = dashboardAUMData.Table4.find(
          (ele) => !!ele.Fund && String(ele.Fund).trim() === curr.fund_code
        );

        const data = !!findItem ? { ...curr, ...findItem } : { ...curr };
        prev = [...prev, data];
        return prev;
      }, []);

    // const totalArray =
    //   !sipSummary &&
    //   !!dashboardAUMData &&
    //   dashboardAUMData.Table2 &&
    //   dashboardAUMData.Table4 &&
    //   dashboardAUMData.Table4[0] &&
    //   !dashboardAUMData.Table4[0] === "1"
    //     ? dashboardAUMData.Table2.reduce((prev, curr) => {
    //         const findItem =
    //           !!dashboardAUMData &&
    //           dashboardAUMData.Table4.find(
    //             (ele) => ele.Fund.trim() === curr.fund.trim()
    //           );
    //         const data = !!findItem ? { ...curr, ...findItem } : { ...curr };
    //         prev = [...prev, data];
    //         return prev;
    //       }, [])
    //     : [];

    let item = !!sipSummary
      ? !!sipSummaryData &&
        sipSummaryData.Table3.length > 0 &&
        sipSummaryData.Table3.find(
          (ele) => ele.Fund.trim() === value.fund.trim()
        )
      : totalArray.find(
          (ele) => !!ele.Fund && String(ele.Fund).trim() === value.fund_code
        );

    return (
      <>
        {!!value && value.fund_code && value.fund_code === "105" && (
          <div className="transaction-details">
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                padding: "10px 20px",
                marginTop: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      !!item && item.image_path
                        ? `https://mfs.kfintech.com/mfs/mobileimages/${
                            !!item.image_path && item.image_path.split("/")[2]
                          }`
                        : null
                    }
                    style={{ marginRight: 20, height: 30, width: 30 }}
                  />
                  <div
                    style={{
                      fontSize: 15,
                      color: "#1f317f",
                      fontFamily: "OpenSans-Bold",
                    }}
                  >
                    {value.descr}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: "#1f317f",
                    fontFamily: "OpenSans-SemiBold",
                  }}
                >
                  Total Assets:{" "}
                  {!!value && value.aum && (
                    <FormatCurrency
                      value={!!value && value.aum ? value.aum : 0}
                    />
                  )}
                  <span
                    style={{
                      color: "#7b8495",
                      fontSize: 10,
                      fontFamily: "OpenSans-Regular",
                    }}
                  >
                    {" "}
                    (as on date {moment(new Date()).format("DD MMM `YY")})
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#FOFOFO",
                width: "100%",
                padding: "10px 20px",
              }}
            ></div>
            <div className="sub-title">Investors</div>
            <div className="simple-card-div">
              <DisplaySimpleCards
              mainClassName={"dashboard-summary-card-aligning"}
                image={"/images/jm_dashboard_new_investors.svg"}
                name="New Investors"
                count={
                  !!item && !!item.New_Investors ? item.New_Investors : "0"
                }
                className="simple-card"
                onClick={this.goToRoute(NEW_INVESTORS, item)}
              />

              <DisplaySimpleCards
              mainClassName={"dashboard-summary-card-aligning"}
                image={"/images/jm_dashboard_active_inves.svg"}
                name="Active Investors"
                count={
                  !!item && !!item.Active_Investors
                    ? item.Active_Investors
                    : "0"
                }
                className="simple-card"
                onClick={this.goToRoute(ACTIVE_INVESTORS, item)}
              />
              <DisplaySimpleCards
              mainClassName={"dashboard-summary-card-aligning"}
                image={"/images/jm_dashboard_inactive_investors.svg"}
                name="Inactive Investors"
                count={
                  !!item && !!item.Inactive_Investors
                    ? item.Inactive_Investors
                    : "0"
                }
                className="simple-card"
                onClick={this.goToRoute(INACTIVE_INVESTORS, item)}
              />
            </div>
            {/* <div className="sub-title brokerage">
              Brokerage Details{" "}
              <span
                style={{
                  color: "#da251d",
                  fontSize: 14,
                  fontFamily: "SourceSansPro-Regular",
                }}
              >
                {" "}
                (Last 6 Months)
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <SubAUMClass
                title={
                  !!item && !!item.Previous6thMon ? item.Previous6thMon : "0"
                }
                date={
                  !!item && !!item.Previous6thMonDt ? item.Previous6thMonDt : ""
                }
              />
              <SubAUMClass
                title={
                  !!item && !!item.Previous5thMon ? item.Previous5thMon : "0"
                }
                date={
                  !!item && !!item.Previous5thMonDt ? item.Previous5thMonDt : ""
                }
              />
              <SubAUMClass
                title={
                  !!item && !!item.Previous4thMon ? item.Previous4thMon : "0"
                }
                date={
                  !!item && !!item.Previous4thMonDt ? item.Previous4thMonDt : ""
                }
              />
              <SubAUMClass
                title={
                  !!item && !!item.BeforelastMonth ? item.BeforelastMonth : "0"
                }
                date={
                  !!item && !!item.BeforelastMonthDt
                    ? item.BeforelastMonthDt
                    : ""
                }
              />
              <SubAUMClass
                title={!!item && !!item.BeforeMonth ? item.BeforeMonth : "0"}
                date={!!item && !!item.BeforeMonthDt ? item.BeforeMonthDt : ""}
              />

              <SubAUMClass
                title={!!item && !!item.PresentMon ? item.PresentMon : "0"}
                date={!!item && !!item.PresentDt ? item.PresentDt : ""}
              />
            </div>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "lightgray",
                marginTop: 20,
              }}
            ></div> */}
          </div>
        )}
      </>
    );
  }
}

export const AmcAum = connect(({ dashboard }) => {
  const { dashboardAUMData, sipSummaryData } = dashboard;

  return { dashboardAUMData, sipSummaryData };
})(MyAmcAum);

export class SubAUMClass extends Component {
  currencyConverter = (value) => {
    if (Math.abs(Number(value)) >= 1.0e5) {
      return `${parseFloat(Math.abs(Number(value)) / 1.0e5)} Lacs`;
    } else if (Math.abs(Number(value)) >= 1.0e7) {
      return `${parseFloat(Math.abs(Number(value)) / 1.0e7)} Cr`;
    } else if (Math.abs(Number(value)) >= 1.0e3) {
      return `${parseFloat(Math.abs(Number(value)) / 1.0e3)} K`;
    } else {
      return Math.abs(Number(value));
    }
  };

  render() {
    const { title, date } = this.props;
    return (
      <Paper
        style={{
          padding: "15px 25px",
          margin: "5px 15px",
          textAlign: "center",
          boxShadow: "0px 0px 3px 0px lavender",
        }}
      >
        <div
          style={{
            fontFamily: "SourceSansPro-SemiBold",
            fontSize: 13,
            color: "#293139",
          }}
        >
          {date}
        </div>
        <div style={{ fontFamily: "SourceSansPro-Bold", color: "#1f317f" }}>
          {!!title ? <FormatCurrency value={title} /> : ""}
        </div>
      </Paper>
    );
  }
}

// NFO CODE

// <div
//           style={{
//             position: "fixed",
//             right: -20,
//             zIndex: 100,
//             top: 200,
//           }}
//         >
//           <div
//             style={{
//               background: "#00579b",
//               // borderRadius: "50%",
//               paddingLeft: 20,
//               width: 120,
//               display: "flex",
//               justifyContent: "flex-start",

//               fontFamily: "SourceSansPro-Bold",
//               letterSpacing: 0.3,
//               boxShadow: "0px 0px 3px 0px black",
//               fontSize: 16,
//               alignItems: "center",
//               height: 40,
//               color: "white",
//               cursor: "pointer",
//             }}
//             onClick={this.gotoNfo}
//           >
//             <img
//               src={BUILD_URL + "/images/newtransaction_active.png"}
//               style={{ height: 18, marginRight: 7 }}
//             />
//             NFO
//           </div>
//         </div>
