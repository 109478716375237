import React, { Component } from "react";
import { Input } from "../../forms/input";
import Signbutton from "../../forms/button";
import { connect } from "react-redux";
import { getEnach } from "shared/store/actions/transactions";
import { EnachModal, EnachAMCModal } from "../customModal";
import { BUILD_URL } from "../home";
import investorData from "../investors/investorData";

class ENACH extends Component {
  constructor(props) {
    super(props);
    const { investorData, communicationDetails, location } = this.props;
    const { state } = location;
    const Xemail =
      !!this.props.communicationDetails &&
      !!this.props.communicationDetails.length > 0 &&
      !!investorData.PAN &&
      (this.props.communicationDetails || []).find(
        (detail) =>
          detail.PAN === investorData.PAN &&
          !!detail.email &&
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(detail.email)
      );

    const Xmobile =
      !!this.props.communicationDetails &&
      !!this.props.communicationDetails.length > 0 &&
      !!investorData.PAN &&
      (this.props.communicationDetails || []).find(
        (detail) => detail.PAN === investorData.PAN && !!detail.Mobile
      );

    const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";

    const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";
    this.state = {
      email: { value: Email || "" },
      EnachOpen: false,
      eNachAMCOpen: false,
      disabled: false,
      pan: {
        value:
          !!investorData && !!investorData.PAN
            ? investorData.PAN
            : !!state && !!state.pan
            ? state.pan
            : "",
      },
      mobile: { value: Mobile },
      name: {
        value:
          !!investorData && !!investorData.invname ? investorData.invname : "",
      },
      ref: "",
      amount: { value: "" },
      countryCode: { value: "+91" },
    };
  }

  changeValue = (label) => async (event) => {
    if (label === "countryCode") {
      const { value } = event.target;
      if (!/^[+]\d{1,3}/.test(value)) {
        this.setState({
          countryCode: { value, errorMessage: "Enter a valid Country Code" },
        });
        return;
      }
      this.setState({
        countryCode: { value },
      });
    }
    if (label === "amount") {
      const { value } = event.target;
      if (parseInt(value) < 500 || parseInt(value) > 10000000) {
        this.setState({
          amount: {
            value,
            errorMessage: "Amount should be between Rs 500 and Rs 10000000",
          },
        });
        return;
      }
      this.setState({
        amount: { value },
      });
      return;
    }
    if (label === "mobile" && event.target.value.length <= 10) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label != "mobile") {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };
  submit = async () => {
    const { ref, EnachOpen, EnachRef, disabled, eNachAMCOpen, ...others } =
      this.state;
    this.setState({
      disabled: true,
    });
    const keys = Object.keys(others);
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "pan"
                  ? "PAN No"
                  : key === "mobile"
                  ? "Mobile Number"
                  : key === "name"
                  ? "Investor Name"
                  : key === "email"
                  ? "Email"
                  : key === "amount"
                  ? "Amount"
                  : key === "countryCode"
                  ? "Country Code"
                  : key
              } is Mandatory`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "amount": {
          this.amountField.focus();
          break;
        }
        case "countryCode": {
          this.countryCodeField.focus();
          break;
        }
        case "pan": {
          this.panField.focus();
          break;
        }
        case "name": {
          this.nameField.focus();
          break;
        }
        case "email": {
          this.emailField.focus();
          break;
        }
        case "mobile": {
          this.mobileField.focus();
          break;
        }
        default:
          break;
      }
    } else if (!/^[+]\d{1,3}/.test(this.state.countryCode.value)) {
      this.setState({
        countryCode: {
          value: "",
          errorMessage: "Please Enter a valid country code",
        },
      });
      return;
    } else if (
      parseInt(this.state.amount.value) < 500 ||
      parseInt(this.state.amount.value > 10000000)
    ) {
      this.setState({
        amount: {
          value: "",
          errorMessage: "Amount should be between Rs 500 and Rs 10000000",
        },
      });
    } else if (
      !!this.state.pan.value &&
      /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.state.pan.value) ===
        false
    ) {
      this.setState({
        pan: {
          ...others["pan"],
          isError: true,
          errorMessage: `Enter a vaild PAN`,
        },
      });
      return;
    } else if (
      !!this.state.email.value &&
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.state.email.value
      ) === false
    ) {
      this.setState({
        email: {
          ...others["email"],
          isError: true,
          errorMessage: `Enter a vaild Email address`,
        },
      });
      return;
    } else if (
      !!this.state.mobile.value &&
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/.test(
        this.state.mobile.value
      ) === false
    ) {
      this.setState({
        mobile: {
          ...others["mobile"],
          isError: true,
          errorMessage: `Enter a vaild Phone number`,
        },
      });
      return;
    } else {
      const params = {
        Email: this.state.email.value,
        Contact: this.state.mobile.value,
        Name: this.state.name.value,
        Pan: this.state.pan.value,
        invdistflag: "P",
        arncode: this.props.token,
        txnamount: this.state.amount.value,
        siamount: this.state.amount.value,
      };
      const response = await this.props.getEnach(params);
      if (
        !!response &&
        !!response.DtData &&
        !!response.DtData[0] &&
        !!response.DtData[0].en_ihno
      ) {
        this.setState({
          EnachRef: response.DtData[0].en_ihno,
          EnachOpen: true,
        });
      }
    }
    this.setState({
      disabled: false,
    });
  };

  OpenEnachAMCOpen = () => {
    this.setState({
      eNachAMCOpen: true,
    });
  };

  OpenEnachAMCClose = () => {
    this.setState({
      eNachAMCOpen: false,
    });
  };

  close = () => {
    this.setState({
      EnachOpen: false,
    });
    this.props.history.push(BUILD_URL + "/dashboard");
    // const refno = encodeURIComponent(btoa(this.state.EnachRef));
    // window.open(
    //   `https://api.karvymfs.com/25/Enach/EnachRegd.aspx?refno=${refno}`,
    //   "_self"
    // );
  };

  onEmailBlur = () => {
    if (
      !!this.state.email.value &&
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.state.email.value
      ) === false
    ) {
      this.setState({
        email: {
          value: "",
          isError: true,
          errorMessage: "Enter a vaild Email ID",
        },
      });
    }
  };
  render() {
    const { mobile, email, pan, name } = this.state;
    const { location, investorData } = this.props;
    const { state } = location;

    return (
      <div className="transaction-details" style={{ minHeight: "75vh" }}>
        <div className="title">eNACH</div>
        <div className="input-margin">
          <Input
            name="PAN"
            disabled={
              (!!state && !!state.pan) || (!!investorData && !!investorData.PAN)
            }
            value={pan.value}
            // onKeyDown={(evt) =>
            //   (evt.key === "e" || evt.key === "." || evt.key === "-") &&
            //   evt.preventDefault()
            // }
            bordercolor="rgba(0, 0, 0, 0.5)"
            onChange={this.changeValue("pan")}
            isShowError={pan.isError}
            inputref={(ele) => (this.panField = ele)}
            errorMessage={this.state.pan.errorMessage}
          />
        </div>
        <div className="input-margin">
          <Input
            name="Investor Name"
            value={name.value}
            bordercolor="rgba(0, 0, 0, 0.5)"
            onChange={this.changeValue("name")}
            isShowError={name.isError}
            inputref={(ele) => (this.nameField = ele)}
            errorMessage={this.state.name.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Email ID"
            value={email.value}
            bordercolor="rgba(0, 0, 0, 0.5)"
            onChange={this.changeValue("email")}
            onBlur={this.onEmailBlur}
            isShowError={email.isError}
            inputref={(ele) => (this.emailField = ele)}
            errorMessage={this.state.email.errorMessage}
          />
        </div>
        <div className="input-margin">
          <Input
            name="Country Code"
            bordercolor="#a6a6a6"
            value={this.state.countryCode.value}
            onChange={this.changeValue("countryCode")}
            isShowError={!!this.state.countryCode.errorMessage}
            errorMessage={this.state.countryCode.errorMessage}
            inputref={(ele) => (this.countryCodeField = ele)}
          />
        </div>
        <div className="input-margin">
          <Input
            name="Mobile"
            value={mobile.value}
            type="number"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            bordercolor="rgba(0, 0, 0, 0.5)"
            onChange={this.changeValue("mobile")}
            isShowError={mobile.isError}
            inputref={(ele) => (this.mobileField = ele)}
            errorMessage={this.state.mobile.errorMessage}
          />
        </div>
        <div className="input-margin">
          <Input
            name="Amount"
            bordercolor="#a6a6a6"
            value={this.state.amount.value}
            onChange={this.changeValue("amount")}
            isShowError={!!this.state.amount.errorMessage}
            errorMessage={this.state.amount.errorMessage}
            inputref={(ele) => (this.amountField = ele)}
          />
        </div>

        <div className="digital-form-button-next-div">
          <Signbutton
            buttontitle={
              !!this.state.disabled ? "Submitting.... Please wait" : "Submit"
            }
            // disabled={this.state.submitting}
            onClick={this.submit}
          />
        </div>

        <EnachAMCModal
          open={!!this.state.eNachAMCOpen}
          close={this.OpenEnachAMCClose}
        />

        <EnachModal
          message={`You have initiated the eNach successfully with Reference Number : ${this.state.EnachRef}`}
          closeYesModal={this.close}
          open={!!this.state.EnachOpen}
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }, props) => {
    const { location } = props;
    const { investorData, communicationDetails, token, userId } = authReducer;
    //   const { fundsList } = transactions;
    const { fundList } = authReducer;

    return {
      investorData,
      communicationDetails,
      token,

      fundList,
      userId,
    };
  },
  { getEnach }
)(ENACH);
