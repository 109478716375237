import React, { Component } from "react";
import Signbutton from "../../forms/button";
import { Paper } from "@material-ui/core";
import CustomizedDialogs from "../customModal";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import {
  confirmPurchase,
  redemptionConfirm,
  swpConfirm,
  submitPurchaseData,
  submitSTPCancellation,
  stpConfirm,
  saveSipData,
  submitSipCancellation,
  sipPauseDateCalculate,
  sipPauseEndDateCalculate,
  sipPauseValidator,
  finalSipPause,
} from "shared/store/actions";
import {
  PAYMENT_TYPE,
  REDEMPTION,
  ALL,
  UNITS,
  U,
  PARTIAL,
  FALSE,
  SWP,
  STP,
  SIP,
  ADDITIONAL_PURCHASE,
  NEW,
  YES,
  SWITCH,
  NEWP,
  EXISTINGP,
} from "shared/store/types";
import moment from "moment";
import CustomizedSelect from "./customizedSelect";
import { Input } from "../../forms/input";
import { NEW_TRANSACTION_URL } from "./index";
import CustomLoader from "../../forms/customLoader";
import { BUILD_URL } from "../../forms/appEssentials";

class NewPurchaseConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      disabled: false,
      stpRefNo: { value: "" },
      swpRefNo: { value: "" },
      redemption: { value: "" },
      nfoNew: { value: "" },
      switchRefNo: { value: "" },
      sipRefNo: { value: "" },
      sipCancellation: { value: "" },
      pause_installemnts: [],
      sip_pause_dates: [],
      selected_pause_date: { value: "" },
      selected_pause_installments: { value: "" },
      sip_pause_end_Data: "",
      sipPauseRefno: { value: "" },
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { state } = location;

    if (!!state.type && state.type === "Sip Pause") {
      const pause_inst = new Array(state.pauseDetails.pause_inst).fill(1);
      this.setState({
        pause_installemnts: pause_inst,
      });
      const params = {
        ihno: state.pauseDetails.ihno,
        fund: state.pauseDetails.fund.trim(),
        // uid: this.props.userId,
      };
      const response = await this.props.sipPauseDateCalculate(params);
      if (
        !!response &&
        !!response.Dtinformation &&
        response.Dtinformation.length > 0
      ) {
        this.setState({
          sip_pause_dates: response.Dtinformation,
        });
      }
    }
  }

  closeModal = () => {
    this.setState({
      open: false,
    });
    this.props.history.replace(NEW_TRANSACTION_URL);
  };

  sipSubmit = () => {
    const { sipDetails } = this.props;
    const { sipType } = sipDetails;

    if (sipType.value === NEW || sipType.value === NEWP || sipType.value === EXISTINGP ) {
      this.confirmPurchase();
    } else {
      this.sipConfirmPurchase();
    }
  };

  sipConfirmPurchase = async () => {
    const { sipDetails } = this.props;
    this.setState({
      disabled: true,
    });
    let date = sipDetails.sip_start_date.value.split("/").reverse();
    const new_start_date = new Date(
      new Date().setFullYear(date[0], Number(date[1]) - 1, date[2])
    ).toLocaleString("en-US", {
      hour12: true,
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    let end_date = sipDetails.sip_end_date.value.split("/").reverse();

    let new_end_date = new Date(
      new Date().setFullYear(end_date[0], Number(end_date[1]) - 1, end_date[2])
    ).toLocaleString("en-US", {
      hour12: true,
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    const params = {
      i_euinDeclaration: YES,
      i_InvDistFlag: "P",
      Option:
        sipDetails.scheme.value.value.Opt ||
        sipDetails.scheme.value.value.fm_option,
      SubArn: sipDetails.subArnCode.value,
      i_PerpetialSIP: ALL,
      i_Amount: sipDetails.installmentAmount.value,
      i_frequency: sipDetails.investmentFrequency.value.FrequencyID,
      i_fund: "105",
      i_subbroker: sipDetails.subBrokerCode.value,
      i_SchemeCode:
        (sipDetails.scheme.value.value.fm_plan ||
          sipDetails.scheme.value.value.Pln) +
        (sipDetails.scheme.value.value.Sch ||
          sipDetails.scheme.value.value.fm_scheme),
      i_brokercode: sipDetails.arnCode.value,
      i_SIPday: sipDetails.sipDay.value.value,
      i_euinno:
        sipDetails.radioValue.value === "no"
          ? sipDetails.euinNo.value.value.split(" ")[0]
          : "",
      i_id: "1",
      EntDt: moment(new Date()).format("MM/DD/YYYY"),
      i_ModeofPayment: sipDetails.mode.value,
      i_EntBy: this.props.userId,
      i_folio: sipDetails.folio.value.value,
      i_SIPEndDate: new_end_date,
      i_SIPStartDate: new_start_date,
      i_NoofInstalment: sipDetails.installments.value,
      Umrn:this.props.location.state?.UMRN
    };
    const response = await this.props.saveSipData(params);

    if (!!response && !response.error) {
      this.setState({
        open: true,
        sipRefNo: {
          value: !!response.DtData[0].appno
            ? `${response.DtData[0].appno}`
            : `${response.DtData[0].Scheme}${response.DtData[0].Pln}${response.DtData[0].Opt}${response.DtData[0].ihno}`,
        },
        disabled: false,
      });
    } else if (!!response.error) {
      alert(`${response.error}`);
    }

    this.setState({
      disabled: false,
    });
  };

  confirmPurchase = async () => {
    const { confirmationData } = this.props;
    const { location } = this.props;
    const { state } = location;
    const { sipDetails } = this.props;
    const { sipType } = sipDetails;
    const { paymenttype } = state;
    this.setState({
      disabled: true,
    });
    const params =
      !!sipType && !!sipType.value && sipType.value === EXISTINGP
        ? {
            paymenttype: state.type === "SIP" ? paymenttype : "DCB",
            folio: state.folio.value.value,
            Trtype: confirmationData.trtype,
            fund: "105",
            Refno: confirmationData.TRefno,
            ModuleName: "Webile",
          }
        : {
            paymenttype: state.type === "SIP" ? paymenttype : "DCB",
            Trtype: confirmationData.trtype,
            fund: "105",
            Refno: confirmationData.TRefno,
            ModuleName: "Webile",
          };

    const response = await this.props.confirmPurchase(params);

    if (!!response && !response.error) {
      this.setState({
        open: true,
        disabled: false,
        nfoNew: {
          value:
            !!response.DtData && !!response.DtData[0]
              ? `${response.DtData[0].dd_scheme.trim()}${response.DtData[0].dd_plan.trim()}${
                  response.DtData[0].dd_option
                }${response.DtData[0].dd_refno}`
              : "NA",
          new_type:
            !!this.props.location && this.props.location.state
              ? this.props.location.state.type
              : "",
        },
      });
    } else if (!!response && !!response.error) {
      alert(`${response.error}`);
    }

    this.setState({
      disabled: false,
    });
  };

  handlesipPauseDateChange = (event) => {
    const { selected_pause_date } = this.state;
    if (
      !!selected_pause_date.value &&
      event.target.value &&
      selected_pause_date.value === event.target.value
    ) {
      return;
    } else {
      this.setState({
        selected_pause_date: { value: event.target.value },
        selected_pause_installments: { value: "" },
        sip_pause_end_Data: "",
      });
    }
  };
  handlePauseInstallments = async (event) => {
    const { selected_pause_installments } = this.state;
    if (!event.target.value) {
      this.setState({
        selected_pause_installments: { value: "" },
        sip_pause_end_Data: "",
      });
    } else if (
      !!event.target.value &&
      selected_pause_installments.value &&
      parseInt(selected_pause_installments.value) ===
        parseInt(event.target.value)
    ) {
      return;
    } else {
      this.setState({
        selected_pause_installments: { value: event.target.value },
      });
      const { location } = this.props;
      const { state } = location;
      let date = this.state.selected_pause_date.value.split("/").reverse();
      const startDate = new Date(
        new Date().setFullYear(date[0], Number(date[1]) - 1, date[2])
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });
      const params = {
        SrtDt: startDate,
        fund: state.pauseDetails.fund.trim(),
        count: event.target.value,
        uid: this.props.userId,
      };
      const response = await this.props.sipPauseEndDateCalculate(params);
      if (
        !!response &&
        !!response.Dtinformation &&
        response.Dtinformation[0].Error_Message === "Success" &&
        !!response.DtData &&
        !!response.DtData[0].enddt
      ) {
        this.setState({
          sip_pause_end_Data: response.DtData[0].enddt,
        });
      } else if (response.Dtinformation && response.Dtinformation[0]) {
        alert(`${response.Dtinformation[0].Error_Message}`);
      }
    }
  };

  confirmSwitch = async () => {
    const { state } = this.props.location;
    this.setState({
      disabled: true,
    });
    const { profileData } = this.props;

    const { investorData, token, userId } = this.props;
    const OTPMsg = Math.floor(100000 + Math.random() * 900000);
    const params = {
      PanNo: investorData.PAN,
      InvestorName: investorData.invname,
      i_RedFlg: state.switchType === "PARTIAL" ? "P" : "F",
      EuinValid: YES,
      i_entby: userId,
      trtype: "S",
      i_Tacno: state.scheme.Acno,
      Bankid: "null~null",
      i_Plan: state.scheme.Pln,
      i_Tplan: state.switchInScheme.fm_plan,
      Desci: "Yes~P~null",
      i_Toption: state.switchInScheme.fm_option,
      i_Acno: state.scheme.Acno,
      i_Fund: "105",
      i_UntAmtFlg: state.switchMode === UNITS ? U : ALL,
      i_oldihno: "0",
      ARNCode: token,
      Subbroker: state.subBrokerCode,
      EuinCode:
        !!state && !!state.euin && !!state.euin.value ? state.euin.value : "",
      i_Agent: !!profileData ? profileData.Distributor_Name : "",
      Otp: OTPMsg,
      i_Option: state.scheme.Opt,
      i_Userid: userId,
      i_Tscheme: state.switchInScheme.fm_scheme,
      i_Scheme: state.scheme.Sch,
      i_Tfund: state.switchInScheme.fm_fund,
      i_InvDistFlag: "P",
      i_UntAmtValue: state.amount,
      EUINFlag: YES,
      ModuleName: "Webile",
    };

    const response = await this.props.submitPurchaseData(params);
    if (!!response && !response.error) {
      this.setState({
        open: true,
        disabled: true,
        switchRefNo: {
          value:
            !!response.DtData && !!response.DtData[0]
              ? response.DtData[0].REFNO
              : "",
        },
      });
    } else if (!!response.error) {
      alert(`${response.error}`);
      this.props.history.push(BUILD_URL + "/dashboard");
    }
    this.setState({
      disabled: false,
    });
  };

  confirmStp = async () => {
    const { stpDetails } = this.props;
    const { token, userId } = this.props;
    this.setState({
      disabled: true,
    });

    const params = {
      Trtype: "STP",
      Stpday: !!stpDetails.sipDay ? stpDetails.sipDay.value : "",
      i_option: !!stpDetails.scheme ? stpDetails.scheme.value.value.Opt : "",
      Refno: "0",
      NoofTransfer: !!stpDetails.transfers ? stpDetails.transfers.value : "",
      Entby: userId,
      InvDistFlag: "P",
      Enddt: !!stpDetails.sipEndDate
        ? new Date(
            stpDetails.sipEndDate.value.split("/").reverse().join("/")
          ).toLocaleDateString("en-US")
        : "",
      Euinvalid: "Y",
      Scheme: !!stpDetails.scheme ? stpDetails.scheme.value.value.Sch : "",
      Stdt: !!stpDetails.sipStartDate
        ? new Date(
            stpDetails.sipStartDate.value.split("/").reverse().join("/")
          ).toLocaleDateString("en-US")
        : "",
      Tooption: !!stpDetails.switchInScheme
        ? stpDetails.switchInScheme.value.value.fm_option
        : "",
      Stpoption: "Fixed",
      // !!stpDetails.swpOption
      //   ? stpDetails.swpOption.value.Description
      //   : "",
      Plan: !!stpDetails.scheme ? stpDetails.scheme.value.value.Pln : "",
      Trdate: moment(new Date()).format("MM/DD/YYYY"),
      Freq:
        !!stpDetails.swpFrequency && !!stpDetails.swpFrequency.value
          ? stpDetails.swpFrequency.value
          : "",
      Batchno: "0",
      ToScheme: !!stpDetails.switchInScheme
        ? stpDetails.switchInScheme.value.value.fm_scheme
        : "",
      Distributor: token,
      Fund: !!stpDetails.scheme ? stpDetails.scheme.value.value.Fund : "",
      Amount: !!stpDetails.amount ? stpDetails.amount.value : "",
      Ihno: "0",
      Euin:
        !!stpDetails.euinNo &&
        !!stpDetails.euinNo.value &&
        !!stpDetails.euinNo.value.value
          ? stpDetails.euinNo.value.value
          : "",
      Acno: !!stpDetails.scheme ? stpDetails.scheme.value.value.Acno : "",
      Toplan: !!stpDetails.scheme
        ? stpDetails.switchInScheme.value.value.fm_plan
        : "",
    };
    const response = await this.props.stpConfirm(params);
    if (response) {
      this.setState({
        stpRefNo: { value: response.DtData[0].appno },
        open: true,
        disabled: false,
      });
    }
    this.setState({
      disabled: false,
    });
  };

  confirmSwp = async () => {
    const { state } = this.props.location;
    let sipStartDateReverse, startDate, sipEndDateReverse, EndDate;
    this.setState({
      disabled: true,
    });
    const { userId } = this.props;
    const {
      scheme,
      swpFrequency,
      sipEndDate,
      sipStartDate,
      amount,
      swpOption,
      withDrawals,
    } = state;

    if (!!sipStartDate) {
      sipStartDateReverse = sipStartDate.split("/").reverse().join("/");
      startDate = new Date(sipStartDateReverse).toLocaleDateString("en-US");
    }

    if (!!sipEndDate) {
      sipEndDateReverse = sipEndDate.split("/").reverse().join("/");
      EndDate = new Date(sipEndDateReverse).toLocaleDateString("en-US");
    }
    const params = {
      Trtype: "SWP",
      Withdrawaltype: swpOption.Description,
      Refno: "0",
      Errno: "0",
      Optn: scheme.Opt,
      Entby: userId,
      InvDistFlag: "P",
      Noofwithdrawals: withDrawals,
      Enddt: EndDate,
      Remarks: "0",
      Scheme: scheme.Sch,
      Stdt: startDate,
      Plan: scheme.Pln,
      Trdate: moment(new Date()).format("MM/DD/YYYY"),
      Freq: swpFrequency.FrequencyID,
      Batchno: "0",
      Fund: scheme.Fund,
      Branch: "0",
      Amount: !!amount ? amount : "0",
      Ihno: "0",
      Acno: scheme.Acno,
    };

    const response = await this.props.swpConfirm(params);

    if (response) {
      this.setState({
        open: true,
        swpRefNo: {
          value: `${scheme.Sch}${scheme.Pln}${scheme.Opt}${response.Table1[0].RefNo}`,
        },
        disabled: false,
      });
    }
    this.setState({
      disabled: false,
    });
  };

  confirmRedemption = async () => {
    const { state } = this.props.location;
    this.setState({
      disabled: true,
    });

    const { scheme, amount, redemptionMode, bank, redemptionType } = state;

    const { investorData, token, userId } = this.props;
    const params = {
      i_InvDistFlag: "P",
      i_Acno: scheme.Acno,
      i_Fund: scheme.Fund,
      i_Plan: scheme.Pln,
      i_Option: scheme.Opt,
      i_RedFlg: redemptionType === PARTIAL ? "P" : "F",
      i_Scheme: scheme.Sch,
      i_Userid: userId,
      i_UntAmtValue: amount,
      ARNCode: token,
      i_pangno: investorData.PAN,
      i_UntAmtFlg: redemptionMode === UNITS ? "U" : "A",
      i_oldihno: "0",
      i_multibankdet: `${bank.bnkname}-${bank.bnkacno}~~ ${bank.bnkacno}~~ ${bank.bnkactype}`,
    };

    const response = await this.props.redemptionConfirm(params);
    if (response) {
      this.setState({
        open: true,
        redemption: { value: response.DtData[0].REFNO },
      });
    }
    this.setState({
      disabled: false,
    });
  };
  confirmSipcancellation = async () => {
    let reason,rejcode
    const { location, token, userId, investorData } = this.props;
    const { state } = location;
    const { pauseDetails, sipType } = state;
    this.setState({
      disabled: true,
    });

    let dsipstdt, Esipstdt, dsipEnddt, EsipEnddt, dtrDate, EsipTrDate;
    if (!!pauseDetails.fromdate) {
      dsipstdt = pauseDetails.fromdate.split("/").reverse();
      Esipstdt = new Date(
        new Date().setFullYear(
          dsipstdt[0],
          Number(dsipstdt[1]) - 1,
          dsipstdt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });
    }

    if (!!pauseDetails.todate) {
      dsipEnddt = pauseDetails.todate.split("/").reverse();
      EsipEnddt = new Date(
        new Date().setFullYear(
          dsipEnddt[0],
          Number(dsipEnddt[1]) - 1,
          dsipEnddt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });
    }
    if (!!pauseDetails.trdate) {
      dtrDate = pauseDetails.trdate.split("/").reverse();
      EsipTrDate = new Date(
        new Date().setFullYear(dtrDate[0], Number(dtrDate[1]) - 1, dtrDate[2])
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });
    }
    if(!!this.props.location.state.cancellationReason){
      const rejectionreason = this.props.location.state.reasons.find((ele)=>ele.RejReason===this.props.location.state.cancellationReason)
      if(rejectionreason){
        reason =this.props.location.state.cancellationReason
        rejcode = rejectionreason.RejCode
      } else {
        reason =  `Others-${this.props.location.state.cancellationReason}`
        rejcode = this.props.location.state.reasons.length
      }
    }

    const body =
      sipType !== "SIP"
        ? {
            subBrokerCode: "",
            ToOption: "",
            Scheme: pauseDetails.sch,
            ToPlan: pauseDetails.tpln,
            customfund: "",
            cycleihno: "0",
            appldt: "",
            branch: "",
            mobile: pauseDetails.mobile,
            cycledtpart: "",
            termdt: !!Esipstdt ? Esipstdt : "",
            todate: !!EsipEnddt ? EsipEnddt : "",
            trdate: !!EsipTrDate ? EsipTrDate : "",
            trtype: sipType,
            Toscheme: pauseDetails.tsch,
            subTrType: "",
            barcode: "",
            batchno: "0",
            disitributorid: this.props.token,
            amt: pauseDetails.amt,
            Optn: pauseDetails.opt,
            Plan: pauseDetails.pln,
            acno: pauseDetails.acno,
            dpid: "",
            fund: pauseDetails.fund.trim(),
            ihno: pauseDetails.ihno,
            Refno: pauseDetails.ihno,
            entby: userId,
            errno: "0",
            lname: pauseDetails.lname,
            rcpno: "",
            chqtype: "",
            cmlflag: "",
            clientid: "",
            remarks: "",
            allotmentFlag: "",
            invdistflag: "P",
            Frequency: pauseDetails.freq,
          }
        : {
            Trtype: sipType,
            Pan: investorData.PAN,
            uid: userId,
            Fund: pauseDetails.fund.trim(),
            Ihno: pauseDetails.ihno,
            Agent: token,
            folio: pauseDetails.acno,
            reason:reason,
            rejcode:rejcode
          };

    const response =
      sipType === "SIP"
        ? await this.props.submitSipCancellation(body)
        : await this.props.submitSTPCancellation(body);

    if (
      !!response &&
      !!response.Dtinformation &&
      !!response.Dtinformation[0] &&
      (!!response.Dtinformation[0].Error_Code ||
        !!response.Dtinformation[0].Error_code) &&
      (response.Dtinformation[0].Error_Code === "0" ||
        response.Dtinformation[0].Error_code === "0")
    ) {
      this.setState({
        open: true,
        disabled: false,
        sipCancellation: {
          value: response.DtData[0].ihno,
          errorMessage: response.Dtinformation[0].Error_Message,
        },
      });
    }

    this.setState({
      disabled: false,
    });
  };
  confirmSipPause = async () => {
    const {
      sip_pause_end_Data,
      selected_pause_installments,
      selected_pause_date,
    } = this.state;
    if (
      !!sip_pause_end_Data ||
      !!selected_pause_installments.value ||
      !!selected_pause_date.value
    ) {
      this.setState({
        disabled: true,
      });
      const { state } = this.props.location;
      const { pauseDetails } = state;

      const dpausesipstdt = selected_pause_date.value.split("/").reverse();
      const Epausesipstdt = new Date(
        new Date().setFullYear(
          dpausesipstdt[0],
          Number(dpausesipstdt[1]) - 1,
          dpausesipstdt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });

      const dpausesipenddt = sip_pause_end_Data.split("/").reverse();
      const Epausesipenddt = new Date(
        new Date().setFullYear(
          dpausesipenddt[0],
          Number(dpausesipenddt[1]) - 1,
          dpausesipenddt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });

      let dreportingdt = pauseDetails.trdate.split("/").reverse();
      let Ereportingdt = new Date(
        new Date().setFullYear(
          dreportingdt[0],
          Number(dreportingdt[1]) - 1,
          dreportingdt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });

      const dsipstdt = pauseDetails.fromdate.split("/").reverse();
      const Esipstdt = new Date(
        new Date().setFullYear(
          dsipstdt[0],
          Number(dsipstdt[1]) - 1,
          dsipstdt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });

      let dsipenddt = pauseDetails.todate.split("/").reverse();
      let Esipenddt = new Date(
        new Date().setFullYear(
          dsipenddt[0],
          Number(dsipenddt[1]) - 1,
          dsipenddt[2]
        )
      ).toLocaleString("en-US", {
        hour12: true,
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      });

      const params = {
        pausesipstdt: Epausesipstdt,
        Scheme: pauseDetails.sch,
        pausesipenddt: Epausesipenddt,
        reportingdt: moment(new Date()).format("MM/DD/YYYY"),
        amt: pauseDetails.amt,
        uid: this.props.userId,
        acno: pauseDetails.acno,
        freq: pauseDetails.freq,
        fund: pauseDetails.fund,
        plan: pauseDetails.pln,
        sipenddt: Esipenddt,
        pauseihno: "0",
        sipregihno: pauseDetails.ihno,
        sipstdt: Esipstdt,
      };
      const validateresponse = await this.props.sipPauseValidator(params);
      if (
        (!!validateresponse &&
          !!validateresponse.Table &&
          validateresponse.Table.length > 0 &&
          validateresponse.Table[0].Error_Message === "Success",
        validateresponse.Table[0].Error_Code === 0)
      ) {
        const params = {
          oldoption: pauseDetails.opt,
          oldscheme: pauseDetails.sch,
          cycleihno: pauseDetails.ihno,
          oldplan: pauseDetails.pln,
          mobile:
            pauseDetails.mobile ||
            this.props.investorData.invname ||
            this.props.Mobile,
          sipamt: pauseDetails.amt,
          termdt: Epausesipstdt,
          trdate: Ereportingdt,
          trtype: "paus",
          batchno: "0",
          disitributorid: this.props.token,
          amt: pauseDetails.amt,
          uid: this.props.userId,
          acno: pauseDetails.acno,
          fund: pauseDetails.fund,
          ihno: pauseDetails.ihno,
          entby: this.props.userId,
          lname: pauseDetails.lname || this.props.investorData.invname,
          refno: pauseDetails.ihno,
          sipenddt: Epausesipenddt,
          noofinst: selected_pause_installments.value,
          invdistflag: "P",
          sipfreq: pauseDetails.freq,
          sipstdt: Esipstdt,
        };

        const final = await this.props.finalSipPause(params);
        if (
          !!final &&
          !!final.Table &&
          final.Table[0].Error_Code === "0" &&
          final.Table[0].Error_Message === "Success"
        ) {
          this.setState({
            open: true,
            pause_installemnts: [],
            sip_pause_dates: [],
            selected_pause_date: { value: "" },
            selected_pause_installments: { value: "" },
            sip_pause_end_Data: "",
            sipPauseRefno: {
              value: final.Table1[0].refno,
              errorMessage: `You have initated the pause SIP Transaction successfully with reference number ${final.Table1[0].refno} , Investor will receive an email for approval`,
            },
          });
        }
      }
    } else {
      alert("please submit all details to confirm");
    }

    this.setState({
      disabled: false,
    });
  };

  gotoTransactionTypes = () => {
    this.props.history.push({
      pathname: NEW_TRANSACTION_URL + "/transction-types",
    });
  };
  render() {
    const { confirmationData, location, investorData, sipDetails, stpDetails } =
      this.props;
    const { state } = location;
    const {
      scheme,
      redemptionMode,
      redemptionType,
      amount,
      swpOption,
      swpFrequency,
      sipEndDate,
      pauseDetails,
      sipStartDate,
      sipDay,
      withDrawals,
      folioDetails,
    } = state;
    const {
      email = {},
      mobile = {},
      fund = {},
      folio = {},
      scheme: sipScheme = {},
      installments = {},
      investmentFrequency = {},
      sipDay: sip_day = {},
      sip_end_date = {},
      sip_start_date = {},
      arnCode = {},
      subArnCode = {},
      mode = {},
      euinNo = {},
      investmentAmount = {},
      installmentAmount = {},
      sipType = {},
    } = sipDetails;
    const Xemail = (this.props.communicationDetails || []).find(
      (detail) =>
        !!state.scheme &&
        state.scheme.Fund &&
        !!state.Folio &&
        detail.Fund.trim() === scheme.Fund.trim() &&
        detail.Acno === state.Folio.value &&
        detail.PAN === investorData.PAN &&
        !!detail.email
    );
    const Xmobile = (this.props.communicationDetails || []).find(
      (detail) =>
        detail.PAN === investorData.PAN &&
        !!state.Folio &&
        !!state.scheme &&
        detail.Acno === state.Folio.value &&
        state.scheme.Fund &&
        detail.Fund.trim() === scheme.Fund.trim() &&
        !!detail.Mobile
    );

    const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";

    const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";
    return (
      <React.Fragment>
        <div className="digital-form-main-details-text">
          {state.title === "SIP" ? "SIP Registration" : state.title}
        </div>
        <Paper className="digital-form-details-paper">
          {state.type != "Sip Pause" && state.type !== "Sip cancellation" && (
            <DetailsGroup
              mainLabel="Email ID"
              subLabel={
                !!state && !!state.InvestorEmail
                  ? state.InvestorEmail
                  : Email ||
                    // email.value ||
                    // confirmationData.Email ||
                    "-NA-"
              }
              mainLabel1="Mobile"
              subLabel2={
                !!state && !!state.InvestorMobile
                  ? state.InvestorMobile
                  : Mobile ||
                    // investorData.Mobile ||
                    // confirmationData.Mobile ||
                    // Mobile ||
                    "-NA-"
              }
            />
          )}
          {state.type === SWITCH ? (
            <div>
              <DetailsGroup
                mainLabel="Switch Out Scheme"
                subLabel={`${state.scheme.SchDesc} - ${state.scheme.PlnDesc}`}
                mainLabel1="Switch Type"
                subLabel2={this.props.location.state.switchType}
              />
              <DetailsGroup
                mainLabel="Switch In Scheme"
                subLabel={state.switchInScheme.fm_schdesc}
                mainLabel1="Switch Mode"
                subLabel2={this.props.location.state.switchMode}
              />
            </div>
          ) : (
            ""
          )}
          <DetailsGroup
            // mainLabel="AMC"
            // subLabel={
            //   state.type === "Sip Pause" || state.type === "Sip cancellation"
            //     ? state.fundName
            //     : state.type === STP
            //     ? !!stpDetails.scheme
            //       ? stpDetails.scheme.value.value.FundDesc
            //       : "-"
            //     : state.type === SWITCH
            //     ? state.scheme.FundDesc
            //     : state.type === SIP
            //     ? fund.value
            //       ? sipType.value === NEW || sipType.value === NEWP
            //         ? fund.value.value.amc_name
            //         : fund.value.value.FundDesc
            //       : "-"
            //     : [REDEMPTION, SWP].includes(state.type)
            //     ? !!scheme
            //       ? scheme.FundDesc
            //       : "-"
            //     : confirmationData.Fdesc
            // }
            mainLabel="Folio"
            subLabel={
              state.type === "Sip Pause" || state.type === "Sip cancellation"
                ? pauseDetails.acno
                : state.type === STP
                ? !!stpDetails.scheme
                  ? stpDetails.scheme.value.value.Acno
                  : "-"
                : state.type === SWITCH
                ? state.scheme.Acno
                : state.type === SIP
                ? folio.value
                  ? folio.value.value
                  : "-"
                : [REDEMPTION, SWP].includes(state.type)
                ? !!scheme
                  ? scheme.Acno
                  : "-"
                : !!state && state.type === ADDITIONAL_PURCHASE
                ? state.folio.value
                : !!+confirmationData.i_Acno
                ? confirmationData.i_Acno
                : "New Folio"
            }
          />
          {state.type !== SWITCH && (
            <DetailsGroup
              mainLabel={
                state.type === SWITCH
                  ? ""
                  : state.type === "STP"
                  ? "Switch out Scheme"
                  : "Scheme"
              }
              subLabel={
                state.type === SWITCH
                  ? ""
                  : state.type === "Sip Pause" ||
                    state.type === "Sip cancellation"
                  ? pauseDetails.Scheme
                  : state.type === SIP
                  ? !!sipScheme.value
                    ? sipScheme.value.value.SchDesc ||
                      sipScheme.value.value.fm_schdesc
                    : "-"
                  : state.type === STP
                  ? !!stpDetails.scheme
                    ? `${stpDetails.scheme.value.value.SchDesc}-${stpDetails.scheme.value.value.OptDesc}`
                    : "-"
                  : [REDEMPTION, SWP].includes(state.type)
                  ? !!scheme
                    ? `${scheme.SchDesc} ${scheme.OptDesc}`
                    : "-"
                  : !!state &&
                    !!state.title &&
                    state.title === "NEW FOLIO PURCHASE SUMMARY"
                  ? `${state.orginalScheme}`
                  : `${confirmationData.Schdesc}`
              }
            />
          )}

          {(state.type === "Sip Pause" ||
            state.type === "Sip Cancellation") && (
            <div>
              <DetailsGroup
                mainLabel="Transaction Type"
                subLabel={state.sipType}
                mainLabel1="Registration Date"
                subLabel2={
                  !!state.pauseDetails && !!state.pauseDetails.trdate
                    ? state.pauseDetails.trdate
                    : "NA"
                }
              />
              <DetailsGroup
                mainLabel="Start Date"
                subLabel={
                  !!state.pauseDetails && !!state.pauseDetails.fromdate
                    ? state.pauseDetails.fromdate
                    : "NA"
                }
                mainLabel1="End Date"
                subLabel2={
                  !!state.pauseDetails && !!state.pauseDetails.todate
                    ? state.pauseDetails.todate
                    : "NA"
                }
              />

              <DetailsGroup
                mainLabel="Frequency"
                subLabel={
                  !!state.pauseDetails && !!state.pauseDetails.freq
                    ? state.pauseDetails.freq
                    : "NA"
                }
                mainLabel1="Amount"
                subLabel2={
                  !!state.pauseDetails && !!state.pauseDetails.amt
                    ? state.pauseDetails.amt
                    : "NA"
                }
              />
            </div>
          )}
          {state.type === SIP && (
            <div>
              <DetailsGroup
                mainLabel="No. of Installments"
                subLabel={installments.value || "Perpetual SIP"}
                mainLabel1="SIP Frequency"
                subLabel2={
                  investmentFrequency.value
                    ? investmentFrequency.value.FrequencyDescription
                    : "-"
                }
              />
              <DetailsGroup
                mainLabel="SIP Day"
                subLabel={sip_day.value ? sip_day.value.value : "-"}
              />
              <DetailsGroup
                mainLabel="Start Date"
                subLabel={sip_start_date.value}
                mainLabel1="End Date"
                subLabel2={sip_end_date.value}
              />

              <DetailsGroup
                mainLabel="EUIN No."
                subLabel={euinNo.value ? euinNo.value.value : "-"}
              />

              <DetailsGroup
                mainLabel="Investor Name"
                subLabel={investorData.invname}
              />
              <DetailsGroup
                mainLabel="Mode of Registration"
                subLabel={mode.value}
              />
              {(sipType.value === NEW || sipType.value === EXISTINGP) && (
                <DetailsGroup
                  mainLabel="Lumpsum Investment Amount(₹)"
                  subLabel={investmentAmount.value}
                />
              )}
              <DetailsGroup
                mainLabel="Installment Payment Amount(₹)"
                subLabel={installmentAmount.value}
              />
            </div>
          )}
          {state.type === STP && (
            <div>
              <DetailsGroup
                // mainLabel1="STP Option"
                // subLabel2={
                //   "Fixed"
                //   // !!stpDetails.swpOption
                //   //   ? stpDetails.swpOption.value.Description
                //   //   : "-"
                // }
                mainLabel="No. of transfers"
                subLabel={
                  !!stpDetails.transfers
                    ? stpDetails.transfers.value
                    : "Perpetual STP"
                }
              />
              <DetailsGroup
                mainLabel="Frequency"
                subLabel={
                  !!stpDetails.swpFrequency
                    ? stpDetails.swpFrequency.value
                    : "-"
                }
                mainLabel1="STP Day"
                subLabel2={!!stpDetails.sipDay ? stpDetails.sipDay.value : "-"}
              />
              <DetailsGroup
                mainLabel="Start Date"
                subLabel={
                  !!stpDetails.sipStartDate
                    ? stpDetails.sipStartDate.value
                    : "-"
                }
                mainLabel1="End Date"
                subLabel2={
                  !!stpDetails.sipEndDate ? stpDetails.sipEndDate.value : "-"
                }
              />
              <DetailsGroup1
                mainLabel="Switch in Scheme"
                subLabel={
                  !!stpDetails.switchInScheme
                    ? stpDetails.switchInScheme.value.value.fm_schdesc
                    : "-"
                }
              />
              <DetailsGroup
                mainLabel="PAN"
                subLabel={investorData.PAN}
                mainLabel1="Installment Amount(₹)"
                subLabel2={!!stpDetails.amount ? stpDetails.amount.value : "-"}
              />
            </div>
          )}
          {state.type === SWP && (
            <div>
              <DetailsGroup
                mainLabel="SWP Option"
                subLabel={
                  swpOption.Description === "Fixed"
                    ? "Fixed Amount Withdrawal"
                    : swpOption.Description === "Capital Appreciation"
                    ? "Capital Appreciation Withdrawal"
                    : swpOption.Description
                }
                mainLabel1="No.of withdrawals"
                subLabel2={withDrawals || "Perpetual SWP"}
              />
              <DetailsGroup
                mainLabel="Frequency"
                subLabel={swpFrequency.FrequencyID || swpFrequency}
                mainLabel1="SWP Day"
                subLabel2={sipDay}
              />
              <DetailsGroup
                mainLabel="Start Date"
                subLabel={sipStartDate}
                mainLabel1="End Date"
                subLabel2={sipEndDate}
              />
              {swpOption.Description === "Fixed" && (
                <DetailsGroup
                  mainLabel="SWP Amount (₹)"
                  subLabel={amount}
                  mainLabel1="Pan"
                  subLabel2={investorData.PAN}
                />
              )}
            </div>
          )}

          {state.type != "Sip cancellation" && (
            <DetailsGroup
              mainLabel={
                state.type === REDEMPTION
                  ? "Redemption Type"
                  : state.type === SWP ||
                    state.type === "Sip Pause" ||
                    state.type === "Sip cancellation"
                  ? ""
                  : "ARN Code"
              }
              subLabel={
                state.type === SWITCH
                  ? this.props.token
                  : state.type === REDEMPTION
                  ? redemptionType
                  : state.type === SWP
                  ? ""
                  : state.type === SIP
                  ? state.arnCode
                  : state.type === STP && !!stpDetails && !!stpDetails.arnCode
                  ? stpDetails.arnCode.value
                  : state.type === ADDITIONAL_PURCHASE
                  ? state.ARNCode
                  : state.type === "NEW_PURCHASE"
                  ? state.arnCode
                  : ""
              }
              mainLabel1={
                state.type === REDEMPTION
                  ? "Redemption Mode"
                  : state.type === SWP ||
                    state.type === "Sip Pause" ||
                    state.type === "Sip cancellation"
                  ? ""
                  : "Sub ARN Code"
              }
              subLabel2={
                state.type === "SWITCH"
                  ? state.subArnCode
                  : state.type === REDEMPTION
                  ? redemptionMode
                  : state.type === SWP
                  ? ""
                  : state.type === SIP
                  ? subArnCode.value
                  : state.type === STP &&
                    !!stpDetails &&
                    !!stpDetails.subArnCode
                  ? stpDetails.subArnCode.value
                  : confirmationData.SubbrokerArn
              }
            />
          )}
          {state.type == "Sip Pause" ||
            (state.type === "Sip cancellation" && (
              <div>
                <DetailsGroup
                  mainLabel="Transaction Type"
                  subLabel={state.type}
                  mainLabel1="Registration Date"
                  subLabel2={
                    !!state.pauseDetails && !!state.pauseDetails.trdate
                      ? state.pauseDetails.trdate
                      : "NA"
                  }
                />
                <DetailsGroup
                  mainLabel="From"
                  subLabel={pauseDetails.fromdate}
                  mainLabel1="To"
                  subLabel2={pauseDetails.todate}
                />

                <DetailsGroup
                  mainLabel="Frequency"
                  subLabel={pauseDetails.freq}
                  mainLabel1="Installment Amount (₹)"
                  subLabel2={pauseDetails.amt}
                />
                {state.type === "Sip cancellation" && state.sipType === "SIP" && (
                  <DetailsGroup
                    mainLabel="Cancellation Reason"
                    subLabel={this.props.location.state.cancellationReason}
                  />
                )}

              </div>
            ))}

          {state.type !== SWP && state.type !== SIP && (
            <div>
              <DetailsGroup
                mainLabel={
                  state.type === REDEMPTION
                    ? "Redemption (Amount/Units)"
                    : state.type == "Sip Pause" ||
                      state.type === "Sip cancellation"
                    ? ""
                    : "EUIN No."
                }
                subLabel={
                  state.type === REDEMPTION
                    ? amount
                    : state.type === STP
                    ? !!stpDetails.euinNo && !!stpDetails.euinNo.value
                      ? stpDetails.euinNo.value.value
                      : ""
                    : state.type === SWITCH
                    ? !!state.euin
                      ? state.euin.value
                      : ""
                    : confirmationData.EUINCode || ""
                }
                mainLabel1={
                  state.type === REDEMPTION
                    ? "PAN"
                    : state.type === STP
                    ? ""
                    : state.type === SWITCH
                    ? "Switch (Amount/Units)"
                    : state.type == "Sip Pause" ||
                      state.type === "Sip cancellation"
                    ? ""
                    : "Investment Amount (₹)"
                }
                subLabel2={
                  state.type === REDEMPTION
                    ? investorData.PAN
                    : state.type === STP
                    ? ""
                    : state.type === SWITCH
                    ? amount
                    : confirmationData.i_UntAmtValue
                }
              />

              {state.type === "Sip Pause" && (
                <div>
                  <div className="input-margin">
                    <CustomizedSelect
                      placeholder="Select Mode"
                      name="Select Pause Start Date"
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      handleChange={this.handlesipPauseDateChange}
                      // handleChange={this.handleSipTypeChange}
                      value={this.state.selected_pause_date.value}
                      // inputRef={ele => (this.sipTypeField = ele)}
                      // isError={sipType.isError}
                      // errorMessage={sipType.errorMessage}
                    >
                      {this.state.sip_pause_dates.map((item, index) => (
                        <MenuItem value={item.pause_start_date} key={index}>
                          {item.pause_start_date}
                        </MenuItem>
                      ))}
                    </CustomizedSelect>
                  </div>
                  <div className="input-margin">
                    <CustomizedSelect
                      placeholder="Select Mode"
                      name="Select Pause Installments"
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      handleChange={this.handlePauseInstallments}
                      value={this.state.selected_pause_installments.value}
                      disabled={!this.state.selected_pause_date.value}
                      // value={sipType.value}
                      // inputRef={ele => (this.sipTypeField = ele)}
                      // isError={sipType.isError}
                      // errorMessage={sipType.errorMessage}
                    >
                      {this.state.pause_installemnts.map((item, index) => (
                        <MenuItem value={index + 1} key={index}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </CustomizedSelect>
                  </div>
                  <Input
                    name={"Sip Pause End Date"}
                    bordercolor="rgba(0, 0, 0, 0.5)"
                    height={15}
                    value={this.state.sip_pause_end_Data || ""}
                    disabled={true}
                    // onChange={this.changeValue("password")}
                    // onBlur={this.showError("password")}
                    // onClick={() => {
                    //   this.setState({
                    //     showPassword: !this.state.showPassword,
                    //   });
                    // }}
                    // isShowError={password.isError}
                    // inputref={(ele) => (this.passwordField = ele)}
                    // onKeyPress={this.keyPress()}
                  />
                </div>
              )}
            </div>
          )}
        </Paper>

        <CustomLoader display={!!this.state.disabled ? true : false} />

        {(!!confirmationData.ID ||
          !!scheme ||
          !!sipDetails.sipType ||
          state.type === "Sip cancellation" ||
          state.type === "Sip Pause" ||
          !!stpDetails.scheme) && (
          <div className="digital-form-button-next-div">
            <Signbutton
              buttontitle={
                !!this.state.disabled ? "Transaction in progress…" : "Confirm"
              }
              width={!!this.state.disabled ? 300 : 150}
              disabled={this.state.disabled}
              onClick={
                state.type === "Sip Pause"
                  ? this.confirmSipPause
                  : state.type === "Sip cancellation"
                  ? this.confirmSipcancellation
                  : state.type === REDEMPTION
                  ? this.confirmRedemption
                  : state.type === SWP
                  ? this.confirmSwp
                  : state.type === STP
                  ? this.confirmStp
                  : state.type === SWITCH
                  ? this.confirmSwitch
                  : state.type === SIP
                  ? this.sipSubmit
                  : this.confirmPurchase
              }
            />
          </div>
        )}

        <CustomizedDialogs
          open={this.state.open}
          type={
            state.type === STP ? "STP" : state.type === SWITCH ? "SWITCH" : ""
          }
          refNo={
            state.type === STP
              ? this.state.stpRefNo.value
              : state.type === SWP
              ? this.state.swpRefNo.value
              : state.type === REDEMPTION
              ? this.state.redemption.value
              : state.type === SWITCH
              ? this.state.switchRefNo.value
              : state.type === "SIP"
              ? this.state.sipRefNo.value
              : state.type === "Sip cancellation"
              ? this.state.sipCancellation
              : state.type === "Sip Pause"
              ? this.state.sipPauseRefno
              : ""
          }
          refrence={this.state.nfoNew}
          transactName={
            state.type === "ADDITIONAL_PURCHASE"
              ? "Additional Purchase"
              : state.type === "NEW_PURCHASE"
              ? "New Purchase"
              : state.type === "SIP"
              ? "SIP"
              : ""
          }
          closeModal={this.closeModal}
          componentName={
            state.type === SWP
              ? "SWP"
              : state.type === REDEMPTION
              ? "REDEMPTION"
              : state.type === "Sip cancellation" &&
                !!state &&
                !!state.sipType &&
                state.sipType === "SIP"
              ? "Sip cancellation"
              : state.type === "Sip cancellation" &&
                !!state &&
                !!state.sipType &&
                state.sipType === "STP"
              ? "STP cancellation"
              : state.type === "Sip cancellation" &&
                !!state &&
                !!state.sipType &&
                state.sipType === "SWP"
              ? "SWP cancellation"
              : state.type === "Sip Pause"
              ? "Sip Pause"
              : ""
          }
          Transaction={true}
          transactionStatus={this.gotoTransactionTypes}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  ({ authReducer, transactions, profileReducer }) => {
    const { profileData } = profileReducer;
    const {
      confirmationData,
      sipDetails = {},
      switchDetails = {},
      stpDetails = {},
    } = transactions;
    let { investorData, communicationDetails, token, userId } = authReducer;

    const Xmobile = (communicationDetails || []).find(
      (detail) => detail.PAN === investorData.PAN && !!detail.Mobile
    );
    const Xemail = (communicationDetails || []).find(
      (detail) => detail.PAN === investorData.PAN && !!detail.email
    );
    const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";
    const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";

    investorData = { ...investorData, Email: Email, Mobile: Mobile };
    return {
      confirmationData,
      communicationDetails,
      investorData,
      token,
      profileData,
      userId,
      sipDetails,
      switchDetails,
      stpDetails,
    };
  },
  {
    confirmPurchase,
    redemptionConfirm,
    sipPauseDateCalculate,
    sipPauseEndDateCalculate,
    swpConfirm,
    submitSipCancellation,
    stpConfirm,
    saveSipData,
    submitSTPCancellation,
    submitPurchaseData,
    sipPauseValidator,
    finalSipPause,
  }
)(NewPurchaseConfirm);

export class DetailsGroup extends Component {
  render() {
    const { reverse } = this.props;
    return (
      <div className="otp ">
        {(!!this.props.mainLabel || !!this.props.subLabel) && (
          <div className="digital-form-details-div">
            <div
              className={
                !!reverse
                  ? "digital-form-details-sub-label"
                  : "digital-form-details-main-label"
              }
            >
              {this.props.mainLabel}
            </div>
            <div
              className={
                !!reverse
                  ? "digital-form-details-main-label"
                  : "digital-form-details-sub-label"
              }
            >
              {this.props.subLabel}
            </div>
          </div>
        )}
        {(!!this.props.mainLabel1 || !!this.props.subLabel2) && (
          <div className="digital-form-details-next-div">
            <div
              className={
                !!reverse
                  ? "digital-form-details-sub-label"
                  : "digital-form-details-main-label"
              }
            >
              {this.props.mainLabel1}
            </div>
            <div
              className={
                !!reverse
                  ? "digital-form-details-main-label"
                  : "digital-form-details-sub-label"
              }
            >
              {this.props.subLabel2}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export class DetailsGroup1 extends Component {
  render() {
    const { reverse } = this.props;
    return (
      <div className="otp ">
        {(!!this.props.mainLabel || !!this.props.subLabel) && (
          <div className="digital-form-details-div">
            <div
              className={
                !!reverse
                  ? "digital-form-details-sub-label"
                  : "digital-form-details-main-label"
              }
            >
              {this.props.mainLabel}
            </div>
            <div
              className={
                !!reverse
                  ? "digital-form-details-main-label"
                  : "digital-form-details-sub-label"
              }
              style={{ width: "150%" }}
            >
              {this.props.subLabel}
            </div>
          </div>
        )}
      </div>
    );
  }
}
// const investorData = { ...this.props.investorData, Email, Mobile };
// const Xmobile = (communicationDetails.reverse() || []).find(
//   (detail) => (detail.PAN === investorData.PAN) && !!detail.Mobile
// );
// const Xemail = (communicationDetails.reverse() || []).find(
//   (detail) => detail.PAN === investorData.PAN && !!detail.email
// );
// const Email = !!Xemail && !!Xemail.email ? Xemail.email : "";
// const Mobile = !!Xmobile && !!Xmobile.Mobile ? Xmobile.Mobile : "";
