import React, { Component } from "react";
import CustomizedSelect from "./customizedSelect";
import { Input } from "../../forms/input";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import Signbutton, { Debtbutton } from "../../forms/button";
import {
  validateSubBroker,
  getNewPurchaseFundList,
  getOtherSchemes,
  getAssetClasses,
  setSwitchDetails,
  getRedemptionPaymentDetails,
} from "shared/store/actions";

import { connect } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import {
  YES,
  PARTIAL,
  FULL,
  UNITS,
  AMOUNT,
  REGULAR,
  PURCHASE,
  SWITCH,
} from "shared/store/types";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import { NEW_TRANSACTION_URL } from ".";
import { SundaramMutualFund } from "./newPurchaseForm";
import { INVESTOR_URL } from "../investors";
class switchComponent extends Component {
  constructor(props) {
    super(props);

    const { switchDetails } = this.props;

    this.state = {
      fund: { value: { value: "105" } },
      folios: [],
      folio: { value: "" },
      schemes: [],
      scheme: { value: "" },
      radioValue: { value: "no" },
      arnCode: { value: this.props.token },
      subArnCode: { value: "" },
      subBrokerCode: { value: "" },
      euinNo: { value: "" },
      category: { value: "" },
      categories: [],
      switchInSchemes: [],
      switchInScheme: { value: "" },
      amount: { value: "" },
      switchType: PARTIAL,
      switchMode: UNITS,
      ...switchDetails,
    };
  }

  handleFundChange = (value) => {
    if (!value) {
      this.setState({
        fund: { value: "" },
        folios: [],
        folio: { value: "" },
        scheme: { value: "" },
        amount: { value: "" },
        switchInScheme: { value: "" },
        category: { value: "" },
      });
      return;
    } else if (!!value && !!this.state.fund && value.value === "105") {
      return;
    } else {
      const fund = value.value;
      const folios = this.props.fundList.reduce((prev, curr) => {
        if (curr.Fund === fund && curr.sSchFlg === YES) {
          return [...prev, curr.Acno];
        }
        return prev;
      }, []);
      this.setState({
        fund: { value },
        folios: Array.from(new Set(folios)),
        folio: { value: "" },
        scheme: { value: "" },
        category: { value: "" },
        switchInScheme: { value: "" },
        switchType: PARTIAL,
        switchMode: UNITS,
      });
      if (!!fund) {
        this.validateBrokerCode(fund)();
      }
    }
  };
  async componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    this.getFolios();
  }

  getFolios = () => {
    const folios = this.props.fundList.reduce((prev, curr) => {
      if (curr.Fund.trim() === "105" && curr.sSchFlg === YES) {
        return [...prev, curr.Acno];
      }
      return prev;
    }, []);
    this.setState({
      fund: { value: { value: "105" } },
      folios: Array.from(new Set(folios)),
      folio: { value: "" },
      scheme: { value: "" },
      category: { value: "" },
      switchInScheme: { value: "" },
      switchType: PARTIAL,
      switchMode: UNITS,
    });
    // if (!!fund) {
    this.validateBrokerCode("117")();
    // }
  };

  validateBrokerCode = (fund) => async () => {
    const { subArnCode, arnCode } = this.state;
    if (
      !!this.state.subArnCode.value &&
      this.state.subArnCode.value === this.state.arnCode.value.split("-")[1]
    ) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: "ARN and Sub ARN Code should not be the same",
        },
      });
      return;
    }
    const params = {
      Fund: "105",
      SubAgentCd: !!subArnCode.value ? `ARN-${subArnCode.value}` : "",
      AgentCd: arnCode.value,
    };
    const response = await this.props.validateSubBroker(params);
    if (!response) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `(Please enter a valid Sub ARN Code)`,
        },
      });
    }
  };

  handleFolioChange = async (value) => {
    if (!value) {
      this.setState({
        folio: { value: "" },
        schemes: [],
        scheme: { value: "" },
        category: { value: "" },
        switchInScheme: { value: "" },
        switchType: PARTIAL,
        switchMode: UNITS,
        amount: { value: "" },
      });
      return;
    } else if (
      !!value &&
      !!this.state.folio &&
      value.value === this.state.folio.value.value
    ) {
      return;
    } else {
      const folio = value.value;
      const schemes = this.schemes(folio);

      this.setState({
        folio: {
          value,
          isError: !schemes.length,
          errorMessage: !schemes.length
            ? "SWITCH in zero balance folios is not allowed"
            : "",
        },
        schemes,
        scheme: { value: "" },
        category: { value: "" },
        switchInScheme: { value: "" },
        switchType: PARTIAL,
        switchMode: UNITS,
        categories: [],
      });

      this.getAssetClasses();
    }
  };
  schemes = (folio) => {
    return this.props.fundList.reduce((prev, curr) => {
      if (
        curr.sSchFlg === YES &&
        curr.Fund.trim() === "105" &&
        curr.Acno === folio &&
        !!curr.BalUnits
      ) {
        return [...prev, curr];
      }
      return prev;
    }, []);
  };
  handleSchemeChange = (value) => {
    if (!value) {
      this.setState({
        scheme: { value: "" },
        minAmount: "",
        amount: { value: "" },
        category: { value: "" },
        switchInScheme: { value: "" },
        switchType: PARTIAL,
        switchMode: UNITS,
        categories: [],
      });
      return;
    } else if (
      !!value.value &&
      !!this.state.scheme.value.value &&
      value.value.SchDesc === this.state.scheme.value.value.SchDesc
    ) {
      return;
    } else {
      const scheme = value.value;
      if (!!value.value && !value.value.BalUnits) {
        this.setState({
          folio: {
            value: "",
            isError: "true",
            errorMessage: "Switch in Zero balance folios is not accepted",
          },
          scheme: { value: "" },
        });
      } else {
        this.setState({
          scheme: { value },
          minAmount: !!scheme ? scheme.MinAmt : "",
          amount: { value: "" },
          category: { value: "" },
          switchInScheme: { value: "" },
        });
      }
      this.getAssetClasses();
    }
  };
  handleCategoryChange = (event) => {
    if (!!event.target.value) {
      this.getOtherSchemes(event.target.value);
      this.setState({
        category: { value: event.target.value },
        switchInScheme: { value: "" },
        switchInSchemes: [],
      });
    }
    // else if(event.target.value===){

    // }
    else {
      this.setState({
        category: { value: "" },
        switchInScheme: { value: "" },
        switchInSchemes: [],
      });
    }
  };
  getAssetClasses = async () => {
    const params = {
      Fund: "105",
      Folio: this.state.folio.value.value,
    };
    const assetClasses = await this.props.getAssetClasses(params);
    if (assetClasses) {
      this.setState({
        categories: assetClasses.DtData,
      });
    }
  };

  changeEuin = (value) => {
    this.setState({
      euinNo: { value },
    });
  };

  getOtherSchemes = async (value) => {
    const params = {
      schtype: REGULAR,
      category: value,
      acno: this.state.folio.value.value,
      fundCode: "105",
      trantype: "S",
    };
    const otherSchemes = await this.props.getOtherSchemes(params);
    if (otherSchemes) {
      this.setState({
        switchInSchemes: otherSchemes.DtData,
      });
    }
  };
  handleSwitchInSchemeChange = (value) => {
    if (!value) {
      this.setState({
        switchInScheme: { value },
      });
      return;
    }
    this.setState({
      switchInScheme: { value },
    });
  };
  changeValue = (label) => (event) => {
    if (
      (label === "subArnCode" || label === "subBrokerCode") &&
      event.target.value.length <= 20
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
    } else if (label === "radioValue" && event.target.value === "no") {
      if (!this.state.euinNo.value) {
        this.setState({
          radioValue: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: { value: event.target.value },
          euinNo: { value: "" },
        });
      }
    } else if (
      label !== "subArnCode" &&
      label !== "subBrokerCode" &&
      label === "radioValue" &&
      event.target.value !== "no"
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
    }
  };

  handleSwitchValueChange(event) {
    if (event.target.value.length <= 10) {
      this.setState({
        amount: { value: event.target.value.replace(/^0+/, "") },
      });
    }
  }

  changeSwitchType = (value) => () => {
    if (value === FULL) {
      this.setState({
        switchType: FULL,
        switchMode: UNITS,
        amount: {
          value: !!this.state.scheme.value
            ? this.state.scheme.value.value.BalUnits
            : "",
        },
      });
    }
    if (value === PARTIAL) {
      this.setState({
        switchType: PARTIAL,
        amount: { value: "" },
      });
    }
  };
  changeSwitchMode = (value) => () => {
    if (value === AMOUNT) {
      this.setState({
        switchMode: AMOUNT,
        amount: { value: "" },
      });
    }
    if (value === UNITS) {
      this.setState({
        switchMode: UNITS,
        amount: { value: "" },
      });
    }
  };
  showError = (label) => () => {
    const {
      [label]: { value },
    } = this.state;
    if (!!this.state.scheme && !!this.state.scheme.value.value) {
      if (label === "amount") {
        this.setState({
          [label]: {
            value,
            isError:
              this.state.switchMode === AMOUNT
                ? this.state.scheme.value.value.MinAmt > this.state.amount.value
                  ? true
                  : !value
                : this.state.scheme.value.value.BalUnits <
                  this.state.amount.value
                ? true
                : !value,
            errorMessage:
              this.state.switchMode === AMOUNT
                ? this.state.scheme.value.value.MinAmt > this.state.amount.value
                  ? `Minimum amount should be ₹ ${this.state.minAmount}`
                  : ""
                : this.state.scheme.value.value.BalUnits <
                  this.state.amount.value
                ? `You don't have enough Units To Redeem!`
                : "",
          },
        });
      }
    } else {
      this.setState({
        [label]: {
          value,
          isError: !value,
        },
      });
    }
  };

  submit = async () => {
    let currValue, currAmountValue;
    const {
      funds,
      fund,
      schemes,
      switchInSchemes,
      folios,
      switchType,
      switchMode,
      minAmount,
      categories,
      arnCode,
      subArnCode,
      subBrokerCode,
      euinNo,
      amount,
      radioValue,
      isError,
      errorMessage,
      ...others
    } = this.state;
    if (this.state.amount.value === "") {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `Switch value is Mandatory*`,
        },
      });
    }
    const arn = this.state.arnCode.value.split("-");
    const keys = Object.keys(others);
    if (!!this.state.scheme.value) {
      currValue = parseFloat(
        this.state.scheme.value.value.CurValue.replace(/,/g, "")
      );
    }
    currAmountValue =
      this.state.switchType !== FULL
        ? parseFloat(this.state.amount.value.replace(/,/g, ""))
        : "";
    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${
                key === "folio"
                  ? "Folio"
                  : key === "scheme"
                  ? "Scheme"
                  : key === "category"
                  ? "Category"
                  : key === "switchInScheme"
                  ? "Switch in Scheme"
                  : key
              } is Mandatory*`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        // case "fund": {
        //   this.fundField.focus();
        //   break;
        // }
        case "folio": {
          this.folioField.focus();
          break;
        }
        case "scheme": {
          this.switchOutField.focus();
          break;
        }
        case "category": {
          this.category.focus();
          break;
        }
        case "switchInScheme": {
          this.switchInScheme.focus();
          break;
        }
      }
    } else if (arn[1] === this.state.subArnCode.value) {
      this.setState({
        subArnCode: {
          value: "",
          isError: true,
          errorMessage: `and ARN code should  not match `,
        },
      });
    } else if (this.state.amount.value === "") {
      this.setState({
        amount: {
          value: "",
          isError: true,
          errorMessage: `Switch value is Mandatory*`,
        },
      });
      this.amountField.focus();
    } else if (
      (this.state.scheme.value.value.MinAmt > this.state.amount.value &&
        this.state.switchMode === AMOUNT) ||
      (this.state.scheme.value.value.BalUnits < this.state.amount.value &&
        this.state.switchMode === UNITS &&
        this.state.switchType === PARTIAL) ||
      (currValue < currAmountValue && this.state.switchMode === AMOUNT)
    ) {
      if (
        currValue < currAmountValue &&
        this.state.scheme.value.value.MinAmt < this.state.amount.value
      ) {
        this.setState({
          amount: {
            ...others["amount"],
            isError: true,
            errorMessage: `You dont have enough amount to Switch plan`,
          },
        });
      } else if (
        this.state.scheme.value.value.MinAmt > this.state.amount.value &&
        this.state.switchMode === AMOUNT
      ) {
        this.setState({
          amount: {
            ...others["amount"],
            isError: true,
            errorMessage: `Minimum Amount value is ₹ ${this.state.minAmount} `,
          },
        });
        this.amountField.focus();
        return;
      } else if (
        this.state.scheme.value.value.BalUnits < this.state.amount.value &&
        this.state.switchMode === UNITS
      ) {
        this.setState({
          ...others["amount"],
          isError: true,
          errorMessage: `You dont have enough Units to Switch plan`,
        });
      }
      this.amountField.focus();
      return;
    } else if (
      this.state.radioValue.value === "no" &&
      this.state.euinNo.value === ""
    ) {
      this.setState({
        euinNo: { value: "", isError: true, errorMessage: `Choose EUIN` },
      });
      this.euinField.focus();
    } else {
      this.props.setSwitchDetails(this.state);
      const params = {
        amount: switchMode === AMOUNT ? this.state.amount.value : "0",
        fund: "105",
        plan: others.scheme.value.value.Pln,
        scheme: others.scheme.value.value.Sch,
        acno: others.scheme.value.value.Acno,
        trdt: moment(new Date()).format("MM/DD/YYYY"),
        units: switchMode === UNITS ? this.state.amount.value : "0",
        ttrtype: PURCHASE,
      };
      const response = await this.props.getRedemptionPaymentDetails(params);
      if (response) {
        this.props.history.push({
          pathname: INVESTOR_URL + "/transction-types/switch/confirm",
          state: {
            title: "Switch Payment Summary",
            type: SWITCH,
            scheme: others.scheme.value.value,
            Folio: { value: others.scheme.value.value.Acno },
            switchInScheme: others.switchInScheme.value.value,
            fund: "105",
            switchMode,
            subArnCode: this.state.subArnCode.value,
            subBrokerCode: this.state.subBrokerCode.value,
            switchType,
            euin: this.state.euinNo.value,
            amount: this.state.amount.value,
          },
        });
      }
    }
  };

  render() {
    const {
      fund,
      funds = [],
      folio,
      folios = [],
      schemes = [],
      scheme,
      radioValue,
      arnCode,
      subArnCode,
      subBrokerCode,
      euinNo,
      categories,
      category,
      switchInScheme,
      switchInSchemes,
      amount,
      switchType,
      switchMode,
    } = this.state;
    const { euinList } = this.props;
    const checkMultiBrokerFlag =
      !!this.state.scheme &&
      !!this.state.scheme.value &&
      !!this.state.scheme.value.value &&
      !!this.state.scheme.value.value.multibrokflg &&
      this.state.scheme.value.value.multibrokflg === "Y";
    return (
      <div className="transaction-details">
        <div className="title">Switch</div>

        <div className="input-margin">
          <div className="input-name ">Folio*</div>
          <IntegrationReactSelect
            suggestions={folios.map((item) => ({
              label: item,
              value: item,
            }))}
            value={folio.value}
            placeholder="Select Folio"
            handleChange={this.handleFolioChange}
            inputRef={(ele) => (this.folioField = ele)}
            isError={folio.isError}
            errorMessage={folio.errorMessage}
          />
        </div>
        <div className="input-margin">
          <div className="input-name ">Switch out Scheme*</div>
          <IntegrationReactSelect
            suggestions={schemes.map((item) => ({
              value: item,
              label: item.PlnDesc
                ? `${item.SchDesc1} - ${item.PlnDesc} - ${item.OptDesc}`
                : item.fm_schdesc,
            }))}
            value={scheme.value}
            placeholder="Select Scheme"
            handleChange={this.handleSchemeChange}
            disabled={!folio.value}
            inputRef={(ele) => (this.switchOutField = ele)}
            isError={scheme.isError}
            errorMessage={scheme.errorMessage}
          />
        </div>
        <div className="redemption-details">
          <div className="keys">
            {!checkMultiBrokerFlag && <div>Balance Units</div>}
            {!checkMultiBrokerFlag && <div>Current Value</div>}
            <div>NAV as on Date</div>
            <div>Minimum Amount (INR)</div>
          </div>
          <div className="values">
            {!checkMultiBrokerFlag && (
              <div>
                {!!scheme && !!scheme.value ? scheme.value.value.BalUnits : ""}
              </div>
            )}
            {!checkMultiBrokerFlag && (
              <div>
                {!!scheme && !!scheme.value
                  ? `₹${scheme.value.value.CurValue}`
                  : ""}
              </div>
            )}
            <div>
              {!!scheme && !!scheme.value ? scheme.value.value.LastNAV : ""}
            </div>
            <div>
              {!!scheme && !!scheme.value
                ? `₹${scheme.value.value.MinAmt}`
                : ""}
            </div>
          </div>
        </div>
        {!!checkMultiBrokerFlag && (
          <div
            style={{
              fontFamily: "SourceSansPro-SemiBold",
              fontSize: 13,
              color: "red",
              margin: 10,
            }}
          >
            {" "}
            Note: This folio has multiple distributors. Hence, the balance units
            and current value will not be displayed.
          </div>
        )}
        <div className="input-margin">
          <CustomizedSelect
            placeholder="Select Category"
            name="Scheme Category*"
            bordercolor="rgba(0, 0, 0, 0.5)"
            handleChange={this.handleCategoryChange}
            value={category.value}
            inputRef={(ele) => (this.category = ele)}
            isError={category.isError}
            disabled={!scheme.value}
            errorMessage={category.errorMessage}
          >
            {categories.map((item, index) => (
              <MenuItem value={item.CatValue} key={index}>
                {item.CatDesc}
              </MenuItem>
            ))}
          </CustomizedSelect>
        </div>

        <div className="input-margin">
          <div className="input-name ">Switch in Scheme*</div>
          <IntegrationReactSelect
            suggestions={switchInSchemes.map((item) => ({
              label: item.fm_schdesc,
              value: item,
            }))}
            label="Fund"
            handleChange={this.handleSwitchInSchemeChange}
            value={switchInScheme.value}
            placeholder="Select Switch in Scheme"
            disabled={!category.value}
            inputRef={(ele) => (this.switchInScheme = ele)}
            isError={switchInScheme.isError}
            errorMessage={switchInScheme.errorMessage}
          />
        </div>

        <div className="input-margin">
          <Input
            name="ARN Code"
            bordercolor="rgba(0, 0, 0, 0.5)"
            value={arnCode.value.split("-")[1]}
            disabled={true}
          />
        </div>

        <div className="input-margin">
          <Input
            name="Sub ARN Code"
            type="number"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            bordercolor="rgba(0, 0, 0, 0.5)"
            disabled={!fund.value}
            isShowError={subArnCode.isError}
            errorMessage={subArnCode.errorMessage}
            value={subArnCode.value}
            onBlur={
              !!fund.value ? this.validateBrokerCode(fund.value.value.Fund) : ""
            }
            onChange={this.changeValue("subArnCode")}
          />
        </div>
        <div className="input-margin">
          <Input
            name="Sub Broker Code"
            type="number"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "." || evt.key === "-") &&
              evt.preventDefault()
            }
            bordercolor="rgba(0, 0, 0, 0.5)"
            value={subBrokerCode.value}
            onChange={this.changeValue("subBrokerCode")}
          />
        </div>

        <div className="input-field-container">
          <div className="input-name">EUIN Declaration*</div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Search"
              name="search"
              value={radioValue.value}
              style={{ flexDirection: "row" }}
              onChange={this.changeValue("radioValue")}
            >
              <div className="investor-radio-button">
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">YES</div>
              </div>
              <div className="investor-radio-button">
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      className="investor-search-radio"
                      style={{ color: "#00579b" }}
                    />
                  }
                  className="investor-formcontrol-label"
                  style={{ margin: 0 }}
                />
                <div className="investor-form-label">NO</div>
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        {radioValue.value === "no" ? (
          <div className="input-margin">
            <IntegrationReactSelect
              suggestions={euinList.map((item) => ({
                label: item.abm_agent,
                value: item.abm_agent,
              }))}
              label="Fund"
              handleChange={this.changeEuin}
              value={euinNo.value}
              disabled={!euinList.length}
              placeholder="Choose EUIN"
              inputRef={(ele) => (this.euinField = ele)}
              isError={euinNo.isError}
              errorMessage={switchInScheme.errorMessage}
            />
          </div>
        ) : (
          <div className="euin-text">
            I/We hereby confirm that the EUIN Declaration box has been
            intentionally left the blank by me/us as this is an 'execution-only'
            transaction without any interaction or advice by any personnel of
            the above distributor or notwithstanding the advice of
            in-appropriateness,if any provided by any personnel of the
            distributor and the distributor has not charged any advisory fees on
            this transaction
          </div>
        )}

        <div className="input-name">Switch Type</div>
        <div className="radio-buttons-div">
          <Debtbutton
            buttontitle="Partial"
            width={150}
            active={switchType === PARTIAL}
            onClick={this.changeSwitchType(PARTIAL)}
          />
          <Debtbutton
            buttontitle="Full"
            width={150}
            active={switchType === FULL}
            onClick={this.changeSwitchType(FULL)}
          />
        </div>

        <div className="input-name">Switch Mode</div>
        <div className="radio-buttons-div">
          <Debtbutton
            buttontitle="Units"
            width={150}
            active={switchMode === UNITS}
            onClick={this.changeSwitchMode(UNITS)}
          />
          <Debtbutton
            buttontitle="Amount"
            disabled={switchType === FULL ? true : false}
            width={150}
            active={switchMode === AMOUNT}
            onClick={this.changeSwitchMode(AMOUNT)}
          />
        </div>

        <Input
          name="Switch (Amount/Units)*"
          type="number"
          bordercolor="rgba(0, 0, 0, 0.5)"
          onKeyDown={(evt) =>
            (evt.key === "e" || evt.key === "." || evt.key === "-") &&
            evt.preventDefault()
          }
          errorMessage={amount.errorMessage}
          isShowError={amount.isError}
          onBlur={this.showError("amount")}
          disabled={switchType === FULL ? true : false}
          onChange={(event) => this.handleSwitchValueChange(event)}
          value={amount.value}
          inputref={(ele) => (this.amountField = ele)}
        />

        <div className="digital-form-button-next-div">
          <Signbutton buttontitle="Submit" onClick={this.submit} width={150} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer, transactions }) => {
    const { fundList, token, userId, investorData, communicationDetails } =
      authReducer;
    const { euinList, switchDetails = {} } = transactions;
    return {
      fundList,
      token,
      userId,
      euinList,
      switchDetails,
      investorData,
      communicationDetails,
    };
  },
  {
    validateSubBroker,
    getNewPurchaseFundList,
    getOtherSchemes,
    setSwitchDetails,
    getAssetClasses,
    getRedemptionPaymentDetails,
  }
)(switchComponent);
