export const firstLetterCaptilize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const firstLetterOnlyCaptilize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const captalize = text => {
  return text.toUpperCase();
};

export const formatDate = date => {
  return `${checkZero(date.getDate())}/${checkZero(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
};
const checkZero = data => {
  return data.length == 1 ? `0${data}` : data;
};
