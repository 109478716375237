import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { Input } from "../../forms/input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IntegrationReactSelect from "../../forms/autoCompleteInput";
import { connect } from "react-redux";
import { LocalActivityOutlined } from "@material-ui/icons";
import Signbutton from "../../forms/button";
import {
  validateSubBroker,
  NfoSchemesSubmission,
  getNfoSchemes,
} from "shared/store/actions";
import { NO, YES } from "shared/store/types";
import CustomizedDialogs from "../customModal";

class NfoTransact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subArnCode: { value: "" },
      subBrokerCode: { value: "" },
      radioValue: { value: "no" },
      euinNo: { value: "" },
      open: false,
      FundSchemes: [],
      scheme: { value: "" },
      nfoLink: "",
      refno: "",
    };
  }
  async componentDidMount() {
    const { location, nfoSchemes } = this.props;
    if (!!location && !!location.fund && !!location.scheme) {
      const params = {
        Fund: "105",
        SubAgentCd: "",
        AgentCd: this.props.token,
      };

      const Euinresponse = await this.props.validateSubBroker(params);
      if (!!nfoSchemes && !!nfoSchemes.Table2 && nfoSchemes.Table2.length > 0) {
        const filteredSchemes = nfoSchemes.Table2.filter(
          (ele) => String(ele.fund).trim() === "105"
        );
        this.setState({
          FundSchemes: filteredSchemes,
        });
      } else {
        const params = { fund: "105" };
        const response = await this.props.getNfoSchemes(params);

        if (!!response && !!response.Table2 && response.Table2.length > 0) {
          const filteredSchemes = response.Table2.filter(
            (ele) =>
              String(ele.fund).trim() === "105" &&
              ele.scheme_desc.toUpperCase().includes(location.scheme)
          );

          this.setState({
            FundSchemes: filteredSchemes,
          });
        }
      }
    } else {
      this.props.history.push(`${process.env.PUBLIC_URL}/dashboard/nfo-funds`);
    }
  }
  changeValue = (label) => (event) => {
    if (label === "radioValue" && event.target.value === "no") {
      if (!this.state.euinNo.value) {
        this.setState({
          radioValue: { value: event.target.value },
        });
      } else {
        this.setState({
          [label]: { value: event.target.value },
          euinNo: { value: "" },
        });
      }
      return;
    } else if (label === "radioValue" && event.target.value === "yes") {
      this.setState({
        radioValue: { value: event.target.value },
      });
      return;
    } else if (
      (label === "subArnCode" || label === "subBrokerCode") &&
      event.target.value.length <= 20
    ) {
      this.setState({
        [label]: { value: event.target.value },
      });
      return;
    }
  };

  changeEuin = (value) => {
    this.setState({
      euinNo: { value },
    });
  };

  submit = async () => {
    const {
      subArnCode,
      radioValue,
      euinNo,
      subBrokerCode,
      nfoLink,
      refno,
      open,
      FundSchemes,
      ...others
    } = this.state;
    const keys = Object.keys(others);
    if (this.state.euinNo.value === "" && radioValue.value === "no") {
      this.setState({
        euinNo: {
          value: "",
          isError: true,
          errorMessage: "Euin Declartion is mandatory*",
        },
      });
    }

    const findError = keys.every((key) => !!others[key].value);
    if (!findError) {
      keys.forEach((key) => {
        !others[key].value &&
          this.setState({
            [key]: {
              ...others[key],
              isError: true,
              errorMessage: `${key} is mandatory*`,
            },
          });
      });
      const firstErroredKey = keys.find((key) => !others[key].value);
      switch (firstErroredKey) {
        case "scheme": {
          this.schemeField.focus();
          break;
        }
      }
    } else if (this.state.euinNo.value === "" && radioValue.value === "no") {
      this.setState({
        euinNo: {
          value: "",
          isError: true,
          errorMessage: "Euin Declartion is mandatory*",
        },
      });
    } else {
      const params = {
        euincode: this.state.euinNo.value.value,
        euinflag: this.state.radioValue.value === "no" ? NO : YES,
        fund: "105",
        scheme: this.state.scheme.value.value,
        userid: this.props.userId,
        invdistflag: "P",
        arncode: this.props.token,
        subarn: this.state.subArnCode.value,
        subbroker: this.state.subBrokerCode.value,
      };
      const response = await this.props.NfoSchemesSubmission(params);
      if (
        !!response &&
        !!response.DtData &&
        !!response.DtData[0] &&
        !!response.DtData[0].refno
      ) {
        this.setState({
          open: true,
          refno: response.DtData[0].refno,
          nfoLink: response.DtData[0].nfo_link,
        });
      }
    }
  };

  closeModal = () => {
    const { location } = this.props;

    this.setState({
      open: false,
    });
    this.props.history.push({
      pathname: `${process.env.PUBLIC_URL}/dashboard/nfo-funds`,
      link: this.state.nfoLink,
      fund: "105",
      scheme: this.state.scheme.value.label,
    });
  };

  handleSchemeChange = (value) => {
    if (!value) {
      this.setState({
        scheme: { value: "" },
      });
      return;
    } else if (
      !!value.value &&
      !!this.state.scheme.value.value &&
      value.value.scheme_desc === this.state.scheme.value.value.scheme_desc
    ) {
      return;
    } else {
      const scheme = value.value;

      this.setState({
        scheme: { value },
      });
    }
  };

  render() {
    const { radioValue, scheme, subBrokerCode, subArnCode, euinNo } =
      this.state;
    const { euinList } = this.props;
    return (
      <div className="container-margin">
        <Paper
          className="digital-form-container"
          style={{
            boxShadow: "0px 0px 2px 0px rgba(211,211,211,0.3)",
            padding: "0px 50px",
            margin: "20px 0px",
            display: "block",
          }}
        >
          <div
            className="digital-form-Right-div"
            style={{ borderLeft: 0, padding: "20px 0px" }}
          >
            <div className="transaction-details">
              <div className="input-margin flex">
                <div className="appbar-div dual-margin ">
                  <div style={{ marginTop: 10 }}>
                    <Input
                      name="ARN"
                      disabled={true}
                      value={this.props.token.split("-")[1]}
                    />
                  </div>
                </div>
                <div className="appbar-div dual-margin">
                  <div style={{ marginTop: 10 }}>
                    <Input
                      name="Sub ARN Code"
                      onKeyDown={(evt) =>
                        (evt.key === "e" ||
                          evt.key === "." ||
                          evt.key === "-") &&
                        evt.preventDefault()
                      }
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      type="number"
                      onBlur={this.validateBrokerCode}
                      onChange={this.changeValue("subArnCode")}
                      inputref={(ele) => (this.subArnField = ele)}
                    />
                  </div>
                </div>
              </div>

              <div className="input-margin flex">
                <div
                  className="appbar-div dual-margin "
                  style={{ marginTop: 10 }}
                >
                  <div className="input-name ">Scheme</div>
                  <IntegrationReactSelect
                    suggestions={
                      !!this.state.FundSchemes &&
                      this.state.FundSchemes.length > 0
                        ? this.state.FundSchemes.map((item) => ({
                            label: item.scheme_desc,
                            value: item.scheme,
                          }))
                        : []
                    }
                    label="AMC"
                    handleChange={this.handleSchemeChange}
                    value={scheme.value}
                    placeholder="Select Scheme"
                    inputRef={(ele) => (this.schemeField = ele)}
                    isError={scheme.isError}
                    errorMessage={scheme.errorMessage}
                  />
                </div>
                <div className="appbar-div dual-margin ">
                  <div style={{ marginTop: 10 }}>
                    <Input
                      name="Sub Broker Code"
                      onKeyDown={(evt) =>
                        (evt.key === "e" ||
                          evt.key === "." ||
                          evt.key === "-") &&
                        evt.preventDefault()
                      }
                      type="number"
                      bordercolor="rgba(0, 0, 0, 0.5)"
                      value={subBrokerCode.value}
                      onChange={this.changeValue("subBrokerCode")}
                      inputref={(ele) => (this.subBrokerField = ele)}
                    />
                  </div>
                </div>
              </div>

              <div className="input-margin flex">
                <div className="appbar-div dual-margin">
                  <div className="input-field-container">
                    <div className="input-name">EUIN Declaration</div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="Search"
                        name="search"
                        value={radioValue.value}
                        style={{ flexDirection: "row" }}
                        onChange={this.changeValue("radioValue")}
                      >
                        <div className="investor-radio-button">
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio
                                className="investor-search-radio"
                                style={{ color: "#00579b" }}
                              />
                            }
                            className="investor-formcontrol-label"
                            style={{ margin: 0 }}
                          />
                          <div className="investor-form-label">YES</div>
                        </div>
                        <div className="investor-radio-button">
                          <FormControlLabel
                            value="no"
                            control={
                              <Radio
                                className="investor-search-radio"
                                style={{ color: "#00579b" }}
                              />
                            }
                            className="investor-formcontrol-label"
                            style={{ margin: 0 }}
                          />
                          <div className="investor-form-label">NO</div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {radioValue.value === "no" ? (
                    <div className="input-margin" style={{ marginBottom: 80 }}>
                      <IntegrationReactSelect
                        suggestions={euinList.map((item) => ({
                          label: item.abm_agent,
                          value: item.abm_agent,
                        }))}
                        label="Euin"
                        handleChange={this.changeEuin}
                        value={euinNo.value}
                        disabled={!euinList.length}
                        placeholder="Select EUIN"
                        inputRef={(ele) => (this.euinField = ele)}
                        isError={euinNo.isError}
                        errorMessage={euinNo.errorMessage}
                      />
                    </div>
                  ) : (
                    <div className="euin-text">
                      I/We hereby confirm that the EUIN box has been
                      intentionally left the blank by me/us as this is an
                      'execution-only' transaction without any interaction or
                      advice by any personnel of the above distributor or
                      notwithstanding the advice of in-appropriateness,if any
                      provided by any personnel of the distributor and the
                      distributor has not charged any advisory fees on this
                      transaction
                    </div>
                  )}{" "}
                </div>

                <div className="appbar-div dual-margin "></div>
               
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
            <Signbutton
              width={300}
              buttontitle="Submit"
              margin={1}
              onClick={this.submit}
            />
          </div>
        </div>
      </div>
         
        </Paper>
        <CustomizedDialogs
          nfoFunds={true}
          scheme={this.state.scheme.value.label}
          open={this.state.open}
          // nforefNo={this.state.refno}
          closeModal={this.closeModal}
          // type={"NFO"}
        />
      </div>
    );
  }
}

export default connect(
  ({ authReducer, dashboard, transactions }) => {
    const { token, userId } = authReducer;
    const { nfoSchemes } = dashboard;
    const { euinList } = transactions;
    return {
      token,
      euinList,
      userId,
      nfoSchemes,
    };
  },
  { validateSubBroker, NfoSchemesSubmission, getNfoSchemes }
)(NfoTransact);

{
  /* <div
style={{
  borderBottom: "1px solid rgba(211,211,211,0.4)",
  width: "100%",
}}
>
<div
  style={{
    fontFamily: "SourceSansPro-Regular",
    fontSize: 13,
    color: "#04078a",
  }}
>
  Logged as{" "}
  <span
    style={{
      fontFamily: "SourceSansPro-Bold",
      fontSize: 13,
      color: "#114a84",
    }}
  >
    {this.props.userId}
  </span>
</div>
<div
  style={{
    fontFamily: "SourceSansPro-Regular",
    fontSize: 13,
    marginBottom: 20,
    marginTop: 5,
    color: "#114a84",
  }}
>
  {this.props.token}
</div>
</div> */
}
