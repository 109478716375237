import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { BUILD_URL } from "../home/home";
import { NonLoginServicesDialog } from ".././home/customModal";
import { second_app_logo, APP_LOGO } from "../forms/appEssentials";

export default class Appbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const ServicesArray = [
      {
        title: "Distributor Services",
        link: "https://mfs.kfintech.com/mfs/distributor/distributor_Login.aspx",
      },
      {
        title: "Channel Partner Services",
        link: "https://cpupload.kfintech.com/ChannelServices/CP_Login.aspx",
      },
      {
        title: "Mutual Fund Investor Services",
        link: "https://mfs.kfintech.com/investor/",
      },
      { title: "Korp Connect", link: "https://mfs.kfintech.com/econnect" },
      {
        title: "RIA Services",
        link: "https://cpupload.kfintech.com/ChannelServices/RIA_Login.aspx",
      },
      {
        title: "Alternative Investment Fund Investor",
        link: "https://mfs.kfintech.com/karvyalternative/",
      },
      {
        title: "AMC Services",
        link: "https://fundswatch.kfintech.com/amcservices/",
      },
      {
        title: "Financial Services",
        link: "https://mfs.kfintech.com/FinancialServices",
      },
    ];
    return (
      <div className="appbardiv">
        <AppBar
          position="static"
          color="inherit"
          className="appbar-div"
          style={{ boxShadow: "0px 0px 7px 0px lightgray" }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "flex-end",
              maxHeight: "100px",
              minHeight: "100px",
              alignItems: "center",
              marginRight: 50,
            }}
          >
           
            <a>
              <img src={second_app_logo} alt="logo_image" className="logo" />
            </a>

            {/* <a
            // href="https://kfintech.com/"
             target="_blank">
              <img src={APP_LOGO} alt="logo_image" className="logo" style={{width:"100%",height:150}} />
            </a> */}
            {/* <div className="home-bar">
              <a href="https://mfs.kfintech.com/mfs/" target="_blank">
                Home
              </a>

              <a href="https://www.kfintech.com/about-us/" target="_blank">
                About
              </a>
              <div className="client-services">
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: 14,
                    fontFamily: "SourceSansPro-Regular",
                    padding: "0px 10px",
                    marginBottom: 3,
                    color: "#00579b",
                  }}
                >
                  Client Services
                </a>
                <div className="dropdown-client-services">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginRight: 25,
                    }}
                  >
                    <div
                      className="arrow-up"
                      style={{
                        borderLeft: "10px solid transparent",
                        borderRight: "10px solid transparent",
                        borderBottom: "10px solid #047cc7",
                        width: 0,
                        height: 0,
                      }}
                    />
                  </div>
                  <div className="client-services-a">
                    {ServicesArray.map((ele, index) => (
                      <a
                        href={ele.link}
                        key={index}
                        target="_blank"
                        style={{ padding: 10 }}
                      >
                        {ele.title}
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              <a href="https://www.kfintech.com/contact-us/" target="_blank">
                Contact us
              </a>
              <button onClick={this.openModal}>Non Login Services</button>
            </div> */}
          </Toolbar>
          {/* <NonLoginServicesDialog
            open={this.state.open}
            closeModal={() => this.closeModal()}
          /> */}
        </AppBar>
      </div>
    );
  }
}
