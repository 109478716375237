import React, { Component } from "react";
import TransactionTypeCards from "./TransactionTypeCards";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import {
  setSipDetails,
  setSwitchDetails,
  setStpDetails,
} from "shared/store/actions";
import {
  PHYSICAL_NEW,
  PHYSICAL_ADD,
  PHYSICAL_RED,
  PHYSICAL_SWITCH,
  PHYSICAL_SIP,
} from "shared/store/types";
import { BUILD_URL } from "../home";
import {
  NEW_PURCHASE_ICON,
  SIP_ICON,
  ADDITIONAL_PURCHASE_ICON,
  REDEMPTION_ICON,
  SWITCH_ICON,
  STP_ICON,
  SWP_ICON,
  SIP_PAUSE_ICON,
  ENACH_ICON,
} from "../../forms/appEssentials";
class TransactionType extends Component {
  componentDidMount() {
    const payload = {};
    this.props.setSipDetails(payload);
    this.props.setSwitchDetails(payload);
    this.props.setStpDetails(payload);
  }

  render() {
    const { showAll } = this.props;
    return (
      <div className="container-margin">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "30px 20px 10px ",
          }}
        >
          <div className="transaction-type-title">Select Transaction Type</div>
          <div className="physical-digital-modes">
            {/* <button className="know-about-physical-digital-button">
              Know about Digital Mode
            </button> */}
            <div className="descripton-about-physical-digital-mode">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: 25,
                }}
              >
                <div
                  className="arrow-up"
                  style={{
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderBottom: "10px solid #047cc7",
                    width: 0,
                    height: 0,
                  }}
                />
              </div>
              <Paper
                style={{
                  width: 350,
                  padding: "20px 30px",
                  borderRadius: 10,
                  backgroundColor: "#047cc7",
                  color: "white",
                }}
              >
                {/* <div
                  style={{
                    fontFamily: "SourceSansPro-SemiBold",
                    fontSize: 15,
                    padding: "10px 0px",
                  }}
                >
                  Phygital Mode
                </div> */}
                {/* <div style={{ fontFamily: "SourceSansPro-Regular", fontSize: 14 }}>
                  Please Follow The Below Steps
                </div> */}
                {/* <div className="transaction-mode-img-div">
                  <div>
                    <img
                      src={BUILD_URL + "/images/stages1.png"}
                      style={{
                        width: 8,
                        height: 80,
                        margin: "5px 0px 3px 0px",
                      }}
                      alt="stages"
                    />
                  </div>
                  <div className="transaction-mode-physical-upload-div">
                    <div className="transaction-mode-physical-upload">
                      Upload Order form
                    </div>
                    <div className="transaction-mode-physical-upload">
                      Upload Cheque
                    </div>
                    <div className="transaction-mode-physical-upload">
                      Sumbit Cheque at nearest Karvy Branch
                    </div>
                  </div>
                </div> */}

                <div
                  style={{
                    fontFamily: "SourceSansPro-SemiBold",
                    fontSize: 15,
                    padding: "10px 0px",
                  }}
                >
                  Digital Mode
                </div>
                <div
                  style={{
                    fontFamily: "SourceSansPro-Regular",
                    fontSize: 14,
                    margin: "10px 0px",
                  }}
                >
                  Digital mode lets you do all the transactions digitally
                  without any effort to physically submit the transactions of
                  your Investors.
                </div>
              </Paper>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 100 }}>
          <TransactionTypeCards
            image={NEW_PURCHASE_ICON}
            name="New Folio Purchase"
            history={this.props.history}
            pan={
              !!this.props.location.state && !!this.props.location.state.pan
                ? this.props.location.state.pan
                : ""
            }
            navigationProps={{
              physical:
                `${process.env.PUBLIC_URL}/investors` +
                "/nfo-new-purchase/physical/transact",
              type: PHYSICAL_NEW,
            }}
          />
          {!!showAll && (
            <React.Fragment>
              <TransactionTypeCards
                image={ADDITIONAL_PURCHASE_ICON}
                name="Additional Purchase"
                history={this.props.history}
                navigationProps={{
                  physical:
                    `${process.env.PUBLIC_URL}/investors` +
                    "/additional-purchase/physical/transact",
                  type: PHYSICAL_ADD,
                  flag: "pSchFlg",
                }}
              />
              <TransactionTypeCards
                image={REDEMPTION_ICON}
                name="Redemption"
                history={this.props.history}
                navigationProps={{
                  physical:
                    `${process.env.PUBLIC_URL}/investors` +
                    "/redemption/physical/transact",
                  type: PHYSICAL_RED,
                  flag: "rSchFlg",
                }}
              />
            </React.Fragment>
          )}

          <TransactionTypeCards
            image={SIP_ICON}
            pan={
              !!this.props.location.state && !!this.props.location.state.pan
                ? this.props.location.state.pan
                : ""
            }
            name="SIP Registration"
            history={this.props.history}
            navigationProps={{
              physical:
                `${process.env.PUBLIC_URL}/investors` +
                "/sip/physical/transact",
              type: PHYSICAL_SIP,
            }}
          />
          {!!showAll && (
            <React.Fragment>
              <TransactionTypeCards
                image={STP_ICON}
                name="STP Registration"
                history={this.props.history}
              />

              <TransactionTypeCards
                image={SWP_ICON}
                name="SWP Registration"
                history={this.props.history}
              />
            </React.Fragment>
          )}

          {!!showAll && (
            <React.Fragment>
              <TransactionTypeCards
                image={SWITCH_ICON}
                name="Switch"
                history={this.props.history}
                navigationProps={{
                  physical:
                    `${process.env.PUBLIC_URL}/investors` +
                    "/switch/physical/transact",
                  type: PHYSICAL_SWITCH,
                  flag: "sSchFlg",
                }}
              />
              <TransactionTypeCards
                image={SIP_PAUSE_ICON}
                name="SIP Pause/Cancellation"
                history={this.props.history}
              />
              {/*
              <TransactionTypeCards
                image={"/images/nct_icon.svg"}
                name="NCT"
                history={this.props.history}
              /> */}
            </React.Fragment>
          )}
          <TransactionTypeCards
            image={ENACH_ICON}
            pan={
              !!this.props.location.state && !!this.props.location.state.pan
                ? this.props.location.state.pan
                : ""
            }
            name="eNACH"
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ authReducer }) => {
    const { showAll } = authReducer;
    return { showAll };
  },
  { setSipDetails, setSwitchDetails, setStpDetails }
)(TransactionType);
